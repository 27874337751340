import React, { useEffect, useState } from "react";
import "./LocalCouchBattler.css";
import PlayerBoard from "./PlayerBoard";

const LocalCouchBattler = () => {
  const [numPlayers, setNumPlayers] = useState(1);
  const [playerActions, setPlayerActions] = useState({
    player1: null,
    player2: null,
    player3: null,
    player4: null,
  });

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const entry = entries[0];
      const contentRect = entry.contentRect;

      const scaleFactor = Math.min(
        contentRect.width / 1536,
        contentRect.height / 768
      );

      document.querySelector(
        ".responsive-couch-div"
      ).style.transform = `scale(${scaleFactor})`;
    });

    const responsiveDiv = document.querySelector(".responsive-couch-div");
    resizeObserver.observe(responsiveDiv);

    const handleKeyDown = event => {
      switch (event.key) {
        case "w":
        case "W":
        case "a":
        case "A":
        case "s":
        case "S":
        case "d":
        case "D":
        case "q":
        case "Q":
          handlePlayerAction("player1", event.key.toUpperCase());
          break;
        case "y":
        case "y":
        case "g":
        case "G":
        case "h":
        case "H":
        case "j":
        case "J":
        case "t":
        case "T":
        case "u":
        case "U":
          handlePlayerAction("player2", event.key.toUpperCase());
          break;
        case "p":
        case "P":
        case "l":
        case "L":
        case "o":
        case "O":
        case ";":
        case ":":
        case "'":
        case '"':
        case "[":
        case "{":
          handlePlayerAction("player3", event.key.toUpperCase());
          break;
        case "ArrowUp":
        case "ArrowLeft":
        case "ArrowDown":
        case "ArrowRight":
        case "PageUp":
        case "PageDown":
          handlePlayerAction("player4", event.key);
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      resizeObserver.unobserve(responsiveDiv);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handlePlayerAction = (player, action) => {
    setPlayerActions(prevActions => ({
      ...prevActions,
      [player]: action,
    }));

    setTimeout(() => {
      setPlayerActions(prevActions => ({
        ...prevActions,
        [player]: null,
      }));
    }, 10);
  };

  const handleAddPlayer = () => {
    setNumPlayers(Math.min(numPlayers + 1, 4));
  };

  const handleRemovePlayer = () => {
    setNumPlayers(Math.max(numPlayers - 1, 1));
  };

  return (
    <div className="couch-container">
      <div className="responsive-couch-div">
        <div className="couch-game-container">
          {[...Array(4)].map((_, index) => (
            <React.Fragment key={index}>
              {index < numPlayers ? (
                <PlayerBoard
                  playerNumber={index + 1}
                  playerActions={playerActions[`player${index + 1}`]}
                />
              ) : (
                <div className="empty-slot"></div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="controls">
          <button onClick={handleAddPlayer}>Add Player</button>
          <button onClick={handleRemovePlayer}>Remove Player</button>
        </div>
      </div>
    </div>
  );
};

export default LocalCouchBattler;
