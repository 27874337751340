import Phaser from "phaser";
import {
    ws,
    initWebSocket,
    setUsernameInGlobal,
} from "../CardMonsters/CardMonstersGlobal";
import CardMonstersMenuScene from "./CardMonstersMenuScene";

class CardMonstersLoadingScreen extends Phaser.Scene {
    constructor(user) {
        super({ key: "CardMonstersLoadingScreen" });
        this.user = user.username;
    }

    init() {
        this.setUsernamePayload;
        this.playerId = null;

        this.ws = null;

        initWebSocket(this.user);
        setUsernameInGlobal(this.user);
    }

    preload() {
        // Load assets needed for the loading screen
    }

    create() {
        // Create the loading screen text
        this.add
            .text(
                this.cameras.main.centerX,
                this.cameras.main.centerY,
                "Card Monsters",
                { fontSize: "32px", fill: "#fff" }
            )
            .setOrigin(0.5);

        this.time.delayedCall(500, () => {
            this.cameras.main.fadeOut(500);
            this.time.delayedCall(500, () => {
                this.scene.start("CardMonstersMenuScene");
            });
        });
    }
}

export default CardMonstersLoadingScreen;
