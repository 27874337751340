import React, { useEffect, useState } from "react";

function DeckBuilder({ ws, cardDatabase }) {
  const [decks, setDecks] = useState([]);
  const [activeDeck, setActiveDeck] = useState(null);

  const [selectedDeckIndex, setSelectedDeckIndex] = useState(-1);
  const [tempName, setTempName] = useState("");
  const [tempCards, setTempCards] = useState({});
  const [message, setMessage] = useState("");

  const [hoveredDeck, setHoveredDeck] = useState(null);
  const [hoveredAddCard, setHoveredAddCard] = useState(null);
  const [hoveredCardItem, setHoveredCardItem] = useState(null);

  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [filterNature, setFilterNature] = useState(false);
  const [filterVanguard, setFilterVanguard] = useState(false);
  const [filterMechanical, setFilterMechanical] = useState(false);
  const [minCost, setMinCost] = useState("");
  const [maxCost, setMaxCost] = useState("");

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!ws) return;

    ws.send(
      JSON.stringify({
        type: "deck",
        action: "getAll",
      })
    );

    ws.addEventListener("message", handleDeckMessages);

    return () => {
      ws.removeEventListener("message", handleDeckMessages);
    };
  }, [ws]);

  const handleOpenModal = cardType => {
    const cardDetails = cardDatabase[cardType];
    setSelectedCard({ type: cardType, ...cardDetails });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCard(null);
    setIsModalOpen(false);
  };

  const handleAddCardFromModal = () => {
    if (selectedCard) {
      handleAddCard(selectedCard.type, 1);
      handleCloseModal();
    }
  };

  const handleDeckMessages = event => {
    let data;
    try {
      data = JSON.parse(event.data);
    } catch (err) {
      return;
    }
    if (data.type !== "deck") return;

    switch (data.action) {
      case "getAll":
        setDecks(data.decks || []);
        setActiveDeck(data.activeDeck || 0);
        setMessage("Loaded decks from server.");
        break;

      case "saveSuccess":
        setDecks(data.decks || []);
        setMessage("Deck saved successfully!");
        break;

      case "setActiveSuccess":
        setActiveDeck(data.activeDeck);
        setMessage("Active deck set!");
        break;

      case "error":
        setMessage("Error: " + data.message);
        break;

      default:
        break;
    }
  };

  const handleSelectDeck = idx => {
    setSelectedDeckIndex(idx);
    if (idx >= 0 && idx < decks.length) {
      const d = decks[idx];
      setTempName(d.name);
      setTempCards({ ...d.cards });
    }
    setMessage("");
  };

  const handleCreateNewDeck = () => {
    const shouldCreate = window.confirm(
      "This will create a new deck and discard any unsaved changes. Do you want to continue?"
    );

    if (shouldCreate) {
      setSelectedDeckIndex(-1);
      setTempName("");
      setTempCards({});
      setMessage("");
    }
  };

  const handleAddCard = (cardType, quantity = 1) => {
    if (!quantity || quantity <= 0) return;
    setTempCards(prev => ({
      ...prev,
      [cardType]: (prev[cardType] || 0) + quantity,
    }));
  };

  const handleIncrementCard = cardType => {
    handleAddCard(cardType, 1);
  };

  const handleDecrementCard = cardType => {
    setTempCards(prev => {
      const currentQty = prev[cardType] || 0;
      if (currentQty <= 1) {
        const newCards = { ...prev };
        delete newCards[cardType];
        return newCards;
      }
      return {
        ...prev,
        [cardType]: currentQty - 1,
      };
    });
  };

  const handleSaveDeck = () => {
    if (!ws) return;

    const index = selectedDeckIndex === -1 ? -1 : selectedDeckIndex;
    const deckToSave = {
      name: tempName,
      cards: tempCards,
    };

    ws.send(
      JSON.stringify({
        type: "deck",
        action: "save",
        index,
        deck: deckToSave,
      })
    );
  };

  const handleSetActive = idx => {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "deck",
        action: "setActive",
        index: idx,
      })
    );
  };

  const filteredCardTypes = cardDatabase
    ? Object.keys(cardDatabase)
        .filter(cardType => cardDatabase[cardType].canAddToDeck)
        .filter(cardType => {
          const card = cardDatabase[cardType];

          if (searchTerm) {
            const cardName = cardType.replace(/_/g, " ").toLowerCase();
            if (!cardName.includes(searchTerm.toLowerCase())) {
              return false;
            }
          }

          const atLeastOneFilterActive =
            filterNature || filterVanguard || filterMechanical;
          if (atLeastOneFilterActive) {
            const resourceType = card.resourceType;
            const matchNature = filterNature && resourceType === "nature";
            const matchVanguard = filterVanguard && resourceType === "vanguard";
            const matchMechanical =
              filterMechanical && resourceType === "mechanical";

            if (!matchNature && !matchVanguard && !matchMechanical) {
              return false;
            }
          }

          const cost = card.cost ?? 0;

          if (minCost !== "" && !isNaN(Number(minCost))) {
            if (cost < Number(minCost)) {
              return false;
            }
          }

          if (maxCost !== "" && !isNaN(Number(maxCost))) {
            if (cost > Number(maxCost)) {
              return false;
            }
          }

          return true;
        })
    : [];

  const styles = {
    deckBuilderContainer: {
      width: "90%",
      border: "1px solid #ccc",
      padding: "1rem",
      margin: "1rem auto",
      overflowY: "auto",
      borderRadius: "8px",
      backgroundColor: "#f9f9f9",
      color: "#000",
      fontFamily: "Arial, sans-serif",
    },
    header: {
      color: "#000",
      marginBottom: "0.5rem",
    },
    message: {
      color: "red",
      marginBottom: "1rem",
    },
    deckListSection: {
      marginBottom: "1rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    deckList: {
      border: "1px solid #ccc",
      padding: "0.5rem",
      marginBottom: "0.5rem",
      maxHeight: "150px",
      overflowY: "auto",
      borderRadius: "4px",
      backgroundColor: "#fff",
      width: "60%",
      color: "#000",
    },
    deckItem: {
      cursor: "pointer",
      padding: "0.5rem",
      borderRadius: "4px",
      marginBottom: "0.2rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "#000",
      transition: "background-color 0.3s",
    },
    checkmark: {
      color: "green",
      fontWeight: "bold",
      marginLeft: "0.5rem",
    },
    createButton: {
      padding: "0.5rem 1rem",
      backgroundColor: "#4CAF50",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      height: "fit-content",
      transition: "background-color 0.3s",
    },
    deckEditor: {
      border: "1px solid #ccc",
      padding: "0.5rem",
      borderRadius: "4px",
      backgroundColor: "#fff",
      color: "#000",
    },
    subHeader: {
      color: "#000",
      marginBottom: "0.5rem",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      marginBottom: "0.5rem",
    },
    label: {
      marginRight: "0.5rem",
      color: "#000",
      width: "50px",
    },
    input: {
      flex: "1",
      padding: "0.3rem",
      border: "1px solid #ccc",
      borderRadius: "4px",
      color: "#000",
    },
    cardList: {
      listStyleType: "none",
      padding: 0,
      marginTop: "0.5rem",
      color: "#000",
      maxHeight: "300px",
      overflowY: "auto",
      border: "1px solid #ddd",
      padding: "0.5rem",
      borderRadius: "4px",
      backgroundColor: "#fff",
    },
    cardItem: {
      marginBottom: "0.5rem",
      display: "flex",
      alignItems: "center",
      color: "#000",
    },
    cardName: {
      color: "#000",
    },
    cardButtons: {
      display: "flex",
      alignItems: "center",
      gap: "0.3rem",
    },
    incrementButton: {
      backgroundColor: "#4CAF50",
      color: "#fff",
      width: "30px",
      height: "30px",
      fontSize: "1rem",
      textAlign: "center",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    decrementButton: {
      backgroundColor: "#f44336",
      color: "#fff",
      width: "30px",
      height: "30px",
      fontSize: "1rem",
      textAlign: "center",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s",
      marginRight: "1rem",
    },
    cardQuantity: {
      minWidth: "1.5rem",
      textAlign: "center",
    },
    cardOptions: {
      marginTop: "1rem",
      color: "#000",
    },
    subSubHeader: {
      color: "#000",
      marginBottom: "0.5rem",
    },
    addCardsContainer: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      maxHeight: "200px",
      gap: "0.5rem",
      marginTop: "1rem",
      border: "1px solid #ddd",
      padding: "0.5rem",
      borderRadius: "4px",
      backgroundColor: "#fff",
    },
    addCardButton: {
      padding: "0.5rem",
      backgroundColor: "#008CBA",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "1rem",
      transition: "background-color 0.3s",
      width: isMobile ? "100%" : "auto",
    },
    saveButton: {
      marginTop: "1rem",
      padding: "0.5rem 1rem",
      backgroundColor: "#4CAF50",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    setActiveButton: {
      marginTop: "1rem",
      marginLeft: "0.5rem",
      padding: "0.5rem 1rem",
      backgroundColor: "#2196F3",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    filterToggles: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "flex-start" : "center",
      marginRight: isMobile ? "0" : "2rem",
      flexWrap: "wrap",
      marginBottom: isMobile ? "1rem" : "0",
    },
    costFilters: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "flex-start" : "center",
      gap: isMobile ? "0.5rem" : "1rem",
      flexShrink: 0,
      width: isMobile ? "100%" : "auto",
    },
    costFilterGroup: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "flex-start" : "center",
      marginBottom: isMobile ? "0.5rem" : "0",
    },
    costLabel: {
      marginRight: isMobile ? "0" : "0.5rem",
      marginBottom: isMobile ? "0.3rem" : "0",
      color: "#000",
      width: isMobile ? "auto" : "100px",
      fontWeight: "bold",
      textAlign: isMobile ? "left" : "right",
    },
    costInput: {
      padding: "0.3rem",
      border: "1px solid #ccc",
      borderRadius: "4px",
      color: "#000",
      width: isMobile ? "100%" : "80px",
    },
    factionsLabel: {
      marginRight: "10px",
      fontWeight: "bold",
      color: "#000",
    },
    toggleLabel: {
      marginRight: isMobile ? "0" : "15px",
      marginBottom: isMobile ? "0.5rem" : "0",
      display: "flex",
      alignItems: "center",
      color: "#000",
    },
    allFilters: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "flex-start" : "center",
      justifyContent: isMobile ? "flex-start" : "space-between",
      flexWrap: "wrap",
      marginBottom: "1rem",
    },
    filterGroup: {
      display: "flex",
      alignItems: "center",
      flex: "1",
      minWidth: "0",
    },
    searchInput: {
      flex: isMobile ? "1 1 100%" : "0 1 300px",
      padding: "0.3rem",
      border: "1px solid #ccc",
      borderRadius: "4px",
      color: "#000",
      width: isMobile ? "100%" : "300px",
      boxSizing: "border-box",
    },

    searchContainer: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "flex-start" : "center",
      width: "100%",
    },

    searchLabel: {
      marginRight: "0",
      marginBottom: isMobile ? "0.3rem" : "0",
      color: "#000",
      fontWeight: "bold",
      width: isMobile ? "auto" : "150px",
      textAlign: "left",
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    modalContent: {
      backgroundColor: "#fff",
      padding: "2rem",
      borderRadius: "8px",
      width: "80%",
      maxWidth: "500px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.26)",
      position: "relative",
    },
    modalHeader: {
      marginBottom: "1rem",
      textAlign: "center",
    },
    cardDetailsList: {
      listStyleType: "none",
      padding: 0,
      marginBottom: "1.5rem",
    },
    cardDetailsListItem: {
      marginBottom: "0.5rem",
    },
    modalButtons: {
      display: "flex",
      justifyContent: "space-between",
    },
    addToDeckButton: {
      padding: "0.5rem 1rem",
      backgroundColor: "#4CAF50",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    closeModalButton: {
      padding: "0.5rem 1rem",
      backgroundColor: "#f44336",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    cardImage: {
      width: "50%",
      height: "auto",
      borderRadius: "4px",
      marginBottom: "1rem",
    },
  };

  return (
    <div style={styles.deckBuilderContainer}>
      <h3 style={styles.header}>Deck Builder</h3>
      <p style={styles.message}>{message}</p>

      <div style={styles.deckListSection}>
        <div style={styles.deckList}>
          <h4 style={styles.subHeader}>Your Decks</h4>
          {decks.map((deck, idx) => {
            const isActive = idx === activeDeck;
            const isSelected = idx === selectedDeckIndex;
            const isHovered = hoveredDeck === idx;

            return (
              <div
                key={idx}
                style={{
                  ...styles.deckItem,
                  backgroundColor: isSelected
                    ? "#bde"
                    : isHovered
                    ? "#e0f7fa"
                    : "inherit",
                }}
                onClick={() => handleSelectDeck(idx)}
                onMouseEnter={() => setHoveredDeck(idx)}
                onMouseLeave={() => setHoveredDeck(null)}
                tabIndex={0}
                onKeyPress={e => {
                  if (e.key === "Enter") handleSelectDeck(idx);
                }}
                aria-label={`Deck ${deck.name} with ${Object.values(
                  deck.cards
                ).reduce((a, b) => a + b, 0)} cards`}>
                <span>
                  {deck.name} (
                  {Object.values(deck.cards).reduce((a, b) => a + b, 0)} cards)
                </span>
                {isActive && (
                  <span
                    style={styles.checkmark}
                    title="Active Deck">
                    ✓
                  </span>
                )}
              </div>
            );
          })}
        </div>

        <button
          style={styles.createButton}
          onClick={handleCreateNewDeck}
          onMouseEnter={() => setHoveredAddCard("create")}
          onMouseLeave={() => setHoveredAddCard(null)}>
          Create New Deck
        </button>
      </div>

      <div style={styles.deckEditor}>
        <h4 style={styles.subHeader}>
          {selectedDeckIndex === -1 ? "New Deck" : "Edit Deck"}
        </h4>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Name:</label>
          <input
            type="text"
            value={tempName}
            onChange={e => setTempName(e.target.value)}
            style={styles.input}
            aria-label="Deck Name"
          />
        </div>

        <div style={{ marginTop: "1rem" }}>
          <label style={styles.label}>Cards:</label>
          <div style={styles.cardList}>
            {Object.entries(tempCards).map(([cardType, qty]) => {
              const isHovered = hoveredCardItem === cardType;

              return (
                <div
                  key={cardType}
                  style={{
                    ...styles.cardItem,
                    backgroundColor: isHovered ? "#e0f7fa" : "inherit",
                  }}
                  onMouseEnter={() => setHoveredCardItem(cardType)}
                  onMouseLeave={() => setHoveredCardItem(null)}>
                  <div style={styles.cardButtons}>
                    <button
                      onClick={() => handleIncrementCard(cardType)}
                      style={styles.incrementButton}
                      aria-label={`Increase quantity of ${cardType.replace(
                        /_/g,
                        " "
                      )}`}>
                      +
                    </button>
                    <span style={styles.cardQuantity}> {qty} </span>
                    <button
                      onClick={() => handleDecrementCard(cardType)}
                      style={styles.decrementButton}
                      aria-label={`Decrease quantity of ${cardType.replace(
                        /_/g,
                        " "
                      )}`}>
                      -
                    </button>
                    <strong style={styles.cardName}>
                      {cardType.replace(/_/g, " ")}
                    </strong>
                  </div>
                </div>
              );
            })}
          </div>

          <div style={styles.cardOptions}>
            <h5 style={styles.subSubHeader}>Library</h5>

            <div style={{ marginBottom: "0.5rem" }}>
              <div style={styles.searchContainer}>
                <label
                  htmlFor="cardSearch"
                  style={styles.searchLabel}>
                  Search Cards:
                </label>
                <input
                  id="cardSearch"
                  type="text"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  style={styles.searchInput}
                  placeholder="Type card name..."
                  aria-label="Card Search"
                />
              </div>
            </div>

            <div>
              {/* All Filters Group */}
              <div style={styles.allFilters}>
                {/* Factions Filters */}
                <div style={styles.filterToggles}>
                  <span style={styles.factionsLabel}>Factions:</span>

                  <label style={styles.toggleLabel}>
                    <input
                      type="checkbox"
                      checked={filterNature}
                      onChange={() => setFilterNature(!filterNature)}
                    />
                    Nature
                  </label>

                  <label style={styles.toggleLabel}>
                    <input
                      type="checkbox"
                      checked={filterVanguard}
                      onChange={() => setFilterVanguard(!filterVanguard)}
                    />
                    Vanguard
                  </label>

                  <label style={styles.toggleLabel}>
                    <input
                      type="checkbox"
                      checked={filterMechanical}
                      onChange={() => setFilterMechanical(!filterMechanical)}
                    />
                    Mechanical
                  </label>
                </div>

                {/* Cost Filters */}
                <div style={styles.costFilters}>
                  {/* Min Cost Filter Group */}
                  <div style={styles.costFilterGroup}>
                    <label
                      htmlFor="minCost"
                      style={styles.costLabel}>
                      Min Cost:
                    </label>
                    <input
                      id="minCost"
                      type="number"
                      value={minCost}
                      onChange={e => setMinCost(e.target.value)}
                      style={styles.costInput}
                      placeholder="Min Cost"
                    />
                  </div>

                  {/* Max Cost Filter Group */}
                  <div style={styles.costFilterGroup}>
                    <label
                      htmlFor="maxCost"
                      style={styles.costLabel}>
                      Max Cost:
                    </label>
                    <input
                      id="maxCost"
                      type="number"
                      value={maxCost}
                      onChange={e => setMaxCost(e.target.value)}
                      style={styles.costInput}
                      placeholder="Max Cost"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={styles.addCardsContainer}>
            {filteredCardTypes.map(cardType => {
              const isHovered = hoveredAddCard === cardType;

              return (
                <button
                  key={cardType}
                  onClick={() => handleOpenModal(cardType)}
                  style={{
                    ...styles.addCardButton,
                    backgroundColor: isHovered
                      ? "#007bb5"
                      : styles.addCardButton.backgroundColor,
                  }}
                  onMouseEnter={() => setHoveredAddCard(cardType)}
                  onMouseLeave={() => setHoveredAddCard(null)}
                  aria-label={`View details of ${cardType.replace(/_/g, " ")}`}>
                  {cardType.replace(/_/g, " ")}
                </button>
              );
            })}
          </div>
        </div>

        <button
          onClick={handleSaveDeck}
          style={styles.saveButton}>
          Save Deck
        </button>

        {selectedDeckIndex >= 0 && (
          <button
            onClick={() => handleSetActive(selectedDeckIndex)}
            style={styles.setActiveButton}
            onMouseEnter={() => setHoveredAddCard("setActive")}
            onMouseLeave={() => setHoveredAddCard(null)}>
            Set as Active
          </button>
        )}
      </div>

      {isModalOpen && selectedCard && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h4 style={styles.modalHeader}>{selectedCard.name}</h4>
            <img
              src={selectedCard.image}
              alt={selectedCard.name}
              style={styles.cardImage}
            />
            <ul style={styles.cardDetailsList}>
              <li>
                <strong>Cost:</strong> {selectedCard.cost}
              </li>
              <li>
                <strong>Resource Type:</strong> {selectedCard.resourceType}
              </li>
              <li>
                <strong>Maximum Health:</strong> {selectedCard.maximumHealth}
              </li>
              <li>
                <strong>Maximum Attack:</strong> {selectedCard.maximumAttack}
              </li>
              <li>
                <strong>Maximum Movement:</strong>{" "}
                {selectedCard.maximumMovement}
              </li>
              <li>
                <strong>Maximum Cooldown:</strong>{" "}
                {selectedCard.maximumCooldown}
              </li>
              <li>
                <strong>Can Attack:</strong>{" "}
                {selectedCard.canAttack ? "Yes" : "No"}
              </li>
              {selectedCard.effectDescription && (
                <li>
                  <strong>Effect:</strong> {selectedCard.effectDescription}
                </li>
              )}
              <li>
                <strong>Flavor Text:</strong> {selectedCard.flavorText} <br />
              </li>
            </ul>
            <div style={styles.modalButtons}>
              <button
                onClick={handleAddCardFromModal}
                style={styles.addToDeckButton}
                aria-label={`Add ${selectedCard.name} to deck`}>
                Add to Deck
              </button>
              <button
                onClick={handleCloseModal}
                style={styles.closeModalButton}
                aria-label="Close card details">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeckBuilder;
