import React, { useEffect } from "react";
import Phaser from "phaser";
import CollisionTestScene from "./CollisionTestScene";

const CollisionTest = () => {
  useEffect(() => {
    const config = {
      type: Phaser.AUTO,
      width: 360,
      height: 600,
      physics: {
        default: "arcade",
      },
      scene: [new CollisionTestScene()],
    };

    const game = new Phaser.Game(config);

    return () => {
      game.destroy(true);
    };
  }, []);

  return (
    <div
      id="phaser-game"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default CollisionTest;
