/*
    TO-DO
    - Add a button to load a character instead
**/

import Phaser from "phaser";
import {
  ws,
  globalUsername,
  setCharacterNameInGlobal,
} from "../CardMonsters/CardMonstersGlobal";
import CardMonstersIntroScene from "./CardMonstersIntroScene";

class CardMonstersMenuScene extends Phaser.Scene {
  constructor() {
    super({ key: "CardMonstersMenuScene" });
  }

  init() {
    this.username = globalUsername;
    this.ws = ws;
  }

  preload() {}

  create() {
    let fullscreenButton = this.add
      .text(10, 10, "[<->]", { fill: "#fff" })
      .setInteractive()
      .on("pointerdown", () => {
        if (this.scale.isFullscreen) {
          this.scale.stopFullscreen();
        } else {
          this.scale.startFullscreen();
        }
      });

    this.characterCreationText = this.add
      .text(
        this.cameras.main.centerX,
        this.cameras.main.centerY,
        "Create a New Character",
        { fill: "#fff" }
      )
      .setOrigin(0.5)
      .setInteractive()
      .on("pointerdown", () => {
        this.hideCharacterCreationButton();
        this.showNamePrompt();
      });

    this.characterNamePromptText = this.add
      .text(
        this.cameras.main.centerX,
        this.cameras.main.centerY,
        "Type Character Name:",
        { fill: "#fff" }
      )
      .setOrigin(0.5)
      .setVisible(false);

    this.namePromptText = this.add
      .text(
        this.cameras.main.centerX,
        this.cameras.main.centerY + 100,
        "Type Character Name:",
        { fill: "#fff" }
      )
      .setVisible(false)
      .setOrigin(0.5);

    let graphics = this.add.graphics();
    graphics.fillStyle(0xffffff, 1);

    let rectWidth = this.cameras.main.width * 0.8;
    let rectHeight = this.cameras.main.height * 0.1;
    let rectX = (this.cameras.main.width - rectWidth) * 0.5;
    let rectY = this.cameras.main.height * 0.55;

    let textY = rectY + rectHeight * 0.5;

    graphics.fillRect(rectX, rectY, rectWidth, rectHeight);
    this.graphics = graphics.setVisible(false);

    this.nameInputField = this.add
      .text(this.cameras.main.centerX, textY, "", {
        fill: "#000",
        font: "16px",
        backgroundColor: null,
      })
      .setOrigin(0.5)
      .setVisible(false);

    this.input.keyboard.on(
      "keydown",
      function (event) {
        if (/^[a-zA-Z0-9]$/.test(event.key)) {
          if (this.nameInputField.text.length < 28) {
            this.nameInputField.text += event.key;
          }
        } else if (event.key === "Backspace") {
          this.nameInputField.text = this.nameInputField.text.slice(0, -1);
        } else if (event.key === "Enter" || event.key === "Return") {
          this.submitCharacterName();
        }
      },
      this
    );

    this.add
      .text(
        this.cameras.main.centerX,
        this.cameras.main.centerY * 0.1,
        `Hello, ${globalUsername}`,
        {
          fontSize: "32px",
          fill: "#fff",
        }
      )
      .setOrigin(0.5);

    this.startGameButton = this.add
      .text(this.cameras.main.centerX, textY + rectHeight + 20, "Start Game", {
        fill: "#fff",
      })
      .setVisible(false)
      .setOrigin(0.5)
      .setInteractive()
      .on("pointerdown", () => {
        this.submitCharacterName();
      });

    this.ws.onmessage = event => {
      const data = JSON.parse(event.data);
      if (data.type === "characterNameCheck") {
        if (data.exists) {
          alert("Character name is already used.");
        } else {
          setCharacterNameInGlobal(this.nameInputField.text); // Set the global character name
          this.scene.start("CardMonstersIntroScene");
        }
      }
    };
  }

  hideCharacterCreationButton() {
    this.characterCreationText.setVisible(false);
  }

  showNamePrompt() {
    this.namePromptText.setVisible(true);
    this.graphics.setVisible(true);
    this.nameInputField.setVisible(true);
    this.nameInputField.setText("");
    this.characterNamePromptText.setVisible(true);
    this.startGameButton.setVisible(true);
  }

  checkCharacterName(name) {
    this.ws.send(
      JSON.stringify({
        type: "checkCharacterName",
        name,
        username: this.username,
      })
    );
  }

  submitCharacterName() {
    if (this.nameInputField.text.length > 0) {
      this.checkCharacterName(this.nameInputField.text);
    } else {
      alert("Character name must have at least 1 character.");
    }
  }

  selectText(element) {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);
  }
}

export default CardMonstersMenuScene;
