import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Phaser from "phaser";
import HexoseScene from "./HexoseScene";

import RulerGoldWoff from "./assets/RulerGold.woff";
import RulerGoldTtf from "./assets/RulerGold.ttf";

const Hexose = () => {
  const user = useSelector(store => store.user);

  useEffect(() => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
      @font-face {
        font-family: 'RulerGold';
        src: url(${RulerGoldWoff}) format('woff'),
             url(${RulerGoldTtf}) format('truetype');
        font-weight: normal;
        font-style: normal;
      }
    `;
    document.head.appendChild(style);

    document.fonts
      .load("1em RulerGold")
      .then(() => {
        const hexoseScene = new HexoseScene(user);
        const isMobile =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          );

        const config = {
          type: Phaser.AUTO,
          scale: {
            mode: Phaser.Scale.FIT,
            autoCenter: Phaser.Scale.CENTER_BOTH,
            width: isMobile ? 400 : 1650,
            height: 700,
            resolution: window.devicePixelRatio || 1,
          },
          render: {
            pixelArt: false,
            antialias: false,
          },
          physics: {
            default: "arcade",
          },
          scene: [hexoseScene],
        };
        const game = new Phaser.Game(config);

        return () => {
          if (HexoseScene.shutdown) {
            HexoseScene.shutdown();
            HexoseScene.destroy();
          }
          game.destroy(true);
          document.head.removeChild(style);
        };
      })
      .catch(error => {
        console.error("Failed to load fonts:", error);
      });
  }, [user]);

  return (
    <div
      id="phaser-game"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default Hexose;
