// CardMonstersGlobal.js
let ws;
let globalUsername = null;
let globalCharacterName = null; // Add this line

function initWebSocket(username) {
  let environment;
  if (window.location.href.includes("localhost")) {
    environment = "localhost";
  } else if (window.location.href.includes("192.168.0")) {
    environment = "localNetwork";
  } else if (window.location.href.includes("2nguyen.dev")) {
    environment = "production";
  }

  switch (environment) {
    case "localhost":
      ws = new WebSocket("ws://localhost:5000/card-monsters");
      break;
    case "localNetwork":
      ws = new WebSocket("ws://192.168.0.3:5000/card-monsters");
      break;
    case "production":
      ws = new WebSocket("wss://2nguyen.dev/card-monsters");
      break;
    default:
      console.log("Environment not recognized. WebSocket not established.");
      break;
  }

  ws.onopen = () => {
    console.log("WebSocket connection established");
    ws.send(JSON.stringify({ type: "connect", username }));
  };

  ws.onmessage = event => {
    const data = JSON.parse(event.data);
    console.log("Message from server:", data);
  };

  ws.onclose = () => {
    console.log("WebSocket connection closed");
  };

  ws.onerror = error => {
    console.error("WebSocket error:", error);
  };
}

function setUsernameInGlobal(username) {
  globalUsername = username;
  console.log(`Global username set to ${globalUsername}`);
}

// New function to set the global character name
function setCharacterNameInGlobal(characterName) {
  globalCharacterName = characterName;
  console.log(`Global character name set to ${globalCharacterName}`);
}

export {
  ws,
  initWebSocket,
  setUsernameInGlobal,
  setCharacterNameInGlobal,
  globalUsername,
  globalCharacterName,
};
