import React from "react";
import { Link } from "react-router-dom";
import TicTacToeLogo from "./tic-tac-toe.svg";
import NumberGuessLogo from "./uncertainty.svg";
import WizardLogo from "./pointy-hat.svg";
import MORPGLogo from "./treasure-map.svg";
import SpaceSurvivorsLogo from "./metroid.svg";
import CardsAndMonstersLogo from "./cardsAndMonstersLogo.svg";
import Hexose from "./bubbles.svg";
import DungeonMatcher from "./dungeon-gate.svg";
import RockPaperScissors from "./tabletop-players.svg";

import "./GamesLandingPage.css";

function GamesLandingPage() {
  const miniGames = [
    {
      name: "Parchment Game",
      image: RockPaperScissors,
      path: "/parchment-game",
    },
    {
      name: "Dungeon Matcher",
      image: DungeonMatcher,
      path: "/dungeon-matcher",
    },
    {
      name: "Hexose",
      image: Hexose,
      path: "/hexose",
    },
    {
      name: "Cards and Monsters",
      image: CardsAndMonstersLogo,
      path: "cardsandmonsters",
    },
    {
      name: "Space Survivors",
      image: SpaceSurvivorsLogo,
      path: "/space-survivors",
    },
    {
      name: "MORPG Demo",
      image: MORPGLogo,
      path: "/morpg",
    },
    {
      name: "Tic Tac Toe",
      image: TicTacToeLogo,
      path: "/tic-tac-toe",
    },
    {
      name: "Collect-A-Wizard",
      image: WizardLogo,
      path: "/collect-wizard",
    },
    {
      name: "Random Number Guess",
      image: NumberGuessLogo,
      path: "/number-guess",
    },
  ];

  return (
    <div className="games-landing-page">
      <h1>Choose an App</h1>
      <div className="games-list">
        {miniGames.map((game, index) => (
          <Link
            to={game.path}
            key={index}
            className="game-item">
            <img
              src={game.image}
              alt={game.name}
              className="game-logo"
            />
            <h2>{game.name}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default GamesLandingPage;
