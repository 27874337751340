// BlacksmithMenu.jsx
import React from "react";

const BlacksmithMenu = ({ selectedButtonIndex, onSelect }) => {
  const blacksmithOptions = ["Forge Weapon", "Forge Armor", "Leave Blacksmith"];

  return (
    <div className="blacksmith-menu">
      {blacksmithOptions.map((option, index) => (
        <button
          key={index}
          onClick={() => onSelect(index)}
          className={`couch-menu-button ${
            index === selectedButtonIndex ? "selected" : ""
          }`}>
          {option}
        </button>
      ))}
    </div>
  );
};

export default BlacksmithMenu;
