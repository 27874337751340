import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Phaser from "phaser";
import DungeonMatcherScene from "./DungeonMatcherScene";

const DungeonMatcher = () => {
  const user = useSelector(store => store.user);

  useEffect(() => {
    const dungeonMatcherSceneInstance = new DungeonMatcherScene(user);
    const config = {
      type: Phaser.AUTO,
      physics: {
        default: "arcade",
      },
      scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        width: 500,
        height: 728,
        resolution: window.devicePixelRatio || 1,
      },
      scene: DungeonMatcherScene,
    };

    const game = new Phaser.Game(config);

    return () => {
      if (dungeonMatcherSceneInstance.shutdown) {
        dungeonMatcherSceneInstance.shutdown();
        dungeonMatcherSceneInstance.destroy();
      }
      game.destroy(true);
    };
  }, [user]);

  return (
    <div
      id="phaser-game"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default DungeonMatcher;
