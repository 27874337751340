import Phaser from "phaser";
import playerImage from "./assets/player.png";
import collectibleImage from "./assets/collectible.png";
import treeImage from "./assets/tree.png";

class CollectWizardScene extends Phaser.Scene {
  constructor(user) {
    super({ key: "CollectWizardScene" });
    this.user = user.username;
  }

  init() {
    this.playerId = null;
    this.x = null;
    this.y = null;
    this.score = 0;

    this.username = this.user;

    this.collectible = null;

    this.trees = [];

    this.ws = null;

    let environment;

    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }

    switch (environment) {
      case "localhost":
        this.ws = new WebSocket("ws://localhost:5000/collect-wizard");
        break;
      case "localNetwork":
        this.ws = new WebSocket("ws://192.168.0.3:5000/collect-wizard");
        break;
      case "production":
        this.ws = new WebSocket("wss://2nguyen.dev/collect-wizard");
        break;
      default:
        console.log("Environment not recognized. WebSocket not established.");
        break;
    }
  }

  preload() {
    this.load.image("player", playerImage);
    this.load.image("collectible", collectibleImage);
    this.load.image("tree", treeImage);
  }

  create() {
    this.add.text(10, 600, this.username, {
      fill: "#fff",
      fontFamily: "Noto Sans Mono",
      fontSize: "12px",
      backgroundColor: "#00447A",
    });

    this.scoreText = this.add.text(10, 10, `Score: ${this.score}`, {
      fill: "#fff",
      fontFamily: "Noto Sans Mono",
      fontSize: "12px",
      backgroundColor: "rgba(0, 68, 122, 0.5)",
    });

    this.ws.onmessage = event => {
      const message = JSON.parse(event.data);

      if (message.method === "connect") {
        this.playerId = message.playerId;
        this.x = message.x;
        this.y = message.y;

        this.player = this.physics.add
          .sprite(this.x, this.y, "player")
          .setCollideWorldBounds(true)
          .setPushable(false);

        this.otherPlayers = this.physics.add.group();

        const payLoad = {
          method: "currentPlayers",
        };

        this.ws.send(JSON.stringify(payLoad));

        this.physics.add.overlap(
          this.player,
          this.collectibles,
          this.handleCollectibleOverlap.bind(this)
        );

        this.physics.add.collider(this.player, this.trees);

        this.physics.add.collider(this.player, this.otherPlayers);

        this.physics.world.setBounds(
          0,
          0,
          this.sys.game.config.width,
          this.sys.game.config.height - 100
        );

        const setUsernamePayload = {
          method: "setUsername",
          username: this.username,
          playerId: this.playerId,
        };
        this.ws.send(JSON.stringify(setUsernamePayload));
      }

      if (message.method === "newCollectible") {
        this.addOrUpdateCollectible(message);
      }

      if (message.method === "removeCollectible") {
        this.removeCollectible(message.collectibleId);
      }

      if (message.method === "newTrees") {
        this.drawTrees(message.trees);
      }

      if (message.method === "currentPlayers") {
        console.log(message);
        const playerId = message.playerId;
        const x = message.x;
        const y = message.y;
        const username = message.username;
        const score = message.score;
        this.addOtherPlayers({
          x: x,
          y: y,
          playerId: playerId,
          username: username,
          score: score,
        });
      }

      if (message.method === "newPlayer") {
        this.addOtherPlayers({
          x: message.x,
          y: message.y,
          playerId: message.playerId,
        });
      }

      if (message.method === "disconnect") {
        this.removePlayer(message.playerId);
      }

      if (message.method === "updateLocation") {
        this.updateLocation({
          x: message.x,
          y: message.y,
          playerId: message.playerId,
        });
      }

      if (message.method === "updateUsername") {
        const { playerId, username } = message;

        this.otherPlayers.getChildren().forEach(player => {
          if (player.playerId === playerId) {
            if (player.usernameText) {
              player.usernameText.setText(username);
            } else {
              player.usernameText = this.add
                .text(player.x, player.y + 50, username, {
                  fontSize: "16px",
                  fill: "#ffffff",
                  align: "center",
                })
                .setOrigin(0.5);
            }
          }
        });
      }

      if (message.method === "updateScore") {
        console.log(message);

        if (message.playerId === this.playerId) {
          this.score = message.score;
          this.scoreText.setText(`Score: ${this.score}`);
        } else {
          // Update another player's score
          // You might need to find the player object in your game and update its score display
          const otherPlayer = this.otherPlayers
            .getChildren()
            .find(player => player.playerId === message.playerId);
          if (otherPlayer) {
            // Assuming you have a way to display the score for other players, update it here
            otherPlayer.score = message.score;
            // Update the score display for the other player
          }
        }
      }
    };

    this.ws.onclose = () => {
      console.log("Disconnected from Collect Wizard server");
    };

    this.ws.onerror = error => {
      console.error(error);
    };

    this.collectibles = this.physics.add.group();
    this.trees = this.physics.add.group();

    this.changeCollectibleTint();

    this.input.on("pointerdown", pointer => {
      this.targetPosition = { x: pointer.x, y: pointer.y };
    });
  }

  update() {
    if (!this.player || !this.player.body) {
      return;
    }

    if (this.targetPosition) {
      const directionX = this.targetPosition.x - this.player.x;
      const directionY = this.targetPosition.y - this.player.y;
      const distance = Math.sqrt(
        directionX * directionX + directionY * directionY
      );
      if (distance < 3) {
        this.player.body.setVelocity(0);
        this.player.x = this.targetPosition.x;
        this.player.y = this.targetPosition.y;
        this.targetPosition = null;
      } else {
        const moveSpeed = 200;
        const velocityX = (directionX / distance) * moveSpeed;
        const velocityY = (directionY / distance) * moveSpeed;

        this.player.body.setVelocityX(velocityX);
        this.player.body.setVelocityY(velocityY);
      }

      const payLoad = {
        method: "movement",
        playerId: this.playerId,
        x: this.player.x,
        y: this.player.y,
      };
      this.ws.send(JSON.stringify(payLoad));
    } else {
      this.player.body.setVelocity(0);
    }

    this.otherPlayers.getChildren().forEach(player => {
      if (player.usernameText) {
        player.usernameText.setPosition(player.x, player.y + 16);
      }
    });
  }

  shutdown() {
    if (this.tintChangeTimeout) {
      clearTimeout(this.tintChangeTimeout);
    }

    if (this.ws) {
      this.ws.close();
    }
  }

  destroy() {
    this.shutdown();
  }

  changeCollectibleTint() {
    const randomTint = Math.random() * 0xffffff;

    this.collectibles.getChildren().forEach(collectible => {
      collectible.setTint(randomTint);
    });

    this.tintChangeTimeout = setTimeout(
      () => this.changeCollectibleTint(),
      100
    );
  }

  handleCollectibleOverlap(player, collectible) {
    const payLoad = {
      method: "collectCollectibleRequest",
      playerId: this.playerId,
      collectibleId: collectible.collectibleId,
    };

    this.ws.send(JSON.stringify(payLoad));
  }

  addOtherPlayers(playerInfo) {
    const otherPlayer = this.physics.add.sprite(
      playerInfo.x,
      playerInfo.y,
      "player"
    );
    otherPlayer.setTint(Math.random() * 0xffffff);
    otherPlayer.playerId = playerInfo.playerId;
    otherPlayer.setCollideWorldBounds(true);
    otherPlayer.setPushable(false);

    const usernameText = this.add
      .text(playerInfo.x, playerInfo.y, playerInfo.username || "", {
        fontSize: "8px",
        fill: "#ffffff",
        align: "center",
      })
      .setOrigin(0.5);

    otherPlayer.usernameText = usernameText;

    this.otherPlayers.add(otherPlayer);

    this.physics.add.collider(otherPlayer, this.trees);
  }

  removePlayer(playerId) {
    this.otherPlayers.getChildren().forEach(player => {
      if (player.playerId === playerId) {
        if (player.usernameText) {
          player.usernameText.destroy();
        }
        player.destroy();
      }
    });
  }

  updateLocation(playerInfo) {
    this.otherPlayers.getChildren().forEach(player => {
      if (player.playerId === playerInfo.playerId) {
        player.setPosition(playerInfo.x, playerInfo.y);
      }
    });
  }

  removeCollectible(collectibleId) {
    this.collectibles.getChildren().forEach(collectible => {
      if (collectible.collectibleId === collectibleId) {
        collectible.destroy();
      }
    });
  }

  addOrUpdateCollectible(collectibleInfo) {
    let collectible = this.collectibles
      .getChildren()
      .find(c => c.collectibleId === collectibleInfo.collectibleId);

    if (collectible) {
      collectible.setPosition(collectibleInfo.x, collectibleInfo.y);
    } else {
      collectible = this.physics.add.sprite(
        collectibleInfo.x,
        collectibleInfo.y,
        "collectible"
      );

      collectible.setTint(Math.random() * 0xffffff);
      collectible.collectibleId = collectibleInfo.collectibleId;
      this.collectibles.add(collectible);
    }
  }

  addOrUpdateOtherPlayer(playerInfo) {
    let otherPlayer = this.otherPlayers
      .getChildren()
      .find(p => p.playerId === playerInfo.playerId);

    if (!otherPlayer) {
      otherPlayer = this.physics.add.sprite(
        playerInfo.x,
        playerInfo.y,
        "player"
      );
      otherPlayer.playerId = playerInfo.playerId;
      this.otherPlayers.add(otherPlayer);

      const usernameText = this.add
        .text(playerInfo.x, playerInfo.y + 50, playerInfo.username || "", {
          fontSize: "16px",
          fill: "#ffffff",
          align: "center",
        })
        .setOrigin(0.5);

      otherPlayer.usernameText = usernameText;
    } else {
      otherPlayer.setPosition(playerInfo.x, playerInfo.y);
      if (otherPlayer.usernameText) {
        otherPlayer.usernameText.setText(playerInfo.username);
      }
    }
  }

  drawTrees(trees) {
    trees.forEach(tree => {
      tree.treeId = tree.treeId;
      tree = this.physics.add.sprite(tree.x, tree.y, "tree");

      const greenColors = [
        0x00ff00, 0x00ff33, 0x00ff66, 0x00ff99, 0x00ffcc, 0x00ffff, 0x33ff00,
        0x33ff33, 0x33ff66, 0x33ff99, 0x33ffcc, 0x33ffff, 0x66ff00, 0x66ff33,
        0x66ff66, 0x66ff99, 0x66ffcc, 0x66ffff, 0x99ff00, 0x99ff33, 0x99ff66,
        0x99ff99, 0x99ffcc, 0x99ffff, 0xccff00, 0xccff33, 0xccff66, 0xccff99,
        0xccffcc, 0xccffff, 0xffff00, 0xffff33, 0xffff66, 0xffff99, 0xffffcc,
      ];

      tree.setTint(greenColors[Math.floor(Math.random() * greenColors.length)]);

      this.trees.add(tree);
      tree.body.setImmovable(true);
    });
  }
}
export default CollectWizardScene;
