import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getWebSocketUrl } from "./WebSocketConfig";
import "./CardsAndMonsters.css";
import { VIEWS } from "./CardsAndMonstersViewEnums";
import CardButton from "./CardButton";

const CardsAndMonstersMain = () => {
  const user = useSelector(store => store.user);
  const history = useHistory();
  const [ws, setWs] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [userState, setUserState] = useState(VIEWS.WORLD);
  const [userCollection, setUserCollection] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [battleRoomId, setBattleRoomId] = useState("");
  const [turnOrder, setTurnOrder] = useState([]);
  const [enemies, setEnemies] = useState([]);
  const [allies, setAllies] = useState([]);
  const [decks, setDecks] = useState([]);
  const [selectedDeck, setSelectedDeck] = useState(null);
  const [deckCards, setDeckCards] = useState([]);
  const [showCardInfo, setShowCardInfo] = useState(false);
  const [selectedBattleCards, setSelectedBattleCards] = useState([]);
  const [selectedEnemies, setSelectedEnemies] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [energyUsed, setEnergyUsed] = useState(0);
  const [
    {
      playerHealth,
      playerMaxHealth,
      playerEnergy,
      playerMaxEnergy,
      playerDefense,
      playerMaxDefense,
      playerDeck,
      playerHand,
      playerDiscard,
      playerPlayedCards,
    },
    setState,
  ] = useState({
    playerHealth: 0,
    playerMaxHealth: 0,
    playerEnergy: 0,
    playerMaxEnergy: 0,
    playerDefense: 0,
    playerMaxDefense: 0,
    playerDeck: [],
    playerHand: [],
    playerDiscard: [],
    playerPlayedCards: [],
  });
  const [currentActingEntity, setCurrentActingEntity] = useState(null);
  const [canPlayerAct, setCanPlayerAct] = useState(false);
  const [defeatedEnemies, setDefeatedEnemies] = useState([]);
  const [rewardsEarned, setRewardsEarned] = useState([]);

  useEffect(() => {
    if (currentActingEntity === user.username) {
      setCanPlayerAct(true);
    } else {
      setCanPlayerAct(false);
    }
  }, [currentActingEntity]);

  const CardInfoDialog = ({ card, onClose }) => {
    if (!card) return null;

    return (
      <div className="popup-overlay">
        <div className="popup">
          <button
            className="close-button"
            onClick={onClose}>
            X
          </button>
          <h3>{card.name}</h3>
          <p>Description: {card.description}</p>
          <p>UUID: {card.id}</p>
          <p>Card ID: {card.card_id}</p>
          <p>Type: {card.type}</p>
          <p>Value: {card.value}</p>
          <p>Cost: {card.cost}</p>
        </div>
      </div>
    );
  };

  const toggleCardSelection = card => {
    if (!canPlayerAct) {
      console.log("Cannot act: not player's turn or player is defeated");
      return;
    }

    setSelectedBattleCards(prevSelected => {
      const isSelected = prevSelected.includes(card.id);
      const cardCost = card.cost;

      if (isSelected) {
        setState(prevState => ({
          ...prevState,
          playerEnergy: prevState.playerEnergy + cardCost,
        }));
        setEnergyUsed(prevEnergyUsed => prevEnergyUsed - cardCost);
        return prevSelected.filter(id => id !== card.id);
      }

      if (playerEnergy >= card.cost) {
        setState(prevState => ({
          ...prevState,
          playerEnergy: prevState.playerEnergy - cardCost,
        }));
        setEnergyUsed(prevEnergyUsed => prevEnergyUsed + cardCost);
        return [...prevSelected, card.id];
      } else {
        return prevSelected;
      }
    });
  };

  const handleCardClick = card => {
    setSelectedCard(card);
    setShowCardInfo(true);
  };

  const handleCloseCardInfo = () => {
    setSelectedCard(null);
    setShowCardInfo(false);
  };

  const toggleEnemySelection = enemy => {
    setSelectedEnemies(prevSelected => {
      if (prevSelected.includes(enemy.id)) {
        return prevSelected.filter(id => id !== enemy.id);
      } else {
        return [...prevSelected, enemy.id];
      }
    });
  };

  const getDisabledCardTypes = selectedCards => {
    const selectedTypes = selectedCards
      .map(id => playerHand.find(card => card.id === id)?.type)
      .filter(Boolean);

    if (
      selectedTypes.includes("attack") &&
      !selectedTypes.includes("defense")
    ) {
      return ["defense"];
    } else if (
      selectedTypes.includes("defense") &&
      !selectedTypes.includes("attack")
    ) {
      return ["attack"];
    }

    return [];
  };

  const togglePlayerSelection = player => {
    setSelectedPlayers(prevSelected => {
      if (prevSelected.includes(player.id)) {
        return prevSelected.filter(id => id !== player.id);
      } else {
        return [...prevSelected, player.id];
      }
    });
  };

  /**
   * Establish a WebSocket connection when the component mounts.
   * Send a message to the server to notify that the user has connected.
   * Listen for messages from the server and update the state accordingly.
   */

  useEffect(() => {
    let ws;
    const url = getWebSocketUrl();

    if (url) {
      ws = new WebSocket(url);
    }

    ws.onopen = () => {
      setIsConnected(true);
      ws.send(
        JSON.stringify({
          type: "USER_CONNECTED",
          username: user.username,
        })
      );
    };

    ws.onmessage = event => {
      const data = JSON.parse(event.data);
      switch (data.type) {
        case "UPDATE_USER_STATE":
          console.log("Received data:", data);
          setUserState(data.state);
          setUserCollection(data.collection);
          setDecks(data.decks || []);
          setSelectedDeck(data.selectedDeck);
          setDeckCards(data.selectedDeck?.cards || []);
          break;

        case "BATTLE_ROOM_INFO":
          console.log("Received data:", data);
          setBattleRoomId(data.battleRoomId);
          setTurnOrder(data.turnOrder || []);
          setEnemies(data.enemies || []);
          setAllies(data.allies || []);
          setDefeatedEnemies(data.defeatedEnemies || []);
          setRewardsEarned(data.rewardsEarned || []);

          if (data.allies && Array.isArray(data.allies)) {
            const playerEntity = data.allies.find(
              entity =>
                entity.id === user.username || entity.name === user.username
            );
            if (playerEntity) {
              setState(prevState => ({
                ...prevState,
                playerMaxHealth: playerEntity.maxHealth,
                playerMaxEnergy: playerEntity.maxEnergy,
                playerMaxDefense: playerEntity.maxDefense,
                playerHealth: playerEntity.currentHealth,
                playerEnergy: playerEntity.currentEnergy,
                playerDefense: playerEntity.currentDefense,
                playerHand: playerEntity.hand || [],
                playerDeck: playerEntity.deck || [],
                playerDiscard: playerEntity.discard || [],
                playerPlayedCards: playerEntity.played || [],
              }));
            }
          }

          setCurrentActingEntity(data.currentActingEntity);
          break;
        default:
          console.log("Unhandled message type:", data.type);
      }
    };

    ws.onclose = () => {
      setIsConnected(false);
    };

    setWs(ws);

    return () => {
      ws.close();
    };
  }, [user]);

  /**
   * Automatically send played cards to the server when the player has selected a card and an entity.
   */

  useEffect(() => {
    const checkAndSendPlayedCards = () => {
      const hasSelectedCard = selectedBattleCards.length > 0;
      const hasSelectedEntity =
        selectedEnemies.length > 0 || selectedPlayers.length > 0;

      if (hasSelectedCard && hasSelectedEntity) {
        sendPlayedCardsToServer();
      }
    };

    checkAndSendPlayedCards();
  }, [selectedBattleCards, selectedEnemies, selectedPlayers]);

  const updateUserState = (newState, deck) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          type: "UPDATE_USER_STATE",
          username: user.username,
          state: newState,
          selectedDeck: deck,
        })
      );
    }
  };

  const sendPlayedCardsToServer = () => {
    const playCardsMessage = {
      type: "PLAY_CARDS",
      username: user.username,
      battleRoomId: battleRoomId,
      cardIds: selectedBattleCards,
      enemyIds: selectedEnemies,
      playerIds: selectedPlayers,
    };

    ws.send(JSON.stringify(playCardsMessage));

    setSelectedBattleCards([]);
    setSelectedEnemies([]);
    setSelectedPlayers([]);
    setEnergyUsed(0);
  };

  const escapeBattle = () => {
    if (
      window.confirm(
        "Are you sure you want to escape to the world? This will end your battle."
      )
    ) {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(
          JSON.stringify({
            type: "ESCAPE_BATTLE",
            username: user.username,
          })
        );
        updateUserState(VIEWS.WORLD);
      }
    }
  };

  const renderWorldView = () => (
    <div className="cam-world">
      <h2>World View</h2>
      <button onClick={() => updateUserState(VIEWS.BATTLE)}>Battle</button>
      <div className="cam-world-footer">
        <button onClick={() => updateUserState(VIEWS.DECKS)}>Decks</button>
        <button onClick={() => updateUserState(VIEWS.COLLECTION)}>
          Collection
        </button>
      </div>
    </div>
  );

  const renderBattleView = () => {
    const hasAttackCardSelected = selectedBattleCards.some(cardId => {
      const card = playerHand.find(card => card.id === cardId);
      return card && card.type === "attack";
    });

    const hasDefenseCardSelected = selectedBattleCards.some(cardId => {
      const card = playerHand.find(card => card.id === cardId);
      return card && card.type === "defense";
    });

    const disabledCardTypes = getDisabledCardTypes(selectedBattleCards);

    const handleEndTurn = () => {
      if (!canPlayerAct) {
        console.log("Cannot end turn: not player's turn or player is defeated");
        return;
      }

      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(
          JSON.stringify({
            type: "END_TURN",
            username: user.username,
            battleRoomId: battleRoomId,
          })
        );

        setSelectedBattleCards([]);
        setSelectedEnemies([]);
        setSelectedPlayers([]);
        setEnergyUsed(0);
      }
    };

    return (
      <div className="cam-battle">
        <div className="battle-container">
          <div className="entity-queue">
            {turnOrder && turnOrder.length > 0 ? (
              <span>
                {turnOrder.map((entity, index) => (
                  <React.Fragment key={entity.id}>
                    {entity.name} ({entity.initiative})
                    {index < turnOrder.length - 1 && ", "}
                  </React.Fragment>
                ))}
              </span>
            ) : (
              <p>No entities in turn order yet.</p>
            )}
          </div>
          <div className="enemy-area">
            {enemies.map(enemy => (
              <div
                key={enemy.id}
                className={`enemy-info ${
                  hasAttackCardSelected ? "selectable" : ""
                }`}>
                {hasAttackCardSelected ? (
                  <button
                    onClick={() => toggleEnemySelection(enemy)}
                    className={`enemy-button ${
                      selectedEnemies.includes(enemy.id) ? "selected" : ""
                    }`}>
                    {enemy.name} (HP:{enemy.currentHealth}|DEF:
                    {enemy.currentDefense})
                  </button>
                ) : (
                  <span>
                    {enemy.name} (HP:{enemy.currentHealth}|DEF:
                    {enemy.currentDefense})
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className="player-area">
            {allies.map(ally => (
              <div
                key={ally.id}
                className={`entity-info ${
                  hasDefenseCardSelected ? "selectable" : ""
                }`}>
                {hasDefenseCardSelected && ally.entityType === "PLAYER" ? (
                  <button
                    onClick={() => togglePlayerSelection(ally)}
                    className={`player-button ${
                      selectedPlayers.includes(ally.id) ? "selected" : ""
                    }`}>
                    {ally.name} (HP:{ally.currentHealth}|DEF:
                    {ally.currentDefense})
                  </button>
                ) : (
                  <span>
                    {ally.name} (HP:{ally.currentHealth}|DEF:
                    {ally.currentDefense})
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className="player-hand">
            <div className="player-info">
              <div className="player-stats">
                <div className="player-stats-text">
                  HP:{playerHealth}/{playerMaxHealth}
                </div>
                <div className="player-stats-text">
                  EP:{playerEnergy}/{playerMaxEnergy}{" "}
                </div>
                <div className="player-stats-text">Defense:{playerDefense}</div>
                <div className="player-stats-text">
                  Deck:{playerDeck.length}
                </div>
                <div className="player-stats-text">
                  Discard:{playerDiscard.length}
                </div>
                <div className="player-stats-text">
                  Hand:{playerHand.length}
                </div>
                <div className="player-stats-text">
                  Played:{playerPlayedCards.length}
                </div>
              </div>
            </div>
            <div className="hand-cards">
              {playerHand && playerHand.length > 0 ? (
                playerHand.map((card, index) => (
                  <React.Fragment key={index}>
                    <button
                      className={`hand-card-button ${
                        selectedBattleCards.includes(card.id) ? "selected" : ""
                      } ${
                        disabledCardTypes.includes(card.type) ? "disabled" : ""
                      } ${
                        playerEnergy < card.cost &&
                        !selectedBattleCards.includes(card.id)
                          ? "energy-disabled"
                          : ""
                      } ${!canPlayerAct ? "turn-disabled" : ""}`}
                      onClick={() => toggleCardSelection(card)}
                      disabled={
                        disabledCardTypes.includes(card.type) &&
                        !selectedBattleCards.includes(card.id)
                      }>
                      {card.name} ({card.cost}) {card.id}
                    </button>
                    <br />
                  </React.Fragment>
                ))
              ) : (
                <p>No cards in hand.</p>
              )}

              <button
                className={`end-turn-button ${
                  !canPlayerAct ? "turn-disabled" : ""
                }`}
                onClick={handleEndTurn}
                disabled={!canPlayerAct}>
                End Turn
              </button>
            </div>
          </div>
        </div>
        <div className="cam-battle-footer">
          <button onClick={escapeBattle}>Escape to World</button>
          <span className="battleRoomId"> Room ID: {battleRoomId} </span>
        </div>
      </div>
    );
  };

  const renderBattleResultsView = () => (
    <div className="cam-battle-results">
      <h2>Battle Results</h2>
      <div className="battle-results-info">
        <span>Enemies Defeated:</span>&nbsp;
        {defeatedEnemies.length > 0 ? (
          defeatedEnemies.map((enemy, index) => (
            <span key={index}>
              {enemy.name}
              {index < defeatedEnemies.length - 1 ? ", " : ""}
            </span>
          ))
        ) : (
          <span>No enemies defeated.</span>
        )}
        <br />
        <span>Rewards:</span>&nbsp;
        {rewardsEarned.length > 0 ? (
          rewardsEarned
            .filter(reward => reward.owner === user.username)
            .map((reward, index) => (
              <span key={index}>
                {reward.name}
                {index <
                rewardsEarned.filter(r => r.owner === user.username).length - 1
                  ? ", "
                  : ""}
              </span>
            ))
        ) : (
          <span>No rewards earned.</span>
        )}
        <br />
        <span>Remaining Enemies: </span> <br />
        <span>Remaining Allies: </span> <br />
        <span>Remaining Health: </span> <br />
      </div>

      <div className="cam-battle-results-footer">
        <button onClick={() => escapeBattle()}>Exit Battle</button>
      </div>
    </div>
  );

  const renderDecksView = () => (
    <div className="cam-decks">
      <h2>Decks View</h2>
      <div className="deck-list">
        {decks.map((deck, index) => (
          <React.Fragment key={index}>
            <button
              onClick={() => {
                setSelectedDeck(deck);
                setDeckCards(deck.cards);
                updateUserState(VIEWS.DECK_EDIT, deck);
              }}>
              {deck.name}
            </button>
            <br />
          </React.Fragment>
        ))}
      </div>

      <div className="cam-decks-footer">
        <button onClick={() => updateUserState(VIEWS.COLLECTION, selectedDeck)}>
          Collection
        </button>
        <button onClick={() => updateUserState(VIEWS.WORLD, selectedDeck)}>
          World Map
        </button>
      </div>
    </div>
  );

  const renderDeckEditView = () => (
    <div className="cam-deck-edit">
      <h2>{selectedDeck ? selectedDeck.name : "No Deck Selected"}</h2>
      <div className="card-grid">
        {deckCards.map((cardId, index) => {
          const card = userCollection.find(card => card.id === cardId);
          return card ? (
            <CardButton
              key={index}
              card={card}
              onClick={handleCardClick}
            />
          ) : null;
        })}
      </div>

      <div className="cam-deck-edit-footer">
        <button onClick={() => updateUserState(VIEWS.DECKS)}>
          Back to Decks
        </button>
      </div>
    </div>
  );

  const renderCollectionView = () => (
    <div className="cam-collection">
      <h2>Collection View</h2>
      <div className="card-grid">
        {userCollection.map((card, index) => (
          <CardButton
            key={index}
            card={card}
            onClick={handleCardClick}
          />
        ))}
      </div>

      <div className="cam-collection-footer">
        <button onClick={() => updateUserState(VIEWS.DECKS)}>Decks</button>
        <button onClick={() => updateUserState(VIEWS.WORLD)}>World Map</button>
      </div>
    </div>
  );

  return (
    <div>
      <div className="cam-header">
        <button onClick={() => history.push("/")}>Exit Game</button>
      </div>
      {userState === VIEWS.WORLD && renderWorldView()}
      {userState === VIEWS.BATTLE && renderBattleView()}
      {userState === VIEWS.DECKS && renderDecksView()}
      {userState === VIEWS.DECK_EDIT && renderDeckEditView()}
      {userState === VIEWS.COLLECTION && renderCollectionView()}
      {userState === VIEWS.BATTLE_RESULTS && renderBattleResultsView()}
      <CardInfoDialog
        card={selectedCard}
        onClose={handleCloseCardInfo}
      />
    </div>
  );
};

export default CardsAndMonstersMain;
