import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateCharacter from "./CreateCharacter";
import ContinueGame from "./ContinueGame";
import BattleMain from "./BattleMain";
import { VIEWS } from "./viewEnums";
import {
  setWebSocket,
  setCharacters,
  setCharacterCreated,
  setCurrentView,
  setSelectedCharacter,
  setMonsters,
} from "../../redux/ElendiaActions";

const Main = () => {
  const dispatch = useDispatch();
  const {
    ws,
    characters,
    characterCreated,
    currentView,
    selectedCharacter,
    monsters,
  } = useSelector(state => state.elendia);
  const username = useSelector(store => store.user.username);

  useEffect(() => {
    let ws;
    let environment;

    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }

    switch (environment) {
      case "localhost":
        ws = new WebSocket("ws://localhost:5000/elendia");
        break;
      case "localNetwork":
        ws = new WebSocket("ws://192.168.0.3:5000/elendia");
        break;
      case "production":
        ws = new WebSocket("wss://2nguyen.dev/elendia");
        break;
      default:
        console.log("Environment not recognized. WebSocket not established.");
        break;
    }

    dispatch(setWebSocket(ws));

    if (ws) {
      ws.addEventListener("open", () => {
        ws.send(JSON.stringify({ type: "INITIAL_CONNECTION", username }));
      });

      ws.addEventListener("message", event => {
        const message = JSON.parse(event.data);
        if (message.type === "NO_CHARACTERS") {
          dispatch(setCharacters([]));
        } else if (message.type === "CHARACTERS_LIST") {
          dispatch(setCharacters(message.characters));
        } else if (message.type === "CHARACTER_CREATED") {
          dispatch(setCharacterCreated(true));
          dispatch(setCurrentView(VIEWS.BATTLE_MAIN));
        } else if (message.type === "USER_VIEW") {
          dispatch(setCurrentView(message.view || VIEWS.MENU_MAIN));
          dispatch(setSelectedCharacter(message.selectedCharacter));
          dispatch(setMonsters(message.monsters || []));
        }
      });
    }

    return () => {
      ws.close();
      dispatch(setWebSocket(null));
    };
  }, [dispatch, username]);

  const changeView = view => {
    dispatch(setCurrentView(view));
    if (ws) {
      ws.send(JSON.stringify({ type: "SET_VIEW", username, view }));
    }
  };

  return (
    <div>
      <h1>Elendia</h1>
      {currentView === VIEWS.MENU_MAIN && (
        <>
          {characters.length > 0 && (
            <button onClick={() => changeView(VIEWS.MENU_CONTINUE_GAME)}>
              Continue Game
            </button>
          )}
          <br />
          <button
            onClick={() =>
              changeView(
                currentView === VIEWS.MENU_CREATE_CHARACTER
                  ? VIEWS.MENU_MAIN
                  : VIEWS.MENU_CREATE_CHARACTER
              )
            }>
            {currentView === VIEWS.MENU_CREATE_CHARACTER
              ? "Back to Main Menu"
              : "Create A Character"}
          </button>
        </>
      )}
      {currentView === VIEWS.MENU_CREATE_CHARACTER && (
        <CreateCharacter
          ws={ws}
          username={username}
          onCharacterCreated={() => {
            dispatch(setCharacterCreated(true));
            changeView(VIEWS.BATTLE_MAIN);
          }}
          changeView={changeView}
        />
      )}
      {currentView === VIEWS.MENU_CONTINUE_GAME && (
        <>
          <ContinueGame
            ws={ws}
            characters={characters}
            changeView={changeView}
          />
        </>
      )}
      {currentView === VIEWS.BATTLE_MAIN && (
        <BattleMain
          changeView={changeView}
          ws={ws}
          username={username}
          selectedCharacter={selectedCharacter}
          monsters={monsters}
        />
      )}
    </div>
  );
};

export default Main;
