const versionInfo = [
  {
    version: "7.0a",
    changes: [
      "Added a card equipping system. Players earn cards by leveling or completing particular tasks.",
      "Reworked equipment modifiers and original player stats.",
      "Began work on adding a few new player stats (such as mana).",
      "Reworked enemy stats.",
      "Added base attack cooldown to all weapons. Weapons either increase or decrease the player's base attack cooldown.",
      "Renamed the bow to long bow and added two other bow types: composite and recurve bow.",
    ],
  },
  {
    version: "6.0a",
    changes: [
      "Added armor applying its defense value against monster damage.",
      "Added evasion and chance to avoid an attack entirely.",
      "Added accessory applying its health value to the player's max health.",
      "Added some new equipment: leather tunic, jambia, cleaver, life bottle, chronograph, and leather boots.",
      "Reworked some equipment modifiers (e.g. add flat health and increase health by a percentage).",
      "Players now passively regenerate health without having to use the 'rest' action.",
      "Players can no longer use the 'rest' action.",
    ],
  },
  {
    version: "5.2a",
    changes: [
      "Added more types of enemies, weapons, and items.",
      "The farther away from the starting location (7, 7), the stronger and more rewarding the enemies become.",
      "Different types of enemies spawn in different areas of the world map.",
      "Added attack 'animations' for new weapons",
      "Added any type of equipment dropping from any enemy",
      "Display the zone's biome and level",
      "Added ability to dismantle equipment for corresponding fragment of that equipment type",
      "Prevent the player from dismantling their equipped equipment or the last of an equipment in their inventory",
      "Display what item or equipment the player obtained from defeating an enemy",
    ],
  },
  {
    version: "4.2a",
    changes: [
      "Disabled the directional buttons when the player cannot move to a particular coordinate.",
      "Updated the menu icons and gave the patch notes their own button.",
      "Implemented a feature to stay better connected to the server.",
    ],
  },
  {
    version: "4.0a",
    changes: [
      "Implemented a message to reconnect when losing connection to the server.",
      "Implemented loading and saving! Stop losing your progress when the server resets or crashes.",
      "Fixed a timestamp bug in the chat messages.",
    ],
  },
];

export default versionInfo;
