import { VIEWS } from "../../components/Elendia/viewEnums";

const initialState = {
  ws: null,
  characters: [],
  characterCreated: false,
  currentView: VIEWS.MENU_MAIN,
  selectedCharacter: null,
  monsters: [],
};

const ElendiaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_WEBSOCKET":
      return { ...state, ws: action.payload };
    case "SET_CHARACTERS":
      return { ...state, characters: action.payload };
    case "SET_CHARACTER_CREATED":
      return { ...state, characterCreated: action.payload };
    case "SET_CURRENT_VIEW":
      return { ...state, currentView: action.payload };
    case "SET_SELECTED_CHARACTER":
      return { ...state, selectedCharacter: action.payload };
    case "SET_MONSTERS":
      return { ...state, monsters: action.payload };
    default:
      return state;
  }
};

export default ElendiaReducer;
