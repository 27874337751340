import React from "react";

const ShopMenu = ({ shopItems, selectedButtonIndex, onSelect }) => {
  return (
    <div className="shop-container">
      <div className="shop-grid">
        {shopItems.map((option, index) => (
          <button
            key={index}
            onClick={() => onSelect(index)}
            className={`couch-menu-button ${
              index === selectedButtonIndex ? "selected" : ""
            }`}>
            {option}
          </button>
        ))}
      </div>
      <button
        onClick={() => onSelect(shopItems.length)}
        className={`couch-menu-button ${
          selectedButtonIndex === shopItems.length ? "selected" : ""
        }`}>
        Leave Shop
      </button>
    </div>
  );
};

export default ShopMenu;
