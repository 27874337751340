import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Phaser from "phaser";
import CardMonstersScene from "./CardMonstersScene";
import CardMonstersLoadingScreen from "./CardMonstersLoadingScreen.js";
import CardMonstersMenuScene from "./CardMonstersMenuScene.js";
import CardMonstersIntroScene from "./CardMonstersIntroScene.js";

import { GAME_DIMENSIONS } from "./CardMonstersConstants.js";

const CardMonsters = () => {
  const user = useSelector(store => store.user);

  useEffect(() => {
    const cardMonstersSceneInstance = new CardMonstersScene(user);
    const cardMonstersLoadingInstance = new CardMonstersLoadingScreen(user);
    const cardMonstersIntroInstance = new CardMonstersIntroScene(user);

    const config = {
      type: Phaser.AUTO,
      width: GAME_DIMENSIONS.width,
      height: GAME_DIMENSIONS.height,
      physics: {
        default: "arcade",
      },
      scene: [
        cardMonstersLoadingInstance,
        CardMonstersMenuScene,
        cardMonstersSceneInstance,
        cardMonstersIntroInstance,
      ],
    };

    const game = new Phaser.Game(config);

    return () => {
      if (cardMonstersSceneInstance.shutdown) {
        cardMonstersSceneInstance.shutdown();
        cardMonstersSceneInstance.destroy();
      }
      game.destroy(true);
    };
  }, [user]);

  return (
    <div
      id="phaser-game"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default CardMonsters;
