import React, { useState, useEffect } from "react";

function NumberGuess() {
  const [guess, setGuess] = useState("");
  const [message, setMessage] = useState("");
  const [lastGuess, setLastGuess] = useState("");
  const [lastGuessResult, setLastGuessResult] = useState("");
  const [guessesList, setGuessesList] = useState([]);
  const [ws, setWs] = useState(null);

  useEffect(() => {
    let ws;
    let environment;

    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }

    switch (environment) {
      case "localhost":
        ws = new WebSocket("ws://localhost:5000/guessing-game");
        break;
      case "localNetwork":
        ws = new WebSocket("ws://192.168.0.3:5000/guessing-game");
        break;
      case "production":
        ws = new WebSocket("wss://2nguyen.dev/guessing-game");
        break;
      default:
        console.log("Environment not recognized. WebSocket not established.");
        break;
    }

    ws.onopen = () => {
      console.log("connected");
    };

    ws.onmessage = evt => {
      const data = JSON.parse(evt.data);
      if (data.message) {
        setLastGuess(guess);
        setLastGuessResult(data.message);
        setMessage(data.message);
      }
      if (data.guesses) {
        setGuessesList(data.guesses);
      }
    };

    ws.onerror = error => {
      console.error(`WebSocket error: ${error.message}`);
    };

    setWs(ws);

    // return () => {
    //   ws.close();
    // };
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    if (ws) {
      ws.send(guess);
    }
  };

  return (
    <div className="container">
      <h3>Number Guessing Game</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          value={guess}
          onChange={e => setGuess(e.target.value)}
        />
        <button type="submit">Guess</button>
      </form>
      {lastGuess && (
        <p>
          Guesses: {lastGuess} - {lastGuessResult}
        </p>
      )}
      {message && <p>{message}</p>}
      {guessesList.length > 0 && <p>All Guesses: {guessesList.join(", ")}</p>}
    </div>
  );
}

export default NumberGuess;
