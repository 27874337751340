// TicTacToeScene.js
import Phaser from "phaser";

class TicTacToeScene extends Phaser.Scene {
  constructor() {
    super({ key: "TicTacToeScene" });
    this.currentPlayer = "X"; // Start with 'X'
  }

  preload() {
    // Load assets if needed
  }

  create() {
    this.initializeBoard();
  }

  initializeBoard() {
    const squareSize = 100; // Size of each square
    const gap = 10; // Gap between squares
    const startX = 100; // Starting X position
    const startY = 100; // Starting Y position

    // Initialize the game state array with null values
    this.board = Array(9).fill(null);

    // Initialize an array to store the visual squares
    this.squares = [];

    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        // Calculate the position of the square
        const x = startX + (squareSize + gap) * j;
        const y = startY + (squareSize + gap) * i;

        // Create a square
        const square = this.add.rectangle(
          x,
          y,
          squareSize,
          squareSize,
          0xffffff
        );

        // Make the square interactive
        square.setInteractive();

        // Calculate the index of the square in the board array
        const index = i * 3 + j;

        // Update the board array and toggle the current player when the square is clicked
        square.on("pointerdown", () => {
          // Check if the square is already filled
          if (this.board[index] !== null) {
            console.log("Square is already filled.");
            return;
          }

          // Record the current player's symbol in the board array
          this.board[index] = this.currentPlayer;

          // Log the move
          console.log(
            `Square clicked: Index ${index}, Player ${this.currentPlayer}`
          );

          // Create a text object for the "X" or "O" piece
          const piece = this.add.text(x, y, this.currentPlayer, {
            fontSize: "50px",
            color: "#000",
          });

          // Center the text object within the square
          piece.setOrigin(0.5);

          // Toggle the current player
          this.currentPlayer = this.currentPlayer === "X" ? "O" : "X";

          // Check for win or draw conditions
          this.checkGameStatus();
        });

        // Store the square in the squares array for visual reference
        this.squares[index] = square;
      }
    }
  }

  checkGameStatus() {
    // Check rows, columns, and diagonals for a win
    const winConditions = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8], // Rows
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8], // Columns
      [0, 4, 8],
      [2, 4, 6], // Diagonals
    ];

    for (let condition of winConditions) {
      const [a, b, c] = condition;
      if (
        this.board[a] &&
        this.board[a] === this.board[b] &&
        this.board[a] === this.board[c]
      ) {
        console.log(`Player ${this.board[a]} wins!`);
        this.showResetButton();
        return;
      }
    }

    // Check for a draw
    if (!this.board.includes(null)) {
      console.log("The game is a draw!");
      this.showResetButton();
    }
  }

  showResetButton() {
    // Remove any existing reset button
    if (this.resetButton) {
      this.resetButton.destroy();
    }

    // Create a reset button
    this.resetButton = this.add
      .text(400, 300, "Reset Game", { fontSize: "30px", color: "#00FF00" })
      .setInteractive()
      .on("pointerdown", () => this.resetGame());
  }

  resetGame() {
    // Reset the game state
    this.board = Array(9).fill(null);
    this.currentPlayer = "X"; // Reset to the starting player

    // Remove the reset button
    if (this.resetButton) {
      this.resetButton.destroy();
      this.resetButton = null; // Ensure it's not referenced anymore
    }

    // Clear the board (remove all pieces)
    this.squares.forEach(square => {
      square.destroy();
    });
    this.squares = [];

    // Recreate the board squares
    this.initializeBoard();

    // Recreate the reset button
    this.showResetButton();
  }
}

export default TicTacToeScene;
