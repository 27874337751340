import React from "react";
import { useHistory } from "react-router-dom";

const CogatPracticeSettings = () => {
    const history = useHistory();

    const handleBackClick = () => {
        history.push("/cogat-practice");
    };

    return (
        <div>
            <h2>Cogat Practice Settings</h2>
            <button onClick={handleBackClick}>Back to Landing Page</button>
            <p>Under construction...</p>
        </div>
    );
};

export default CogatPracticeSettings;
