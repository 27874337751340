import React from "react";

function BinaryGame({ currentRoom, messages, sendMessage }) {
  return (
    <div>
      <button onClick={() => sendMessage("Button 1 clicked")}>Button 1</button>
      <button onClick={() => sendMessage("Button 2 clicked")}>Button 2</button>
      {messages.map((message, index) => (
        <div key={index}>{message.content}</div>
      ))}
    </div>
  );
}

export default BinaryGame;
