import { combineReducers } from "redux";
import errors from "./errors.reducer";
import user from "./user.reducer";
import ElendiaReducer from "./ElendiaReducer";
const rootReducer = combineReducers({
  elendia: ElendiaReducer,
  errors,
  user,
});

export default rootReducer;
