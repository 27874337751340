import Phaser from "phaser";

Phaser.GameObjects.Graphics.prototype.fillHexagon = function (x, y, size) {
  this.beginPath();
  for (let i = 0; i < 6; i++) {
    const angle = (i * Math.PI) / 3;
    const px = x + size * Math.cos(angle);
    const py = y + size * Math.sin(angle);
    if (i === 0) {
      this.moveTo(px, py);
    } else {
      this.lineTo(px, py);
    }
  }
  this.closePath();
  this.fillPath();
};

import JUNK_ORB from "./assets/round-star.svg";
import MIMIC_ORB from "./assets/gluttonous-smile.png";
import BONE_ORB from "./assets/dinosaur-bones.png";
import KNIGHT_ORB from "./assets/barbute.png";
import KNIGHT_INJURED_ORB from "./assets/cracked-helm.png";
import ANVIL_ORB from "./assets/anvil.png";
import ARMOR_ORB from "./assets/shield.svg";
import THORN_ORB from "./assets/thorn-helix.svg";
import QUIVER_ORB from "./assets/quiver.svg";
import ROOT_ORB from "./assets/tree-roots.svg";
import SEED_ORB from "./assets/plant-seed.svg";
import IGNITION_ORB from "./assets/fireflake.svg";
import CHARRED_ORB from "./assets/hot-spices.svg";

import CRACKED_ORB_EFFECT from "./assets/cracked-glass.svg";

import CHARACTER_EMBER from "./assets/character-ember-transparent.png";
import CHARACTER_LANCE from "./assets/character-lance-transparent.png";
import CHARACTER_FLORA from "./assets/character-flora-transparent.png";
import CHARACTER_VOLTAGE from "./assets/character-voltage-transparent.png";
import CHARACTER_RIME from "./assets/character-rime-transparent.png";
import CHARACTER_SPIKE from "./assets/character-spike-transparent.png";

import ARROW_RIGHT from "./assets/play-button.png";
import SPECIAL_BUTTON from "./assets/round-star.svg";

import SIMPLE_EXPLOSION_00 from "./assets/explosion/pixelExplosion00.png";
import SIMPLE_EXPLOSION_01 from "./assets/explosion/pixelExplosion01.png";
import SIMPLE_EXPLOSION_02 from "./assets/explosion/pixelExplosion02.png";
import SIMPLE_EXPLOSION_03 from "./assets/explosion/pixelExplosion03.png";
import SIMPLE_EXPLOSION_04 from "./assets/explosion/pixelExplosion04.png";
import SIMPLE_EXPLOSION_05 from "./assets/explosion/pixelExplosion05.png";
import SIMPLE_EXPLOSION_06 from "./assets/explosion/pixelExplosion06.png";
import SIMPLE_EXPLOSION_07 from "./assets/explosion/pixelExplosion07.png";
import SIMPLE_EXPLOSION_08 from "./assets/explosion/pixelExplosion08.png";
import EXPLOSION_A_01 from "./assets/explosion-1-a/Sprites/explosion-1.png";
import EXPLOSION_A_02 from "./assets/explosion-1-a/Sprites/explosion-2.png";
import EXPLOSION_A_03 from "./assets/explosion-1-a/Sprites/explosion-3.png";
import EXPLOSION_A_04 from "./assets/explosion-1-a/Sprites/explosion-4.png";
import EXPLOSION_A_05 from "./assets/explosion-1-a/Sprites/explosion-5.png";
import EXPLOSION_A_06 from "./assets/explosion-1-a/Sprites/explosion-6.png";
import EXPLOSION_A_07 from "./assets/explosion-1-a/Sprites/explosion-7.png";
import EXPLOSION_A_08 from "./assets/explosion-1-a/Sprites/explosion-8.png";
import EXPLOSION_B_01 from "./assets/explosion-1-b/Sprites/explosion-1-b-1.png";
import EXPLOSION_B_02 from "./assets/explosion-1-b/Sprites/explosion-1-b-2.png";
import EXPLOSION_B_03 from "./assets/explosion-1-b/Sprites/explosion-1-b-3.png";
import EXPLOSION_B_04 from "./assets/explosion-1-b/Sprites/explosion-1-b-4.png";
import EXPLOSION_B_05 from "./assets/explosion-1-b/Sprites/explosion-1-b-5.png";
import EXPLOSION_B_06 from "./assets/explosion-1-b/Sprites/explosion-1-b-6.png";
import EXPLOSION_B_07 from "./assets/explosion-1-b/Sprites/explosion-1-b-7.png";
import EXPLOSION_B_08 from "./assets/explosion-1-b/Sprites/explosion-1-b-8.png";
import EXPLOSION_F_01 from "./assets/explosion-1-f/Sprites/explosion-f1.png";
import EXPLOSION_F_02 from "./assets/explosion-1-f/Sprites/explosion-f2.png";
import EXPLOSION_F_03 from "./assets/explosion-1-f/Sprites/explosion-f3.png";
import EXPLOSION_F_04 from "./assets/explosion-1-f/Sprites/explosion-f4.png";
import EXPLOSION_F_05 from "./assets/explosion-1-f/Sprites/explosion-f5.png";
import EXPLOSION_F_06 from "./assets/explosion-1-f/Sprites/explosion-f6.png";
import EXPLOSION_F_07 from "./assets/explosion-1-f/Sprites/explosion-f7.png";
import EXPLOSION_F_08 from "./assets/explosion-1-f/Sprites/explosion-f8.png";
import EXPLOSION_G_01 from "./assets/explosion-1-g/Sprites/frame1.png";
import EXPLOSION_G_02 from "./assets/explosion-1-g/Sprites/frame2.png";
import EXPLOSION_G_03 from "./assets/explosion-1-g/Sprites/frame3.png";
import EXPLOSION_G_04 from "./assets/explosion-1-g/Sprites/frame4.png";
import EXPLOSION_G_05 from "./assets/explosion-1-g/Sprites/frame5.png";
import EXPLOSION_G_06 from "./assets/explosion-1-g/Sprites/frame6.png";
import EXPLOSION_G_07 from "./assets/explosion-1-g/Sprites/frame7.png";
import CONCUSSIVE_EXPLOSION from "./assets/concussive_explosion_256x256px.png";
import VOID_EXPLOSION from "./assets/void_explosion_256x256px.png";
import WIND_EXPLOSION from "./assets/wind_explosion_256x256px.png";
import FRAG_EXPLOSION from "./assets/frag_explosion_256x256px.png";
import PLUS_EXPLOSION from "./assets/explosion_9.png";

import FIRE_ORB_SYMBOL from "./assets/flame.svg";
import THUNDER_ORB_SYMBOL from "./assets/electric.svg";
import ICE_ORB_SYMBOL from "./assets/snowflake-1.svg";
import DARK_ORB_SYMBOL from "./assets/death-skull.svg";
import NATURE_ORB_SYMBOL from "./assets/linden-leaf.svg";
import STEEL_ORB_SYMBOL from "./assets/plain-dagger.svg";
import HEART_ORB_SYMBOL from "./assets/hearts.svg";

import SHIELD_ABILITY from "./assets/shield.svg";
import ANVIL_ABILITY from "./assets/anvil-impact.svg";
import THORN_ARROW_ABILITY from "./assets/thorn-helix.svg";
import LOAD_BOW_ABILITY from "./assets/quiver.svg";
import WILD_GROWTH_ABILITY from "./assets/flowers.svg";
import CONFLAGRATE_ABILITY from "./assets/fire-silhouette.svg";
import METEOR_STORM_ABILITY from "./assets/three-burning-balls.svg";

const COLOR_POINTER_OVER = 0x003153;
const COLOR_POINTER_OUT = 0x0087bd;
const COLOR_POINTER_DOWN = 0x191970;
const COLOR_POINTER_UP = 0x003153;

const JUNK_COLOR = 0x1b1b1b;
const MIMIC_COLOR = 0x2a3439;
const BONE_COLOR = 0x536878;
const KNIGHT_COLOR = 0x6b4423;
const KNIGHT_INJURED_COLOR = 0x9e7b58;
const ANVIL_COLOR = 0x000000;
const ARMOR_COLOR = 0xffffff;
const THORN_COLOR = 0x568203;
const QUIVER_COLOR = 0x6f4e37;
const ROOT_COLOR = 0x013220;
const SEED_COLOR = 0xace1af;
const IGNITION_COLOR = 0xff6347;
const CHARRED_COLOR = 0x3d0c02;

const EFFECT_ORB_COLORS = new Set([
  JUNK_COLOR,
  MIMIC_COLOR,
  BONE_COLOR,
  KNIGHT_COLOR,
  KNIGHT_INJURED_COLOR,
  ANVIL_COLOR,
  ARMOR_COLOR,
  THORN_COLOR,
  QUIVER_COLOR,
  ROOT_COLOR,
  SEED_COLOR,
  IGNITION_COLOR,
  CHARRED_COLOR,
]);

const CHARACTER_ICON_SPACING = 130;
const CHARACTER_ICON_SPACING_Y = 150;
const CHARACTER_ICON_X_OFFSET = 65;
const CHARACTER_ICON_Y_OFFSET = 70;
const CHARACTER_ICON_RADIUS = 50;
const CHARACTER_ICON_OUTLINE_RADIUS = CHARACTER_ICON_RADIUS + 4;

const XP_UI_RADIUS = 15;

const GAME_OVER_OVERLAY_COLOR = 0x000000;
const GAME_OVER_OVERLAY_ALPHA = 0.9;

const GRID_WIDTH = 9;
const GRID_HEIGHT = 14;
const CELL_SIZE = 40;
const BOARD_Y_OFFSET = CELL_SIZE * 2;

const MATCHES_REQUIRED = 3;
const XP_NEEDED_TO_LEVEL = 100;
const SINGLE_PLAYER_BONUS_MODE_ATTACK_TURNS = 10;
const GAME_OVER_COLOR = 0xbebfc5;

const PLAYER_KEY_BINDINGS = [
  {
    left: "A",
    right: "D",
    drop: "S",
    special: "W",
    toggleCPU: "ONE",
  },
  { left: "G", right: "J", drop: "H", special: "Y", toggleCPU: "TWO" },
  {
    left: "L",
    right: "QUOTES",
    drop: "SEMICOLON",
    special: "P",
    toggleCPU: "THREE",
  },
  {
    left: "LEFT",
    right: "RIGHT",
    drop: "DOWN",
    special: "UP",
    toggleCPU: "FOUR",
  },
];

const FIRE_COLOR = 0xff3800;
const THUNDER_COLOR = 0xfaca16;
const ICE_COLOR = 0x00b9e8;
const DARK_COLOR = 0x800080;
const NATURE_COLOR = 0x006400;
const STEEL_COLOR = 0x778899;
const HEART_COLOR = 0xff91af;
// const STAR = 0xff91af;

const ELEMENT_ORB_COLORS = new Set([
  FIRE_COLOR,
  THUNDER_COLOR,
  ICE_COLOR,
  DARK_COLOR,
  NATURE_COLOR,
  STEEL_COLOR,
  HEART_COLOR,
]);

const CHARACTERS = [
  {
    name: "Ember",
    description: "Flame Mage",
    color: 0x964b00,
    useImage: true,
    textureKey: "characterEmber",
    customOrbWeightings: {
      [FIRE_COLOR]: 100,
      [THUNDER_COLOR]: 80,
      [HEART_COLOR]: 80,
      [DARK_COLOR]: 80,
      [IGNITION_COLOR]: 50,
    },
    customAttackOrbWeightings: {
      [CHARRED_COLOR]: 100,
    },
    abilities: [
      {
        name: "Conflagrate",
        image: "conflagrateAbility",
        description: "Ignite a random non-Anvil Effect Orb on your own board",
        cost: [
          {
            [FIRE_COLOR]: 25,
          },
        ],
      },
      {
        name: "Meteor Storm",
        image: "meteorStormAbility",
        description: "Chars 5 random orbs on a randomdefending player's board",
        cost: [{ [FIRE_COLOR]: 50 }],
      },
    ],
  },
  {
    name: "Lance",
    description: "Steel Knight",
    color: 0xb2beb5,
    useImage: true,
    textureKey: "characterLance",
    customOrbWeightings: {
      [FIRE_COLOR]: 50,
      [THUNDER_COLOR]: 50,
      [ICE_COLOR]: 10,
      [NATURE_COLOR]: 10,
      [STEEL_COLOR]: 100,
    },
    customAttackOrbWeightings: {
      [KNIGHT_INJURED_COLOR]: 100,
    },
    abilities: [
      {
        name: "Raise Shield",
        image: "shieldAbility",
        description: "Add 1 Armor (prevents damage)",
        cost: [
          {
            [STEEL_COLOR]: 10,
          },
        ],
      },
      {
        name: "Anvil Drop",
        image: "anvilAbility",
        description: "Drop an Anvil Orb on your own board",
        cost: [{ [STEEL_COLOR]: 50 }],
      },
    ],
  },
  {
    name: "Flora",
    description: "Nature Ranger",
    color: 0x4a5d23,
    useImage: true,
    textureKey: "characterFlora",
    customOrbWeightings: {
      [THUNDER_COLOR]: 65,
      [ICE_COLOR]: 65,
      [DARK_COLOR]: 65,
      [NATURE_COLOR]: 100,
      [QUIVER_COLOR]: 60,
      [HEART_COLOR]: 65,
    },
    customAttackOrbWeightings: {
      [SEED_COLOR]: 100,
    },
    abilities: [
      {
        name: "Thorn Arrow",
        image: "thornArrowAbility",
        description: "Cracks defending player's orb",
        cost: [
          {
            [NATURE_COLOR]: 10,
          },
        ],
      },
      {
        name: "Load Bow",
        image: "loadBowAbility",
        description: "Guarantees a Quiver Orb on the next turn",
        cost: [
          {
            [NATURE_COLOR]: 10,
          },
        ],
      },
      {
        name: "Wild Growth",
        image: "wildGrowthAbility",
        description:
          "Transforms non-Nature orbs on Flora's board into Nature orbs",
        cost: [
          {
            [NATURE_COLOR]: 50,
          },
        ],
      },
    ],
  },
  {
    name: "Spike",
    description: "Toxic Shinobi",
    color: 0x6c3082,
    useImage: true,
    textureKey: "characterSpike",
  },
  {
    name: "Rime",
    description: "Frost Druid",
    color: 0x007fff,
    useImage: true,
    textureKey: "characterRime",
  },
  {
    name: "Voltage",
    description: "Swift Thief",
    color: 0xe9d66b,
    useImage: true,
    textureKey: "characterVoltage",
  },
  {
    name: "Heart",
    description: "Caring Beastmaster",
    color: 0xda70d6,
    useImage: false,
    textureKey: "characterHeart",
  },
];

export const ORB_CATEGORIES = {
  EFFECT: {
    weight: 0,
    orbs: {
      [JUNK_COLOR]: 0,
      [BONE_COLOR]: 0,
      [MIMIC_COLOR]: 0,
      [KNIGHT_COLOR]: 0,
      [KNIGHT_INJURED_COLOR]: 0,
      [ANVIL_COLOR]: 0,
      [ARMOR_COLOR]: 0,
      [THORN_COLOR]: 0,
      [QUIVER_COLOR]: 0,
      [SEED_COLOR]: 0,
      [IGNITION_COLOR]: 0,
      [CHARRED_COLOR]: 0,
    },
  },
  ELEMENT: {
    weight: 100,
    orbs: {
      [FIRE_COLOR]: 10,
      [THUNDER_COLOR]: 10,
      [ICE_COLOR]: 10,
      [DARK_COLOR]: 10,
      [NATURE_COLOR]: 10,
      [STEEL_COLOR]: 10,
      [HEART_COLOR]: 10,
      // [STAR]: 10,
    },
  },
};

export default class HexoseScene extends Phaser.Scene {
  constructor(user) {
    super({ key: "HexoseScene" });
    this.players = [];
    this.player = {};
    this.singlePlayerBonusActive = false;

    this.isClassicMode = false;
    this.isRPGMode = false;
    this.isGameScreenActive = false;
    this.modeChosen = false;
    this.modeSelectContainer = null;
    this.modeSelectIndex = 0;
    this.modeSelectTexts = [];
    this.modeSelectInputHandlers = [];

    this.user = user.username;
    this.isMultiplayer = false;
    this.ws = null;
  }

  create() {
    this.showModeSelectScreen();
    this.setupAddingAndRemovingPlayersInputHandlers();
    this.createAnimations();
    // this.createMultiplayerToggle();
  }

  createMultiplayerToggle() {
    const multiplayerStyle = {
      fontFamily: "RulerGold",
      fontSize: "28px",
      color: "#ffffff",
      backgroundColor: "#444444",
      padding: { x: 10, y: 5 },
      align: "center",
    };

    this.multiplayerText = this.add
      .text(100, 100, "Multiplayer: OFF", multiplayerStyle)
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true });

    this.multiplayerText.on("pointerdown", () => {
      this.isMultiplayer = !this.isMultiplayer;

      if (this.isMultiplayer) {
        this.multiplayerText.setText("Multiplayer: ON");
        this.multiplayerText.setColor("#00ff00");

        this.players = [];
        this.setupWebsocket();

        this.modeSelectTexts = [this.modeSelectTexts[0]];

        if (this.rpgModeText) {
          this.rpgModeText.setVisible(false);
        }
      } else {
        this.multiplayerText.setText("Multiplayer: OFF");
        this.multiplayerText.setColor("#ffffff");

        this.players = [];
        this.shutdownWebsocket();

        if (this.rpgModeText) {
          this.modeSelectTexts = [this.modeSelectTexts[0], this.rpgModeText];
          this.rpgModeText.setVisible(true);
        }
      }
    });
  }

  setupWebsocket() {
    let environment;
    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }

    switch (environment) {
      case "localhost":
        this.ws = new WebSocket("ws://localhost:5000/hexose");
        break;
      case "localNetwork":
        this.ws = new WebSocket("ws://192.168.0.7:5000/hexose");
        break;
      case "production":
        this.ws = new WebSocket("wss://2nguyen.dev/hexose");
        break;
      default:
        console.log("Environment not recognized. WebSocket not established.");
        return;
    }

    this.ws.onopen = () => {};

    this.ws.onmessage = event => {
      const data = JSON.parse(event.data);
      console.log("Received from server:", data);

      switch (data.method) {
        default:
          console.warn("Unhandled server message method:", data.method);
          break;
      }
    };

    this.ws.onclose = () => {
      console.log("Disconnected from Hexose server");
    };

    this.ws.onerror = error => {
      console.error("WebSocket Error:", error);
    };
  }

  startClassicMode() {
    this.isClassicMode = true;
    this.isRPGMode = false;
    this.modeChosen = true;
    this.hideModeSelectScreen();

    let player;

    if (this.isMultiplayer) {
      player = this.player;
      this.createGrid(player);
      this.createUI(player, 0);
      this.setupPlayerInput(player, 0);
      this.createStoredOrbsUI(player);
      this.showBoardUI(player);
      this.multiplayerSpawnNewPair(player);
    } else {
      player = this.addPlayer();
      this.showBoardUI(player);
      this.spawnNewPair(player);
    }

    player.isCharacterChosen = true;
    player.selectedCharacter = null;
  }

  moveLeft(player) {
    if (player.isBusy || !player.currentPair) return;
    const { x, y } = player.currentPair;

    if (this.canMove(player, x - 1, y)) {
      player.currentPair.x--;
      this.updatePairPosition(player);
      this.updateGhostPairPosition(player);
    }
  }

  moveRight(player) {
    if (player.isBusy || !player.currentPair) return;
    const { x, y } = player.currentPair;

    if (this.canMove(player, x + 1, y)) {
      player.currentPair.x++;
      this.updatePairPosition(player);
      this.updateGhostPairPosition(player);
    }
  }

  canMove(player, x, y) {
    const { horizontal } = player.currentPair;

    if (x < 0) return false;
    if (horizontal && x + 1 >= GRID_WIDTH) return false;
    if (!horizontal && x >= GRID_WIDTH) return false;

    if (y < 0) {
      return true;
    }

    if (y >= GRID_HEIGHT) return false;
    if (!horizontal && y + 1 >= GRID_HEIGHT) return false;

    if (player.grid[y][x]) return false;

    if (horizontal && player.grid[y][x + 1]) return false;
    if (!horizontal && player.grid[y + 1][x]) return false;

    return true;
  }

  updatePairPosition(player) {
    const { x, y, ball1, ball2, horizontal } = player.currentPair;

    const pos1 = this.getHexPosition(x, y, player.xOffset);
    this.tweens.add({
      targets: ball1,
      x: pos1.px,
      y: pos1.py,
      duration: 75,
      ease: "Linear",
    });

    if (horizontal) {
      const pos2 = this.getHexPosition(x + 1, y, player.xOffset);
      this.tweens.add({
        targets: ball2,
        x: pos2.px,
        y: pos2.py,
        duration: 75,
        ease: "Linear",
      });
    } else {
      const pos2 = this.getHexPosition(x, y + 1, player.xOffset);
      this.tweens.add({
        targets: ball2,
        x: pos2.px,
        y: pos2.py,
        duration: 75,
        ease: "Linear",
      });
    }

    this.updatePairLoseIndicator(player);
  }

  getHexPosition(col, row, xOffset = 0) {
    const offsetY = col % 2 === 1 ? CELL_SIZE / 2 : 0;
    const hexWidthFactor = 0.866;
    const px = col * CELL_SIZE * hexWidthFactor + xOffset + CELL_SIZE / 2;
    const py = row * CELL_SIZE + offsetY + CELL_SIZE / 2 + BOARD_Y_OFFSET;

    return { px, py };
  }

  spawnNewPair(player) {
    if (!player || !player.nextPairContainer) {
      console.warn("Attempted to spawn a new pair for a non-existent player.");
      return;
    }

    if (player.upcomingPairs.length === 0) {
      player.upcomingPairs.push({
        color1: this.getOrbColorForPlayer(player),
        color2: this.getOrbColorForPlayer(player),
      });
    }

    const { color1, color2 } = player.upcomingPairs.shift();

    const xStart = 3;
    const yStart = -1;

    if (player?.selectedCharacter?.name === "Flora") {
      const ball1 = this.createBall(xStart, yStart - 1, color1, player);
      const ball2 = this.createBall(xStart, yStart, color2, player);

      player.currentPair = {
        x: xStart,
        y: yStart - 1,
        ball1,
        ball2,
        horizontal: false,
      };
    } else {
      const ball1 = this.createBall(xStart, yStart, color1, player);
      const ball2 = this.createBall(xStart + 1, yStart, color2, player);

      player.currentPair = {
        x: xStart,
        y: yStart,
        ball1,
        ball2,
        horizontal: true,
      };
    }

    player.upcomingPairs.push({
      color1: this.getOrbColorForPlayer(player),
      color2: this.getOrbColorForPlayer(player),
    });

    this.updateNextPairDisplay(player);
    this.createGhostPair(color1, color2, player);
    this.updateGhostPairPosition(player);
    this.updatePairLoseIndicator(player);
  }

  getOrbColorForPlayer(player) {
    if (this.isClassicMode) {
      return this.getRandomOrbColor();
    }

    if (
      this.isRPGMode &&
      player.selectedCharacter &&
      player.selectedCharacter.customOrbWeightings
    ) {
      const customWeightings = player.selectedCharacter.customOrbWeightings;

      let totalWeight = 0;
      for (const color in customWeightings) {
        totalWeight += customWeightings[color];
      }

      if (totalWeight > 0) {
        let rand = Phaser.Math.RND.frac() * totalWeight;
        for (const color in customWeightings) {
          if (rand <= customWeightings[color]) {
            return parseInt(color, 10);
          }
          rand -= customWeightings[color];
        }
      }
    }

    return this.getRandomOrbColor();
  }

  shutdownWebsocket() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
  }

  sendMoveRequest(direction, player) {
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      console.warn("Socket is not open. Cannot request move.");
      return;
    }

    this.ws.send(
      JSON.stringify({
        method: `move${direction.charAt(0).toUpperCase() + direction.slice(1)}`,
        playerId: player.playerId,
      })
    );
  }

  sendDropRequest(player) {
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      console.warn("Socket is not open. Cannot request move.");
      return;
    }

    this.ws.send(
      JSON.stringify({
        method: "drop",
        playerId: player.playerId,
      })
    );
  }

  setupPlayerInput(player, playerIndex) {
    let lastDropTime = 0;
    const DROP_COOLDOWN = 50;

    const canDropNow = () => {
      const now = performance.now();
      if (now - lastDropTime >= DROP_COOLDOWN) {
        lastDropTime = now;
        return true;
      }
      return false;
    };

    const leftHandler = () => {
      if (!player.isCharacterChosen) {
        player.selectedCharacterIndex =
          (player.selectedCharacterIndex - 1 + CHARACTERS.length) %
          CHARACTERS.length;
        this.updateCharacterSelectionHighlight(player);
      } else {
        if (this.isMultiplayer) {
          this.sendMoveRequest("left", player);
        } else {
          this.moveLeft(player);
        }
      }
    };

    this.input.keyboard.on(
      `keydown-${player.keyConfig.left}`,
      leftHandler,
      this
    );
    player.inputHandlers.push({
      eventName: `keydown-${player.keyConfig.left}`,
      callback: leftHandler,
    });

    const rightHandler = () => {
      if (!player.isCharacterChosen) {
        player.selectedCharacterIndex =
          (player.selectedCharacterIndex + 1) % CHARACTERS.length;
        this.updateCharacterSelectionHighlight(player);
      } else {
        if (this.isMultiplayer) {
          this.sendMoveRequest("right", player);
        } else {
          this.moveRight(player);
        }
      }
    };

    this.input.keyboard.on(
      `keydown-${player.keyConfig.right}`,
      rightHandler,
      this
    );
    player.inputHandlers.push({
      eventName: `keydown-${player.keyConfig.right}`,
      callback: rightHandler,
    });

    const dropHandler = () => {
      if (!this.isMultiplayer) {
        // Single-player offline logic
        if (!player.isCharacterChosen) {
          player.isCharacterChosen = true;
          player.selectedCharacter = CHARACTERS[player.selectedCharacterIndex];

          this.hideCharacterSelectionUI(player);
          this.showBoardUI(player);
          this.createChosenCharacterIcon(player);
          this.createAbilityUI(player);

          this.spawnNewPair(player);
        } else {
          if (!player.isBusy) {
            this.dropPair(player);
          }
        }
      } else {
        // Multiplayer approach
        if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
          console.warn("Socket is not open. Cannot drop pair.");
          return;
        }

        // If not chosen character yet, you might still need that logic.
        // Otherwise, just send the drop request.
        if (!player.isCharacterChosen) {
          player.isCharacterChosen = true;
          player.selectedCharacter = CHARACTERS[player.selectedCharacterIndex];

          this.hideCharacterSelectionUI(player);
          this.showBoardUI(player);
          this.createChosenCharacterIcon(player);
          this.createAbilityUI(player);

          // Usually we'd request a new pair from the server here,
          // or if you already have one, do nothing.
          this.multiplayerSpawnNewPair(player);
        } else {
          // Let the server handle "drop" logic
          this.ws.send(
            JSON.stringify({
              method: "drop",
              playerId: player.playerId,
            })
          );
        }
      }
    };

    this.input.keyboard.on(
      `keydown-${player.keyConfig.drop}`,
      () => {
        if (canDropNow()) {
          dropHandler();
        }
      },
      this
    );
    player.inputHandlers.push({
      eventName: `keydown-${player.keyConfig.drop}`,
      callback: dropHandler,
    });

    const createSpecialButton = (x, y, handler) => {
      let specialButtonInterval = null;

      const specialButton = this.add
        .image(x, y, "special_button")
        .setOrigin(0.5)
        .setDisplaySize(55, 55)
        .setInteractive({ useHandCursor: true })
        .setTint(COLOR_POINTER_OUT);

      specialButton.on("pointerover", () => {
        specialButton.setTint(COLOR_POINTER_OVER);
      });

      specialButton.on("pointerout", () => {
        specialButton.setTint(COLOR_POINTER_OUT);
        if (specialButtonInterval) {
          clearInterval(specialButtonInterval);
          specialButtonInterval = null;
        }
      });

      specialButton.on("pointerdown", () => {
        specialButton.setTint(COLOR_POINTER_DOWN);

        handler();

        if (!specialButtonInterval) {
          specialButtonInterval = setInterval(() => {
            handler();
          }, 300);
        }
      });

      specialButton.on("pointerup", () => {
        specialButton.setTint(COLOR_POINTER_UP);
        if (specialButtonInterval) {
          clearInterval(specialButtonInterval);
          specialButtonInterval = null;
        }
      });

      return specialButton;
    };

    if (!this.isClassicMode) {
      const specialHandler = () => {
        if (!player.isCharacterChosen) return;

        const character = player.selectedCharacter;
        if (!character.abilities || character.abilities.length === 0) return;

        const abilities = character.abilities;
        const currentIndex = player.currentAbilityIndex;
        const isToggled = player.abilityIsToggled;

        if (abilities.length === 1) {
          // Only one ability: toggle on/off
          player.abilityIsToggled = !isToggled;
          this.updateAbilityUI(player);
          return;
        }

        if (!isToggled) {
          player.abilityIsToggled = true;
        } else {
          let nextIndex = currentIndex + 1;
          if (nextIndex >= abilities.length) {
            nextIndex = 0;
          }
          player.currentAbilityIndex = nextIndex;
          player.abilityIsToggled = false;
        }
        this.updateAbilityUI(player);
      };

      this.input.keyboard.on(
        `keydown-${player.keyConfig.special}`,
        specialHandler,
        this
      );
      player.inputHandlers.push({
        eventName: `keydown-${player.keyConfig.special}`,
        callback: specialHandler,
      });
    }

    if (playerIndex === 0) {
      const touchButtonsContainer = this.add.container(
        player.xOffset + 20,
        GRID_HEIGHT * CELL_SIZE + 80
      );

      let specialButton = null;
      if (!this.isClassicMode) {
        const specialHandler = () => {
          if (!player.isCharacterChosen) return;

          const character = player.selectedCharacter;
          if (!character.abilities || character.abilities.length === 0) return;

          const abilities = character.abilities;
          const currentIndex = player.currentAbilityIndex;
          const isToggled = player.abilityIsToggled;

          if (abilities.length === 1) {
            // Only one ability: toggle on/off
            player.abilityIsToggled = !isToggled;
            this.updateAbilityUI(player);
            return;
          }

          if (!isToggled) {
            player.abilityIsToggled = true;
          } else {
            let nextIndex = currentIndex + 1;
            if (nextIndex >= abilities.length) {
              nextIndex = 0;
            }
            player.currentAbilityIndex = nextIndex;
            player.abilityIsToggled = false;
          }
          this.updateAbilityUI(player);
        };

        specialButton = createSpecialButton(15, 35, specialHandler);
      }

      const leftButton = this.add
        .image(115, 35, "arrow_right")
        .setOrigin(0.5)
        .setDisplaySize(55, 55)
        .setRotation(Phaser.Math.DegToRad(180))
        .setInteractive({ useHandCursor: true })
        .setTint(COLOR_POINTER_OUT);

      const rightButton = this.add
        .image(315, 35, "arrow_right")
        .setOrigin(0.5)
        .setDisplaySize(55, 55)
        .setRotation(Phaser.Math.DegToRad(0))
        .setInteractive({ useHandCursor: true })
        .setTint(COLOR_POINTER_OUT);

      this.createArrowButton(leftButton, Phaser.Math.DegToRad(180), player);
      this.createArrowButton(rightButton, Phaser.Math.DegToRad(0), player);

      let downButtonInterval = null;
      const downButton = this.add
        .image(215, 35, "arrow_right")
        .setOrigin(0.5)
        .setDisplaySize(55, 55)
        .setRotation(Phaser.Math.DegToRad(90))
        .setInteractive({ useHandCursor: true })
        .setTint(COLOR_POINTER_OUT)
        .on("pointerover", () => {
          downButton.setTint(COLOR_POINTER_OVER);
        })
        .on("pointerout", () => {
          downButton.setTint(COLOR_POINTER_OUT);
          if (downButtonInterval) {
            clearInterval(downButtonInterval);
            downButtonInterval = null;
          }
        })
        .on("pointerdown", () => {
          if (!canDropNow()) return;

          downButton.setTint(COLOR_POINTER_DOWN);

          dropHandler();

          if (!downButtonInterval) {
            downButtonInterval = setInterval(() => {
              dropHandler();
            }, 500);
          }
        })
        .on("pointerup", () => {
          downButton.setTint(COLOR_POINTER_UP);
          if (downButtonInterval) {
            clearInterval(downButtonInterval);
            downButtonInterval = null;
          }
        });

      touchButtonsContainer.add([leftButton, downButton, rightButton]);

      if (specialButton) {
        touchButtonsContainer.add(specialButton);
      }

      player.touchButtons = touchButtonsContainer;
    }

    const toggleCPUHandler = () => {
      if (!player.isCharacterChosen) return;

      player.isCPU = !player.isCPU;

      if (player.isCPU) {
        player.cpuInterval = setInterval(() => {
          if (player.isGameOver || player.isRemoved) {
            clearInterval(player.cpuInterval);
            player.cpuInterval = null;
            return;
          }

          const randomAction = Phaser.Math.Between(0, 3);

          if (randomAction === 0) {
            this.moveLeft(player);
          } else if (randomAction === 1) {
            this.moveRight(player);
          } else if (randomAction === 2) {
            if (!player.isBusy) {
              this.dropPair(player);
            }
          } else if (randomAction === 3 && !this.isClassicMode) {
            if (!player.selectedCharacter) return;
            const character = player.selectedCharacter;
            if (!character.abilities || character.abilities.length === 0)
              return;

            const abilities = character.abilities;
            const currentIndex = player.currentAbilityIndex;
            const isToggled = player.abilityIsToggled;

            if (abilities.length === 1) {
              player.abilityIsToggled = !isToggled;
              this.updateAbilityUI(player);
            } else {
              if (!isToggled) {
                player.abilityIsToggled = true;
              } else {
                let nextIndex = currentIndex + 1;
                if (nextIndex >= abilities.length) {
                  nextIndex = 0;
                }
                player.currentAbilityIndex = nextIndex;
                player.abilityIsToggled = false;
              }
              this.updateAbilityUI(player);
            }
          }
        }, 100);
      } else {
        if (player.cpuInterval) {
          clearInterval(player.cpuInterval);
          player.cpuInterval = null;
        }
      }
    };

    this.input.keyboard.on(
      `keydown-${player.keyConfig.toggleCPU}`,
      toggleCPUHandler,
      this
    );
    player.inputHandlers.push({
      eventName: `keydown-${player.keyConfig.toggleCPU}`,
      callback: toggleCPUHandler,
    });
  }

  isEffectOrb(color) {
    return EFFECT_ORB_COLORS.has(color);
  }

  isElementOrb(color) {
    return ELEMENT_ORB_COLORS.has(color);
  }

  showBoardUI(player) {
    if (player.gridBackground) player.gridBackground.setVisible(true);
    if (player.scoreText) player.scoreText.setVisible(true);
    if (player.damageQueueDisplay) player.damageQueueDisplay.setVisible(true);
    if (player.storedOrbsContainer) {
      player.storedOrbsContainer.setVisible(true);
    }

    if (player.nextPairContainer) {
      player.nextPairContainer.setVisible(true);
    }

    if (player.xpCircleBackground) player.xpCircleBackground.setVisible(true);
    if (player.xpCircleFill) player.xpCircleFill.setVisible(true);
    if (player.xpLevelText) player.xpLevelText.setVisible(true);
  }

  showGameOverOverlay(player) {
    const overlayWidth = GRID_WIDTH * CELL_SIZE - CELL_SIZE + 5;
    const overlayHeight = GRID_HEIGHT * CELL_SIZE + CELL_SIZE / 2;
    const overlayX = player.xOffset - 5;
    const overlayY = 1;

    const container = this.add.container(overlayX, overlayY);
    player.gameOverOverlay = container;

    const bg = this.add
      .rectangle(
        0,
        0,
        overlayWidth,
        overlayHeight,
        GAME_OVER_OVERLAY_COLOR,
        GAME_OVER_OVERLAY_ALPHA
      )
      .setOrigin(0);
    container.add(bg);

    let textY = 50;
    if (this.isRPGMode && player.selectedCharacter) {
      const nameText = this.add.text(
        10,
        textY,
        `Character: ${player.selectedCharacter.name}`,
        {
          fontFamily: "RulerGold",
          fontSize: "20px",
          color: "#ffffff",
        }
      );
      container.add(nameText);
      textY += 40;
    }

    const orbTitleText = this.add.text(10, textY, "Total Orbs Crushed:", {
      fontFamily: "RulerGold",
      fontSize: "18px",
      color: "#ffffff",
    });
    container.add(orbTitleText);
    textY += 30;

    player.totalOrbsCrushed.forEach(orb => {
      const orbGraphic = this.add.graphics();
      orbGraphic.fillStyle(orb.color, 1);
      orbGraphic.fillCircle(8, 8, 8);
      orbGraphic.x = 10;
      orbGraphic.y = textY;
      container.add(orbGraphic);

      const qty = this.add.text(25, textY - 6, `x${orb.quantity}`, {
        fontFamily: "RulerGold",
        fontSize: "16px",
        color: "#ffffff",
      });
      container.add(qty);
      textY += 24;
    });

    textY += 20;

    const highestChainText = this.add.text(
      10,
      textY,
      `Highest Chain: ${player.highestChain}`,
      {
        fontFamily: "RulerGold",
        fontSize: "18px",
        color: "#ffffff",
      }
    );
    container.add(highestChainText);
    textY += 30;

    const turnsText = this.add.text(
      10,
      textY,
      `Total Turns Played: ${player.playerTurns}`,
      {
        fontFamily: "RulerGold",
        fontSize: "18px",
        color: "#ffffff",
      }
    );
    container.add(turnsText);

    if (player.isWinner) {
      const winnerText = this.add.text(
        10,
        textY + 30,
        "Congratulations, you are the winner!",
        {
          fontFamily: "RulerGold",
          fontSize: "18px",
          color: "#ffff00",
        }
      );
      container.add(winnerText);
      textY += 30;
    }
  }

  showModeSelectScreen() {
    this.modeSelectContainer = this.add.container(
      this.cameras.main.centerX,
      this.cameras.main.centerY
    );

    const buttonStyle = {
      fontFamily: "RulerGold",
      fontSize: "32px",
      color: "#ffffff",
      backgroundColor: "#333333",
      padding: { x: 10, y: 5 },
      align: "center",
    };

    const classicModeText = this.add
      .text(0, -40, "Classic Mode", buttonStyle)
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true });

    classicModeText.on("pointerdown", () => {
      if (this.modeSelectIndex === 0) {
        this.confirmModeSelection();
      } else {
        this.modeSelectIndex = 0;
        this.updateModeSelectHighlight();
      }
    });

    this.rpgModeText = this.add
      .text(0, 40, "RPG Mode", buttonStyle)
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true });

    this.rpgModeText.on("pointerdown", () => {
      if (this.isMultiplayer) {
        return;
      }
      if (this.modeSelectIndex === 1) {
        this.confirmModeSelection();
      } else {
        this.modeSelectIndex = 1;
        this.updateModeSelectHighlight();
      }
    });

    this.modeSelectContainer.add([classicModeText, this.rpgModeText]);
    this.modeSelectTexts = [classicModeText, this.rpgModeText];

    this.setupModeSelectInput();

    this.updateModeSelectHighlight();
  }

  setupModeSelectInput() {
    const leftHandler = () => {
      this.modeSelectIndex =
        (this.modeSelectIndex + this.modeSelectTexts.length - 1) %
        this.modeSelectTexts.length;
      this.updateModeSelectHighlight();
    };
    this.input.keyboard.on("keydown-A", leftHandler, this);
    this.modeSelectInputHandlers.push({
      eventName: "keydown-A",
      callback: leftHandler,
    });

    const rightHandler = () => {
      this.modeSelectIndex =
        (this.modeSelectIndex + 1) % this.modeSelectTexts.length;
      this.updateModeSelectHighlight();
    };
    this.input.keyboard.on("keydown-D", rightHandler, this);
    this.modeSelectInputHandlers.push({
      eventName: "keydown-D",
      callback: rightHandler,
    });

    const confirmHandler = () => {
      this.confirmModeSelection();
    };
    this.input.keyboard.on("keydown-S", confirmHandler, this);
    this.modeSelectInputHandlers.push({
      eventName: "keydown-S",
      callback: confirmHandler,
    });
  }

  confirmModeSelection() {
    if (this.modeSelectIndex === 0) {
      this.startClassicMode();
    } else {
      this.startRPGMode();
    }
  }

  removeModeSelectInput() {
    this.modeSelectInputHandlers.forEach(({ eventName, callback }) => {
      this.input.keyboard.off(eventName, callback, this);
    });
    this.modeSelectInputHandlers = [];
  }

  updateModeSelectHighlight() {
    this.modeSelectTexts.forEach((textObj, index) => {
      if (index === this.modeSelectIndex) {
        textObj.setColor("#ffff00");
        textObj.setFontStyle("bold");
      } else {
        textObj.setColor("#ffffff");
        textObj.setFontStyle("normal");
      }
    });
  }

  hideModeSelectScreen() {
    if (this.modeSelectContainer) {
      this.modeSelectContainer.destroy();
      this.modeSelectContainer = null;
    }

    if (this.multiplayerText) {
      this.multiplayerText.destroy();
      this.multiplayerText = null;
    }

    this.removeModeSelectInput();
  }

  startRPGMode() {
    this.isClassicMode = false;
    this.isRPGMode = true;

    this.modeChosen = true;

    this.hideModeSelectScreen();

    this.addPlayer();
  }

  handleAddPlayer() {
    if (!this.modeChosen) {
      console.log("Mode not chosen. Cannot add player.");
      return;
    }

    if (this.players.length >= 4) {
      console.log("Maximum number of players reached.");
      return;
    }

    const newPlayer = this.addPlayer();

    if (this.isClassicMode) {
      newPlayer.isCharacterChosen = true;
      newPlayer.selectedCharacter = null;

      this.showBoardUI(newPlayer);
      this.spawnNewPair(newPlayer);
    }
  }

  attemptRoll(player, currentX, currentY) {
    const directions = [-1, 1];
    for (const dir of directions) {
      const newX = currentX + dir;
      if (newX < 0 || newX >= GRID_WIDTH) continue;

      const adjacentCell = player.grid[currentY][newX];
      if (!adjacentCell) {
        const cellBelow = player.grid[currentY + 1]
          ? player.grid[currentY + 1][newX]
          : null;
        if (!cellBelow || cellBelow.originalColor !== ANVIL_COLOR) {
          return { x: newX, y: currentY };
        }
      }
    }
    return null;
  }

  applyGravityNoRoll(player, callback) {
    let moved = true;
    const gravityTweens = [];

    const performGravity = () => {
      moved = false;

      for (let y = GRID_HEIGHT - 1; y >= 0; y--) {
        for (let x = 0; x < GRID_WIDTH; x++) {
          const ball = player.grid[y][x];
          if (!ball) continue;

          const gravityDirs = this.getHexGravityDirectionsNoRoll(x);
          for (const dir of gravityDirs) {
            const nx = x + dir.dx;
            const ny = y + dir.dy;
            if (
              ny >= 0 &&
              ny < GRID_HEIGHT &&
              nx >= 0 &&
              nx < GRID_WIDTH &&
              !player.grid[ny][nx]
            ) {
              player.grid[ny][nx] = ball;
              player.grid[y][x] = null;

              const { px, py } = this.getHexPosition(nx, ny, player.xOffset);

              const dx = px - ball.x;
              const dy = py - ball.y;

              const rotationDelta = this.calculateRotationDelta(dir.dx, dir.dy);
              const newRotation = ball.rotation + rotationDelta;

              ball.rotation = Phaser.Math.Wrap(newRotation, 0, Math.PI * 2);

              const tween = this.tweens.add({
                targets: ball,
                x: px,
                y: py,
                rotation: ball.rotation,
                duration: 25,
                ease: "Power2",
                onComplete: () => {
                  if (gravityTweens.length === 0 && callback) {
                    callback();
                  }
                },
              });

              gravityTweens.push(tween);
              moved = true;
              break;
            }
          }
        }
      }

      if (!moved) {
        if (callback) {
          callback();
        }
      } else {
        if (gravityTweens.length > 0) {
          this.time.delayedCall(25, performGravity);
        }
      }
    };

    performGravity();
  }

  handleDamageQueue(player, onComplete) {
    const incomingOrbs = player.damageQueue.splice(
      0,
      player.damageQueue.length
    );

    const newDamageQueue = [];
    const orbsToDrop = [];

    for (const orbColor of incomingOrbs) {
      if (orbColor === ARMOR_COLOR) {
        newDamageQueue.push(orbColor);
      } else if (orbColor === ANVIL_COLOR) {
        orbsToDrop.push(orbColor);
      } else if (
        orbColor === THORN_COLOR ||
        orbColor === SEED_COLOR ||
        orbColor === CHARRED_COLOR
      ) {
        const armorIndex = newDamageQueue.findIndex(c => c === ARMOR_COLOR);
        if (armorIndex !== -1) {
          newDamageQueue.splice(armorIndex, 1);
          continue;
        } else {
          orbsToDrop.push(orbColor);
        }
      } else {
        const isEffectOrb =
          orbColor in ORB_CATEGORIES.EFFECT.orbs && orbColor !== ANVIL_COLOR;

        if (isEffectOrb) {
          const armorIndex = newDamageQueue.findIndex(c => c === ARMOR_COLOR);
          if (armorIndex !== -1) {
            newDamageQueue.splice(armorIndex, 1);
            continue;
          }
        }

        orbsToDrop.push(orbColor);
      }
    }

    player.damageQueue = newDamageQueue;

    if (orbsToDrop.length === 0) {
      this.updateDamageQueueDisplay(player);
      if (onComplete) onComplete();
      return;
    }

    const dropNextOrb = () => {
      if (orbsToDrop.length === 0) {
        this.updateDamageQueueDisplay(player);
        if (onComplete) onComplete();
        return;
      }

      const orbColor = orbsToDrop.shift();

      if (this.checkIfAnyOrbAboveGrid(player)) {
        this.gameOver(player);
        return;
      }

      if (orbColor === ANVIL_COLOR) {
        this.dropAnvilOrb(player, () => {
          this.applyGravity(player, dropNextOrb);
        });
      } else if (orbColor === THORN_COLOR) {
        this.dropThornOrb(player, () => {
          this.applyGravity(player, dropNextOrb);
        });
      } else if (orbColor === SEED_COLOR) {
        this.dropSeedOrb(player, () => {
          this.applyGravity(player, dropNextOrb);
        });
      } else if (orbColor === CHARRED_COLOR)
        this.dropCharredOrb(player, () => {
          this.applyGravity(player, dropNextOrb);
        });
      else {
        const randomCol = Phaser.Math.RND.between(0, GRID_WIDTH - 1);
        const spawnRow = 0;
        const newBall = this.createBall(randomCol, spawnRow, orbColor, player);
        player.grid[spawnRow][randomCol] = newBall;

        this.applyGravity(player, dropNextOrb);
      }
    };

    dropNextOrb();
  }

  updatePairLoseIndicator(player) {
    const { x, y, horizontal, ball1, ball2 } = player.currentPair || {};
    if (!ball1 || !ball2) return;

    let willLoseBall1 = false;
    let willLoseBall2 = false;

    if (player?.selectedCharacter?.name === "Flora") {
      if (player.grid[0][x] || player.grid[1][x]) {
        willLoseBall1 = true;
        willLoseBall2 = true;
      }
    } else {
      if (!this.canPlaceOnTopRow(player, x)) {
        willLoseBall1 = true;
      }
      if (!this.canPlaceOnTopRow(player, x + 1)) {
        willLoseBall2 = true;
      }
    }

    if (willLoseBall1) this.attachLoseIndicator(ball1);
    else this.removeLoseIndicator(ball1);

    if (willLoseBall2) this.attachLoseIndicator(ball2);
    else this.removeLoseIndicator(ball2);
  }

  attachLoseIndicator(ball) {
    if (ball.loseIndicator) return;

    const blinkingX = this.createBlinkingLoseIndicator();

    ball.add(blinkingX);

    ball.loseIndicator = blinkingX;
  }

  removeLoseIndicator(ball) {
    if (ball.loseIndicator) {
      ball.loseIndicator.destroy();
      ball.loseIndicator = null;
    }
  }

  canPlaceOnTopRow(player, col) {
    if (col < 0 || col >= GRID_WIDTH) return false;
    return !player.grid[0][col];
  }

  createUI(player, playerIndex) {
    this.createXPUI(player);

    const gridBackground = this.add.graphics();
    gridBackground.fillStyle(0xcccccc, 0.3);

    for (let y = 0; y < GRID_HEIGHT; y++) {
      for (let x = 0; x < GRID_WIDTH; x++) {
        const { px, py } = this.getHexPosition(x, y, player.xOffset);
        gridBackground.fillHexagon(px, py, CELL_SIZE / 1.7);
      }
    }

    gridBackground.setVisible(false);
    player.gridBackground = gridBackground;

    const damageQueueDisplay = this.add.container(
      player.xOffset + GRID_WIDTH * CELL_SIZE + 10,
      GRID_HEIGHT * CELL_SIZE + 20 + BOARD_Y_OFFSET
    );
    damageQueueDisplay.setVisible(false);
    player.damageQueueDisplay = damageQueueDisplay;

    const nextPairContainer = this.add.container(
      player.xOffset + GRID_WIDTH * CELL_SIZE,
      50 + BOARD_Y_OFFSET / 2
    );
    nextPairContainer.setVisible(false);

    player.nextPairContainer = nextPairContainer;

    const nextText = this.add.text(0, 0, "NEXT", {
      fontFamily: "RulerGold",
      fontSize: "20px",
      fill: "#fff",
      fontStyle: "bold",
    });
    nextText.setOrigin(0.5, 0);
    nextText.setPosition(0, 0);
    nextPairContainer.add(nextText);
    player.nextPairText = nextText;
  }

  addPlayer() {
    const playerIndex = this.players.length;
    const xOffset = playerIndex * (GRID_WIDTH * CELL_SIZE + 50);

    const player = {
      isBusy: false,
      isGameOver: false,
      isWinner: false,
      grid: [],
      currentPair: null,
      ghostPair: null,
      xOffset,
      keyConfig: PLAYER_KEY_BINDINGS[playerIndex],
      inputHandlers: [],
      soloPlayerTurns: 0,
      nextAnvilReplaceLevel: 25,
      playerTurns: 0,

      upcomingPairs: [],
      upcomingPairsInitialized: false,
      nextPairContainer: null,
      nextPairText: null,
      nextPairOrbs: [],
      damageQueue: [],

      totalOrbsCrushed: [],
      storedOrbs: [],

      highestChain: 0,

      isCharacterChosen: this.isClassicMode ? true : false,
      selectedCharacterIndex: 0,
      selectedCharacter: null,
      characterIcons: [],
      characterIconOutlines: [],
      characterLabels: [],
      characterChosenIcon: null,
      gridBackground: null,
      scoreText: null,
      damageQueueDisplay: null,
      storedOrbsContainer: null,

      xp: 0,
      level: 1,
      xpText: null,

      invisibleAddPlayerButton: null,
      touchButtons: null,

      chainNotification: null,
      chainTween: null,

      gameOverOverlay: null,

      isCPU: false,
      cpuInterval: null,
    };

    this.createGrid(player);
    this.createUI(player, playerIndex);
    this.setupPlayerInput(player, playerIndex);
    this.createStoredOrbsUI(player);

    if (!this.isClassicMode) {
      this.createCharacterSelectUI(player);
    }

    this.players.push(player);

    if (this.players.length === 1) {
      this.singlePlayerBonusActive = true;
    } else {
      this.singlePlayerBonusActive = false;
    }

    return player;
  }

  removePlayer() {
    const removedPlayer = this.players.pop();
    if (!removedPlayer) return;

    removedPlayer.isRemoved = true;

    removedPlayer.grid.forEach(row => {
      row.forEach(cell => {
        if (cell) {
          cell.destroy();
        }
      });
    });

    if (removedPlayer.currentPair) {
      removedPlayer.currentPair.ball1.destroy();
      removedPlayer.currentPair.ball2.destroy();
    }
    if (removedPlayer.ghostPair) {
      removedPlayer.ghostPair.ghost1.destroy();
      removedPlayer.ghostPair.ghost2.destroy();
    }

    if (removedPlayer.scoreText) {
      removedPlayer.scoreText.destroy();
    }
    if (removedPlayer.gridBackground) {
      removedPlayer.gridBackground.destroy();
    }
    if (removedPlayer.damageQueueDisplay) {
      removedPlayer.damageQueueDisplay.destroy();
    }

    if (removedPlayer.characterIcons) {
      removedPlayer.characterIcons.forEach(icon => icon.destroy());
    }
    if (removedPlayer.characterIconOutlines) {
      removedPlayer.characterIconOutlines.forEach(outline => outline.destroy());
    }
    if (removedPlayer.characterLabels) {
      removedPlayer.characterLabels.forEach(label => label.destroy());
    }
    if (removedPlayer.characterChosenIcon) {
      removedPlayer.characterChosenIcon.destroy();
    }

    if (removedPlayer.nextPairContainer) {
      removedPlayer.nextPairContainer.destroy();
      removedPlayer.nextPairContainer = null;
    }
    removedPlayer.nextPairOrbs = [];
    removedPlayer.nextPairText = null;
    removedPlayer.upcomingPairs = [];

    if (removedPlayer.touchButtons) {
      removedPlayer.touchButtons.destroy();
      removedPlayer.touchButtons = null;
    }
    if (removedPlayer.chainNotification) {
      removedPlayer.chainNotification.destroy();
      removedPlayer.chainNotification = null;
    }
    if (removedPlayer.chainTween) {
      removedPlayer.chainTween.stop();
      removedPlayer.chainTween = null;
    }

    if (removedPlayer.characterChosenIconBackground) {
      removedPlayer.characterChosenIconBackground.destroy();
      removedPlayer.characterChosenIconBackground = null;
    }
    if (removedPlayer.abilityContainer) {
      removedPlayer.abilityContainer.destroy();
      removedPlayer.abilityContainer = null;
    }

    if (removedPlayer.storedOrbsContainer) {
      removedPlayer.storedOrbsContainer.destroy();
      removedPlayer.storedOrbsContainer = null;
    }

    if (removedPlayer.gameOverOverlay) {
      removedPlayer.gameOverOverlay.destroy();
      removedPlayer.gameOverOverlay = null;
    }

    if (removedPlayer.xpCircleBackground) {
      removedPlayer.xpCircleBackground.destroy();
      removedPlayer.xpCircleBackground = null;
    }
    if (removedPlayer.xpCircleFill) {
      removedPlayer.xpCircleFill.destroy();
      removedPlayer.xpCircleFill = null;
    }
    if (removedPlayer.xpLevelText) {
      removedPlayer.xpLevelText.destroy();
      removedPlayer.xpLevelText = null;
    }

    if (removedPlayer.xpToggleCPUButton) {
      removedPlayer.xpToggleCPUButton.destroy();
      removedPlayer.xpToggleCPUButton = null;
    }

    if (removedPlayer.invisibleAddPlayerButton) {
      removedPlayer.invisibleAddPlayerButton.destroy();
      removedPlayer.invisibleAddPlayerButton = null;
    }

    removedPlayer.inputHandlers.forEach(({ eventName, callback }) => {
      this.input.keyboard.off(eventName, callback, this);
      if (eventName === "pointerdown") {
        this.input.off("pointerdown", callback, this);
      }
    });
    removedPlayer.inputHandlers = [];
  }

  createGrid(player) {
    for (let y = 0; y < GRID_HEIGHT; y++) {
      player.grid[y] = [];
      for (let x = 0; x < GRID_WIDTH; x++) {
        player.grid[y][x] = null;
      }
    }
  }

  applyGravity(player, callback) {
    let moved = true;
    const gravityTweens = [];

    const performGravity = () => {
      moved = false;

      for (let y = GRID_HEIGHT - 1; y >= 0; y--) {
        for (let x = 0; x < GRID_WIDTH; x++) {
          const ball = player.grid[y][x];
          if (!ball) continue;

          const gravityDirs = this.getHexGravityDirections(x);
          for (const dir of gravityDirs) {
            const nx = x + dir.dx;
            const ny = y + dir.dy;
            if (
              ny >= 0 &&
              ny < GRID_HEIGHT &&
              nx >= 0 &&
              nx < GRID_WIDTH &&
              !player.grid[ny][nx]
            ) {
              player.grid[ny][nx] = ball;
              player.grid[y][x] = null;

              const { px, py } = this.getHexPosition(nx, ny, player.xOffset);

              const dx = px - ball.x;
              const dy = py - ball.y;

              const rotationDelta = this.calculateRotationDelta(dir.dx, dir.dy);
              const newRotation = ball.rotation + rotationDelta;

              ball.rotation = Phaser.Math.Wrap(newRotation, 0, Math.PI * 2);

              const tween = this.tweens.add({
                targets: ball,
                x: px,
                y: py,
                rotation: ball.rotation,
                duration: 100,
                ease: "Power2",
                onComplete: () => {
                  if (gravityTweens.length === 0 && callback) {
                    callback();
                  }
                },
              });

              gravityTweens.push(tween);
              moved = true;
              break;
            }
          }
        }
      }

      if (!moved) {
        if (callback) {
          callback();
        }
      } else {
        if (gravityTweens.length > 0) {
          this.time.delayedCall(25, performGravity);
        }
      }
    };

    performGravity();
  }

  lockPair(player) {
    const { x, y, ball1, ball2, horizontal } = player.currentPair;

    if (y < -1) {
      this.gameOver(player);
      return;
    }

    if (y === -1) {
      if (!this.canMove(player, x, y + 1)) {
        this.gameOver(player);
        return;
      }
      return;
    }

    player.grid[y][x] = ball1;
    if (horizontal) {
      player.grid[y][x + 1] = ball2;
    } else {
      player.grid[y + 1][x] = ball2;
    }

    this.removeGhostPair(player);

    this.applyGravityNoRoll(player, () => {
      this.applyGravity(player, () => {
        this.handleDamageQueue(player, () => {
          if (this.checkIfAnyOrbAboveGrid(player)) {
            this.gameOver(player);
            return;
          }

          this.checkMatches(player, () => {
            if (this.checkIfAnyOrbAboveGrid(player)) {
              this.gameOver(player);
              return;
            }

            player.playerTurns++;
            player.soloPlayerTurns++;

            if (
              player?.selectedCharacter?.name === "Ember" &&
              player.playerTurns % 10 === 0
            ) {
              const validOrbs = [];
              for (let gy = 0; gy < GRID_HEIGHT; gy++) {
                for (let gx = 0; gx < GRID_WIDTH; gx++) {
                  const orb = player.grid[gy][gx];
                  if (
                    orb &&
                    !this.isEffectOrb(orb.originalColor) &&
                    orb.originalColor !== FIRE_COLOR
                  ) {
                    validOrbs.push({ x: gx, y: gy, orb });
                  }
                }
              }

              if (validOrbs.length > 0) {
                const { x: vx, y: vy, orb } = Phaser.Math.RND.pick(validOrbs);

                const explosion = this.add
                  .sprite(orb.x, orb.y, "fragExplosion")
                  .setOrigin(0.5)
                  .setScale(0.5);
                explosion.setDepth(999);
                explosion.play("fragExplosion");
                explosion.once("animationcomplete", () => {
                  explosion.destroy();
                });

                orb.destroy();

                player.grid[vy][vx] = this.createBall(
                  vx,
                  vy,
                  IGNITION_COLOR,
                  player
                );
              }
            }

            if (this.singlePlayerBonusActive) {
              if (
                player.soloPlayerTurns >= SINGLE_PLAYER_BONUS_MODE_ATTACK_TURNS
              ) {
                const effectOrbs = [JUNK_COLOR /* etc. */];
                const baseOrbs = 1;
                const bonusOrbs = Math.min(player.level - 1, 9);
                const totalOrbs = baseOrbs + bonusOrbs;

                for (let i = 0; i < totalOrbs; i++) {
                  const randomEffectOrb = Phaser.Math.RND.pick(effectOrbs);
                  player.damageQueue.push(randomEffectOrb);
                }
                player.soloPlayerTurns = 0;
                this.updateDamageQueueDisplay(player);

                if (this.checkIfTopRowFull(player)) {
                  this.gameOver(player);
                  return;
                }

                this.spawnNewPair(player);
                player.isBusy = false;
                return;
              }

              while (player.level >= player.nextAnvilReplaceLevel) {
                for (let gy = 0; gy < GRID_HEIGHT; gy++) {
                  for (let gx = 0; gx < GRID_WIDTH; gx++) {
                    const orb = player.grid[gy][gx];
                    if (orb && orb.originalColor === ANVIL_COLOR) {
                      orb.destroy();
                      player.grid[gy][gx] = this.createBall(
                        gx,
                        gy,
                        JUNK_COLOR,
                        player
                      );
                    }
                  }
                }

                this.baseOrbs++;
                player.nextAnvilReplaceLevel += 25;
              }
            }
            if (player.abilityIsToggled && player.selectedCharacter) {
              const currentAbility =
                player.selectedCharacter.abilities[player.currentAbilityIndex];

              if (
                currentAbility?.cost &&
                this.hasEnoughOrbsForAbility(player, currentAbility.cost)
              ) {
                this.removeOrbsForAbility(player, currentAbility.cost);
                this.useAbility(player, currentAbility);
                // Optionally toggle off after use:
                // player.abilityIsToggled = false;
              }
            }

            this.spawnNewPair(player);
            player.isBusy = false;

            if (this.players.length > 1 && !player.isGameOver) {
              const otherActivePlayers = this.players.filter(
                p => p !== player && !p.isGameOver
              );
              if (otherActivePlayers.length === 0) {
                player.isWinner = true;
                this.gameOver(player);
                return;
              }
            }
          });
        });
      });
    });
  }

  addElementOrbsToPlayer(player, color, quantity) {
    if (!ORB_CATEGORIES.ELEMENT.orbs[color]) {
      return;
    }

    const storedOrbEntry = player.storedOrbs.find(o => o.color === color);
    if (storedOrbEntry) {
      storedOrbEntry.quantity += quantity;
    } else {
      player.storedOrbs.push({ color, quantity });
    }

    const totalOrbEntry = player.totalOrbsCrushed.find(o => o.color === color);
    if (totalOrbEntry) {
      totalOrbEntry.quantity += quantity;
    } else {
      player.totalOrbsCrushed.push({ color, quantity });
    }

    this.updateStoredOrbsUI(player);
  }

  collectClustersForColor(player, color) {
    const visited = new Set();
    const clusters = [];

    const checkAdjacentMatches = (sx, sy, outputPositions) => {
      if (sx < 0 || sx >= GRID_WIDTH || sy < 0 || sy >= GRID_HEIGHT) {
        return;
      }

      const orb = player.grid[sy][sx];
      if (!orb) return;

      const key = `${sx},${sy}`;
      if (visited.has(key)) return;

      // Decide if it can join this cluster:
      // 1) same color
      // 2) or Quiver if the player is Flora
      const isBaseColor = orb.originalColor === color;
      const isFloraQuiver =
        player?.selectedCharacter?.name === "Flora" &&
        orb.originalColor === QUIVER_COLOR;

      if (!isBaseColor && !isFloraQuiver) {
        return;
      }

      visited.add(key);
      outputPositions.push({ x: sx, y: sy });

      const neighbors = this.getHexNeighbors(sx, sy);
      neighbors.forEach(n => checkAdjacentMatches(n.x, n.y, outputPositions));
    };

    for (let y = 0; y < GRID_HEIGHT; y++) {
      for (let x = 0; x < GRID_WIDTH; x++) {
        const key = `${x},${y}`;
        if (visited.has(key)) continue;

        const orb = player.grid[y][x];
        if (!orb) continue;

        const isBaseColor = orb.originalColor === color;
        const isFloraQuiver =
          player?.selectedCharacter?.name === "Flora" &&
          orb.originalColor === QUIVER_COLOR;
        if (isBaseColor || isFloraQuiver) {
          const newCluster = [];
          checkAdjacentMatches(x, y, newCluster);
          if (newCluster.length > 0) {
            clusters.push(newCluster);
          }
        }
      }
    }

    return clusters;
  }

  checkMatches(player, callback, chainCount = 0) {
    if (player?.selectedCharacter?.name === "Lance") {
      this.checkMatchesForLance(player, callback, chainCount);
      return;
    }

    if (player?.selectedCharacter?.name === "Flora") {
      this.checkMatchesForFlora(player, callback, chainCount);
      return;
    }

    let emberMatchRequired = MATCHES_REQUIRED;
    if (player?.selectedCharacter?.name === "Ember") {
      emberMatchRequired += 1;
    }

    let matchFound = false;
    const visited = new Set();
    const foundMatches = [];

    const checkAdjacentMatches = (sx, sy, baseColor, matches) => {
      if (sx < 0 || sx >= GRID_WIDTH || sy < 0 || sy >= GRID_HEIGHT) return;

      const orb = player.grid[sy][sx];
      if (!orb) return;

      const key = `${sx},${sy}`;
      if (visited.has(key)) return;

      const sameColor = orb.originalColor === baseColor;

      const floraQuiver =
        player?.selectedCharacter?.name === "Flora" &&
        orb.originalColor === QUIVER_COLOR;

      if (!sameColor && !floraQuiver) {
        return;
      }

      visited.add(key);
      matches.push({ x: sx, y: sy });

      const neighbors = this.getHexNeighbors(sx, sy);
      neighbors.forEach(n => {
        checkAdjacentMatches(n.x, n.y, baseColor, matches);
      });
    };

    for (let y = 0; y < GRID_HEIGHT; y++) {
      for (let x = 0; x < GRID_WIDTH; x++) {
        if (visited.has(`${x},${y}`)) continue;

        const orb = player.grid[y][x];
        if (!orb) continue;

        const color = orb.originalColor;

        if (
          this.isEffectOrb(color) &&
          color !== IGNITION_COLOR &&
          !(
            player?.selectedCharacter?.name === "Flora" &&
            color === QUIVER_COLOR
          )
        ) {
          visited.add(`${x},${y}`);
          continue;
        }

        const matches = [];
        checkAdjacentMatches(x, y, color, matches);

        if (matches.length > 0) {
          const nonCrackedOrbs = matches.filter(({ x, y }) => {
            const orbObj = player.grid[y][x];
            return orbObj && !orbObj.isCracked;
          });

          if (nonCrackedOrbs.length >= emberMatchRequired) {
            if (player?.selectedCharacter?.name === "Flora") {
              const hasQuiver = nonCrackedOrbs.some(({ x, y }) => {
                const orbObj = player.grid[y][x];
                return orbObj?.originalColor === QUIVER_COLOR;
              });
              if (!hasQuiver) {
                continue;
              }
            }

            foundMatches.push({
              color,
              size: matches.length,
              positions: matches,
            });
          }
        }
      }
    }

    this.processMatchesForXPAndChainAndDamage(
      player,
      foundMatches,
      chainCount,
      this.checkMatches,
      callback
    );
  }

  handleChainDamage(player, chainCount, baseDamage) {
    if (chainCount >= 1) {
      baseDamage += 0.1;
    }
    if (chainCount >= 2) {
      baseDamage += 1;
    }

    const multiplier = this.getChainMultiplier(chainCount);
    let playerMultiplier = player.level * 0.05;

    const finalDamage = Math.floor(
      baseDamage * (multiplier + playerMultiplier)
    );

    if (chainCount >= 2) {
      this.showChainNotification(player, chainCount);
    }

    if (this.players.length > 1 && finalDamage > 0) {
      const currentIndex = this.players.indexOf(player);

      let nextIndex = (currentIndex + 1) % this.players.length;
      let loopCount = 0;

      while (
        (this.players[nextIndex] === player ||
          this.players[nextIndex].isGameOver) &&
        loopCount < this.players.length
      ) {
        nextIndex = (nextIndex + 1) % this.players.length;
        loopCount++;
      }

      if (
        this.players[nextIndex] === player ||
        this.players[nextIndex].isGameOver
      ) {
        return;
      }

      const target = this.players[nextIndex];

      let attackOrbColor;
      if (
        this.isRPGMode &&
        player.selectedCharacter &&
        player.selectedCharacter.customAttackOrbWeightings
      ) {
        attackOrbColor = this.selectOrbFromWeightings(
          player.selectedCharacter.customAttackOrbWeightings
        );
      } else {
        // Default effect orbs to send as attacks
        const effectOrbs = [
          JUNK_COLOR,
          // BONE_COLOR,
          // MIMIC_COLOR,
          // KNIGHT_COLOR,
          // ANVIL_COLOR,
          // ARMOR_COLOR,
          // THORN_COLOR,
          // QUIVER_COLOR,
          // ROOT_COLOR,
          // SEED_COLOR,
          // IGNITION_COLOR,
          // CHARRED_COLOR,
        ];
        attackOrbColor = Phaser.Math.RND.pick(effectOrbs);
      }

      for (let i = 0; i < finalDamage; i++) {
        target.damageQueue.push(attackOrbColor);
      }

      this.updateDamageQueueDisplay(target);
    }
  }

  getChainMultiplier(newChainCount) {
    if (newChainCount === 0) {
      return 0.1;
    } else if (newChainCount >= 1 && newChainCount <= 100) {
      return 1.0 + 0.25 * (newChainCount - 1);
    } else {
      throw new Error("newChainCount out of supported range (0-100)");
    }
  }

  processMatchesForXPAndChainAndDamage(
    player,
    foundMatches,
    chainCount,
    nextCheckFunction,
    callback
  ) {
    if (!foundMatches || foundMatches.length === 0) {
      callback?.();
      return;
    }

    let emberMatchRequired = MATCHES_REQUIRED;
    let emberAttackRequired = MATCHES_REQUIRED + 1;

    if (player?.selectedCharacter?.name === "Ember") {
      emberMatchRequired += 1;
      emberAttackRequired += 1;
    }

    const newChainCount = chainCount + foundMatches.length;
    if (newChainCount > player.highestChain) {
      player.highestChain = newChainCount;
    }

    let xpFromMatches = foundMatches.reduce((totalXp, matchGroup) => {
      const totalOrbCount = matchGroup.positions.length;
      const xpN = totalOrbCount - MATCHES_REQUIRED + 1;
      const xpForGroup = 1 + (xpN > 0 ? xpN : 0);
      return totalXp + xpForGroup;
    }, 0);

    const chainMultiplier = this.getChainMultiplier(newChainCount);
    xpFromMatches = Math.ceil(xpFromMatches * chainMultiplier);
    this.addXP(player, xpFromMatches);

    const totalMatchDamage = foundMatches.reduce((sum, matchGroup) => {
      const nonCrackedCount = matchGroup.positions.reduce((count, pos) => {
        const orb = player.grid[pos.y][pos.x];
        return orb && !orb.isCracked ? count + 1 : count;
      }, 0);

      if (player?.selectedCharacter?.name === "Ember") {
        if (
          matchGroup.color === IGNITION_COLOR &&
          nonCrackedCount >= emberAttackRequired
        ) {
          return sum + (nonCrackedCount - MATCHES_REQUIRED);
        } else {
          return sum;
        }
      } else {
        if (nonCrackedCount >= MATCHES_REQUIRED + 1) {
          return sum + (nonCrackedCount - MATCHES_REQUIRED);
        }
        return sum;
      }
    }, 0);

    this.handleChainDamage(player, newChainCount, totalMatchDamage);

    foundMatches.forEach(matchGroup => {
      const totalOrbCount = matchGroup.positions.length;
      this.addElementOrbsToPlayer(player, matchGroup.color, totalOrbCount);
    });

    const allMatchedBalls = [];
    const matchedPositions = [];
    foundMatches.forEach(matchGroup => {
      matchGroup.positions.forEach(({ x, y }) => {
        allMatchedBalls.push({ x, y, ball: player.grid[y][x] });
        matchedPositions.push({ x, y });
      });
    });

    this.processMatchedBalls(
      player,
      allMatchedBalls,
      matchedPositions,
      newChainCount,
      nextCheckFunction.bind(this),
      callback
    );
  }

  processMatchedBalls(
    player,
    allMatchedBalls,
    matchedPositions,
    newChainCount,
    nextCheckFunction,
    callback
  ) {
    this.flashAndRemoveBalls(player, allMatchedBalls, () => {
      this.convertAdjacentJunkOrbs(player, matchedPositions);
      this.removeAdjacentBoneOrbs(player, matchedPositions);
      this.convertAdjacentMimicOrbs(player, matchedPositions);
      this.handleKnightOrbs(player, matchedPositions);
      this.removeAdjacentRootOrbs(player, matchedPositions);
      this.removeAdjacentCharredOrbs(player, matchedPositions);

      this.applyGravity(player, () => {
        nextCheckFunction(
          player,
          () => {
            if (callback) callback();
          },
          newChainCount
        );
      });
    });
  }

  convertAdjacentJunkOrbs(player, matchedPositions) {
    const junkOrbsToConvert = new Set();

    matchedPositions.forEach(pos => {
      const neighbors = this.getHexNeighbors(pos.x, pos.y);
      neighbors.forEach(n => {
        const neighborOrb = player.grid[n.y][n.x];
        if (neighborOrb && neighborOrb.originalColor === JUNK_COLOR) {
          const key = `${n.x},${n.y}`;
          junkOrbsToConvert.add(key);
        }
      });
    });

    junkOrbsToConvert.forEach(key => {
      const [x, y] = key.split(",").map(Number);
      const ball = player.grid[y][x];

      if (ball && ball.originalColor === JUNK_COLOR) {
        const orbX = ball.x;
        const orbY = ball.y;

        ball.setAlpha(0);

        const explosion = this.add
          .sprite(orbX, orbY, "voidExplosion")
          .setOrigin(0.5)
          .setScale(0.25);

        explosion.setDepth(999);

        explosion.play("voidExplosion");

        explosion.once("animationcomplete", () => {
          explosion.destroy();
        });

        ball.destroy();
        this.addXP(player, 1);

        const newColor = this.getOrbColorForPlayer(player);
        player.grid[y][x] = this.createBall(x, y, newColor, player);
      }
    });
  }

  removeAdjacentBoneOrbs(player, matchedPositions) {
    const specialOrbsToRemove = new Set();

    matchedPositions.forEach(pos => {
      const neighbors = this.getHexNeighbors(pos.x, pos.y);
      neighbors.forEach(n => {
        const neighborOrb = player.grid[n.y][n.x];
        if (neighborOrb && neighborOrb.originalColor === BONE_COLOR) {
          const key = `${n.x},${n.y}`;
          specialOrbsToRemove.add(key);
        }
      });
    });

    specialOrbsToRemove.forEach(key => {
      const [x, y] = key.split(",").map(Number);
      const ball = player.grid[y][x];
      if (ball && ball.originalColor === BONE_COLOR) {
        player.grid[y][x] = null;
        ball.destroy();
        this.addXP(player, 1);
      }
    });
  }

  convertAdjacentMimicOrbs(player, matchedPositions) {
    const mimicOrbsToConvert = new Set();

    matchedPositions.forEach(pos => {
      const neighbors = this.getHexNeighbors(pos.x, pos.y);
      neighbors.forEach(n => {
        const neighborOrb = player.grid[n.y][n.x];
        if (neighborOrb && neighborOrb.originalColor === MIMIC_COLOR) {
          const key = `${n.x},${n.y}`;
          mimicOrbsToConvert.add(key);
        }
      });
    });

    mimicOrbsToConvert.forEach(key => {
      const [x, y] = key.split(",").map(Number);
      const ball = player.grid[y][x];
      if (ball && ball.originalColor === MIMIC_COLOR) {
        ball.destroy();
        this.addXP(player, 1);

        const newColor = this.getOrbColorForPlayer(player);
        player.grid[y][x] = this.createBall(x, y, newColor, player);
      }
    });
  }

  handleKnightOrbs(player, matchedPositions) {
    const knightsToInjure = new Set();
    const knightsToRemove = new Set();

    matchedPositions.forEach(({ x, y }) => {
      const neighbors = this.getHexNeighbors(x, y);
      neighbors.forEach(({ x: nx, y: ny }) => {
        const neighborOrb = player.grid[ny][nx];
        if (!neighborOrb) return;

        if (neighborOrb.originalColor === KNIGHT_COLOR) {
          knightsToInjure.add(`${nx},${ny}`);
        } else if (neighborOrb.originalColor === KNIGHT_INJURED_COLOR) {
          knightsToRemove.add(`${nx},${ny}`);
        }
      });
    });

    knightsToInjure.forEach(key => {
      const [kx, ky] = key.split(",").map(Number);
      const orb = player.grid[ky][kx];
      if (orb && orb.originalColor === KNIGHT_COLOR) {
        orb.destroy();
        this.addXP(player, 1);

        player.grid[ky][kx] = this.createBall(
          kx,
          ky,
          KNIGHT_INJURED_COLOR,
          player
        );
      }
    });

    knightsToRemove.forEach(key => {
      const [kx, ky] = key.split(",").map(Number);
      const orb = player.grid[ky][kx];
      if (orb && orb.originalColor === KNIGHT_INJURED_COLOR) {
        player.grid[ky][kx] = null;
        orb.destroy();
        this.addXP(player, 1);
      }
    });
  }

  removeAdjacentRootOrbs(player, matchedPositions) {
    const specialOrbsToRemove = new Set();

    matchedPositions.forEach(pos => {
      const neighbors = this.getHexNeighbors(pos.x, pos.y);

      neighbors.forEach(n => {
        const neighborOrb = player.grid[n.y][n.x];
        if (neighborOrb && neighborOrb.originalColor === ROOT_COLOR) {
          const key = `${n.x},${n.y}`;
          specialOrbsToRemove.add(key);
        }
      });
    });

    specialOrbsToRemove.forEach(key => {
      const [x, y] = key.split(",").map(Number);
      const orb = player.grid[y][x];
      if (orb && orb.originalColor === ROOT_COLOR) {
        player.grid[y][x] = null;
        orb.destroy();
      }
    });
  }

  removeAdjacentCharredOrbs(player, matchedPositions) {
    const specialOrbsToRemove = new Set();

    matchedPositions.forEach(pos => {
      const neighbors = this.getHexNeighbors(pos.x, pos.y);

      neighbors.forEach(n => {
        const neighborOrb = player.grid[n.y][n.x];
        if (neighborOrb && neighborOrb.originalColor === CHARRED_COLOR) {
          const key = `${n.x},${n.y}`;
          specialOrbsToRemove.add(key);
        }
      });
    });

    specialOrbsToRemove.forEach(key => {
      const [x, y] = key.split(",").map(Number);
      const orb = player.grid[y][x];
      if (orb && orb.originalColor === CHARRED_COLOR) {
        player.grid[y][x] = null;
        orb.destroy();
      }
    });
  }

  gameOver(player) {
    for (let y = GRID_HEIGHT - 1; y >= 0; y--) {
      for (let x = 0; x < GRID_WIDTH; x++) {
        const orb = player.grid[y][x];
        if (orb) {
          orb.removeAll(true);

          const ballGraphic = this.add.graphics();
          ballGraphic.fillStyle(GAME_OVER_COLOR, 1);
          ballGraphic.fillCircle(0, 0, CELL_SIZE / 2 - 2);
          orb.add(ballGraphic);
        }
      }
    }

    player.isGameOver = true;
    player.isBusy = false;

    if (player.currentPair) {
      player.currentPair.ball1.destroy();
      player.currentPair.ball2.destroy();
      player.currentPair = null;
    }
    this.removeGhostPair(player);
    this.showGameOverOverlay(player);
  }

  resetGame(player) {
    for (let y = 0; y < GRID_HEIGHT; y++) {
      for (let x = 0; x < GRID_WIDTH; x++) {
        if (player.grid[y][x]) {
          player.grid[y][x].destroy();
          player.grid[y][x] = null;
        }
      }
    }

    if (player.gameOverOverlay) {
      player.gameOverOverlay.destroy();
      player.gameOverOverlay = null;
    }

    player.xp = 0;
    player.level = 1;
    player.highestChain = 0;
    player.playerTurns = 0;
    player.soloPlayerTurns = 0;
    player.totalOrbsCrushed = [];
    player.storedOrbs = [];
    player.isGameOver = false;
    player.isWinner = false;
    player.isBusy = false;
    player.damageQueue = [];

    if (player.currentPair) {
      if (player.currentPair.ball1) {
        player.currentPair.ball1.destroy();
        player.currentPair.ball1 = null;
      }
      if (player.currentPair.ball2) {
        player.currentPair.ball2.destroy();
        player.currentPair.ball2 = null;
      }
      player.currentPair = null;
    }

    this.updateDamageQueueDisplay(player);
    this.updateStoredOrbsUI(player);
    this.updateXPDisplay(player);

    this.createGrid(player);
    this.spawnNewPair(player);

    if (
      !player.selectedCharacter ||
      !player.selectedCharacter.abilities ||
      player.selectedCharacter.abilities.length === 0
    ) {
      return;
    }
    player.currentAbilityIndex = 0;
    player.abilityIsToggled = false;
    this.updateAbilityUI(player);
  }

  flashAndRemoveBalls(player, matchedBalls, onComplete) {
    const uniqueMap = new Map();
    matchedBalls.forEach(({ x, y, ball }) => {
      if (!ball) return;
      const key = `${x},${y}`;
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, { x, y, ball });
      }
    });

    const finalBalls = [...uniqueMap.values()];

    const flashCount = 6;
    const flashDuration = 325;
    const interval = flashDuration / flashCount;

    let flashIndex = 0;
    let explosionsCompleted = 0;
    const totalExplosions = finalBalls.length;

    const flashEvent = this.time.addEvent({
      delay: interval,
      repeat: flashCount - 1,
      callback: () => {
        flashIndex++;

        finalBalls.forEach(({ x, y, ball }) => {
          if (!ball || !ball.scene) return;

          const ballGraphic = ball.getAt(0);
          const spiralGraphic = ball.getAt(1);
          if (!ballGraphic || !spiralGraphic) return;

          ballGraphic.clear();
          if (flashIndex % 2 === 1) {
            ballGraphic.fillStyle(0xffffff, 1);
          } else {
            if (flashIndex === flashCount) {
              ballGraphic.fillStyle(0xffffff, 0);
            } else {
              ballGraphic.fillStyle(ball.originalColor, 1);
            }
          }
          ballGraphic.fillCircle(0, 0, CELL_SIZE / 2 - 2);

          if (flashIndex === flashCount) {
            spiralGraphic.setAlpha(0);
            player.grid[y][x] = null;
            ball.destroy();

            const { x: orbX, y: orbY } = ball;
            const randomOffsetX = Phaser.Math.FloatBetween(-0.1, 0.1);
            const randomOffsetY = Phaser.Math.FloatBetween(-0.1, 0.1);
            const randomScale = Phaser.Math.FloatBetween(1.2, 1.7);

            const explosion = this.add
              .sprite(orbX, orbY, "explosionA01")
              .setOrigin(0.5 + randomOffsetX, 0.5 + randomOffsetY)
              .setScale(randomScale);

            explosion.setDepth(999);
            explosion.play("explosionA");

            explosion.once("animationcomplete", () => {
              explosion.destroy();

              explosionsCompleted++;

              if (explosionsCompleted === totalExplosions) {
                if (onComplete) onComplete();
              }
            });
          }
        });

        if (flashIndex === flashCount) {
          flashEvent.remove();
        }
      },
    });
  }

  checkIfTopRowFull(player) {
    for (let x = 0; x < GRID_WIDTH; x++) {
      if (player.grid[0][x] !== null) {
        return true;
      }
    }
    return false;
  }

  checkIfAnyOrbAboveGrid(player) {
    if (player.currentPair) {
      const { x, y, horizontal } = player.currentPair;
      if (y < -1) {
        return true;
      }
      if (!horizontal && y + 1 < -1) {
        return true;
      }
    }
    return false;
  }

  dropPair(player) {
    if (player.isGameOver) {
      this.resetGame(player);
      return;
    }

    if (!player.currentPair) return;
    player.isBusy = true;
    while (
      this.canMove(player, player.currentPair.x, player.currentPair.y + 1)
    ) {
      player.currentPair.y++;
    }
    this.updatePairPosition(player);

    this.time.delayedCall(150, () => {
      this.lockPair(player);
    });
  }

  updateNextPairDisplay(player) {
    if (!player || !player.nextPairContainer) {
      console.warn("Attempted to update display for a non-existent player.");
      return;
    }

    while (player.nextPairContainer.list.length > 1) {
      player.nextPairContainer.removeAt(1, true);
    }
    player.nextPairOrbs = [];

    if (player.upcomingPairs.length === 0) return;

    const { color1, color2 } = player.upcomingPairs[0];

    const orb1 = this.createPreviewOrb(color1);
    const orb2 = this.createPreviewOrb(color2);

    orb1.x = -20;
    orb1.y = 20;
    orb2.x = 5;
    orb2.y = 20;

    player.nextPairContainer.add(orb1);
    player.nextPairContainer.add(orb2);

    player.nextPairOrbs.push(orb1, orb2);
  }

  createGhostPair(color1, color2, player) {
    if (player.ghostPair) {
      player.ghostPair.ghost1.destroy();
      player.ghostPair.ghost2.destroy();

      if (player.ghostPair.loseIndicator1) {
        player.ghostPair.loseIndicator1.destroy();
      }
      if (player.ghostPair.loseIndicator2) {
        player.ghostPair.loseIndicator2.destroy();
      }
    }

    const ghost1 = this.createBall(0, 0, color1, player, 0.6, 0xffffff);
    const ghost2 = this.createBall(0, 0, color2, player, 0.6, 0xffffff);

    player.ghostPair = {
      ghost1,
      ghost2,
      horizontal: true,
      loseIndicator1: null,
      loseIndicator2: null,
    };

    const { b1pos, b2pos } = this.simulatePairDrop(player);
    const pos1 = this.getHexPosition(b1pos.x, b1pos.y, player.xOffset);
    const pos2 = this.getHexPosition(b2pos.x, b2pos.y, player.xOffset);

    ghost1.setPosition(pos1.px, pos1.py);
    ghost2.setPosition(pos2.px, pos2.py);
  }

  simulatePairDrop(player) {
    const tempGrid = this.cloneGrid(player.grid);
    let { x, y, horizontal } = player.currentPair;
    let unplaceable = false;

    const ghostRow = y < 0 ? 0 : y;

    if (
      ghostRow < 0 ||
      ghostRow >= GRID_HEIGHT ||
      x < 0 ||
      x >= GRID_WIDTH ||
      tempGrid[ghostRow][x]
    ) {
      unplaceable = true;
    } else {
      tempGrid[ghostRow][x] = "GHOST_BALL_1";
    }

    let x2 = x;
    let y2 = ghostRow;
    if (horizontal) {
      x2 = x + 1;
    } else {
      y2 = ghostRow + 1;
    }

    if (
      y2 < 0 ||
      y2 >= GRID_HEIGHT ||
      x2 < 0 ||
      x2 >= GRID_WIDTH ||
      tempGrid[y2][x2]
    ) {
      unplaceable = true;
    } else if (!unplaceable) {
      tempGrid[y2][x2] = "GHOST_BALL_2";
    }

    if (unplaceable) {
      return {
        b1pos: { x: -1000, y: -1000 },
        b2pos: { x: -1000, y: -1000 },
      };
    }

    let moved;
    do {
      moved = false;
      for (let row = GRID_HEIGHT - 1; row >= 0; row--) {
        for (let col = 0; col < GRID_WIDTH; col++) {
          if (
            tempGrid[row][col] === "GHOST_BALL_1" ||
            tempGrid[row][col] === "GHOST_BALL_2"
          ) {
            const nx = col;
            const ny = row + 1;
            if (ny < GRID_HEIGHT && !tempGrid[ny][nx]) {
              tempGrid[ny][nx] = tempGrid[row][col];
              tempGrid[row][col] = null;
              moved = true;
            }
          }
        }
      }
    } while (moved);

    do {
      moved = false;
      for (let row = GRID_HEIGHT - 1; row >= 0; row--) {
        for (let col = 0; col < GRID_WIDTH; col++) {
          if (
            tempGrid[row][col] === "GHOST_BALL_1" ||
            tempGrid[row][col] === "GHOST_BALL_2"
          ) {
            const gravityDirs = this.getHexGravityDirections(col);
            for (const { dx, dy } of gravityDirs) {
              const nx = col + dx;
              const ny = row + dy;
              if (
                nx >= 0 &&
                nx < GRID_WIDTH &&
                ny >= 0 &&
                ny < GRID_HEIGHT &&
                !tempGrid[ny][nx]
              ) {
                tempGrid[ny][nx] = tempGrid[row][col];
                tempGrid[row][col] = null;
                moved = true;
                break;
              }
            }
          }
        }
      }
    } while (moved);

    let b1pos = { x: -1, y: -1 };
    let b2pos = { x: -1, y: -1 };
    for (let row = 0; row < GRID_HEIGHT; row++) {
      for (let col = 0; col < GRID_WIDTH; col++) {
        if (tempGrid[row][col] === "GHOST_BALL_1") {
          b1pos = { x: col, y: row };
        } else if (tempGrid[row][col] === "GHOST_BALL_2") {
          b2pos = { x: col, y: row };
        }
      }
    }

    return { b1pos, b2pos };
  }

  cloneGrid(grid) {
    const newGrid = [];
    for (let y = 0; y < GRID_HEIGHT; y++) {
      newGrid[y] = [];
      for (let x = 0; x < GRID_WIDTH; x++) {
        newGrid[y][x] = grid[y][x];
      }
    }
    return newGrid;
  }

  updateGhostPairPosition(player) {
    if (!player.ghostPair || !player.currentPair) return;

    const { b1pos, b2pos } = this.simulatePairDrop(player);

    const unplaceable = b1pos.y < 0 && b2pos.y < 0;

    if (!unplaceable) {
      const pos1 = this.getHexPosition(b1pos.x, b1pos.y, player.xOffset);
      const pos2 = this.getHexPosition(b2pos.x, b2pos.y, player.xOffset);

      player.ghostPair.ghost1.setPosition(pos1.px, pos1.py);
      player.ghostPair.ghost2.setPosition(pos2.px, pos2.py);
    } else {
      player.ghostPair.ghost1.setPosition(-1000, -1000);
      player.ghostPair.ghost2.setPosition(-1000, -1000);
    }
  }

  removeGhostPair(player) {
    if (player.ghostPair) {
      player.ghostPair.ghost1.destroy();
      player.ghostPair.ghost2.destroy();
      player.ghostPair = null;
    }
  }

  createGhostBall(color, player) {
    const container = this.add.container(0, 0);
    const ring = this.add.graphics();
    ring.lineStyle(2, 0xffffff, 1);
    ring.strokeCircle(0, 0, CELL_SIZE / 2 - 2);
    container.add(ring);
    container.setAlpha(0.5);
    return container;
  }

  updateGhostPairPosition(player) {
    if (!player.ghostPair || !player.currentPair) return;

    const { b1pos, b2pos } = this.simulatePairDrop(player);

    const pos1 = this.getHexPosition(b1pos.x, b1pos.y, player.xOffset);
    const pos2 = this.getHexPosition(b2pos.x, b2pos.y, player.xOffset);

    player.ghostPair.ghost1.setPosition(pos1.px, pos1.py);
    player.ghostPair.ghost2.setPosition(pos2.px, pos2.py);
  }

  createBlinkingLoseIndicator() {
    const indicatorContainer = this.add.container(0, 0);

    const graphics = this.add.graphics();
    const radius = CELL_SIZE / 2 - 2;

    graphics.fillStyle(0x000, 1.0);
    graphics.fillCircle(0, 0, radius);

    graphics.lineStyle(4, 0xff0000, 1.0);
    graphics.strokeCircle(0, 0, radius);

    graphics.lineStyle(4, 0xff0000, 1.0);

    const offset = radius * 0.707;

    graphics.beginPath();
    graphics.moveTo(-offset, -offset);
    graphics.lineTo(offset, offset);
    graphics.strokePath();

    graphics.beginPath();
    graphics.moveTo(offset, -offset);
    graphics.lineTo(-offset, offset);
    graphics.strokePath();

    indicatorContainer.add(graphics);

    this.tweens.add({
      targets: indicatorContainer,
      alpha: { from: 0.5, to: 1.0 },
      duration: 250,
      yoyo: true,
      repeat: -1,
    });

    return indicatorContainer;
  }

  initializePlayerForGameScreen(player) {
    this.generateInitialUpcomingPairs(player, 2);
  }

  onGameScreenActivated() {
    this.players.forEach(player => {
      if (!player.upcomingPairsInitialized) {
        this.initializePlayerForGameScreen(player);
        player.upcomingPairsInitialized = true;
      }
    });
  }

  generateInitialUpcomingPairs(player, count) {
    for (let i = 0; i < count; i++) {
      player.upcomingPairs.push(this.generateRandomPair(player));
    }
  }

  generateRandomPair(player) {
    const color1 = this.getOrbColorForPlayer(player);
    const color2 = this.getOrbColorForPlayer(player);
    return { color1, color2 };
  }

  getRandomOrbColor() {
    const totalCategoryWeight = Object.values(ORB_CATEGORIES).reduce(
      (sum, category) => sum + category.weight,
      0
    );

    let rand = Phaser.Math.RND.frac() * totalCategoryWeight;
    let pickedCategoryKey = null;

    for (const [categoryKey, categoryValue] of Object.entries(ORB_CATEGORIES)) {
      if (rand <= categoryValue.weight) {
        pickedCategoryKey = categoryKey;
        break;
      }
      rand -= categoryValue.weight;
    }

    if (!pickedCategoryKey) {
      return STAR;
    }

    const chosenCategory = ORB_CATEGORIES[pickedCategoryKey];
    const totalOrbsWeight = Object.entries(chosenCategory.orbs).reduce(
      (sum, [, orbWeight]) => sum + orbWeight,
      0
    );

    if (totalOrbsWeight === 0) {
      return STAR;
    }

    let orbRand = Phaser.Math.RND.frac() * totalOrbsWeight;
    for (const [orbColorString, orbWeight] of Object.entries(
      chosenCategory.orbs
    )) {
      if (orbRand <= orbWeight) {
        return parseInt(orbColorString, 10);
      }
      orbRand -= orbWeight;
    }

    return STAR;
  }

  createBall(col, row, color, player, alpha = 1, outlineColor = null) {
    const { px, py } = this.getHexPosition(col, row, player.xOffset);

    const ball = this.add.container(px, py);

    ball.isCracked = false;

    const ballGraphic = this.add.graphics();
    ballGraphic.fillStyle(color, 1);
    ballGraphic.fillCircle(0, 0, CELL_SIZE / 2 - 2);
    ballGraphic.setAlpha(alpha);
    ball.add(ballGraphic);

    if (outlineColor !== null) {
      const outlineGraphic = this.add.graphics();
      outlineGraphic.lineStyle(2, outlineColor, 1);
      outlineGraphic.strokeCircle(0, 0, CELL_SIZE / 2 - 1);
      outlineGraphic.setAlpha(1);
      ball.add(outlineGraphic);
    }

    const addCenteredSprite = (texture, tint = null, scale = 1) => {
      const sprite = this.add.image(0, 0, texture);
      sprite.setOrigin(0.5);
      sprite.setDisplaySize(CELL_SIZE - 6, CELL_SIZE - 6);
      sprite.setAlpha(alpha);

      if (tint !== null) {
        sprite.setTint(tint);
      }

      if (scale !== 1) {
        sprite.setScale(scale);
      }

      ball.add(sprite);
    };

    switch (color) {
      case MIMIC_COLOR:
        addCenteredSprite("mimicOrb");
        break;

      case BONE_COLOR:
        addCenteredSprite("boneOrb");
        break;

      case KNIGHT_COLOR:
        addCenteredSprite("knightOrb");
        break;

      case KNIGHT_INJURED_COLOR:
        addCenteredSprite("knightInjuredOrb");
        break;

      case ANVIL_COLOR:
        const grayTint = 0x808080;
        addCenteredSprite("anvilOrb", grayTint);
        break;

      case ARMOR_COLOR:
        addCenteredSprite("armorOrb");
        break;

      case THORN_COLOR:
        addCenteredSprite("thornOrb", null, 0.175);
        break;

      case QUIVER_COLOR:
        addCenteredSprite("quiverOrb", null, 0.175);
        break;

      case ROOT_COLOR:
        addCenteredSprite("rootOrb", null, 0.175);
        break;

      case SEED_COLOR:
        addCenteredSprite("seedOrb", null, 0.175);
        break;

      case IGNITION_COLOR:
        addCenteredSprite("ignitionOrb", null, 0.25);
        break;

      case CHARRED_COLOR:
        addCenteredSprite("charredOrb", null, 0.175);
        break;

      case JUNK_COLOR:
        addCenteredSprite("junkOrb", null, 1.0);
        break;

      case FIRE_COLOR:
        addCenteredSprite("fireOrbSymbol", null, 0.055);
        break;

      case THUNDER_COLOR:
        addCenteredSprite("thunderOrbSymbol", null, 0.07);
        break;

      case ICE_COLOR:
        addCenteredSprite("iceOrbSymbol", null, 0.055);
        break;

      case DARK_COLOR:
        addCenteredSprite("darkOrbSymbol", null, 0.055);
        break;

      case NATURE_COLOR:
        addCenteredSprite("natureOrbSymbol", null, 0.055);
        break;

      case STEEL_COLOR:
        addCenteredSprite("steelOrbSymbol", null, 0.055);
        break;

      case HEART_COLOR:
        addCenteredSprite("heartOrbSymbol", null, 0.055);
        break;

      default:
        const spiralGraphic = this.add.graphics();
        spiralGraphic.lineStyle(2, 0xffffff, 0.5 * alpha);

        const centerX = 0;
        const centerY = 0;
        const radius = CELL_SIZE / 2 - 4;
        const turns = 2;
        const points = 50;

        spiralGraphic.beginPath();
        for (let i = 0; i < points; i++) {
          const t = i / (points - 1);
          const angle = turns * Math.PI * 2 * t;
          const scale = t * radius;
          const x = centerX + Math.cos(angle) * scale;
          const y = centerY + Math.sin(angle) * scale;

          if (i === 0) {
            spiralGraphic.moveTo(x, y);
          } else {
            spiralGraphic.lineTo(x, y);
          }
        }
        spiralGraphic.strokePath();
        spiralGraphic.setAlpha(alpha);
        ball.add(spiralGraphic);
        break;
    }

    ball.originalColor = color;
    ball.rotation = 0;
    return ball;
  }

  selectOrbFromWeightings(weightings) {
    const colors = Object.keys(weightings).map(color => parseInt(color, 10));
    const weights = Object.values(weightings);
    const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);

    if (totalWeight === 0) {
      return STAR; // Default fallback
    }

    let rand = Phaser.Math.RND.frac() * totalWeight;
    for (let i = 0; i < colors.length; i++) {
      if (rand < weights[i]) {
        return colors[i];
      }
      rand -= weights[i];
    }

    return STAR; // Fallback in case of rounding errors
  }

  isSameOrSubset(newLine, existingLine) {
    const existingSet = new Set(existingLine.map(pos => `${pos.x},${pos.y}`));

    for (const pos of newLine) {
      const key = `${pos.x},${pos.y}`;
      if (!existingSet.has(key)) {
        return false;
      }
    }
    return true;
  }

  checkAndPushMatch(foundMatches, linePositions, color, player) {
    if (linePositions.length === 0) return;

    const nonCrackedCount = linePositions.reduce((count, { x, y }) => {
      const orb = player.grid[y][x];
      return orb && !orb.isCracked ? count + 1 : count;
    }, 0);

    if (nonCrackedCount >= MATCHES_REQUIRED) {
      let skip = false;
      for (const existing of foundMatches) {
        if (
          existing.color === color &&
          this.isSameOrSubset(linePositions, existing.positions)
        ) {
          skip = true;
          break;
        }
      }

      if (!skip) {
        foundMatches.push({
          color,
          size: linePositions.length,
          positions: [...linePositions],
        });
      }
    }
  }

  getHexNeighbors(x, y) {
    const neighbors = [];
    if (x % 2 === 0) {
      neighbors.push({ x: x - 1, y: y });
      neighbors.push({ x: x + 1, y: y });
      neighbors.push({ x: x, y: y - 1 });
      neighbors.push({ x: x - 1, y: y - 1 });
      neighbors.push({ x: x + 1, y: y - 1 });
      neighbors.push({ x: x, y: y + 1 });
    } else {
      neighbors.push({ x: x - 1, y: y });
      neighbors.push({ x: x + 1, y: y });
      neighbors.push({ x: x, y: y - 1 });
      neighbors.push({ x: x, y: y + 1 });
      neighbors.push({ x: x - 1, y: y + 1 });
      neighbors.push({ x: x + 1, y: y + 1 });
    }

    return neighbors.filter(
      n => n.x >= 0 && n.x < GRID_WIDTH && n.y >= 0 && n.y < GRID_HEIGHT
    );
  }

  getHexGravityDirections(x) {
    if (x % 2 === 0) {
      return [
        { dx: 0, dy: 1 },
        { dx: -1, dy: 0 },
        { dx: 1, dy: 0 },
      ];
    } else {
      return [
        { dx: 0, dy: 1 },
        { dx: -1, dy: 1 },
        { dx: 1, dy: 1 },
      ];
    }
  }

  getHexGravityDirectionsNoRoll(x) {
    return [{ dx: 0, dy: 1 }];
  }

  showChainNotification(player, chainCount) {
    if (player.chainNotification) {
      player.chainNotification.destroy();
      player.chainNotification = null;
    }
    if (player.chainTween) {
      player.chainTween.stop();
      player.chainTween = null;
    }

    const baseFontSize = 32;
    const fontSize = baseFontSize + chainCount * 2;

    const baseXPosition = 88 + player.xOffset;
    const xPosition = baseXPosition - chainCount * 3;

    const notificationY = (GRID_HEIGHT * CELL_SIZE) / 2 - 55;
    const notification = this.add.text(
      xPosition,
      notificationY,
      `Chain ${chainCount}!`,
      {
        fontFamily: "RulerGold",
        fontSize: `${fontSize}px`,
        fill: "#F89710",
        stroke: "#000",
        strokeThickness: 7,
        padding: { x: 10, y: 5 },
        borderRadius: 5,
      }
    );

    notification.setDepth(1000);

    notification.setAlpha(0);

    const tween = this.tweens.add({
      targets: notification,
      alpha: 1,
      y: notificationY - 20,
      duration: 1250,
      ease: "Power2",
      yoyo: true,
      onComplete: () => {
        notification.destroy();
        player.chainNotification = null;
        player.chainTween = null;
      },
    });

    player.chainNotification = notification;
    player.chainTween = tween;
  }

  showLevelUpNotification(player) {
    const notification = this.add.text(
      65 + player.xOffset,
      (GRID_HEIGHT * CELL_SIZE) / 2,
      `Leveled Up!\nLevel ${player.level}!`,
      {
        fontFamily: "RulerGold",
        fontSize: "38px",
        fill: "#ff0",
        stroke: "#000",
        strokeThickness: 7,
        align: "center",
      }
    );

    notification.setDepth(1000);

    notification.setAlpha(0);
    this.tweens.add({
      targets: notification,
      alpha: 1,
      duration: 1500,
      ease: "Power1",
      yoyo: true,
      onComplete: () => {
        notification.destroy();
      },
    });
  }

  updateDamageQueueDisplay(player) {
    player.damageQueueDisplay.removeAll(true);

    const orbSize = 10;
    const orbSpacing = 2;
    const orbsPerColumn = 15;
    const totalOrbs = player.damageQueue.length;
    const totalColumns = Math.ceil(totalOrbs / orbsPerColumn);
    const startX = -40;

    for (let i = 0; i < totalOrbs; i++) {
      let orbColor = 0xb31b1b;

      const actualColor = player.damageQueue[i];
      if (actualColor === ARMOR_COLOR) {
        orbColor = 0x808080;
      } else if (actualColor === ANVIL_COLOR) {
        orbColor = 0xe4d00a;
      }

      const column = Math.floor(i / orbsPerColumn);
      const row = i % orbsPerColumn;

      const orbX = startX + column * (orbSize + orbSpacing);
      const orbY = -(row * (orbSize + orbSpacing));

      const orbGraphic = this.add.graphics();
      orbGraphic.fillStyle(orbColor, 1);
      orbGraphic.fillCircle(orbX, orbY, orbSize / 2);

      player.damageQueueDisplay.add(orbGraphic);
    }
  }

  createStoredOrbsUI(player) {
    const storedOrbsContainer = this.add.container(
      player.xOffset + GRID_WIDTH * CELL_SIZE - 35,
      200
    );

    storedOrbsContainer.setVisible(false);

    player.storedOrbsContainer = storedOrbsContainer;

    const bg = this.add
      .rectangle(0, 0, 120, 200, 0x000000, 0.2)
      .setOrigin(0, 0);
    storedOrbsContainer.add(bg);

    const orbsTitleText = this.add.text(5, 5, "Current", {
      fontFamily: "RulerGold",
      fontSize: "18px",
      color: "#ffffff",
      align: "left",
    });
    storedOrbsContainer.add(orbsTitleText);

    const orbsTitleBounds = orbsTitleText.getBounds();

    const invisibleAddPlayerButton = this.add
      .rectangle(
        orbsTitleBounds.x,
        orbsTitleBounds.y,
        orbsTitleBounds.width,
        orbsTitleBounds.height,
        0xff0000
      )
      .setOrigin(0, 0)
      .setAlpha(0.01)
      .setInteractive({ useHandCursor: true });

    invisibleAddPlayerButton.on("pointerdown", () => {
      this.handleAddPlayer();
    });

    player.invisibleAddPlayerButton = invisibleAddPlayerButton;

    this.updateStoredOrbsUI(player);
  }

  updateStoredOrbsUI(player) {
    if (!player.storedOrbsContainer) return;

    const container = player.storedOrbsContainer;

    while (container.list.length > 2) {
      const child = container.list[2];
      child.destroy();
    }

    let startY = 30;
    player.storedOrbs.forEach((orb, index) => {
      const orbGraphic = this.add.graphics();
      orbGraphic.fillStyle(orb.color, 1);
      orbGraphic.fillCircle(8, 8, 8);
      orbGraphic.x = 10;
      orbGraphic.y = startY + index * 24;
      container.add(orbGraphic);

      const qtyText = this.add.text(25, orbGraphic.y - 6, `x${orb.quantity}`, {
        fontFamily: "RulerGold",
        fontSize: "16px",
        color: "#ffffff",
      });
      container.add(qtyText);
    });
  }

  shutdown() {
    super.shutdown && super.shutdown();
    this.shutdownWebsocket();
  }

  destroy() {
    super.destroy && super.destroy();
    this.shutdownWebsocket();
  }

  preload() {
    let progressBox = this.add.graphics();
    let progressBar = this.add.graphics();

    let boxWidth = 320;
    let boxHeight = 50;
    let boxX = this.cameras.main.width / 2 - boxWidth / 2;
    let boxY = this.cameras.main.height / 2 - boxHeight / 2;

    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(boxX, boxY, boxWidth, boxHeight);

    let loadingText = this.make.text({
      x: this.cameras.main.width / 2,
      y: this.cameras.main.height / 2 - 50,
      text: "Loading...",
      style: {
        font: "20px monospace",
        fill: "#ffffff",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    this.load.on("progress", value => {
      progressBar.clear();
      progressBar.fillStyle(0xffffff, 1);
      progressBar.fillRect(
        boxX + 10,
        boxY + 10,
        (boxWidth - 20) * value,
        boxHeight - 20
      );
    });

    this.load.on("complete", () => {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
    });

    this.load.image("characterEmber", CHARACTER_EMBER);
    this.load.image("characterLance", CHARACTER_LANCE);
    this.load.image("characterFlora", CHARACTER_FLORA);
    this.load.image("characterRime", CHARACTER_RIME);
    this.load.image("characterSpike", CHARACTER_SPIKE);
    this.load.image("characterVoltage", CHARACTER_VOLTAGE);
    this.load.image("junkOrb", JUNK_ORB, { scale: 1 });
    this.load.svg("special_button", SPECIAL_BUTTON, { scale: 1 });
    this.load.image("mimicOrb", MIMIC_ORB);
    this.load.image("boneOrb", BONE_ORB);
    this.load.image("knightOrb", KNIGHT_ORB);
    this.load.image("knightInjuredOrb", KNIGHT_INJURED_ORB);
    this.load.image("anvilOrb", ANVIL_ORB);
    this.load.image("armorOrb", ARMOR_ORB);
    this.load.image("thornOrb", THORN_ORB, { scale: 1 });
    this.load.image("quiverOrb", QUIVER_ORB, { scale: 1 });
    this.load.image("crackedOrbEffect", CRACKED_ORB_EFFECT);
    this.load.image("rootOrb", ROOT_ORB);
    this.load.image("seedOrb", SEED_ORB);
    this.load.image("ignitionOrb", IGNITION_ORB, { scale: 1 });
    this.load.image("charredOrb", CHARRED_ORB, { scale: 1 });

    this.load.image("arrow_right", ARROW_RIGHT);

    this.load.svg("shieldAbility", SHIELD_ABILITY, { scale: 1 });
    this.load.svg("anvilAbility", ANVIL_ABILITY, { scale: 1 });
    this.load.svg("thornArrowAbility", THORN_ARROW_ABILITY, { scale: 1 });
    this.load.svg("loadBowAbility", LOAD_BOW_ABILITY, { scale: 1 });
    this.load.svg("wildGrowthAbility", WILD_GROWTH_ABILITY, { scale: 1 });
    this.load.svg("conflagrateAbility", CONFLAGRATE_ABILITY, { scale: 1 });
    this.load.svg("meteorStormAbility", METEOR_STORM_ABILITY, { scale: 1 });

    this.load.svg("fireOrbSymbol", FIRE_ORB_SYMBOL, { scale: 1 });
    this.load.svg("thunderOrbSymbol", THUNDER_ORB_SYMBOL, { scale: 1 });
    this.load.svg("iceOrbSymbol", ICE_ORB_SYMBOL, { scale: 1 });
    this.load.svg("darkOrbSymbol", DARK_ORB_SYMBOL, { scale: 1 });
    this.load.svg("natureOrbSymbol", NATURE_ORB_SYMBOL, { scale: 1 });
    this.load.svg("steelOrbSymbol", STEEL_ORB_SYMBOL, { scale: 1 });
    this.load.svg("heartOrbSymbol", HEART_ORB_SYMBOL, { scale: 1 });

    this.load.image("simpleExplosion00", SIMPLE_EXPLOSION_00);
    this.load.image("simpleExplosion01", SIMPLE_EXPLOSION_01);
    this.load.image("simpleExplosion02", SIMPLE_EXPLOSION_02);
    this.load.image("simpleExplosion03", SIMPLE_EXPLOSION_03);
    this.load.image("simpleExplosion04", SIMPLE_EXPLOSION_04);
    this.load.image("simpleExplosion05", SIMPLE_EXPLOSION_05);
    this.load.image("simpleExplosion06", SIMPLE_EXPLOSION_06);
    this.load.image("simpleExplosion07", SIMPLE_EXPLOSION_07);
    this.load.image("simpleExplosion08", SIMPLE_EXPLOSION_08);

    this.load.image("explosionA01", EXPLOSION_A_01);
    this.load.image("explosionA02", EXPLOSION_A_02);
    this.load.image("explosionA03", EXPLOSION_A_03);
    this.load.image("explosionA04", EXPLOSION_A_04);
    this.load.image("explosionA05", EXPLOSION_A_05);
    this.load.image("explosionA06", EXPLOSION_A_06);
    this.load.image("explosionA07", EXPLOSION_A_07);
    this.load.image("explosionA08", EXPLOSION_A_08);

    this.load.image("explosionB01", EXPLOSION_B_01);
    this.load.image("explosionB02", EXPLOSION_B_02);
    this.load.image("explosionB03", EXPLOSION_B_03);
    this.load.image("explosionB04", EXPLOSION_B_04);
    this.load.image("explosionB05", EXPLOSION_B_05);
    this.load.image("explosionB06", EXPLOSION_B_06);
    this.load.image("explosionB07", EXPLOSION_B_07);
    this.load.image("explosionB08", EXPLOSION_B_08);

    this.load.image("explosionF01", EXPLOSION_F_01);
    this.load.image("explosionF02", EXPLOSION_F_02);
    this.load.image("explosionF03", EXPLOSION_F_03);
    this.load.image("explosionF04", EXPLOSION_F_04);
    this.load.image("explosionF05", EXPLOSION_F_05);
    this.load.image("explosionF06", EXPLOSION_F_06);
    this.load.image("explosionF07", EXPLOSION_F_07);
    this.load.image("explosionF08", EXPLOSION_F_08);

    this.load.image("explosionG01", EXPLOSION_G_01);
    this.load.image("explosionG02", EXPLOSION_G_02);
    this.load.image("explosionG03", EXPLOSION_G_03);
    this.load.image("explosionG04", EXPLOSION_G_04);
    this.load.image("explosionG05", EXPLOSION_G_05);
    this.load.image("explosionG06", EXPLOSION_G_06);
    this.load.image("explosionG07", EXPLOSION_G_07);

    this.load.spritesheet("concussiveExplosion", CONCUSSIVE_EXPLOSION, {
      frameWidth: 256,
      frameHeight: 256,
      startFrame: 0,
      endFrame: 31,
    });

    this.load.spritesheet("voidExplosion", VOID_EXPLOSION, {
      frameWidth: 256,
      frameHeight: 256,
      startFrame: 0,
      endFrame: 71,
    });

    this.load.spritesheet("windExplosion", WIND_EXPLOSION, {
      frameWidth: 256,
      frameHeight: 256,
      startFrame: 0,
      endFrame: 48,
    });

    this.load.spritesheet("fragExplosion", FRAG_EXPLOSION, {
      frameWidth: 256,
      frameHeight: 256,
      startFrame: 0,
      endFrame: 34,
    });

    this.load.spritesheet("plusExplosion", PLUS_EXPLOSION, {
      frameWidth: 32,
      frameHeight: 32,
      startFrame: 0,
      endFrame: 15,
    });
  }

  createXPUI(player) {
    const xpCircleBackground = this.add.graphics();
    xpCircleBackground.x = player.xOffset + GRID_WIDTH * CELL_SIZE;
    xpCircleBackground.y = GRID_HEIGHT / CELL_SIZE + CELL_SIZE * 1.75;
    xpCircleBackground.setVisible(false);
    xpCircleBackground.fillStyle(0x000000, 1);
    xpCircleBackground.lineStyle(3, 0xed9121, 1);

    xpCircleBackground.beginPath();
    xpCircleBackground.arc(0, 0, XP_UI_RADIUS, 0, 2 * Math.PI);
    xpCircleBackground.closePath();

    xpCircleBackground.fillPath();
    xpCircleBackground.strokePath();

    const xpCircleFill = this.add.graphics();
    xpCircleFill.x = xpCircleBackground.x;
    xpCircleFill.y = xpCircleBackground.y;
    xpCircleFill.setVisible(false);

    const xpLevelText = this.add.text(
      xpCircleBackground.x,
      xpCircleBackground.y,
      player.level.toString(),
      {
        fontFamily: "RulerGold",
        fontSize: "20px",
        fill: "#fff",
      }
    );
    xpLevelText.setOrigin(0.5);
    xpLevelText.setVisible(false);

    const xpToggleCPUButton = this.add.circle(
      xpCircleBackground.x,
      xpCircleBackground.y,
      XP_UI_RADIUS,
      0xffffff,
      0
    );
    xpToggleCPUButton.setInteractive({ useHandCursor: true });

    xpToggleCPUButton.on("pointerdown", () => {
      this.toggleCPUForPlayer(player);
    });

    player.xpCircleBackground = xpCircleBackground;
    player.xpCircleFill = xpCircleFill;
    player.xpLevelText = xpLevelText;
  }

  updateXPDisplay(player) {
    if (
      !player ||
      player.isRemoved ||
      !player.xpCircleFill ||
      !player.xpLevelText
    ) {
      console.warn(
        "Attempted to update XP display for a non-existent/removed player or missing XP circle objects."
      );
      return;
    }

    const progress = player.xp / XP_NEEDED_TO_LEVEL;

    player.xpCircleFill.clear();

    player.xpCircleFill.fillStyle(0x00b9e8, 1);
    player.xpCircleFill.beginPath();

    const startAngle = Phaser.Math.DegToRad(-90);
    const endAngle = startAngle + Phaser.Math.DegToRad(360 * progress);

    player.xpCircleFill.arc(0, 0, XP_UI_RADIUS, startAngle, endAngle, false);

    player.xpCircleFill.lineTo(0, 0);
    player.xpCircleFill.closePath();
    player.xpCircleFill.fillPath();

    player.xpLevelText.setText(`${player.level}`);
  }

  addXP(player, amount) {
    if (!player || player.isRemoved) {
      console.warn("Attempted to add XP to a non-existent or removed player.");
      return;
    }
    player.xp += amount;
    while (player.xp >= XP_NEEDED_TO_LEVEL) {
      player.xp -= XP_NEEDED_TO_LEVEL;
      player.level += 1;

      if (this.singlePlayerBonusActive) {
        player.damageQueue.push(ANVIL_COLOR);
        this.updateDamageQueueDisplay(player);
      }

      this.showLevelUpNotification(player);
    }
    this.updateXPDisplay(player);
  }

  updateCharacterSelectionHighlight(player) {
    player.characterIconOutlines.forEach((outline, index) => {
      outline.setVisible(index === player.selectedCharacterIndex);
    });
  }

  createCharacterSelectUI(player) {
    const columns = 3;
    const rowSpacing = CHARACTER_ICON_SPACING_Y;

    CHARACTERS.forEach((charData, index) => {
      const row = Math.floor(index / columns);
      const col = index % columns;

      const iconX =
        player.xOffset + CHARACTER_ICON_X_OFFSET + col * CHARACTER_ICON_SPACING;
      const iconY = CHARACTER_ICON_Y_OFFSET + row * rowSpacing;

      let icon;

      if (charData.useImage) {
        const background = this.add.circle(
          iconX,
          iconY,
          CHARACTER_ICON_RADIUS,
          charData.color
        );
        background.setDepth(0);

        player.characterIcons.push(background);

        icon = this.add.image(iconX, iconY, charData.textureKey);

        const maxImageSize = CHARACTER_ICON_RADIUS + 50 * Math.SQRT2;
        const scale = Math.min(
          maxImageSize / icon.width,
          maxImageSize / icon.height
        );

        icon.setScale(scale);

        const maskShape = this.make.graphics({ x: 0, y: 0, add: false });
        maskShape.fillStyle(0xffffff);
        maskShape.beginPath();
        maskShape.arc(0, 0, CHARACTER_ICON_RADIUS, 0, Math.PI * 2, true);
        maskShape.closePath();
        maskShape.fillPath();

        const mask = maskShape.createGeometryMask();

        icon.setMask(mask);

        maskShape.x = iconX;
        maskShape.y = iconY;

        icon.setDepth(1);

        player.characterIcons.push(icon);
      } else {
        icon = this.add.circle(
          iconX,
          iconY,
          CHARACTER_ICON_RADIUS,
          charData.color
        );
        player.characterIcons.push(icon);
      }

      const outline = this.add.circle(
        iconX,
        iconY,
        CHARACTER_ICON_OUTLINE_RADIUS
      );
      outline.setStrokeStyle(4, 0x00ff00);
      outline.setDepth(2);

      player.characterIconOutlines.push(outline);

      const label = this.add.text(
        iconX,
        iconY + CHARACTER_ICON_RADIUS + 10,
        `${charData.name}\n${charData.description}`,
        {
          fontFamily: "RulerGold",
          fontSize: "14px",
          color: "#ffffff",
          align: "center",
          wordWrap: { width: CHARACTER_ICON_RADIUS * 2 },
        }
      );
      label.setOrigin(0.5, 0);

      player.characterLabels.push(label);

      icon.setInteractive({ useHandCursor: true });

      icon.on("pointerdown", () => {
        if (player.selectedCharacterIndex !== index) {
          player.selectedCharacterIndex = index;
          this.updateCharacterSelectionHighlight(player);
        } else {
          player.isCharacterChosen = true;
          player.selectedCharacter = CHARACTERS[player.selectedCharacterIndex];

          this.hideCharacterSelectionUI(player);
          this.showBoardUI(player);
          this.spawnNewPair(player);
          this.createChosenCharacterIcon(player);
          this.createAbilityUI(player);
        }
      });
    });

    this.updateCharacterSelectionHighlight(player);
  }

  hideCharacterSelectionUI(player) {
    player.characterIcons.forEach(icon => icon.setVisible(false));
    player.characterIconOutlines.forEach(outline => outline.setVisible(false));
    player.characterLabels.forEach(label => label.setVisible(false));
  }

  setupAddingAndRemovingPlayersInputHandlers() {
    this.input.keyboard.on("keydown-PLUS", () => {
      this.handleAddPlayer();
    });

    this.input.keyboard.on("keydown-MINUS", () => {
      if (!this.modeChosen) return;

      if (this.players.length > 1) {
        this.removePlayer();
      }
    });
  }

  createPreviewOrb(color) {
    const radius = 8;
    const container = this.add.container(0, 0);

    const orbGraphic = this.add.graphics();
    orbGraphic.fillStyle(color, 1);
    orbGraphic.fillCircle(radius, radius, radius - 2);
    container.add(orbGraphic);

    return container;
  }

  calculateRotationDelta(dx, dy) {
    const distance = Math.sqrt(dx * dx + dy * dy) * CELL_SIZE * 0.866;

    const circumference = Math.PI * CELL_SIZE;
    let rotationDelta = (distance / circumference) * 2 * Math.PI;

    if (dy > 0) {
      rotationDelta = Math.abs(rotationDelta);
    } else if (dy < 0) {
      rotationDelta = -Math.abs(rotationDelta);
    }

    return rotationDelta;
  }

  sendOrbToRandomPlayer(player, orbColor, quantity = 1) {
    if (this.players.length <= 1) {
      return;
    }

    const validTargets = this.players.filter(
      p => p !== player && !p.isGameOver
    );

    if (validTargets.length === 0) {
      return;
    }

    const target = Phaser.Math.RND.pick(validTargets);

    for (let i = 0; i < quantity; i++) {
      target.damageQueue.push(orbColor);
    }

    this.updateDamageQueueDisplay(target);
  }

  sendOrbToNextActivePlayer(player, orbColor, quantity = 1) {
    if (this.players.length <= 1) {
      return;
    }

    const startIndex = this.players.indexOf(player);
    if (startIndex === -1) {
      return;
    }

    let nextIndex = (startIndex + 1) % this.players.length;

    while (nextIndex !== startIndex) {
      const nextPlayer = this.players[nextIndex];

      if (!nextPlayer.isGameOver) {
        for (let i = 0; i < quantity; i++) {
          nextPlayer.damageQueue.push(orbColor);
        }
        this.updateDamageQueueDisplay(nextPlayer);
        return;
      }

      nextIndex = (nextIndex + 1) % this.players.length;
    }

    return;
  }

  toggleCPUForPlayer(player) {
    if (!player.isCharacterChosen) return;

    player.isCPU = !player.isCPU;

    if (player.isCPU) {
      player.cpuInterval = setInterval(() => {
        if (player.isGameOver || player.isRemoved) {
          clearInterval(player.cpuInterval);
          player.cpuInterval = null;
          return;
        }

        const randomAction = Phaser.Math.Between(0, 3);

        if (randomAction === 0) {
          this.moveLeft(player);
        } else if (randomAction === 1) {
          this.moveRight(player);
        } else if (randomAction === 2) {
          if (!player.isBusy) {
            this.dropPair(player);
          }
        } else if (randomAction === 3 && !this.isClassicMode) {
          if (!player.selectedCharacter) return;
          const character = player.selectedCharacter;
          if (!character.abilities || character.abilities.length === 0) return;

          const abilities = character.abilities;
          const currentIndex = player.currentAbilityIndex;
          const isToggled = player.abilityIsToggled;

          if (abilities.length === 1) {
            player.abilityIsToggled = !isToggled;
            this.updateAbilityUI(player);
          } else {
            if (!isToggled) {
              player.abilityIsToggled = true;
            } else {
              let nextIndex = currentIndex + 1;
              if (nextIndex >= abilities.length) {
                nextIndex = 0;
              }
              player.currentAbilityIndex = nextIndex;
              player.abilityIsToggled = false;
            }
            this.updateAbilityUI(player);
          }
        }
      }, 100);
    } else {
      if (player.cpuInterval) {
        clearInterval(player.cpuInterval);
        player.cpuInterval = null;
      }
    }
  }

  setOrbCracked(orb) {
    if (!orb) return;

    if (!orb.crackedEffectSprite) {
      const crackedSprite = this.add.image(0, 0, "crackedOrbEffect");
      crackedSprite.setOrigin(0.5);
      crackedSprite.setDisplaySize(CELL_SIZE - 4, CELL_SIZE - 4);

      crackedSprite.setTint(0x000000);

      crackedSprite.setAlpha(0.5);

      crackedSprite.setDepth(2);

      orb.add(crackedSprite);
      orb.crackedEffectSprite = crackedSprite;
    }

    orb.isCracked = true;
  }

  loadBowAbility(player, callback) {
    this.replaceOrbInUpcomingPair(player, QUIVER_COLOR);

    if (callback) {
      callback();
    }
  }

  replaceOrbInUpcomingPair(player, orbColor) {
    if (!player.upcomingPairs) {
      console.warn("Player has no upcomingPairs array defined.");
      return;
    }

    if (player.upcomingPairs.length === 0) {
      player.upcomingPairs.push({
        color1: this.getOrbColorForPlayer(player),
        color2: this.getOrbColorForPlayer(player),
      });
    }

    const nextPair = player.upcomingPairs[0];
    if (!nextPair) return;

    const isColor1 = nextPair.color1 === orbColor;
    const isColor2 = nextPair.color2 === orbColor;

    if (isColor1 && isColor2) {
      return;
    }

    if (isColor1 && !isColor2) {
      nextPair.color2 = orbColor;
      return;
    }

    if (!isColor1 && isColor2) {
      nextPair.color1 = orbColor;
      return;
    }

    nextPair.color1 = orbColor;
  }

  useAbility(player, ability, callback) {
    switch (player.selectedCharacter.name) {
      case "Ember":
        switch (ability.name) {
          case "Conflagrate":
            this.conflagrateAbility(player, callback);
            break;

          case "Meteor Storm":
            this.sendOrbToNextActivePlayer(player, CHARRED_COLOR, 5);
            break;
        }
      case "Lance":
        switch (ability.name) {
          case "Raise Shield":
            player.damageQueue.push(ARMOR_COLOR);
            break;

          case "Anvil Drop":
            player.damageQueue.push(ANVIL_COLOR);
            break;
          default:
            break;
        }
      case "Flora":
        switch (ability.name) {
          case "Thorn Arrow":
            this.sendOrbToNextActivePlayer(player, THORN_COLOR, 1);
            break;

          case "Load Bow":
            this.loadBowAbility(player, callback);
            break;

          case "Wild Growth":
            this.wildGrowthAbility(player, callback);
            break;
        }
      default:
        break;
    }
  }

  conflagrateAbility(player, callback) {
    if (!player?.grid) {
      callback?.();
      return;
    }

    const validOrbs = [];

    for (let gy = 0; gy < GRID_HEIGHT; gy++) {
      for (let gx = 0; gx < GRID_WIDTH; gx++) {
        const orb = player.grid[gy][gx];
        if (!orb) continue;

        if (orb.originalColor === ANVIL_COLOR) {
          continue;
        }

        if (this.isElementOrb(orb.originalColor)) {
          continue;
        }

        validOrbs.push({ x: gx, y: gy, orb });
      }
    }

    if (validOrbs.length > 0) {
      const { x, y, orb } = Phaser.Math.RND.pick(validOrbs);

      const explosion = this.add
        .sprite(orb.x, orb.y, "fragExplosion")
        .setOrigin(0.5)
        .setScale(0.5);

      explosion.setDepth(999);
      explosion.play("fragExplosion");
      explosion.once("animationcomplete", () => {
        explosion.destroy();
      });

      orb.destroy();

      player.grid[y][x] = this.createBall(x, y, IGNITION_COLOR, player);
    }

    callback?.();
  }

  createAbilityUI(player) {
    const selectedCharacter = player.selectedCharacter;
    if (
      !selectedCharacter.abilities ||
      selectedCharacter.abilities.length === 0
    ) {
      return;
    }

    const abilityContainer = this.add.container(
      player.xOffset + GRID_WIDTH * CELL_SIZE,
      150
    );
    player.abilityContainer = abilityContainer;

    const abilityBackground = this.add
      .rectangle(0, 0, 40, 40, selectedCharacter.color)
      .setOrigin(0.5, 0.5);
    abilityContainer.add(abilityBackground);
    player.abilityBackground = abilityBackground;

    const firstAbilityKey = selectedCharacter.abilities[0].image;
    const abilityImage = this.add
      .image(0, 0, firstAbilityKey)
      .setOrigin(0.5, 0.5);
    abilityImage.setScale(0.075);
    abilityContainer.add(abilityImage);
    player.abilityImage = abilityImage;

    const abilityCostContainer = this.add.container(-35, 40);
    abilityContainer.add(abilityCostContainer);
    player.abilityCostContainer = abilityCostContainer;

    player.currentAbilityIndex = 0;
    player.abilityIsToggled = false;

    this.updateAbilityUI(player);
  }

  updateAbilityUI(player) {
    const selectedCharacter = player.selectedCharacter;
    if (!selectedCharacter || !selectedCharacter.abilities) return;

    const currentIndex = player.currentAbilityIndex;
    const currentAbility = selectedCharacter.abilities[currentIndex];

    if (player.abilityImage) {
      player.abilityImage.setTexture(currentAbility.image);
    }

    if (player.abilityCostContainer) {
      player.abilityCostContainer.removeAll(true);
    }

    if (currentAbility.cost && Array.isArray(currentAbility.cost)) {
      let offsetX = 0;

      currentAbility.cost.forEach(costObject => {
        Object.entries(costObject).forEach(([color, costValue]) => {
          const orbGraphic = this.add.graphics();
          orbGraphic.fillStyle(color, 1);
          orbGraphic.fillCircle(0, 0, 8);
          orbGraphic.x = offsetX;
          orbGraphic.y = 0;
          player.abilityCostContainer.add(orbGraphic);

          const costText = this.add.text(
            orbGraphic.x + 6,
            orbGraphic.y - 12,
            `${costValue}`,
            {
              fontFamily: "RulerGold",
              fontSize: "16px",
              color: "#ffffff",
            }
          );
          player.abilityCostContainer.add(costText);

          offsetX += 30;
        });
      });
    }

    if (player.abilityIsToggled) {
      player.abilityBackground.setStrokeStyle(4, 0x00ff00);
    } else {
      player.abilityBackground.setStrokeStyle(0);
    }
  }

  createChosenCharacterIcon(player) {
    if (this.isClassicMode) {
      return;
    }

    const iconX = player.xOffset + GRID_WIDTH * CELL_SIZE;
    const iconY = GRID_HEIGHT / CELL_SIZE + CELL_SIZE / 1.5;
    const selectedCharacter = player.selectedCharacter;

    if (selectedCharacter.useImage && selectedCharacter.textureKey) {
      const background = this.add.circle(
        iconX,
        iconY,
        15,
        selectedCharacter.color
      );
      background.setDepth(0);
      player.characterChosenIconBackground = background;

      const icon = this.add.image(iconX, iconY, selectedCharacter.textureKey);

      const maxImageSize = 15 * 2 * Math.SQRT2;
      const scale = Math.min(
        maxImageSize / icon.width,
        maxImageSize / icon.height
      );
      icon.setScale(scale);

      const maskShape = this.make.graphics({ x: 0, y: 0, add: false });
      maskShape.fillStyle(0xffffff);
      maskShape.beginPath();
      maskShape.arc(0, 0, 15, 0, Math.PI * 2, true);
      maskShape.closePath();
      maskShape.fillPath();

      const mask = maskShape.createGeometryMask();
      icon.setMask(mask);

      maskShape.x = iconX;
      maskShape.y = iconY;

      icon.setDepth(1);

      player.characterChosenIcon = icon;

      player.characterChosenIconMask = maskShape;
    } else {
      const color = selectedCharacter.color;
      player.characterChosenIcon = this.add.circle(iconX, iconY, 15, color);
    }
  }

  dropCharredOrb(player, dropNextOrb) {
    if (!player?.grid) {
      dropNextOrb?.();
      return;
    }

    const validPositions = [];
    for (let y = 0; y < GRID_HEIGHT; y++) {
      for (let x = 0; x < GRID_WIDTH; x++) {
        const orb = player.grid[y][x];
        if (
          orb &&
          !this.isEffectOrb(orb.originalColor) &&
          orb.originalColor !== QUIVER_COLOR
        ) {
          validPositions.push({ x, y });
        }
      }
    }

    if (validPositions.length === 0) {
      dropNextOrb?.();
      return;
    }

    const { x, y } = Phaser.Utils.Array.GetRandom(validPositions);
    const orbToReplace = player.grid[y][x];

    const explosion = this.add
      .sprite(orbToReplace.x, orbToReplace.y, "fragExplosion")
      .setOrigin(0.5)
      .setScale(0.5);

    explosion.setDepth(999);
    explosion.play("fragExplosion");
    explosion.once("animationcomplete", () => {
      explosion.destroy();

      orbToReplace.destroy();
      player.grid[y][x] = this.createBall(x, y, CHARRED_COLOR, player);

      dropNextOrb?.();
    });
  }

  removeOrbsForAbility(player, costArray) {
    if (!costArray || !Array.isArray(costArray)) return;

    for (const costObject of costArray) {
      for (const [colorKey, needed] of Object.entries(costObject)) {
        const storedOrb = player.storedOrbs.find(
          o => o.color === parseInt(colorKey)
        );
        if (storedOrb) {
          storedOrb.quantity = Math.max(storedOrb.quantity - needed, 0);
        }
      }
    }

    player.storedOrbs = player.storedOrbs.filter(o => o.quantity > 0);

    this.updateStoredOrbsUI(player);
  }

  hasEnoughOrbsForAbility(player, costArray) {
    if (!costArray || !Array.isArray(costArray)) return false;

    for (const costObject of costArray) {
      for (const [colorKey, needed] of Object.entries(costObject)) {
        const storedOrb = player.storedOrbs.find(
          o => o.color === parseInt(colorKey)
        );
        if (!storedOrb || storedOrb.quantity < needed) {
          return false;
        }
      }
    }

    return true;
  }

  createArrowButton = (button, rotation, player) => {
    let arrowButtonInterval = null;

    button.on("pointerover", () => {
      button.setTint(COLOR_POINTER_OVER);
    });

    button.on("pointerout", () => {
      button.setTint(COLOR_POINTER_OUT);
      if (arrowButtonInterval) {
        clearInterval(arrowButtonInterval);
        arrowButtonInterval = null;
      }
    });

    button.on("pointerdown", () => {
      button.setTint(COLOR_POINTER_DOWN);

      if (arrowButtonInterval) {
        clearInterval(arrowButtonInterval);
        arrowButtonInterval = null;
      }

      if (rotation === Phaser.Math.DegToRad(180)) {
        if (!player.isCharacterChosen) {
          player.selectedCharacterIndex =
            (player.selectedCharacterIndex - 1 + CHARACTERS.length) %
            CHARACTERS.length;
          this.updateCharacterSelectionHighlight(player);
        }
        if (this.isMultiplayer) {
          this.sendMoveRequest("left", player);
        } else {
          this.moveLeft(player);
        }

        arrowButtonInterval = setInterval(() => {
          this.moveLeft(player);
        }, 150);
      } else if (rotation === Phaser.Math.DegToRad(0)) {
        if (!player.isCharacterChosen) {
          player.selectedCharacterIndex =
            (player.selectedCharacterIndex + 1) % CHARACTERS.length;
          this.updateCharacterSelectionHighlight(player);
        }
        if (this.isMultiplayer) {
          this.sendMoveRequest("right", player);
        } else {
          this.moveRight(player);
        }

        arrowButtonInterval = setInterval(() => {
          this.moveRight(player);
        }, 150);
      }
    });

    button.on("pointerup", () => {
      button.setTint(COLOR_POINTER_UP);
      if (arrowButtonInterval) {
        clearInterval(arrowButtonInterval);
        arrowButtonInterval = null;
      }
    });
  };

  createAnimations() {
    this.anims.create({
      key: "simpleExplosion",
      frames: [
        { key: "simpleExplosion00" },
        { key: "simpleExplosion01" },
        { key: "simpleExplosion02" },
        { key: "simpleExplosion03" },
        { key: "simpleExplosion04" },
        { key: "simpleExplosion05" },
        { key: "simpleExplosion06" },
        { key: "simpleExplosion07" },
        { key: "simpleExplosion08" },
      ],
      frameRate: 6,
      repeat: 0,
    });

    this.anims.create({
      key: "explosionA",
      frames: [
        { key: "explosionA01" },
        { key: "explosionA02" },
        { key: "explosionA03" },
        { key: "explosionA04" },
        { key: "explosionA05" },
        { key: "explosionA06" },
        { key: "explosionA07" },
        { key: "explosionA08" },
      ],
      frameRate: 17,
      repeat: 0,
    });

    this.anims.create({
      key: "explosionB",
      frames: [
        { key: "explosionB01" },
        { key: "explosionB02" },
        { key: "explosionB03" },
        { key: "explosionB04" },
        { key: "explosionB05" },
        { key: "explosionB06" },
        { key: "explosionB07" },
        { key: "explosionB08" },
      ],
      frameRate: 8,
      repeat: 0,
    });

    this.anims.create({
      key: "explosionF",
      frames: [
        { key: "explosionF01" },
        { key: "explosionF02" },
        { key: "explosionF03" },
        { key: "explosionF04" },
        { key: "explosionF05" },
        { key: "explosionF06" },
        { key: "explosionF07" },
        { key: "explosionF08" },
      ],
      frameRate: 10,
      repeat: 0,
    });

    this.anims.create({
      key: "explosionG",
      frames: [
        { key: "explosionG01" },
        { key: "explosionG02" },
        { key: "explosionG03" },
        { key: "explosionG04" },
        { key: "explosionG05" },
        { key: "explosionG06" },
        { key: "explosionG07" },
      ],
      frameRate: 10,
      repeat: 0,
    });

    this.anims.create({
      key: "concussiveExplosion",
      frames: this.anims.generateFrameNumbers("concussiveExplosion", {
        start: 0,
        end: 31,
      }),
      frameRate: 40,
      repeat: 0,
    });

    this.anims.create({
      key: "voidExplosion",
      frames: this.anims.generateFrameNumbers("voidExplosion", {
        start: 0,
        end: 71,
      }),
      frameRate: 60,
      repeat: 0,
    });

    this.anims.create({
      key: "windExplosion",
      frames: this.anims.generateFrameNumbers("windExplosion", {
        start: 0,
        end: 48,
      }),
      frameRate: 60,
      repeat: 0,
    });

    this.anims.create({
      key: "fragExplosion",
      frames: this.anims.generateFrameNumbers("fragExplosion", {
        start: 0,
        end: 34,
      }),
      frameRate: 60,
      repeat: 0,
    });

    this.anims.create({
      key: "plusExplosion",
      frames: this.anims.generateFrameNumbers("plusExplosion", {
        start: 0,
        end: 15,
      }),
      frameRate: 20,
      repeat: 0,
    });
  }

  wildGrowthAbility(player, callback) {
    if (!player?.grid) {
      callback?.();
      return;
    }

    const colorCounts = new Map();

    for (let y = 0; y < GRID_HEIGHT; y++) {
      for (let x = 0; x < GRID_WIDTH; x++) {
        const orb = player.grid[y][x];
        if (
          orb &&
          !this.isEffectOrb(orb.originalColor) &&
          orb.originalColor !== QUIVER_COLOR
        ) {
          const c = orb.originalColor;
          colorCounts.set(c, (colorCounts.get(c) || 0) + 1);
        }
      }
    }

    if (colorCounts.size === 0) {
      callback?.();
      return;
    }

    let minCount = Infinity;
    colorCounts.forEach(count => {
      if (count < minCount) {
        minCount = count;
      }
    });

    const minColors = [];
    colorCounts.forEach((count, color) => {
      if (count === minCount) {
        minColors.push(color);
      }
    });

    const chosenColor = Phaser.Utils.Array.GetRandom(minColors);

    const chosenColorPositions = [];
    for (let y = 0; y < GRID_HEIGHT; y++) {
      for (let x = 0; x < GRID_WIDTH; x++) {
        const orb = player.grid[y][x];
        if (orb && orb.originalColor === chosenColor) {
          chosenColorPositions.push({ x, y });
        }
      }
    }

    Phaser.Utils.Array.Shuffle(chosenColorPositions);
    const toReplace = chosenColorPositions.slice(0, 5);

    toReplace.forEach(pos => {
      const orb = player.grid[pos.y][pos.x];
      if (!orb) return;

      const explosion = this.add
        .sprite(orb.x, orb.y, "windExplosion")
        .setOrigin(0.5)
        .setScale(0.5);
      explosion.setDepth(999);
      explosion.play("windExplosion");
      explosion.once("animationcomplete", () => {
        explosion.destroy();
      });

      orb.destroy();

      player.grid[pos.y][pos.x] = this.createBall(
        pos.x,
        pos.y,
        NATURE_COLOR,
        player
      );
    });

    callback?.();
  }

  dropSeedOrb(player, callback) {
    const randomCol = Phaser.Math.RND.between(0, GRID_WIDTH - 1);
    const spawnRow = 0;

    const seedOrb = this.createBall(randomCol, spawnRow, SEED_COLOR, player);
    player.grid[spawnRow][randomCol] = seedOrb;

    this.processSeedDrop(player, randomCol, spawnRow, callback);
  }

  processSeedDrop(player, x, y, callback) {
    const nextY = y + 1;

    if (nextY >= GRID_HEIGHT) {
      this.runSeedTransformation(player, x, y, callback);
      return;
    }

    const belowOrb = player.grid[nextY][x];

    if (!belowOrb) {
      this.time.delayedCall(10, () => {
        const currentSeedOrb = player.grid[y][x];
        if (!currentSeedOrb) {
          if (callback) callback();
          return;
        }

        player.grid[y][x] = null;
        player.grid[nextY][x] = currentSeedOrb;

        const { px, py } = this.getHexPosition(x, nextY, player.xOffset);
        this.tweens.add({
          targets: currentSeedOrb,
          x: px,
          y: py,
          duration: 10,
          ease: "Power2",
          onComplete: () => {
            this.processSeedDrop(player, x, nextY, callback);
          },
        });
      });
    } else if (this.isEffectOrb(belowOrb.originalColor) || belowOrb.isCracked) {
      this.destroyOrbWithPlusAnimation(player, x, y);
      player.grid[y][x] = null;
      if (callback) callback();
    } else {
      this.runSeedTransformation(player, x, y, callback);
    }
  }

  runSeedTransformation(player, col, row, callback) {
    this.destroyOrbWithPlusAnimation(player, col, row);
    player.grid[row][col] = null;

    for (let y = GRID_HEIGHT - 1; y >= 0; y--) {
      const targetOrb = player.grid[y][col];

      if (!targetOrb) {
        break;
      }

      if (!this.isEffectOrb(targetOrb.originalColor) && !targetOrb.isCracked) {
        targetOrb.destroy();

        player.grid[y][col] = this.createBall(col, y, ROOT_COLOR, player);

        break;
      }
    }

    if (callback) callback();
  }

  dropThornOrb(player, callback) {
    const randomCol = Phaser.Math.RND.between(0, GRID_WIDTH - 1);
    const spawnRow = 0;

    const thornOrb = this.createBall(randomCol, spawnRow, THORN_COLOR, player);
    player.grid[spawnRow][randomCol] = thornOrb;

    this.processThornDrop(player, randomCol, spawnRow, callback);
  }

  processThornDrop(player, x, y, callback) {
    const nextY = y + 1;

    if (nextY >= GRID_HEIGHT) {
      this.destroyOrbWithPlusAnimation(player, x, y);
      if (callback) callback();
      return;
    }

    const belowOrb = player.grid[nextY][x];

    if (!belowOrb) {
      this.time.delayedCall(10, () => {
        const thornOrb = player.grid[y][x];
        if (!thornOrb) {
          if (callback) callback();
          return;
        }
        player.grid[y][x] = null;
        player.grid[nextY][x] = thornOrb;

        const { px, py } = this.getHexPosition(x, nextY, player.xOffset);

        this.tweens.add({
          targets: thornOrb,
          x: px,
          y: py,
          duration: 10,
          ease: "Power2",
          onComplete: () => {
            this.processThornDrop(player, x, nextY, callback);
          },
        });
      });
    } else {
      if (this.isEffectOrb(belowOrb.originalColor)) {
        this.destroyOrbWithPlusAnimation(player, x, y);
        if (callback) callback();
      } else {
        let crackY = nextY;
        let foundOrbToCrack = null;

        while (crackY < GRID_HEIGHT) {
          const targetOrb = player.grid[crackY][x];
          if (!targetOrb) {
            break;
          }
          if (!targetOrb.isCracked) {
            foundOrbToCrack = targetOrb;
            break;
          }
          crackY++;
        }

        if (foundOrbToCrack) {
          this.setOrbCracked(foundOrbToCrack);
          this.playPlusAnimationOnOrb(foundOrbToCrack);

          this.removeThornOrb(player, x, y);
        } else {
          this.destroyOrbWithPlusAnimation(player, x, y);
        }

        if (callback) callback();
      }
    }
  }

  removeThornOrb(player, x, y) {
    const thornOrb = player.grid[y][x];
    if (thornOrb) {
      thornOrb.destroy();
      player.grid[y][x] = null;
    }
  }

  placeAnvilOrb(player, x, y, callback) {
    if (y < 0) {
      this.gameOver(player);
      return;
    }

    if (this.checkIfAnyOrbAboveGrid(player)) {
      this.gameOver(player);
      return;
    }

    const anvilOrb = this.createBall(x, y, ANVIL_COLOR, player);
    player.grid[y][x] = anvilOrb;

    const { px, py } = this.getHexPosition(x, y, player.xOffset);
    anvilOrb.x = this.getHexPosition(x, 0, player.xOffset).px;
    anvilOrb.y = this.getHexPosition(x, 0, player.xOffset).py;

    this.tweens.add({
      targets: anvilOrb,
      x: px,
      y: py,
      duration: 10 * y,
      ease: "Power2",
      onComplete: () => {
        if (callback) callback();
      },
    });
  }

  dropAnvilOrb(player, callback) {
    const randomCol = Phaser.Math.RND.between(0, GRID_WIDTH - 1);
    this.processAnvilDrop(player, randomCol, 0, callback);
  }

  processAnvilDrop(player, currentX, currentY, callback) {
    if (currentY >= GRID_HEIGHT) {
      const finalY = GRID_HEIGHT - 1;
      this.placeAnvilOrb(player, currentX, finalY, callback);
      return;
    }

    const belowCell = player.grid[currentY][currentX];

    if (!belowCell) {
      this.time.delayedCall(25, () => {
        this.processAnvilDrop(player, currentX, currentY + 1, callback);
      });
    } else if (belowCell.originalColor !== ANVIL_COLOR) {
      this.destroyOrbWithExplosionAnimation(player, currentX, currentY);
      this.time.delayedCall(25, () => {
        this.processAnvilDrop(player, currentX, currentY + 1, callback);
      });
    } else {
      const rolled = this.attemptRoll(player, currentX, currentY);
      if (rolled) {
        this.time.delayedCall(25, () => {
          this.processAnvilDrop(player, rolled.x, rolled.y, callback);
        });
      } else {
        const finalY = currentY - 1;
        this.placeAnvilOrb(player, currentX, finalY, callback);
      }
    }
  }

  playPlusAnimationOnOrb(orb) {
    if (!orb) return;

    const orbX = orb.x;
    const orbY = orb.y;

    const flashCircle = this.add.graphics();
    flashCircle.fillStyle(0xffffff, 1);
    flashCircle.fillCircle(0, 0, 32);
    flashCircle.setPosition(orbX, orbY);
    flashCircle.setDepth(999);

    this.time.delayedCall(50, () => {
      flashCircle.destroy();
    });

    const randomOffsetX = Phaser.Math.FloatBetween(-0.1, 0.1);
    const randomOffsetY = Phaser.Math.FloatBetween(-0.1, 0.1);

    const explosion = this.add
      .sprite(orbX, orbY, "simpleExplosion00")
      .setOrigin(0.5 + randomOffsetX, 0.5 + randomOffsetY)
      .setScale(1.5);

    explosion.setDepth(999);

    const randomColor = Phaser.Display.Color.RandomRGB();
    const blendedColor = Phaser.Display.Color.Interpolate.ColorWithColor(
      randomColor,
      Phaser.Display.Color.ValueToColor(0xffffff),
      100,
      75
    );
    const mutedColor = Phaser.Display.Color.GetColor(
      blendedColor.r,
      blendedColor.g,
      blendedColor.b
    );
    explosion.setTint(mutedColor);

    explosion.play("plusExplosion");
    explosion.once("animationcomplete", () => {
      explosion.destroy();
    });
  }

  destroyOrbWithPlusAnimation(player, x, y) {
    const orb = player.grid[y][x];
    if (orb) {
      const { x: orbX, y: orbY } = orb;

      const flashCircle = this.add.graphics();
      flashCircle.fillStyle(0xffffff, 1);
      flashCircle.fillCircle(0, 0, 32);
      flashCircle.setPosition(orbX, orbY);
      flashCircle.setDepth(999);

      this.time.delayedCall(50, () => {
        flashCircle.destroy();
      });

      const randomOffsetX = Phaser.Math.FloatBetween(-0.1, 0.1);
      const randomOffsetY = Phaser.Math.FloatBetween(-0.1, 0.1);

      const explosion = this.add
        .sprite(orbX, orbY, "simpleExplosion00")
        .setOrigin(0.5 + randomOffsetX, 0.5 + randomOffsetY)
        .setScale(1.5);

      explosion.setDepth(999);

      const randomColor = Phaser.Display.Color.RandomRGB();
      const blendedColor = Phaser.Display.Color.Interpolate.ColorWithColor(
        randomColor,
        Phaser.Display.Color.ValueToColor(0xffffff),
        100,
        75
      );
      const mutedColor = Phaser.Display.Color.GetColor(
        blendedColor.r,
        blendedColor.g,
        blendedColor.b
      );

      explosion.setTint(mutedColor);

      explosion.play("plusExplosion");
      explosion.once("animationcomplete", () => {
        explosion.destroy();
      });

      this.tweens.add({
        targets: orb,
        alpha: 0,
        duration: 75,
        ease: "Power2",
        onComplete: () => {
          // orb.destroy();
        },
      });

      player.grid[y][x] = null;
    }
  }

  destroyOrbWithExplosionAnimation(player, x, y) {
    const orb = player.grid[y][x];
    if (orb) {
      const { x: orbX, y: orbY } = orb;

      const flashCircle = this.add.graphics();
      flashCircle.fillStyle(0xffffff, 1);
      flashCircle.fillCircle(0, 0, 32);
      flashCircle.setPosition(orbX, orbY);
      flashCircle.setDepth(999);

      this.time.delayedCall(50, () => {
        flashCircle.destroy();
      });

      const randomOffsetX = Phaser.Math.FloatBetween(-0.1, 0.1);
      const randomOffsetY = Phaser.Math.FloatBetween(-0.1, 0.1);

      const explosion = this.add
        .sprite(orbX, orbY, "simpleExplosion00")
        .setOrigin(0.5 + randomOffsetX, 0.5 + randomOffsetY)
        .setScale(1.5);

      explosion.setDepth(999);

      const randomColor = Phaser.Display.Color.RandomRGB();
      const blendedColor = Phaser.Display.Color.Interpolate.ColorWithColor(
        randomColor,
        Phaser.Display.Color.ValueToColor(0xffffff),
        100,
        75
      );
      const mutedColor = Phaser.Display.Color.GetColor(
        blendedColor.r,
        blendedColor.g,
        blendedColor.b
      );

      explosion.setTint(mutedColor);

      explosion.play("explosionB");
      explosion.once("animationcomplete", () => {
        explosion.destroy();
      });

      this.tweens.add({
        targets: orb,
        alpha: 0,
        duration: 75,
        ease: "Power2",
        onComplete: () => {
          orb.destroy();
        },
      });

      player.grid[y][x] = null;
    }
  }

  processGrenadeDrop(player, x, y, callback) {
    const nextY = y + 1;

    if (nextY >= GRID_HEIGHT) {
      this.destroyOrbWithExplosionAnimation(player, x, y);
      if (callback) callback();
      return;
    }

    const belowOrb = player.grid[nextY][x];

    if (!belowOrb) {
      this.time.delayedCall(15, () => {
        const grenadeOrb = player.grid[y][x];
        if (!grenadeOrb) {
          if (callback) callback();
          return;
        }

        player.grid[y][x] = null;
        player.grid[nextY][x] = grenadeOrb;

        const { px, py } = this.getHexPosition(x, nextY, player.xOffset);
        this.tweens.add({
          targets: grenadeOrb,
          x: px,
          y: py,
          duration: 15,
          ease: "Power2",
          onComplete: () => {
            this.processGrenaderop(player, x, nextY, callback);
          },
        });
      });
    } else {
      if (this.isEffectOrb(belowOrb.originalColor)) {
        this.destroyOrbWithExplosionAnimation(player, x, y);
        if (callback) callback();
      } else {
        this.destroyOrbWithExplosionAnimation(player, x, nextY);
        this.destroyOrbWithExplosionAnimation(player, x, y);
        if (callback) callback();
      }
    }
  }

  drawStar(
    graphics,
    cx,
    cy,
    spikes,
    outerRadius,
    innerRadius,
    fillColor,
    lineColor
  ) {
    let rot = (Math.PI / 2) * 3;
    const step = Math.PI / spikes;

    graphics.lineStyle(2, lineColor, 1);
    graphics.fillStyle(fillColor, 1);
    graphics.beginPath();
    graphics.moveTo(cx, cy - outerRadius);

    for (let i = 0; i < spikes; i++) {
      let x = cx + Math.cos(rot) * outerRadius;
      let y = cy + Math.sin(rot) * outerRadius;
      graphics.lineTo(x, y);
      rot += step;

      x = cx + Math.cos(rot) * innerRadius;
      y = cy + Math.sin(rot) * innerRadius;
      graphics.lineTo(x, y);
      rot += step;
    }

    graphics.closePath();
    graphics.fillPath();
    graphics.strokePath();
  }

  // chainingCheckMatches(player, callback, chainCount = 0) {
  //   // If the player is Lance, use a different match-checking method (as in your original code).
  //   if (player?.selectedCharacter?.name === "Lance") {
  //     this.checkMatchesForLance(player, callback, chainCount);
  //     return;
  //   }

  //   const foundMatches = [];

  //   /**
  //    * Recursively collects orbs of the same baseColor, plus QUIVER_COLOR orbs if the player is Flora.
  //    * @param {number} sx - current x
  //    * @param {number} sy - current y
  //    * @param {string} baseColor - color we’re matching against
  //    * @param {Set} localVisited - keeps track of visited orbs in this search
  //    * @param {Array} cluster - array of matched positions in this search
  //    */
  //   const checkAdjacentMatches = (sx, sy, baseColor, localVisited, cluster) => {
  //     // 1) Boundary checks
  //     if (sx < 0 || sx >= GRID_WIDTH || sy < 0 || sy >= GRID_HEIGHT) {
  //       return;
  //     }
  //     // 2) Grab the orb
  //     const orb = player.grid[sy][sx];
  //     if (!orb) return;

  //     // 3) If we've already visited this orb in *this* search, skip
  //     const key = `${sx},${sy}`;
  //     if (localVisited.has(key)) {
  //       return;
  //     }

  //     // 4) Decide whether it belongs to this group:
  //     //    - orb.originalColor === baseColor, or
  //     //    - if player is Flora, orb.originalColor === QUIVER_COLOR
  //     const sameColor = orb.originalColor === baseColor;
  //     const floraQuiver =
  //       player?.selectedCharacter?.name === "Flora" &&
  //       orb.originalColor === QUIVER_COLOR;

  //     if (!sameColor && !floraQuiver) {
  //       return;
  //     }

  //     // Mark visited in *this* adjacency search
  //     localVisited.add(key);
  //     cluster.push({ x: sx, y: sy });

  //     // 5) Explore neighbors
  //     const neighbors = this.getHexNeighbors(sx, sy);
  //     neighbors.forEach(n => {
  //       checkAdjacentMatches(n.x, n.y, baseColor, localVisited, cluster);
  //     });
  //   };

  //   // Loop through every cell to start a color-based adjacency search
  //   for (let y = 0; y < GRID_HEIGHT; y++) {
  //     for (let x = 0; x < GRID_WIDTH; x++) {
  //       // Grab the orb
  //       const orb = player.grid[y][x];
  //       if (!orb) continue;

  //       const color = orb.originalColor;

  //       // If it's some "effect orb" that doesn't form normal matches, skip it
  //       if (this.isEffectOrb(color)) {
  //         continue;
  //       }

  //       // For each orb, we create a *new* local visited set.
  //       // That way, each color search is independent.
  //       const localVisited = new Set();
  //       const matches = [];

  //       // Perform adjacency search for `color` starting from (x, y)
  //       checkAdjacentMatches(x, y, color, localVisited, matches);

  //       // If we found a cluster, see if it meets the match criteria
  //       if (matches.length > 0) {
  //         // Filter out cracked orbs
  //         const nonCrackedOrbs = matches.filter(({ x, y }) => {
  //           const orbObj = player.grid[y][x];
  //           return orbObj && !orbObj.isCracked;
  //         });

  //         // Must be at least MATCHES_REQUIRED
  //         if (nonCrackedOrbs.length >= MATCHES_REQUIRED) {
  //           // If Flora, require at least one QUIVER orb
  //           if (player?.selectedCharacter?.name === "Flora") {
  //             const hasQuiver = nonCrackedOrbs.some(({ x, y }) => {
  //               const orbObj = player.grid[y][x];
  //               return orbObj?.originalColor === QUIVER_COLOR;
  //             });
  //             if (!hasQuiver) {
  //               // Skip if no Quiver in the group
  //               continue;
  //             }
  //           }

  //           // Valid match
  //           foundMatches.push({
  //             color,
  //             size: matches.length,
  //             positions: matches,
  //           });
  //         }
  //       }
  //     }
  //   }

  //   // Finally, handle the found matches
  //   this.processMatchesForXPAndChainAndDamage(
  //     player,
  //     foundMatches,
  //     chainCount,
  //     this.checkMatches,
  //     callback
  //   );
  // }

  checkMatchesForLance(player, callback, chainCount = 0) {
    let matchFound = false;
    const foundMatches = [];

    for (let y = 0; y < GRID_HEIGHT; y++) {
      for (let x = 0; x < GRID_WIDTH; x++) {
        const currentBall = player.grid[y][x];
        if (!currentBall) continue;

        const color = currentBall.originalColor;

        if (this.isEffectOrb(color)) {
          continue;
        }

        const verticalPositions = this.gatherLine(player, x, y, 0, -1, color)
          .reverse()
          .concat([{ x, y }])
          .concat(this.gatherLine(player, x, y, 0, 1, color));

        this.checkAndPushMatch(foundMatches, verticalPositions, color, player);

        const diagDownRightPositions = this.gatherLineDiagonals(
          player,
          x,
          y,
          "downRight",
          color
        )
          .reverse()
          .concat([{ x, y }])
          .concat(this.gatherLineDiagonals(player, x, y, "upLeft", color));

        this.checkAndPushMatch(
          foundMatches,
          diagDownRightPositions,
          color,
          player
        );

        const diagDownLeftPositions = this.gatherLineDiagonals(
          player,
          x,
          y,
          "downLeft",
          color
        )
          .reverse()
          .concat([{ x, y }])
          .concat(this.gatherLineDiagonals(player, x, y, "upRight", color));

        this.checkAndPushMatch(
          foundMatches,
          diagDownLeftPositions,
          color,
          player
        );
      }
    }

    this.processMatchesForXPAndChainAndDamage(
      player,
      foundMatches,
      chainCount,
      this.checkMatchesForLance,
      callback
    );
  }

  gatherLine(player, startX, startY, dx, dy, color) {
    const positions = [];
    let x = startX + dx;
    let y = startY + dy;

    while (
      y >= 0 &&
      y < GRID_HEIGHT &&
      x >= 0 &&
      x < GRID_WIDTH &&
      player.grid[y][x] &&
      player.grid[y][x].originalColor === color
    ) {
      positions.push({ x, y });
      x += dx;
      y += dy;
    }

    return positions;
  }

  gatherLineDiagonals(player, startX, startY, direction, color) {
    const positions = [];
    let curX = startX;
    let curY = startY;

    while (true) {
      const { nextX, nextY } = this.getNextDiagonal(curX, curY, direction);
      if (
        nextY < 0 ||
        nextY >= GRID_HEIGHT ||
        nextX < 0 ||
        nextX >= GRID_WIDTH
      ) {
        break;
      }
      const nextBall = player.grid[nextY][nextX];
      if (!nextBall || nextBall.originalColor !== color) {
        break;
      }
      positions.push({ x: nextX, y: nextY });
      curX = nextX;
      curY = nextY;
    }

    return positions;
  }

  getNextDiagonal(x, y, direction) {
    const even = x % 2 === 0;

    switch (direction) {
      case "upLeft":
        return even
          ? { nextX: x - 1, nextY: y - 1 }
          : { nextX: x - 1, nextY: y };
      case "upRight":
        return even
          ? { nextX: x + 1, nextY: y - 1 }
          : { nextX: x + 1, nextY: y };
      case "downLeft":
        return even
          ? { nextX: x - 1, nextY: y }
          : { nextX: x - 1, nextY: y + 1 };
      case "downRight":
        return even
          ? { nextX: x + 1, nextY: y }
          : { nextX: x + 1, nextY: y + 1 };
      default:
        return { nextX: x, nextY: y };
    }
  }

  checkMatchesForFlora(player, callback, chainCount = 0) {
    if (!player?.grid) {
      callback?.();
      return;
    }

    const allBoardColors = new Set();
    for (let y = 0; y < GRID_HEIGHT; y++) {
      for (let x = 0; x < GRID_WIDTH; x++) {
        const orb = player.grid[y][x];
        if (orb) {
          allBoardColors.add(orb.originalColor);
        }
      }
    }

    const normalColors = [...allBoardColors].filter(color => {
      return !this.isEffectOrb(color) && color !== QUIVER_COLOR;
    });

    let allColorClusters = [];
    for (const color of normalColors) {
      const clusters = this.collectClustersForColor(player, color);
      clusters.forEach(c => {
        allColorClusters.push({ color, positions: c });
      });
    }

    const validClusters = allColorClusters.filter(cluster => {
      const nonCracked = cluster.positions.filter(({ x, y }) => {
        const orbObj = player.grid[y][x];
        return orbObj && !orbObj.isCracked;
      });
      if (nonCracked.length < MATCHES_REQUIRED) return false;

      const hasQuiver = cluster.positions.some(({ x, y }) => {
        const orbObj = player.grid[y][x];
        return orbObj?.originalColor === QUIVER_COLOR;
      });
      if (!hasQuiver) return false;

      return true;
    });

    if (validClusters.length === 0) {
      callback?.();
      return;
    }

    let largestCluster = validClusters[0];
    for (let i = 1; i < validClusters.length; i++) {
      if (validClusters[i].positions.length > largestCluster.positions.length) {
        largestCluster = validClusters[i];
      }
    }

    const foundMatches = [
      {
        color: largestCluster.color,
        size: largestCluster.positions.length,
        positions: largestCluster.positions,
      },
    ];

    this.processMatchesForXPAndChainAndDamage(
      player,
      foundMatches,
      chainCount,
      this.checkMatchesForFlora.bind(this),
      callback
    );
  }
}
