import React from "react";

const AdventureLocationMenu = ({
  selectedLocation,
  selectedButtonIndex,
  onSelect,
  setPlayerState,
}) => {
  const options = ["Explore", "Fight Monster", "Return"];

  const handleSelect = index => {
    if (index < 3) {
      onSelect(index);
    } else {
      console.log("Attempting to return to ADVENTURE state");
      setPlayerState("ADVENTURE");
      onSelect(Math.floor(selectedButtonIndex / 3) * 2); // Pass the correct index
    }
  };

  return (
    <div className="adventure-location-container">
      <h2>{selectedLocation}</h2>
      <div className="adventure-location-grid">
        {options.map((option, index) => (
          <button
            key={`${selectedLocation}-${index}`}
            onClick={() => handleSelect(index)}
            className={`couch-menu-button ${
              index === selectedButtonIndex % 3 ? "selected" : ""
            }`}>
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AdventureLocationMenu;
