import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const CogatPracticeLanding = () => {
    const user = useSelector((store) => store.user);
    const [ws, setWs] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const history = useHistory();

    useEffect(() => {
        let ws;
        let environment;

        if (location.href.includes("localhost")) {
            environment = "localhost";
        } else if (location.href.includes("192.168.0")) {
            environment = "localNetwork";
        } else if (location.href.includes("2nguyen.dev")) {
            environment = "production";
        }

        switch (environment) {
            case "localhost":
                ws = new WebSocket("ws://localhost:5000/cogat-practice");
                break;
            case "localNetwork":
                ws = new WebSocket("ws://192.168.0.3:5000/cogat-practice");
                break;
            case "production":
                ws = new WebSocket("wss://2nguyen.dev/cogat-practice");
                break;
            default:
                console.log(
                    "Environment not recognized. WebSocket not established."
                );
                break;
        }

        return () => {
            ws.close();
        };
    }, []);

    const goToTest = () => {
        history.push("/cogat-practice-test");
    };

    const goToSettings = () => {
        history.push("/cogat-practice-settings");
    };

    return (
        <div>
            <h2>Cogat Practice</h2>
            <p>Under construction...</p>
            <button onClick={goToTest}>Go to Test</button>
            <button onClick={goToSettings}>Go to Settings</button>
        </div>
    );
};

export default CogatPracticeLanding;
