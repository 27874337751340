import React from "react";
import "./PlayerBoard.css";

const PlayerBoard = ({ rows, cols, focusedCell }) => {
  const cells = Array(rows * cols).fill(null);

  return (
    <div className="player-board-container">
      <div
        className="player-board"
        style={{ gridTemplateColumns: `repeat(${cols}, 1fr)` }}>
        {cells.map((_, index) => (
          <button
            key={index}
            className={`cell ${
              focusedCell && focusedCell[0] * cols + focusedCell[1] === index
                ? "focused"
                : ""
            }`}
            onClick={() => console.log(`Clicked cell ${index}`)}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PlayerBoard;
