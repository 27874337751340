import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentView } from "../../redux/ElendiaActions";
import { VIEWS } from "./viewEnums";
import BattlePlayerCards from "./BattlePlayerCards";
import BattleAlliesGrid from "./BattleAlliesGrid";

const BattleMain = () => {
  const dispatch = useDispatch();
  const selectedCharacter = useSelector(
    state => state.elendia.selectedCharacter
  );
  const monsters = useSelector(state => state.elendia.monsters);
  const ws = useSelector(state => state.elendia.ws);
  const username = useSelector(state => state.user.username);

  const handleAttack = monster => {
    if (ws) {
      ws.send(
        JSON.stringify({
          type: "ATTACK_MONSTER",
          username,
          characterId: selectedCharacter.id,
          monsterId: monster.id,
        })
      );
    }
  };

  return (
    <div>
      <h2>Battle Main</h2>
      <div>
        <h3>Character: {selectedCharacter.name}</h3>
        <h3>Monsters:</h3>
        {monsters.map(monster => (
          <button
            key={monster.id}
            onClick={() => handleAttack(monster)}>
            {monster.name}
          </button>
        ))}
      </div>
      <BattlePlayerCards />
      <BattleAlliesGrid />
      <button onClick={() => dispatch(setCurrentView(VIEWS.MENU_MAIN))}>
        Return to Main Menu
      </button>
    </div>
  );
};

export default BattleMain;
