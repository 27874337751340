// cardsData.js
import RatCard from "./assets/rat_card.svg";
import RatToken from "./assets/rat_token.svg";
import BoneDragonCard from "./assets/bone_dragon_card.svg";
import BoneDragonToken from "./assets/bone_dragon_token.svg";
import GoblinCard from "./assets/goblin_card.svg";
import GoblinToken from "./assets/goblin_token.svg";
import ShopCard from "./assets/shop_card.svg";
import ShopToken from "./assets/shop_token.svg";
import RobeCard from "./assets/robe_card.svg";
import RobeToken from "./assets/robe_token.svg";

const Faction = {
  Arcanists: "Arcanists",
  Forestkin: "Forestkin",
  Chaos: "Chaos",
  Machinists: "Machinists",
};

export const cards = [
  {
    cardID: 0,
    card_image: RatCard,
    token_image: RatToken,
    name: "Con Chuột",
    description:
      "The two con chuột fought each other in the pit. The winner was declared the con chuột king.",
    faction: Faction.Forestkin,
    maximum_moves: 2,
    health: 1,
    attack: 1,
    cost: 1,
  },
  {
    cardID: 1,
    card_image: BoneDragonCard,
    token_image: BoneDragonToken,
    name: "Rồng Xương",
    description:
      "The bone dragon was a fearsome sight. It was also a fearsome smell. It was a fearsome everything.",
    faction: Faction.Chaos,
    maximum_moves: 1,
    health: 3,
    attack: 2,
    cost: 3,
  },
  {
    cardID: 2,
    card_image: GoblinCard,
    token_image: GoblinToken,
    name: "Grumblin",
    description:
      "The grumblin was small and green. It was also very annoying. It was also very dead.",
    faction: Faction.Forestkin,
    maximum_moves: 1,
    health: 2,
    attack: 1,
    cost: 2,
  },
  {
    cardID: 3,
    card_image: ShopCard,
    token_image: ShopToken,
    name: "Rang Đồng",
    description:
      "The shop was full of items. Some of them were even useful. Most of them were not.",
    faction: Faction.Machinists,
    maximum_moves: 0,
    health: 3,
    attack: 0,
    cost: 3,
  },
  {
    cardID: 4,
    card_image: RobeCard,
    token_image: RobeToken,
    name: "Smellcaster",
    description:
      "The smellcaster was a powerful wizard. His power was only surpassed by his smell.",

    faction: Faction.Arcanists,
    maximum_moves: 1,
    health: 2,
    attack: 2,
    cost: 2,
  },
];
