import React from "react";

const AdventureMenu = ({
  adventureOptions,
  selectedButtonIndex,
  onSelect,
  setPlayerState,
}) => {
  const handleSelect = index => {
    if (index < adventureOptions.length * 2) {
      setPlayerState("ADVENTURE_LOCATION");
      onSelect(index);
    } else {
      setPlayerState("ADVENTURE");
      onSelect(adventureOptions.length * 2);
    }
  };

  return (
    <div className="adventure-container">
      <div className="adventure-grid">
        {adventureOptions.map((row, rowIndex) =>
          row.map((option, colIndex) => {
            const index = rowIndex * 2 + colIndex;
            return (
              <button
                key={`${rowIndex}-${colIndex}`}
                onClick={() => handleSelect(index)}
                className={`couch-menu-button ${
                  index === selectedButtonIndex ? "selected" : ""
                }`}>
                {option}
              </button>
            );
          })
        )}
      </div>
      <button
        onClick={() => handleSelect(adventureOptions.length * 2)}
        className={`couch-menu-button ${
          selectedButtonIndex === adventureOptions.length * 2 ? "selected" : ""
        }`}>
        Return to Town
      </button>
    </div>
  );
};

export default AdventureMenu;
