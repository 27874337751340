import React, { useState, useEffect } from "react";
import BinaryGame from "./BinaryChatRoomGame";

function BinaryLobby() {
  const [ws, setWs] = useState(null);
  const [roomName, setRoomName] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    let ws;

    let environment;

    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }

    switch (environment) {
      case "localhost":
        ws = new WebSocket("ws://localhost:5000/binary");
        break;
      case "localNetwork":
        ws = new WebSocket("ws://192.168.0.3:5000/binary");
        break;
      case "production":
        ws = new WebSocket("wss://2nguyen.dev/binary");
        break;
      default:
        console.log("Environment not recognized. WebSocket not established.");
        break;
    }

    ws.onopen = () => {
      console.log("connected");
      setIsConnected(true);
    };

    ws.onclose = () => {
      console.log("disconnected");
      setIsConnected(false);
      // Optionally, attempt to reconnect
    };

    ws.onerror = error => {
      console.error("WebSocket error:", error);
      // Handle errors, possibly by attempting to reconnect
    };

    ws.onmessage = function (event) {
      const data = JSON.parse(event.data);
      console.log("Received message:", data);
      if (data.type === "roomCreated") {
        console.log("Room created:", data.roomId, data.roomName);
      } else if (data.type === "rooms") {
        // Updated to handle 'rooms' type
        setRooms(data.rooms); // Update the rooms list
      } else if (data.type === "message") {
        setMessages(prevMessages => [
          ...prevMessages,
          { content: data.content, roomId: data.roomId },
        ]);
        console.log(`Message from room ${data.roomId}: ${data.content}`);
      } else if (data.type === "joinedRoom") {
        setCurrentRoom(data.roomId);
        setMessages([]);
      }
    };

    setWs(ws);

    // return () => {
    //   ws.close();
    // };
  }, []); // Empty dependency array means this effect runs once on mount

  const createRoom = () => {
    if (ws && isConnected) {
      const message = JSON.stringify({
        action: "createRoom",
        roomName: roomName,
      });
      console.log("Sending message:", message); // Debug log
      ws.send(message);
      setRoomName("");
    } else {
      console.error("WebSocket connection is not open.");
    }
  };

  const joinRoom = roomId => {
    if (ws && isConnected) {
      const message = JSON.stringify({
        action: "joinRoom",
        roomId: roomId,
      });
      ws.send(message);
      setCurrentRoom(roomId); // Set the current room
      setMessages([]);
    } else {
      console.error("WebSocket connection is not open.");
    }
  };

  const sendMessage = content => {
    if (ws && isConnected && currentRoom) {
      const message = JSON.stringify({
        action: "sendMessage",
        content: content,
        roomId: currentRoom, // Include the room ID in the message
      });
      ws.send(message);
    }
  };

  return (
    <div>
      <h2>Binary Chat Room</h2>
      <input
        type="text"
        value={roomName}
        onChange={e => setRoomName(e.target.value)}
      />
      <button
        onClick={createRoom}
        disabled={!isConnected}>
        Create Room
      </button>
      {rooms.map((room, index) => (
        <div key={index}>
          <button onClick={() => joinRoom(room.id)}>Join {room.name}</button>
        </div>
      ))}
      {currentRoom && (
        <BinaryGame
          currentRoom={currentRoom}
          messages={messages}
          sendMessage={sendMessage}
        />
      )}
    </div>
  );
}

export default BinaryLobby;
