import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Phaser from "phaser";
import CollectWizardScene from "./CollectWizardScene";
import "./CollectWizard.css";

const CollectWizard = () => {
  const user = useSelector(store => store.user);

  useEffect(() => {
    const collectWizardScene = new CollectWizardScene(user);
    const config = {
      type: Phaser.AUTO,
      width: 360,
      height: 700,
      physics: {
        default: "arcade",
      },
      scene: [collectWizardScene],
    };

    const game = new Phaser.Game(config);

    return () => {
      if (collectWizardScene.shutdown) {
        collectWizardScene.shutdown();
        collectWizardScene.destroy();
      }
      game.destroy(true);
    };
  }, [user]);

  return (
    <div
      id="phaser-game"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default CollectWizard;
