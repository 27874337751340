import Phaser from "phaser";
import playerImage from "./assets/player.png";
import enemyImage from "./assets/enemy.png";
import treeImage01 from "./assets/tree01.png";
import wallImage from "./assets/wall.png";
import playerProjectile from "./assets/player_projectile.png";

class SpaceSurvivorsScene extends Phaser.Scene {
  constructor(user) {
    super({ key: "SpaceSurvivorsScene" });
    this.user = user.username;
    this.treeGroup = null;
    this.wallGroup = null;
    this.projectileGroup = null;
    this.enemyGroup = null;
  }

  init() {
    this.setUsernamePayload;
    this.playerId = null;
    this.x = null;
    this.y = null;
    this.color = null;
    this.score = 0;

    this.ws = null;

    let environment;

    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }

    switch (environment) {
      case "localhost":
        this.ws = new WebSocket("ws://localhost:5000/space-survivors");
        break;
      case "localNetwork":
        this.ws = new WebSocket("ws://192.168.0.3:5000/space-survivors");
        break;
      case "production":
        this.ws = new WebSocket("wss://2nguyen.dev/space-survivors");
        break;
      default:
        console.log("Environment not recognized. WebSocket not established.");
        break;
    }
  }

  preload() {
    this.load.image("player", playerImage);
    this.load.image("enemy", enemyImage);
    this.load.image("tree01", treeImage01);
    this.load.image("wall", wallImage);
    this.load.image("playerProjectile", playerProjectile);
  }

  create() {
    this.ws.onopen = () => {
      let connectPayload = JSON.stringify({
        method: "connect_check_username",
        username: this.user,
      });

      this.ws.send(connectPayload);
    };

    this.treeGroup = this.physics.add.group({});
    this.wallGroup = this.physics.add.group({});
    this.projectileGroup = this.physics.add.group();
    this.enemyGroup = this.physics.add.group();

    this.input.on("pointerdown", pointer => {
      let targetX = pointer.x;
      let targetY = pointer.y;

      // Convert screen coordinates to world coordinates
      let worldPoint = this.cameras.main.getWorldPoint(targetX, targetY);

      // Send movement request to the server
      let movePayload = JSON.stringify({
        method: "move_to",
        playerId: this.playerId,
        targetX: worldPoint.x,
        targetY: worldPoint.y,
      });

      this.ws.send(movePayload);
    });

    this.ws.onmessage = event => {
      let message = JSON.parse(event.data);

      switch (message.method) {
        case "existing_player_object":
        case "successful_connection":
          this.handlePlayerInitialization(message);
          break;
        case "reconnected":
          this.handlePlayerReconnection(message);
          break;
        case "force_disconnect":
          alert(message.reason);
          window.location.href = "/";
          break;
        case "all_players_info":
          if (!this.otherPlayers) {
            this.otherPlayers = {};
          }

          Object.keys(this.otherPlayers).forEach(playerId => {
            if (!message.players.some(p => p.playerId === playerId)) {
              this.otherPlayers[playerId].destroy();
              delete this.otherPlayers[playerId];
            }
          });

          message.players.forEach(playerData => {
            if (playerData.playerId === this.playerId) {
              // Move the player's own object if it matches the player's ID
              if (this.player) {
                this.player.setPosition(playerData.x, playerData.y);
                this.player.setTint(
                  parseInt(playerData.color.replace("#", "0x"), 16)
                );
              } else {
                this.player = this.physics.add
                  .sprite(playerData.x, playerData.y, "player")
                  .setCollideWorldBounds(true)
                  .setPushable(false)
                  .setTint(parseInt(playerData.color.replace("#", "0x"), 16));
              }
            } else if (!this.otherPlayers[playerData.playerId]) {
              // Create a new object for other players if it doesn't exist
              this.otherPlayers[playerData.playerId] = this.physics.add
                .sprite(playerData.x, playerData.y, "player")
                .setCollideWorldBounds(true)
                .setPushable(false)
                .setTint(parseInt(playerData.color.replace("#", "0x"), 16));
            } else {
              // Update the position and tint of other players' objects
              this.otherPlayers[playerData.playerId].setPosition(
                playerData.x,
                playerData.y
              );
              this.otherPlayers[playerData.playerId].setTint(
                parseInt(playerData.color.replace("#", "0x"), 16)
              );
            }
          });
          break;
        case "trees_info":
          this.createTreesFromServer(message.trees);
          break;
        case "walls_info":
          this.createWallsFromServer(message.walls);
          break;
        case "projectiles_update":
          this.updateProjectiles(message.projectiles);
          break;
        case "enemy_update":
          this.updateEnemies(message.enemies);
          break;
        case "update_score":
          const newScore = message.score;
          scoreText.setText(`Score: ${newScore}`);
        default:
          console.log("Message not recognized.");
          break;
      }
    };

    const scoreText = this.add
      .text(20, this.sys.game.config.height - 50, "Score: 0", {
        fontSize: "32px",
        fill: "#FFFFFF",
      })
      .setOrigin(0, 1)
      .setScrollFactor(0);
  }

  shutdown() {
    if (this.ws) {
      this.ws.close();
    }
  }

  destroy() {
    this.shutdown();
  }

  handlePlayerInitialization(message) {
    this.playerId = message.playerId;
    this.x = message.x;
    this.y = message.y;
    this.playerColor = message.color;

    if (this.player) {
      // If the player object already exists, just update its position and tint
      this.player.setPosition(this.x, this.y);
      this.player.setTint(parseInt(this.playerColor.replace("#", "0x"), 16));
    } else {
      // Otherwise, create a new player object
      this.player = this.physics.add
        .sprite(this.x, this.y, "player")
        .setCollideWorldBounds(true)
        .setPushable(false)
        .setTint(parseInt(this.playerColor.replace("#", "0x"), 16));
    }

    const setUsernamePayload = JSON.stringify({
      method: "set_username",
      playerId: this.playerId,
      username: this.user,
    });

    this.ws.send(setUsernamePayload);

    this.cameras.main.startFollow(this.player);
    // this.cameras.main.setZoom(1);
  }

  handlePlayerReconnection(message) {
    this.playerId = message.playerId;
    this.x = message.x;
    this.y = message.y;
    this.playerColor = message.color;

    if (this.player) {
      // If the player object already exists, just update its position and tint
      this.player.setPosition(this.x, this.y);
      this.player.setTint(parseInt(this.playerColor.replace("#", "0x"), 16));
    } else {
      // Otherwise, create a new player object
      this.player = this.physics.add
        .sprite(this.x, this.y, "player")
        .setCollideWorldBounds(true)
        .setPushable(false)
        .setTint(parseInt(this.playerColor.replace("#", "0x"), 16));
    }

    this.cameras.main.startFollow(this.player);
    // this.cameras.main.setZoom(1);
  }

  createTreesFromServer(treesData) {
    treesData.forEach(treeData => {
      const treeSprite = this.treeGroup.create(
        treeData.x,
        treeData.y,
        "tree01"
      );
      treeSprite.setTint(parseInt(treeData.color.replace("#", "0x"), 16));
    });
  }

  createWallsFromServer(wallsData) {
    wallsData.forEach(wallData => {
      const wallSprite = this.wallGroup.create(wallData.x, wallData.y, "wall");
    });
  }

  updateProjectiles(projectilesData) {
    // Get all current projectile IDs
    const currentProjectileIds = this.projectileGroup
      .getChildren()
      .map(projectile => projectile.getData("id"));

    // Filter out projectiles that no longer exist in the incoming data
    const projectilesToDelete = currentProjectileIds.filter(
      id => !projectilesData.some(data => data.id === id)
    );

    // Delete projectiles that no longer exist
    projectilesToDelete.forEach(id => {
      const projectileToDelete = this.projectileGroup
        .getChildren()
        .find(projectile => projectile.getData("id") === id);
      if (projectileToDelete) {
        projectileToDelete.destroy();
      }
    });

    // Update or create projectiles based on incoming data
    projectilesData.forEach(projectileData => {
      let existingProjectile = this.projectileGroup
        .getChildren()
        .find(projectile => projectile.getData("id") === projectileData.id);

      if (existingProjectile) {
        // Update position of existing projectile
        existingProjectile.setPosition(projectileData.x, projectileData.y);
      } else {
        // Create new projectile if it doesn't exist
        const projectile = this.projectileGroup.create(
          projectileData.x,
          projectileData.y,
          "playerProjectile"
        );
        projectile.setData({
          id: projectileData.id,
          // Additional properties can be set here if needed
        });
      }
    });
  }

  updateEnemies(enemiesData) {
    // Remove enemies that no longer exist
    this.enemyGroup.getChildren().forEach((enemy, index) => {
      if (!enemiesData.some(data => data.id === enemy.getData("id"))) {
        enemy.destroy();
      }
    });

    // Create new enemies if they don't exist
    enemiesData.forEach(enemyData => {
      if (
        !this.enemyGroup
          .getChildren()
          .some(e => e.getData("id") === enemyData.id)
      ) {
        const enemy = this.enemyGroup.create(enemyData.x, enemyData.y, "enemy");
        enemy.setData({
          id: enemyData.id,
          // Additional properties can be set here if needed
        });
      }
    });

    // Update existing enemies positions
    this.enemyGroup.getChildren().forEach(enemy => {
      const enemyData = enemiesData.find(e => e.id === enemy.getData("id"));
      if (enemyData) {
        enemy.setPosition(enemyData.x, enemyData.y);
      }
    });
  }
}

export default SpaceSurvivorsScene;
