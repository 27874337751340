import Phaser from "phaser";
import { ws, globalUsername, globalCharacterName } from "./CardMonstersGlobal";
import dogSprite from "./assets/sprites/dog.png";

class CardMonstersIntroScene extends Phaser.Scene {
  constructor(user) {
    super({ key: "CardMonstersIntroScene" });
    this.dogSprites = {};
  }

  init() {
    this.username = globalUsername;
    this.characterName = globalCharacterName;
    this.ws = ws;
  }

  preload() {
    this.load.image("dog", dogSprite);
  }

  create() {
    if (this.ws.readyState === WebSocket.OPEN) {
      const message = {
        type: "createDog",
        username: this.username,
        characterName: this.characterName,
      };
      console.log("Sending message to server:", message);
      this.ws.send(JSON.stringify(message));
    } else {
      this.ws.onopen = () => {
        const message = {
          type: "createDog",
          username: this.username,
          characterName: this.characterName,
        };
        console.log("Sending message to server:", message);
        this.ws.send(JSON.stringify(message));
      };
    }

    let fullscreenButton = this.add
      .text(10, 10, "[<->]", { fill: "#fff" })
      .setInteractive()
      .on("pointerdown", () => {
        if (this.scale.isFullscreen) {
          this.scale.stopFullscreen();
        } else {
          this.scale.startFullscreen();
        }
      });

    this.add
      .text(
        this.cameras.main.centerX,
        this.cameras.main.centerY,
        "Card Monsters Intro View",
        { fontSize: "16px", fill: "#fff" }
      )
      .setOrigin(0.5);

    this.ws.onmessage = event => {
      let message = JSON.parse(event.data);

      switch (message.type) {
        case "dogCreated":
          this.createOrUpdateDogSprite(message.characterName, message.position);
          break;
        case "dogPositions":
          message.dogPositions.forEach(dog => {
            this.createOrUpdateDogSprite(dog.characterName, dog.position);
          });
          break;
        default:
          console.log("Message not recognized.");
          break;
      }
    };

    this.input.on("pointerdown", pointer => {
      const newPosition = { x: pointer.x, y: pointer.y };
      const updateMessage = {
        type: "updateDogPosition",
        username: this.username,
        characterName: this.characterName,
        position: newPosition,
      };
      console.log("Sending update position message to server:", updateMessage);
      this.ws.send(JSON.stringify(updateMessage));
    });
  }

  createOrUpdateDogSprite(characterName, position) {
    if (this.dogSprites[characterName]) {
      this.dogSprites[characterName].sprite.setPosition(position.x, position.y);
      this.dogSprites[characterName].label.setPosition(
        position.x,
        position.y + 20
      );
    } else {
      const sprite = this.add.sprite(position.x, position.y, "dog");
      const label = this.add
        .text(position.x, position.y + 20, characterName, {
          fontSize: "12px",
          fill: "#fff",
        })
        .setOrigin(0.5);
      this.dogSprites[characterName] = { sprite, label };
    }
  }

  shutdown() {
    if (this.ws) {
      this.ws.close();
    }
  }

  destroy() {
    this.shutdown();
  }
}

export default CardMonstersIntroScene;
