import Phaser from "phaser";
import SHIELD_IMAGE from "./assets/checked-shield.svg";
import HEALTH_IMAGE from "./assets/hearts.svg";
import ATTACK_IMAGE from "./assets/punch.svg";
import COIN_IMAGE from "./assets/metal-disc.svg";
import MONSTER_IMAGE from "./assets/shambling-zombie.svg";

import CHARACTER_IMAGE from "./assets/character.svg";
import SETTINGS_IMAGE from "./assets/gears.svg";

export default class DungeonMatcherScene extends Phaser.Scene {
  constructor() {
    super({ key: "DungeonMatcherScene" });
  }

  initializePlayer() {
    this.playerLevel = 1;
    this.playerXP = 0;
    this.playerMaxHealth = 50;
    this.playerHealth = 50;
    this.playerMaxArmor = 10;
    this.playerArmor = 0;
    this.playerUpgrade = 0;
    this.playerGold = 0;
    this.playerStrength = 0;
    this.playerDexterity = 0;
    this.playerWisdom = 0;
    this.playerVitality = 0;
    this.playerLuck = 0;

    this.playerWeapon = null;
    this.playerArmorSlot = null;
    this.playerAccessory = null;

    this.totalCirclesSpawned = 0;
    this.enemyMinHealth = 1;
    this.enemyMaxHealth = 2;
    this.enemyMinArmor = 0;
    this.enemyDamage = 1;
  }

  create() {
    this.createTopUI();

    this.initializePlayer();

    this.gridSize = 6;
    this.cellSize = 80;
    this.boardOffsetY = 80 + 10;

    this.colors = [0x808080, 0xffff00, 0x0000ff, 0xff0000];

    this.colorToIconKey = {
      0x808080: "attack_icon",
      0xffff00: "coin_icon",
      0x0000ff: "shield_icon",
      0xff0000: "health_icon",
      0xffffff: "monster_icon",
    };

    this.board = [];

    this.generateNewBoard(false);

    this.createBottomUI();

    this.selectedCircles = [];
    this.lineGraphics = this.add.graphics({
      lineStyle: { width: 4, color: 0x00ff00 },
    });
    this.lineGraphics.setDepth(9999);

    this.isDragging = false;

    this.input.on("pointerdown", this.handlePointerDown, this);
    this.input.on("pointermove", this.handlePointerMove, this);
    this.input.on("pointerup", this.handlePointerUp, this);

    this.gameOverOverlay = null;
    this.restartButton = null;
    this.characterOverlay = null;
    this.settingsOverlay = null;
    this.confirmOverlay = null;
    this.levelUpOverlayContainer = null;
    this.skipConfirmationContainer = null;
  }

  createCircle(row, col, color) {
    const container = this.add.container(
      col * this.cellSize + this.cellSize / 2,
      -this.cellSize
    );

    const iconKey = this.colorToIconKey[color];
    if (iconKey) {
      const icon = this.add.image(0, 0, iconKey);

      icon.setScale(0.5);

      icon.setTint(color);

      container.add(icon);
    }

    container.setData("row", row);
    container.setData("col", col);
    container.setData("color", color);

    this.totalCirclesSpawned++;

    if (this.totalCirclesSpawned % 500 === 0) {
      this.enemyMinHealth++;
      this.enemyMaxHealth++;
      this.enemyMinArmor++;
      this.enemyDamage++;
    }

    if (color === 0xffffff) {
      const health = Phaser.Math.Between(
        this.enemyMinHealth,
        this.enemyMaxHealth
      );
      const armor = Phaser.Math.Between(
        this.enemyMinArmor,
        this.enemyMinArmor + 1
      );
      const damage = this.enemyDamage;

      container.setData("health", health);
      container.setData("armor", armor);
      container.setData("damage", damage);
      container.setData("turnsInPlay", 0);

      const offsetX = 14;

      const textStyle = {
        fontSize: "16px",
        color: "#B2BEB5",
        stroke: "#ffffff",
        strokeThickness: 4,
        align: "center",
      };

      const damageText = this.add
        .text(offsetX, -20, damage, { ...textStyle, color: "#B2BEB5" })
        .setOrigin(0.5);
      const armorText = this.add
        .text(offsetX, 0, armor, { ...textStyle, color: "#0000ff" })
        .setOrigin(0.5);
      const healthText = this.add
        .text(offsetX, 20, health, { ...textStyle, color: "#ff0000" })
        .setOrigin(0.5);

      container.add(damageText);
      container.add(armorText);
      container.add(healthText);

      container.statsTexts = { damageText, armorText, healthText };
    }

    this.add.existing(container);

    this.tweens.add({
      targets: container,
      y: row * this.cellSize + this.cellSize / 2 + this.boardOffsetY,
      duration: 300,
      ease: "Bounce.easeOut",
      onUpdate: () => {
        if (container.statsTexts) {
          const offsetX = this.cellSize / 2 - 14;
          container.statsTexts.damageText.setPosition(offsetX, -20);
          container.statsTexts.armorText.setPosition(offsetX, 0);
          container.statsTexts.healthText.setPosition(offsetX, 20);
        }
      },
    });

    return container;
  }

  createTopUI() {
    const topUIHeight = 80;
    const width = this.cameras.main.width;
    const halfWidth = width / 2;

    this.topUIBg = this.add
      .rectangle(halfWidth, topUIHeight / 2, width, topUIHeight, 0x2c2c2c)
      .setOrigin(0.5, 0.5);
    this.topUIBg.setDepth(10000);

    const slotSize = 60;
    const spacing = 15;
    const slotY = topUIHeight / 2;
    let startX = 2 * slotSize + 1.5 * spacing;

    const characterIconX = startX - (slotSize + spacing) - 10;
    this.characterIcon = this.add
      .image(characterIconX, slotY, "character_icon")
      .setDepth(10001)
      .setScale(0.5)
      .setInteractive({ useHandCursor: true });

    this.characterIcon.on("pointerdown", () => {
      this.showCharacterOverlay();
    });

    for (let i = 0; i < 4; i++) {
      const slotX = startX + i * (slotSize + spacing);
      const slot = this.add
        .rectangle(slotX, slotY, slotSize, slotSize, 0x555555)
        .setStrokeStyle(2, 0xffffff);
      slot.setDepth(10001);
    }

    const rightmostSlotX = startX + 3 * (slotSize + spacing);
    const settingsIconX = rightmostSlotX + slotSize + spacing + 10;
    this.settingsIcon = this.add
      .image(settingsIconX, slotY, "settings_icon")
      .setDepth(10001)
      .setScale(0.5)
      .setInteractive({ useHandCursor: true });

    this.settingsIcon.on("pointerdown", () => {
      this.showSettingsOverlay();
    });
  }

  createBottomUI() {
    const bottomUIHeight = 100;
    const width = this.cameras.main.width;
    const halfWidth = width / 2;
    const bottomUIY = this.cameras.main.height - bottomUIHeight / 2;

    this.bottomUIBg = this.add
      .rectangle(halfWidth, bottomUIY, width, bottomUIHeight, 0x2c2c2c)
      .setOrigin(0.5, 0.5);
    this.bottomUIBg.setDepth(10000);

    const statsBaseX = 50;
    const statsBaseY = bottomUIY - bottomUIHeight / 2 + 20;
    const lineHeight = 20;

    this.levelText = this.add
      .text(statsBaseX, statsBaseY, "Level: 1", {
        font: "16px Arial",
        fill: "#ffffff",
      })
      .setDepth(10001);

    this.xpText = this.add
      .text(statsBaseX, statsBaseY + lineHeight, "XP: 0 / 100", {
        font: "16px Arial",
        fill: "#ffffff",
      })
      .setDepth(10001);

    this.healthText = this.add
      .text(statsBaseX + 150, statsBaseY, `HP: ${this.playerHealth} / 50`, {
        font: "16px Arial",
        fill: "#ffffff",
      })
      .setDepth(10001);

    this.armorText = this.add
      .text(
        statsBaseX + 150,
        statsBaseY + lineHeight,
        `Armor: ${this.playerArmor} / ${this.playerMaxArmor}`,
        {
          font: "16px Arial",
          fill: "#ffffff",
        }
      )
      .setDepth(10001);

    this.goldText = this.add
      .text(statsBaseX + 300, statsBaseY, "Gold: 0 / 100", {
        font: "16px Arial",
        fill: "#ffffff",
      })
      .setDepth(10001);

    this.upgradeText = this.add
      .text(
        statsBaseX + 300,
        statsBaseY + lineHeight,
        `Upgrade: ${this.playerUpgrade} / 100`,
        {
          font: "16px Arial",
          fill: "#ffffff",
        }
      )
      .setDepth(10001);
  }

  handlePointerDown(pointer) {
    const circle = this.getCircleUnderPointer(pointer.x, pointer.y);
    if (circle) {
      this.isDragging = true;
      this.selectedCircles = [circle];
      this.lineGraphics.clear();
    }
  }

  handlePointerMove(pointer) {
    if (!this.isDragging) return;

    const circle = this.getCircleUnderPointer(pointer.x, pointer.y);
    if (!circle) {
      this.redrawLine();
      this.updatePotentialEnemyKills();
      return;
    }

    const lastIndex = this.selectedCircles.length - 1;
    const lastSelected = this.selectedCircles[lastIndex];

    if (this.selectedCircles.length > 1) {
      const secondToLastCircle = this.selectedCircles[lastIndex - 1];
      if (circle === secondToLastCircle) {
        this.selectedCircles.pop();
        this.redrawLine();
        this.updatePotentialEnemyKills();
        return;
      }
    }

    const colorA = lastSelected.getData("color");
    const colorB = circle.getData("color");

    if (
      this.areAdjacent(lastSelected, circle) &&
      this.canMatchColors(colorA, colorB)
    ) {
      if (!this.selectedCircles.includes(circle)) {
        this.selectedCircles.push(circle);
      }
    }

    this.redrawLine();
    this.updatePotentialEnemyKills();
  }

  handlePointerUp() {
    if (!this.isDragging) return;

    this.isDragging = false;

    this.clearAllRedX();

    const numSelected = this.selectedCircles.length;

    if (numSelected >= 3) {
      this.popAndRemoveSelected(this.selectedCircles);

      this.endOfPlayerTurn();
    }

    this.selectedCircles = [];
    this.lineGraphics.clear();
  }

  updateHealthText() {
    this.healthText.setText(
      `HP: ${this.playerHealth} / ${this.playerMaxHealth}`
    );
  }

  checkLevelUp() {
    while (this.playerXP >= 100) {
      this.playerXP -= 100;
      this.playerLevel++;

      this.showLevelUpOverlay();
    }

    this.levelText.setText(`Level: ${this.playerLevel}`);
    this.xpText.setText(`XP: ${this.playerXP} / 100`);
  }

  showLevelUpOverlay() {
    if (this.levelUpOverlayContainer) {
      this.levelUpOverlayContainer.destroy();
      this.levelUpOverlayContainer = null;
    }

    const allStats = [
      "playerStrength",
      "playerDexterity",
      "playerWisdom",
      "playerVitality",
      "playerLuck",
    ];

    Phaser.Utils.Array.Shuffle(allStats);
    const chosenStats = allStats.slice(0, 4);

    const width = this.cameras.main.width;
    const height = this.cameras.main.height;

    this.levelUpOverlayContainer = this.add.container(0, 0).setDepth(999999);

    const overlayBg = this.add
      .rectangle(width / 2, height / 2, width, height, 0x000000, 0.7)
      .setOrigin(0.5);

    const titleText = this.add
      .text(width / 2, height / 2 - 180, "Level Up!", {
        fontSize: "32px",
        color: "#ffffff",
      })
      .setOrigin(0.5);

    const instructionText = this.add
      .text(
        width / 2,
        height / 2 - 130,
        "Please choose two stats to level up:",
        {
          fontSize: "20px",
          color: "#ffffff",
        }
      )
      .setOrigin(0.5);

    this.levelUpStatTextObjects = [];

    let startY = height / 2 - 60;
    const lineHeight = 40;

    let selectedStatKeys = [];

    chosenStats.forEach((statKey, index) => {
      const statName = statKey.replace("player", "");
      const currentValue = this[statKey];

      const statText = this.add
        .text(
          width / 2,
          startY + index * lineHeight,
          `${statName}: ${currentValue}`,
          {
            fontSize: "24px",
            color: "#ffffff",
            backgroundColor: "#444444",
            padding: { x: 10, y: 5 },
          }
        )
        .setOrigin(0.5)
        .setInteractive({ useHandCursor: true });

      statText.on("pointerdown", () => {
        if (!selectedStatKeys.includes(statKey)) {
          if (selectedStatKeys.length < 2) {
            selectedStatKeys.push(statKey);
            statText.setStyle({ backgroundColor: "#777777" });
          }
        } else {
          selectedStatKeys = selectedStatKeys.filter(k => k !== statKey);
          statText.setStyle({ backgroundColor: "#444444" });
        }
        toggleConfirmButton();
      });

      this.levelUpStatTextObjects.push(statText);
    });

    const confirmText = this.add
      .text(width / 2 - 80, height / 2 + 90, "[ Confirm ]", {
        fontSize: "24px",
        color: "#ffffff",
        backgroundColor: "#333333",
        padding: { x: 10, y: 5 },
      })
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true })
      .setVisible(false);

    confirmText.on("pointerdown", () => {
      selectedStatKeys.forEach(key => {
        this[key] += 1;
      });

      const oldMaxHealth = this.playerMaxHealth;
      this.playerMaxHealth =
        50 + this.playerStrength * 5 + this.playerVitality * 10;

      const diff = this.playerMaxHealth - oldMaxHealth;
      if (diff > 0) {
        this.playerHealth += diff;
      }
      if (this.playerHealth > this.playerMaxHealth) {
        this.playerHealth = this.playerMaxHealth;
      }

      this.updateHealthText();

      if (this.levelUpOverlayContainer) {
        this.levelUpOverlayContainer.destroy();
        this.levelUpOverlayContainer = null;
      }
    });

    const skipText = this.add
      .text(width / 2 + 80, height / 2 + 90, "[ Skip ]", {
        fontSize: "24px",
        color: "#ffffff",
        backgroundColor: "#333333",
        padding: { x: 10, y: 5 },
      })
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true });

    skipText.on("pointerdown", () => {
      this.showSkipConfirmation(() => {
        if (this.levelUpOverlayContainer) {
          this.levelUpOverlayContainer.destroy();
          this.levelUpOverlayContainer = null;
        }
      });
    });

    const toggleConfirmButton = () => {
      confirmText.setVisible(selectedStatKeys.length === 2);
    };

    this.levelUpOverlayContainer.add([
      overlayBg,
      titleText,
      instructionText,
      confirmText,
      skipText,
      ...this.levelUpStatTextObjects,
    ]);
  }

  updatePotentialEnemyKills() {
    this.clearAllRedX();

    if (this.selectedCircles.length < 3) return;

    const whiteCount = this.selectedCircles.filter(
      c => c.getData("color") === 0xffffff
    ).length;

    const grayCount = this.selectedCircles.filter(
      c => c.getData("color") === 0x808080
    ).length;

    if (whiteCount === 0 && grayCount === 0) {
      return;
    }

    const damagePerCircle = 1 + this.playerStrength;

    const whiteDamage = whiteCount * damagePerCircle;
    const grayDamage = grayCount * damagePerCircle;

    const totalDamage = whiteDamage + grayDamage;

    this.selectedCircles.forEach(circle => {
      const color = circle.getData("color");
      if (color === 0xffffff || color === 0x808080) {
        const currentHealth = circle.getData("health");
        const armor = circle.getData("armor") || 0;

        let effectiveDamage = totalDamage - armor;
        if (effectiveDamage < 0) {
          effectiveDamage = 0;
        }

        const newHealth = currentHealth - effectiveDamage;
        if (newHealth <= 0) {
          this.showRedX(circle);
        }
      }
    });
  }

  popAndRemoveSelected(selected) {
    if (selected.length < 3) {
      return;
    }

    const hasWhite = selected.some(
      circle => circle.getData("color") === 0xffffff
    );
    const hasGray = selected.some(
      circle => circle.getData("color") === 0x808080
    );

    let circlesToRemove = [];

    if (hasWhite || hasGray) {
      const whiteOrGrayCount = selected.filter(circle => {
        const color = circle.getData("color");
        return color === 0xffffff || color === 0x808080;
      }).length;

      const damage = whiteOrGrayCount * (1 + this.playerStrength);

      selected.forEach(circle => {
        const color = circle.getData("color");

        if (color === 0xffffff) {
          let armor = circle.getData("armor") || 0;
          let health = circle.getData("health");

          let effectiveDamage = damage;

          if (armor > 0) {
            const armorDamage = Math.min(effectiveDamage, armor);
            armor -= armorDamage;
            effectiveDamage -= armorDamage;
            circle.setData("armor", armor);

            if (circle.statsTexts && circle.statsTexts.armorText) {
              circle.statsTexts.armorText.setText(armor);
            }
          }

          if (effectiveDamage > 0) {
            health -= effectiveDamage;
            circle.setData("health", health);
            if (circle.statsTexts && circle.statsTexts.healthText) {
              circle.statsTexts.healthText.setText(health);
            }
          }

          if (health <= 0) {
            circlesToRemove.push(circle);
            this.playerXP += 1;
          }
        } else if (color === 0x808080) {
          circlesToRemove.push(circle);
        } else {
          circlesToRemove.push(circle);
        }
      });

      this.checkLevelUp();
    } else {
      circlesToRemove = selected.slice();
    }
    const numRed = selected.filter(
      circle => circle.getData("color") === 0xff0000
    ).length;
    if (numRed > 0) {
      this.playerHealth += numRed;
      if (this.playerHealth > this.playerMaxHealth) {
        this.playerHealth = this.playerMaxHealth;
      }
      this.updateHealthText();
    }

    const numBlue = selected.filter(
      circle => circle.getData("color") === 0x0000ff
    ).length;
    if (numBlue > 0) {
      for (let i = 0; i < numBlue; i++) {
        if (this.playerArmor < this.playerMaxArmor) {
          this.playerArmor++;
        } else {
          this.playerUpgrade++;
          if (this.playerUpgrade > 100) {
            let leftover = this.playerUpgrade - 100;
            this.playerMaxArmor++;
            this.playerUpgrade = 0;

            while (leftover > 100) {
              leftover -= 100;
              this.playerMaxArmor++;
            }
            this.playerUpgrade = leftover;
          }
        }
      }
      this.updateArmorText();
    }

    const numYellow = selected.filter(
      circle => circle.getData("color") === 0xffff00
    ).length;
    if (numYellow > 0) {
      this.playerGold += numYellow;
      this.checkGold();
      this.updateGoldText();
    }

    circlesToRemove.forEach(circle => {
      const row = circle.getData("row");
      const col = circle.getData("col");
      this.board[row][col] = null;

      if (circle.statsTexts) {
        circle.statsTexts.damageText.destroy();
        circle.statsTexts.armorText.destroy();
        circle.statsTexts.healthText.destroy();
      }
    });

    this.animateCircleRemoval(circlesToRemove);
  }

  applyDamageToPlayer(damage) {
    if (this.playerArmor > 0) {
      const armorAbsorbed = Math.min(damage, this.playerArmor);
      this.playerArmor -= armorAbsorbed;
      damage -= armorAbsorbed;
    }

    if (damage > 0) {
      this.playerHealth -= damage;
    }

    this.updateHealthText();
    this.updateArmorText();

    if (this.playerHealth <= 0) {
      this.playerHealth = 0;
      this.updateHealthText();
      this.showGameOverOverlay();
    }
  }

  updateArmorText() {
    this.armorText.setText(
      `Armor: ${this.playerArmor} / ${this.playerMaxArmor}`
    );
    this.upgradeText.setText(`Upgrade: ${this.playerUpgrade} / 100`);
  }

  updateGoldText() {
    this.goldText.setText(`Gold: ${this.playerGold} / 100`);
  }

  checkGold() {
    if (this.playerGold >= 100) {
      console.log("The player reached 100 gold!");
      let leftover = this.playerGold - 100;
      this.playerGold = 0;
      this.playerGold += leftover;
    }
  }

  removeCircleFromBoard(circle) {
    const row = circle.getData("row");
    const col = circle.getData("col");

    this.board[row][col] = null;

    if (circle.statsTexts) {
      circle.statsTexts.damageText.destroy();
      circle.statsTexts.armorText.destroy();
      circle.statsTexts.healthText.destroy();
    }

    circle.destroy();
  }

  endOfPlayerTurn() {
    let anyAttacksHappened = false;

    for (let row = 0; row < this.gridSize; row++) {
      for (let col = 0; col < this.gridSize; col++) {
        const circle = this.board[row][col];
        if (!circle) continue;

        if (circle.getData("color") === 0xffffff) {
          let turns = circle.getData("turnsInPlay");
          turns++;
          circle.setData("turnsInPlay", turns);

          if (turns >= 1) {
            const damage = circle.getData("damage") || 1;
            this.applyDamageToPlayer(damage);
            anyAttacksHappened = true;
            this.animateEnemyAttack(circle);
          }
        }
      }
    }

    if (anyAttacksHappened) {
      this.animateDamageFlash();
    }
  }

  showSkipConfirmation(onConfirm) {
    if (this.skipConfirmationContainer) {
      this.skipConfirmationContainer.destroy();
      this.skipConfirmationContainer = null;
    }

    const width = this.cameras.main.width;
    const height = this.cameras.main.height;
    this.skipConfirmationContainer = this.add.container(0, 0).setDepth(1000000);

    const bgRect = this.add
      .rectangle(width / 2, height / 2, width, height, 0x000000, 0.7)
      .setOrigin(0.5);

    const promptText = this.add
      .text(
        width / 2,
        height / 2 - 40,
        "Are you sure you want to skip adding stats?",
        {
          fontSize: "22px",
          color: "#ffffff",
          align: "center",
        }
      )
      .setOrigin(0.5);

    const yesButton = this.add
      .text(width / 2 - 60, height / 2 + 20, "[ Yes ]", {
        fontSize: "20px",
        color: "#ff4d4d",
        backgroundColor: "#333333",
        padding: { x: 8, y: 5 },
      })
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true });

    const noButton = this.add
      .text(width / 2 + 60, height / 2 + 20, "[ No ]", {
        fontSize: "20px",
        color: "#ffffff",
        backgroundColor: "#333333",
        padding: { x: 8, y: 5 },
      })
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true });

    yesButton.on("pointerdown", () => {
      if (onConfirm) onConfirm();
      if (this.skipConfirmationContainer) {
        this.skipConfirmationContainer.destroy();
        this.skipConfirmationContainer = null;
      }
    });

    noButton.on("pointerdown", () => {
      if (this.skipConfirmationContainer) {
        this.skipConfirmationContainer.destroy();
        this.skipConfirmationContainer = null;
      }
    });

    this.skipConfirmationContainer.add([
      bgRect,
      promptText,
      yesButton,
      noButton,
    ]);
  }

  showCharacterOverlay() {
    if (this.characterOverlayContainer) {
      this.characterOverlayContainer.destroy();
      this.characterOverlayContainer = null;
    }

    const width = this.cameras.main.width;
    const height = this.cameras.main.height;

    this.characterOverlayContainer = this.add.container(0, 0).setDepth(200000);

    const backgroundRect = this.add.rectangle(
      width / 2,
      height / 2,
      width,
      height,
      0x000000,
      0.7
    );

    const titleText = this.add
      .text(width / 2, height / 2 - 250, "Player Stats", {
        fontSize: "28px",
        color: "#ffffff",
      })
      .setOrigin(0.5);

    let statsTextStr = `
  Level: ${this.playerLevel}
  XP: ${this.playerXP}
  Health: ${this.playerHealth} / ${this.playerMaxHealth}
  Armor: ${this.playerArmor} / ${this.playerMaxArmor}
  Gold: ${this.playerGold}
  Upgrade: ${this.playerUpgrade}
  Strength: ${this.playerStrength}
  Dexterity: ${this.playerDexterity}
  Wisdom: ${this.playerWisdom}
  Vitality: ${this.playerVitality}
  Luck: ${this.playerLuck}
  Weapon: ${this.playerWeapon ? this.playerWeapon : "None"}
  Armor Slot: ${this.playerArmorSlot ? this.playerArmorSlot : "None"}
  Accessory: ${this.playerAccessory ? this.playerAccessory : "None"}
  `;
    const statsText = this.add
      .text(width / 2, height / 2 - 50, statsTextStr, {
        fontSize: "20px",
        color: "#ffffff",
      })
      .setOrigin(0.5);

    const closeButton = this.add
      .text(width / 2, height / 2 + 120, "[ Close ]", {
        fontSize: "24px",
        color: "#ffffff",
        backgroundColor: "#333333",
        padding: { x: 10, y: 5 },
      })
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true });

    closeButton.on("pointerdown", () => {
      if (this.characterOverlayContainer) {
        this.characterOverlayContainer.destroy();
        this.characterOverlayContainer = null;
      }
    });

    this.characterOverlayContainer.add([
      backgroundRect,
      titleText,
      statsText,
      closeButton,
    ]);
  }

  showSettingsOverlay() {
    if (this.settingsOverlayContainer) {
      this.settingsOverlayContainer.destroy();
      this.settingsOverlayContainer = null;
    }

    const width = this.cameras.main.width;
    const height = this.cameras.main.height;

    this.settingsOverlayContainer = this.add.container(0, 0).setDepth(200000);

    const bgRect = this.add.rectangle(
      width / 2,
      height / 2,
      width,
      height,
      0x000000,
      0.7
    );

    const titleText = this.add
      .text(width / 2, height / 2 - 80, "Settings", {
        fontSize: "28px",
        color: "#ffffff",
      })
      .setOrigin(0.5);

    const backButton = this.add
      .text(width / 2, height / 2 - 20, "[ Back to Game ]", {
        fontSize: "24px",
        color: "#ffffff",
        backgroundColor: "#333333",
        padding: { x: 10, y: 5 },
      })
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true });

    backButton.on("pointerdown", () => {
      if (this.settingsOverlayContainer) {
        this.settingsOverlayContainer.destroy();
        this.settingsOverlayContainer = null;
      }
    });

    const exitButton = this.add
      .text(width / 2, height / 2 + 40, "[ Exit Game ]", {
        fontSize: "24px",
        color: "#ff4d4d",
        backgroundColor: "#333333",
        padding: { x: 10, y: 5 },
      })
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true });

    exitButton.on("pointerdown", () => {
      this.showExitConfirmation();
    });

    this.settingsOverlayContainer.add([
      bgRect,
      titleText,
      backButton,
      exitButton,
    ]);
  }

  showExitConfirmation() {
    if (this.confirmOverlayContainer) {
      this.confirmOverlayContainer.destroy();
      this.confirmOverlayContainer = null;
    }

    const width = this.cameras.main.width;
    const height = this.cameras.main.height;

    this.confirmOverlayContainer = this.add.container(0, 0).setDepth(300000);

    const bgRect = this.add.rectangle(
      width / 2,
      height / 2,
      width,
      height,
      0x000000,
      0.7
    );

    const confirmText = this.add
      .text(
        width / 2,
        height / 2 - 40,
        "Are you sure you want to leave\nthe game and lose all your progress?",
        {
          fontSize: "22px",
          color: "#ffffff",
          align: "center",
        }
      )
      .setOrigin(0.5);

    const yesButton = this.add
      .text(width / 2 - 60, height / 2 + 20, "[ Yes ]", {
        fontSize: "20px",
        color: "#ff4d4d",
        backgroundColor: "#333333",
        padding: { x: 8, y: 5 },
      })
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true });

    const noButton = this.add
      .text(width / 2 + 60, height / 2 + 20, "[ No ]", {
        fontSize: "20px",
        color: "#ffffff",
        backgroundColor: "#333333",
        padding: { x: 8, y: 5 },
      })
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true });

    yesButton.on("pointerdown", () => {
      window.location.hash = "/";
    });

    noButton.on("pointerdown", () => {
      if (this.confirmOverlayContainer) {
        this.confirmOverlayContainer.destroy();
        this.confirmOverlayContainer = null;
      }
    });

    this.confirmOverlayContainer.add([
      bgRect,
      confirmText,
      yesButton,
      noButton,
    ]);
  }

  showGameOverOverlay() {
    const width = this.cameras.main.width;
    const height = this.cameras.main.height;

    this.gameOverOverlay = this.add
      .rectangle(width / 2, height / 2, width, height, 0x000000, 0.7)
      .setDepth(100000);

    this.gameOverText = this.add
      .text(width / 2, height / 2 - 40, "GAME OVER", {
        fontSize: "32px",
        color: "#ffffff",
      })
      .setOrigin(0.5)
      .setDepth(100001);

    this.restartButton = this.add
      .text(width / 2, height / 2 + 20, "[ Restart ]", {
        fontSize: "24px",
        color: "#ffffff",
        backgroundColor: "#333333",
        padding: { x: 10, y: 5 },
      })
      .setOrigin(0.5)
      .setDepth(100001)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.restartGame();
      });
  }

  restartGame() {
    if (this.gameOverOverlay) this.gameOverOverlay.destroy();
    if (this.restartButton) this.restartButton.destroy();
    if (this.gameOverText) this.gameOverText.destroy();
    this.gameOverOverlay = null;
    this.restartButton = null;

    this.initializePlayer();

    this.updateHealthText();
    this.levelText.setText("Level: 1");
    this.xpText.setText("XP: 0 / 100");
    this.armorText.setText("Armor: 0 / 10");
    this.goldText.setText("Gold: 0 / 100");
    this.weaponText.setText("Weapon: None");
    this.armorSlotText.setText("Armor: None");
    this.accessoryText.setText("Accessory: None");

    this.clearBoard();

    this.generateNewBoard(false);
  }

  getRandomColor(allowWhite = true) {
    const WHITE = 0xffffff;
    const rand = Math.random();

    if (allowWhite && rand < 0.1) {
      return WHITE;
    } else {
      return Phaser.Utils.Array.GetRandom(this.colors);
    }
  }

  canMatchColors(colorA, colorB) {
    const WHITE = 0xffffff;
    const GRAY = 0x808080;

    if (colorA === colorB) {
      return true;
    }
    if (
      (colorA === WHITE && colorB === GRAY) ||
      (colorA === GRAY && colorB === WHITE)
    ) {
      return true;
    }
    return false;
  }

  clearBoard() {
    for (let row = 0; row < this.gridSize; row++) {
      for (let col = 0; col < this.gridSize; col++) {
        const circle = this.board[row][col];
        if (circle) {
          if (circle.statsTexts) {
            circle.statsTexts.damageText.destroy();
            circle.statsTexts.armorText.destroy();
            circle.statsTexts.healthText.destroy();
          }
          circle.destroy();
        }
        this.board[row][col] = null;
      }
    }
  }

  generateNewBoard(allowWhite = true) {
    for (let row = 0; row < this.gridSize; row++) {
      this.board[row] = [];

      for (let col = 0; col < this.gridSize; col++) {
        const color = this.getRandomColor(allowWhite);
        const circle = this.createCircle(row, col, color);
        this.board[row][col] = circle;
      }
    }
  }

  getCircleUnderPointer(x, y) {
    for (let row = 0; row < this.gridSize; row++) {
      for (let col = 0; col < this.gridSize; col++) {
        const circle = this.board[row][col];
        if (circle) {
          const dx = circle.x - x;
          const dy = circle.y - y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < this.cellSize / 2 - 4) {
            return circle;
          }
        }
      }
    }
    return null;
  }

  areAdjacent(circleA, circleB) {
    const rowA = circleA.getData("row");
    const colA = circleA.getData("col");
    const rowB = circleB.getData("row");
    const colB = circleB.getData("col");

    return Math.abs(rowA - rowB) <= 1 && Math.abs(colA - colB) <= 1;
  }

  redrawLine() {
    this.lineGraphics.clear();

    if (this.selectedCircles.length > 1) {
      this.lineGraphics.lineStyle(10, 0x00ff00);
      this.lineGraphics.beginPath();

      const firstCircle = this.selectedCircles[0];
      this.lineGraphics.moveTo(firstCircle.x, firstCircle.y);

      for (let i = 1; i < this.selectedCircles.length; i++) {
        this.lineGraphics.lineTo(
          this.selectedCircles[i].x,
          this.selectedCircles[i].y
        );
      }

      this.lineGraphics.strokePath();
    }
  }

  collapseBoard() {
    for (let col = 0; col < this.gridSize; col++) {
      const emptyRows = [];
      for (let row = this.gridSize - 1; row >= 0; row--) {
        if (this.board[row][col] === null) {
          emptyRows.push(row);
        } else if (emptyRows.length > 0) {
          const newRow = emptyRows.shift();
          this.board[newRow][col] = this.board[row][col];
          this.board[row][col] = null;

          const circle = this.board[newRow][col];
          circle.setData("row", newRow);

          this.tweens.add({
            targets: circle,
            y: newRow * this.cellSize + this.cellSize / 2 + this.boardOffsetY,
            duration: 200,
            ease: "Bounce.easeOut",
            onUpdate: () => {
              if (circle.statsTexts) {
                const offsetX = this.cellSize / 2 - 14;

                circle.statsTexts.damageText.setPosition(offsetX, -20);
                circle.statsTexts.armorText.setPosition(offsetX, 0);
                circle.statsTexts.healthText.setPosition(offsetX, 20);
              }
            },
          });

          emptyRows.push(row);
          emptyRows.sort((a, b) => b - a);
        }
      }
    }

    for (let row = 0; row < this.gridSize; row++) {
      for (let col = 0; col < this.gridSize; col++) {
        if (this.board[row][col] === null) {
          const color = this.getRandomColor(true);
          const circle = this.createCircle(row, col, color);
          this.board[row][col] = circle;
        }
      }
    }
  }

  animateCircleRemoval(circlesToRemove) {
    let completed = 0;
    const total = circlesToRemove.length;

    circlesToRemove.forEach(circle => {
      const row = circle.getData("row");
      const col = circle.getData("col");

      if (row == null || col == null) {
        completed++;
        return;
      }

      this.tweens.add({
        targets: circle,
        scale: 0,
        alpha: 0,
        duration: 200,
        ease: "Back.easeIn",
        onComplete: () => {
          this.board[row][col] = null;

          if (circle.statsTexts) {
            circle.statsTexts.damageText.destroy();
            circle.statsTexts.armorText.destroy();
            circle.statsTexts.healthText.destroy();
          }

          circle.destroy();
          completed++;

          if (completed === total) {
            this.collapseBoard();
          }
        },
      });
    });
  }

  showRedX(circle) {
    if (circle.redX) return;

    const xMark = this.add.text(circle.x, circle.y, "X", {
      fontSize: "75px",
      color: "#ff0000",
      fontStyle: "bold",
    });
    xMark.setOrigin(0.5);
    xMark.setDepth(99999);

    circle.redX = xMark;
  }

  hideRedX(circle) {
    if (circle.redX) {
      circle.redX.destroy();
      circle.redX = null;
    }
  }

  clearAllRedX() {
    for (let row = 0; row < this.gridSize; row++) {
      for (let col = 0; col < this.gridSize; col++) {
        const circle = this.board[row][col];
        if (circle && circle.redX) {
          this.hideRedX(circle);
        }
      }
    }
  }

  animateEnemyAttack(enemyCircle) {
    const fleck = this.add.circle(enemyCircle.x, enemyCircle.y, 5, 0xff0000);
    fleck.setDepth(999999);

    this.tweens.add({
      targets: fleck,
      y: this.bottomUIBg.y,
      duration: 500,
      onComplete: () => {
        fleck.destroy();
      },
    });
  }

  animateDamageFlash() {
    this.tweens.addCounter({
      from: 0,
      to: 1,
      duration: 1000,
      yoyo: true,
      onUpdate: tween => {
        const value = tween.getValue();
        const colorObj = Phaser.Display.Color.Interpolate.ColorWithColor(
          { r: 0, g: 0, b: 0 },
          { r: 255, g: 0, b: 0 },
          100,
          value * 100
        );
        const finalColor = Phaser.Display.Color.GetColor(
          colorObj.r,
          colorObj.g,
          colorObj.b
        );
        this.bottomUIBg.setFillStyle(finalColor);
      },
      onComplete: () => {
        this.bottomUIBg.setFillStyle(0x2c2c2c);
      },
    });
  }

  preload() {
    this.load.image("shield_icon", SHIELD_IMAGE);
    this.load.image("health_icon", HEALTH_IMAGE);
    this.load.image("attack_icon", ATTACK_IMAGE);
    this.load.image("coin_icon", COIN_IMAGE);
    this.load.image("monster_icon", MONSTER_IMAGE);

    this.load.image("character_icon", CHARACTER_IMAGE);
    this.load.image("settings_icon", SETTINGS_IMAGE);
  }
}
