import React, { useEffect, useState } from "react";
import "./PlayerBoard.css";
import TownMenu from "./TownMenu";
import ShopMenu from "./ShopMenu";
import InnMenu from "./InnMenu";
import AdventureMenu from "./AdventureMenu";
import BlacksmithMenu from "./BlacksmithMenu";
import AdventureLocationMenu from "./AdventureLocationMenu";

const PlayerState = {
  CLASS_SELECTION: "CLASS_SELECTION",
  SHOP: "SHOP",
  INN: "INN",
  TOWN: "TOWN",
  ADVENTURE: "ADVENTURE",
  ADVENTURE_LOCATION: "ADVENTURE_LOCATION",
  BLACKSMITH: "BLACKSMITH",
};

const PlayerBoard = ({ playerNumber, playerActions }) => {
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const [playerState, setPlayerState] = useState(PlayerState.CLASS_SELECTION);

  const classes = ["Brute", "Pickpocket", "Apprentice"];
  const townOptions = [
    ["Adventure", "Shop"],
    ["Inn", "Blacksmith"],
  ];
  const blacksmithOptions = ["Forge Weapon", "Forge Armor", "Leave Blacksmith"];

  const adventureOptions = [
    ["Forest", "Cave"],
    ["Mountain", "Desert"],
  ];

  const adventureLocationOptions = {
    Forest: ["Explore", "Fight Monster", "Return"],
    Cave: ["Mine Ore", "Find Treasure", "Return"],
    Mountain: ["Climb Peak", "Search for Herbs", "Return"],
    Desert: ["Cross Sandstorm", "Discover Oasis", "Return"],
  };

  const shopItems = [
    "Sample Item",
    "Sample Item 2",
    "Sample Item 3",
    "Sample Item 4",
    "Sample Item 5",
    "Sample Item 6",
  ];

  const leaveShopIndex = shopItems.length;
  const numRows = townOptions.length;
  const numCols = townOptions[0].length;
  const shopRows = 2;
  const shopCols = 3;

  useEffect(() => {
    switch (playerActions) {
      case "W":
      case "Y":
      case "P":
      case "ArrowUp":
        moveSelection(-1, "vertical");
        break;
      case "A":
      case "G":
      case "L":
      case "ArrowLeft":
        moveSelection(-1, "horizontal");
        break;
      case "S":
      case "H":
      case ";":
      case "ArrowDown":
        moveSelection(1, "vertical");
        break;
      case "D":
      case "J":
      case "'":
      case "ArrowRight":
        moveSelection(1, "horizontal");
        break;
      case "Q":
      case "T":
      case "O":
      case "PageUp":
        selectCurrentOption();
        break;
    }
  }, [playerActions]);

  const moveSelection = (direction, axis) => {
    let newIndex = selectedButtonIndex;

    switch (playerState) {
      case PlayerState.CLASS_SELECTION:
        newIndex += direction;
        newIndex = Math.max(0, Math.min(newIndex, classes.length - 1));
        break;

      case PlayerState.SHOP:
        const currentRow = Math.floor(selectedButtonIndex / shopCols);
        const currentCol = selectedButtonIndex % shopCols;

        if (axis === "horizontal") {
          const newCol = currentCol + direction;
          if (newCol >= 0 && newCol < shopCols) {
            newIndex = currentRow * shopCols + newCol;
          }
        } else if (axis === "vertical") {
          const newRow = currentRow + direction;
          if (newRow >= 0 && newRow < shopRows) {
            newIndex = newRow * shopCols + currentCol;
          } else if (
            newRow === shopRows &&
            currentCol >= 0 &&
            currentCol < shopCols
          ) {
            newIndex = leaveShopIndex;
          }
        }

        newIndex = Math.max(0, Math.min(newIndex, leaveShopIndex));
        break;

      case PlayerState.INN:
        newIndex += direction;
        newIndex = Math.max(0, Math.min(newIndex, 2));
        break;

      case PlayerState.TOWN:
        const townRow = Math.floor(selectedButtonIndex / numCols);
        const townCol = selectedButtonIndex % numCols;

        if (axis === "horizontal") {
          const newCol = townCol + direction;
          if (newCol >= 0 && newCol < numCols) {
            newIndex = townRow * numCols + newCol;
          }
        } else if (axis === "vertical") {
          const newRow = townRow + direction;
          if (newRow >= 0 && newRow < numRows) {
            newIndex = newRow * numCols + townCol;
          }
        }
        break;

      case PlayerState.ADVENTURE:
        const rows = 2;
        const cols = 2;
        const totalOptions = rows * cols + 1; // +1 for Return to Town

        const currentAdvRow = Math.floor(selectedButtonIndex / cols);
        const currentAdvCol = selectedButtonIndex % cols;

        if (axis === "horizontal") {
          const newCol = currentAdvCol + direction;
          if (newCol >= 0 && newCol < cols) {
            newIndex = currentAdvRow * cols + newCol;
          }
        } else if (axis === "vertical") {
          const newRow = currentAdvRow + direction;
          if (newRow >= 0 && newRow < rows) {
            newIndex = newRow * cols + currentAdvCol;
          } else if (
            newRow === rows &&
            currentAdvCol >= 0 &&
            currentAdvCol < cols
          ) {
            newIndex = totalOptions; // Move to Return to Town option
          }
        }

        break;

      case PlayerState.ADVENTURE_LOCATION:
        const currentLocationIndex = Math.floor(selectedButtonIndex / 3);
        const currentActionIndex = selectedButtonIndex % 3;

        if (axis === "horizontal") {
          const newActionIndex = currentActionIndex + direction;
          if (newActionIndex >= 0 && newActionIndex < 3) {
            newIndex = currentLocationIndex * 3 + newActionIndex;
          }
        } else if (axis === "vertical") {
          const newLocationIndex = currentLocationIndex + direction;
          if (
            newLocationIndex >= 0 &&
            newLocationIndex < adventureOptions.length
          ) {
            newIndex = newLocationIndex * 3 + currentActionIndex;
          }
        }

        break;

      case PlayerState.BLACKSMITH:
        newIndex += direction;
        newIndex = Math.max(
          0,
          Math.min(newIndex, blacksmithOptions.length - 1)
        );
        break;

      default:
        break;
    }

    setSelectedButtonIndex(newIndex);
  };

  const selectCurrentOption = () => {
    switch (playerState) {
      case PlayerState.CLASS_SELECTION:
        const selectedClass = classes[selectedButtonIndex];
        console.log(`Player ${playerNumber} selected class: ${selectedClass}`);
        setPlayerState(PlayerState.TOWN);
        setSelectedButtonIndex(0);
        break;

      case PlayerState.SHOP:
        if (selectedButtonIndex === leaveShopIndex) {
          console.log(`Player ${playerNumber} selected: Leave Shop`);
          setPlayerState(PlayerState.TOWN);
          setSelectedButtonIndex(0);
        } else {
          const selectedOption = shopItems[selectedButtonIndex];
          console.log(
            `Player ${playerNumber} selected shop option: ${selectedOption}`
          );
        }
        break;

      case PlayerState.INN:
        const innOptions = ["Rest", "Chat", "Leave Inn"];
        const selectedInnOption = innOptions[selectedButtonIndex];

        if (selectedInnOption === "Leave Inn") {
          setPlayerState(PlayerState.TOWN);
          setSelectedButtonIndex(0);
        }

        console.log(
          `Player ${playerNumber} selected inn option: ${selectedInnOption}`
        );
        break;

      case PlayerState.TOWN:
        const flatTownOptions = townOptions.flat();
        const selectedTownOption = flatTownOptions[selectedButtonIndex];

        if (selectedTownOption === "Shop") {
          setPlayerState(PlayerState.SHOP);
          setSelectedButtonIndex(0);
        } else if (selectedTownOption === "Inn") {
          setPlayerState(PlayerState.INN);
          setSelectedButtonIndex(0);
        } else if (selectedTownOption === "Adventure") {
          setPlayerState("ADVENTURE");
          setSelectedButtonIndex(0);
        } else if (selectedTownOption === "Blacksmith") {
          setPlayerState(PlayerState.BLACKSMITH);
          setSelectedButtonIndex(0);
        }

        console.log(
          `Player ${playerNumber} selected town option: ${selectedTownOption}`
        );
        break;

      case PlayerState.ADVENTURE:
        if (selectedButtonIndex < adventureOptions.length * 2) {
          setPlayerState(PlayerState.ADVENTURE_LOCATION);
          console.log(`Player ${playerNumber} selected adventure location`);
        } else {
          setPlayerState(PlayerState.TOWN);
          setSelectedButtonIndex(0);
          console.log("Player returned to town");
        }
        break;

      case PlayerState.ADVENTURE_LOCATION:
        const currentLocationIndex = Math.floor(selectedButtonIndex / 3);
        const currentActionIndex = selectedButtonIndex % 3;

        const selectedLocation = Object.keys(adventureLocationOptions)[
          currentLocationIndex
        ];
        const selectedAction =
          adventureLocationOptions[selectedLocation][currentActionIndex];

        console.log(
          `Player ${playerNumber} selected action: ${selectedAction} in ${selectedLocation}`
        );

        if (selectedAction === "Return") {
          setPlayerState(PlayerState.ADVENTURE);
          setSelectedButtonIndex(0); // Reset to the first option in AdventureMenu
        } else {
          // Handle other actions here
          console.log(`Handling action: ${selectedAction}`);
        }
        break;

      case PlayerState.BLACKSMITH:
        const selectedBlacksmithOption = blacksmithOptions[selectedButtonIndex];

        if (selectedBlacksmithOption === "Leave Blacksmith") {
          setPlayerState(PlayerState.TOWN);
          setSelectedButtonIndex(0);
        }

        console.log(
          `Player ${playerNumber} selected blacksmith option: ${selectedBlacksmithOption}`
        );
        break;

      default:
        break;
    }
  };

  const handleMouseClick = index => {
    if (index === selectedButtonIndex) {
      selectCurrentOption();
    } else {
      setSelectedButtonIndex(index);
    }
  };

  return (
    <div className="couch-player-board">
      <center>Player {playerNumber}</center>
      <div className="couch-player-menu">
        {playerState === PlayerState.CLASS_SELECTION ? (
          classes.map((option, index) => (
            <button
              key={index}
              onClick={() => handleMouseClick(index)}
              className={`couch-menu-button ${
                index === selectedButtonIndex ? "selected" : ""
              }`}>
              {option}
            </button>
          ))
        ) : playerState === PlayerState.SHOP ? (
          <ShopMenu
            shopItems={shopItems}
            selectedButtonIndex={selectedButtonIndex}
            onSelect={index => handleMouseClick(index)}
          />
        ) : playerState === PlayerState.BLACKSMITH ? (
          <BlacksmithMenu
            selectedButtonIndex={selectedButtonIndex}
            onSelect={index => handleMouseClick(index)}
          />
        ) : playerState === PlayerState.INN ? (
          <InnMenu
            selectedButtonIndex={selectedButtonIndex}
            onSelect={index => handleMouseClick(index)}
          />
        ) : playerState === PlayerState.ADVENTURE ? (
          <AdventureMenu
            adventureOptions={adventureOptions}
            selectedButtonIndex={selectedButtonIndex}
            onSelect={handleMouseClick}
            setPlayerState={setPlayerState}
          />
        ) : playerState === PlayerState.ADVENTURE_LOCATION ? (
          <AdventureLocationMenu
            selectedLocation={
              adventureOptions[Math.floor(selectedButtonIndex / 3)][
                selectedButtonIndex % 3
              ]
            }
            selectedButtonIndex={selectedButtonIndex}
            onSelect={handleMouseClick}
            setPlayerState={setPlayerState}
          />
        ) : (
          <TownMenu
            townOptions={townOptions}
            selectedButtonIndex={selectedButtonIndex}
            onSelect={index => handleMouseClick(index)}
          />
        )}
      </div>
    </div>
  );
};

export default PlayerBoard;
