import React, { useState, useEffect } from "react";

const LocalRPGMain = () => {
  const [timer, setTimer] = useState({ minutes: 0, seconds: 0 });
  const [counter, setCounter] = useState(0);

  const monsters = {
    "Monster A": {
      hp: 3,
      xpReward: 1,
      goldReward: 5,
      attackDamage: 1,
    },
    "Monster B": {
      hp: 5,
      xpReward: 2,
      goldReward: 10,
      attackDamage: 2,
    },
    "Monster C": {
      hp: 10,
      xpReward: 3,
      goldReward: 15,
      attackDamage: 3,
    },
  };

  const initialPlayerState = {
    class: null,
    selection: [0, 0],
    pressed: [null, null],
    chosenClass: null,
    gold: 0,
    level: 1,
    experience: 5,
    maxHealth: 10,
    currentHealth: 10,
    maxMana: 1,
    currentMana: 1,
    studyTime: 10,
    baseAttack: 1,
    currentAttack: 1,
    weaponLevel: 1,
    weaponMultiplier: 1.0,
    weaponUpgradeCost: 10,
    miningLevel: 0,
    improveMiningCost: 25,
    upgradeStatus: false,
    fullHealCooldown: false,
    currentMonster: "Monster A",
    monsterAHP: monsters["Monster A"].hp,
    monsterBHP: monsters["Monster B"].hp,
    monsterCHP: monsters["Monster C"].hp,
    isDead: false,
    diedAt: null,
  };

  const [gameState, setGameState] = useState({
    players: [{ ...initialPlayerState }, { ...initialPlayerState }],
  });

  const formatTimerString = ({ minutes, seconds }) =>
    `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;

  /**
   * 10ms tick COUNTER
   * */

  useEffect(() => {
    const counterInterval = setInterval(() => {
      setCounter(prevCounter => (prevCounter + 1) % 101);
    }, 10);

    return () => clearInterval(counterInterval);
  }, []);

  /**
   * TIMER
   */
  useEffect(() => {
    let intervalId;

    const updateTimer = () => {
      setTimer(prevTime => {
        const newSeconds = prevTime.seconds + 1;
        const newMinutes = prevTime.minutes + Math.floor(newSeconds / 60);

        return {
          minutes: newMinutes,
          seconds: newSeconds % 60,
        };
      });
    };

    intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, []);

  /**
   * GOLD INTERVAL
   */

  useEffect(() => {
    const goldIntervals = gameState.players.map((player, index) => {
      let interval = null;

      if (player.pressed[0] === 1 && player.pressed[1] === 0) {
        interval = setInterval(() => {
          setGameState(prevState => {
            const updatedPlayers = [...prevState.players];
            updatedPlayers[index] = {
              ...updatedPlayers[index],
              gold: updatedPlayers[index].gold + 1 + player.miningLevel,
            };

            return {
              ...prevState,
              players: updatedPlayers,
            };
          });
        }, 1000);
      }

      return interval;
    });

    return () => {
      goldIntervals.forEach(interval => {
        if (interval) {
          clearInterval(interval);
        }
      });
    };
  }, [gameState.players]);

  /**
   * REST INTERVAL
   * */

  useEffect(() => {
    const restIntervals = gameState.players.map((player, index) => {
      let interval = null;

      if (player.pressed[0] === 1 && player.pressed[1] === 2) {
        interval = setInterval(() => {
          setGameState(prevState => {
            const updatedPlayers = [...prevState.players];
            updatedPlayers[index] = {
              ...updatedPlayers[index],
              currentHealth: Math.min(
                updatedPlayers[index].currentHealth + 1,
                updatedPlayers[index].maxHealth
              ),
            };

            return {
              ...prevState,
              players: updatedPlayers,
            };
          });
        }, 1000);
      }

      return interval;
    });

    return () => {
      restIntervals.forEach(interval => {
        if (interval) {
          clearInterval(interval);
        }
      });
    };
  }, [gameState.players]);

  /**
   * STUDY INTERVAL
   */

  useEffect(() => {
    const studyIntervals = gameState.players.map((player, index) => {
      let interval = null;

      if (player.pressed[0] === 4 && player.pressed[1] === 2) {
        interval = setInterval(() => {
          setGameState(prevState => {
            const updatedPlayers = [...prevState.players];
            const newStudyTime = Math.max(
              updatedPlayers[index].studyTime - 1,
              0
            );

            updatedPlayers[index] = {
              ...updatedPlayers[index],
              studyTime: newStudyTime,
            };

            if (newStudyTime === 0) {
              updatedPlayers[index] = {
                ...updatedPlayers[index],
                maxMana: updatedPlayers[index].maxMana + 1,
                currentMana: updatedPlayers[index].currentMana + 1,
                studyTime: 10,
              };
            }

            return {
              ...prevState,
              players: updatedPlayers,
            };
          });
        }, 1000);
      }

      return interval;
    });

    return () => {
      studyIntervals.forEach(interval => {
        if (interval) {
          clearInterval(interval);
        }
      });
    };
  }, [gameState.players]);

  /**
   * FIGHT INTERVAL
   */

  useEffect(() => {
    const fightIntervals = gameState.players.map((player, index) => {
      let interval = null;

      if (player.pressed[0] === 0 && !player.isDead) {
        interval = setInterval(() => {
          setGameState(prevState => {
            const updatedPlayers = [...prevState.players];
            let monsterHP;
            let rewardGold;
            let rewardXP;
            let monsterAttackDamage;

            if (player.pressed[1] === 0) {
              // Fighting Monster A
              monsterHP = Math.max(
                updatedPlayers[index].monsterAHP -
                  updatedPlayers[index].currentAttack *
                    (updatedPlayers[index].currentMana + 1),
                0
              );
              rewardGold = monsters["Monster A"].goldReward;
              rewardXP = monsters["Monster A"].xpReward;
              monsterAttackDamage = monsters["Monster A"].attackDamage;
            } else if (player.pressed[1] === 1) {
              // Fighting Monster B
              monsterHP = Math.max(
                updatedPlayers[index].monsterBHP -
                  updatedPlayers[index].currentAttack *
                    (updatedPlayers[index].currentMana + 1),
                0
              );
              rewardGold = monsters["Monster B"].goldReward;
              rewardXP = monsters["Monster B"].xpReward;
              monsterAttackDamage = monsters["Monster B"].attackDamage;
            } else if (player.pressed[1] === 2) {
              // Fighting Monster C
              monsterHP = Math.max(
                updatedPlayers[index].monsterCHP -
                  updatedPlayers[index].currentAttack *
                    (updatedPlayers[index].currentMana + 1),
                0
              );
              rewardGold = monsters["Monster C"].goldReward;
              rewardXP = monsters["Monster C"].xpReward;
              monsterAttackDamage = monsters["Monster C"].attackDamage;
            }

            let updatedPlayer = { ...updatedPlayers[index] };

            updatedPlayer.currentMana = Math.max(
              updatedPlayer.currentMana - updatedPlayer.currentMana,
              0
            );

            const scaledMonsterDamage = Math.floor(
              monsterAttackDamage * updatedPlayer.level
            );
            updatedPlayer.currentHealth = Math.max(
              updatedPlayer.currentHealth - scaledMonsterDamage,
              0
            );

            if (updatedPlayer.currentHealth <= 0 && !updatedPlayer.isDead) {
              updatedPlayer = {
                ...updatedPlayer,
                isDead: true,
                diedAt: Date.now(),
                pressed: [null, null],
              };
            }

            if (player.pressed[1] === 0) {
              updatedPlayer.monsterAHP = monsterHP;
            } else if (player.pressed[1] === 1) {
              updatedPlayer.monsterBHP = monsterHP;
            } else if (player.pressed[1] === 2) {
              updatedPlayer.monsterCHP = monsterHP;
            }

            if (monsterHP <= 0) {
              updatedPlayer = {
                ...updatedPlayer,
                experience: updatedPlayer.experience - rewardXP,
                gold: updatedPlayer.gold + rewardGold,
                pressed: [null, null],

                ...(player.pressed[1] === 0
                  ? {
                      monsterAHP:
                        monsters["Monster A"].hp * updatedPlayer.level,
                    }
                  : player.pressed[1] === 1
                  ? {
                      monsterBHP:
                        monsters["Monster B"].hp * updatedPlayer.level,
                    }
                  : {
                      monsterCHP:
                        monsters["Monster C"].hp * updatedPlayer.level,
                    }),
              };
            }

            updatedPlayers[index] = updatedPlayer;

            return levelUpPlayer(index, {
              ...prevState,
              players: updatedPlayers,
            });
          });
        }, 1000);
      }

      return interval;
    });

    return () => {
      fightIntervals.forEach(interval => {
        if (interval) {
          clearInterval(interval);
        }
      });
    };
  }, [gameState.players]);

  /**
   * RESURRECTION INTERVAL
   */

  useEffect(() => {
    const resurrectionIntervals = gameState.players.map((player, index) => {
      let interval = null;

      if (player.isDead) {
        interval = setInterval(() => {
          const timeSinceDeath = Date.now() - player.diedAt;
          if (timeSinceDeath >= 10000) {
            setGameState(prevState => {
              const updatedPlayers = [...prevState.players];
              updatedPlayers[index] = {
                ...updatedPlayers[index],
                isDead: false,
                diedAt: null,
                currentHealth: updatedPlayers[index].maxHealth,
                selection: [1, 2],
                pressed: [1, 2],
              };

              return {
                ...prevState,
                players: updatedPlayers,
              };
            });
          }
        }, 100);
      }

      return interval;
    });

    return () => {
      resurrectionIntervals.forEach(interval => {
        if (interval) {
          clearInterval(interval);
        }
      });
    };
  }, [gameState.players]);

  const applyClassStats = (player, className) => {
    switch (className) {
      case "Warrior":
        return {
          maxHealth: 25,
          currentHealth: 25,
          baseAttack: 2,
          currentAttack: 2,
        };
      case "Ranger":
        return {
          maxHealth: 15,
          currentHealth: 15,
          baseAttack: 1,
          currentAttack: 1,
          gold: 5,
        };
      case "Wizard":
        return {
          maxHealth: 10,
          currentHealth: 10,
          baseAttack: 1,
          currentAttack: 1,
          maxMana: 3,
          currentMana: 3,
        };
      default:
        return {};
    }
  };

  const levelUpPlayer = (playerIndex, prevState) => {
    const playerState = prevState.players[playerIndex];

    if (playerState.experience <= 0) {
      const newLevel = playerState.level + 1;
      const newExperience = newLevel * 10;
      const newMaxHealth = playerState.maxHealth + 10;
      const newBaseAttack = playerState.baseAttack + 1;
      const newCurrentAttack = Math.ceil(
        newBaseAttack * playerState.weaponMultiplier
      );

      return {
        ...prevState,
        players: prevState.players.map((player, index) =>
          index === playerIndex
            ? {
                ...player,
                level: newLevel,
                experience: newExperience,
                maxHealth: newMaxHealth,
                currentHealth: newMaxHealth,
                maxMana: player.maxMana + 1,
                currentMana: player.maxMana + 1,
                baseAttack: newBaseAttack,
                currentAttack: newCurrentAttack,
              }
            : player
        ),
      };
    }

    return prevState;
  };

  const handleImproveMining = playerIndex => {
    setGameState(prevState => {
      const playerState = prevState.players[playerIndex];
      const currentGold = playerState.gold;
      const improveCost = playerState.improveMiningCost;

      if (currentGold >= improveCost) {
        return {
          ...prevState,
          players: prevState.players.map((player, index) =>
            index === playerIndex
              ? {
                  ...player,
                  gold: currentGold - improveCost,
                  miningLevel: player.miningLevel + 1,
                  improveMiningCost: improveCost * 10,
                  pressed: [null, null],
                }
              : player
          ),
        };
      }
      return prevState;
    });
  };

  /**
   * KEYBOARD CONTROLS
   */
  const player1Keys = {
    up: ["w", "W"],
    left: ["a", "A"],
    down: ["s", "S"],
    right: ["d", "D"],
    action: ["t", "T"],
  };

  const player2Keys = {
    up: ["ArrowUp"],
    left: ["ArrowLeft"],
    down: ["ArrowDown"],
    right: ["ArrowRight"],
    action: ["\\", "|"],
  };

  useEffect(() => {
    const handleKeyPress = event => {
      event.preventDefault();

      gameState.players.forEach((player, index) => {
        const playerKeys = index === 0 ? player1Keys : player2Keys;

        if (playerKeys.up.includes(event.key)) {
          if (player.class) {
            setGameState(prevState => {
              const updatedPlayers = [...prevState.players];
              updatedPlayers[index] = {
                ...player,
                selection: [
                  Math.max(0, player.selection[0] - 1),
                  player.selection[1],
                ],
              };
              return { ...prevState, players: updatedPlayers };
            });
          }
        } else if (playerKeys.left.includes(event.key)) {
          if (player.class) {
            setGameState(prevState => {
              const updatedPlayers = [...prevState.players];
              updatedPlayers[index] = {
                ...player,
                selection: [
                  player.selection[0],
                  Math.max(0, player.selection[1] - 1),
                ],
              };
              return { ...prevState, players: updatedPlayers };
            });
          } else {
            setGameState(prevState => {
              const updatedPlayers = [...prevState.players];
              updatedPlayers[index] = {
                ...player,
                selection: [0, Math.max(0, player.selection[1] - 1)],
              };
              return { ...prevState, players: updatedPlayers };
            });
          }
        } else if (playerKeys.down.includes(event.key)) {
          if (player.class) {
            setGameState(prevState => {
              const updatedPlayers = [...prevState.players];
              updatedPlayers[index] = {
                ...player,
                selection: [
                  Math.min(4, player.selection[0] + 1),
                  player.selection[1],
                ],
              };
              return { ...prevState, players: updatedPlayers };
            });
          }
        } else if (playerKeys.right.includes(event.key)) {
          if (player.class) {
            setGameState(prevState => {
              const updatedPlayers = [...prevState.players];
              updatedPlayers[index] = {
                ...player,
                selection: [
                  player.selection[0],
                  Math.min(2, player.selection[1] + 1),
                ],
              };
              return { ...prevState, players: updatedPlayers };
            });
          } else {
            setGameState(prevState => {
              const updatedPlayers = [...prevState.players];
              updatedPlayers[index] = {
                ...player,
                selection: [0, Math.min(2, player.selection[1] + 1)],
              };
              return { ...prevState, players: updatedPlayers };
            });
          }
        } else if (playerKeys.action.includes(event.key)) {
          if (!player.class) {
            const selectedClass = ["Warrior", "Ranger", "Wizard"][
              player.selection[1]
            ];
            setGameState(prevState => {
              const updatedPlayers = [...prevState.players];
              updatedPlayers[index] = {
                ...player,
                chosenClass: selectedClass,
                class: selectedClass,
                ...applyClassStats(player, selectedClass),
              };
              return { ...prevState, players: updatedPlayers };
            });
          } else {
            const [x, y] = player.selection;
            if (x === 4 && y === 0) {
              handleWeaponUpgrade(index);
            } else if (x === 2 && y === 2) {
              handleFullHeal(index);
            } else if (x === 2 && y === 0) {
              handleImproveMining(index);
            } else {
              setGameState(prevState => {
                const updatedPlayers = [...prevState.players];
                updatedPlayers[index] = {
                  ...player,
                  pressed: player.selection,
                };
                return { ...prevState, players: updatedPlayers };
              });
            }
          }
        }
      });
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [gameState.players]);

  const handleFullHeal = playerIndex => {
    setGameState(prevState => {
      const playerState = prevState.players[playerIndex];
      const healCost = playerState.level * 10;

      if (playerState.gold >= healCost && !playerState.fullHealCooldown) {
        const newState = {
          ...prevState,
          players: prevState.players.map((player, index) =>
            index === playerIndex
              ? {
                  ...player,
                  gold: player.gold - healCost,
                  currentHealth: player.maxHealth,
                  currentMana: player.maxMana,
                  pressed: [null, null],
                  fullHealCooldown: true,
                }
              : player
          ),
        };

        setTimeout(() => {
          setGameState(prevState => ({
            ...prevState,
            players: prevState.players.map((player, index) =>
              index === playerIndex
                ? { ...player, fullHealCooldown: false }
                : player
            ),
          }));
        }, 10000);

        return newState;
      }
      return prevState;
    });
  };

  const handleWeaponUpgrade = playerIndex => {
    setGameState(prevState => {
      const playerState = prevState.players[playerIndex];
      const currentGold = playerState.gold;
      const upgradeCost = playerState.weaponUpgradeCost;

      if (currentGold >= upgradeCost) {
        const newState = {
          ...prevState,
          players: prevState.players.map((player, index) =>
            index === playerIndex
              ? {
                  ...player,
                  gold: currentGold - upgradeCost,
                  weaponLevel: player.weaponLevel + 1,
                  weaponUpgradeCost: Math.ceil(upgradeCost * 10.0),
                  pressed: [null, null],
                  upgradeStatus: true,
                }
              : player
          ),
        };

        newState.players[playerIndex].weaponMultiplier = Math.min(
          playerState.weaponMultiplier + 0.5,
          20.0 // Max multiplier cap
        );
        newState.players[playerIndex].currentAttack = Math.ceil(
          playerState.baseAttack *
            newState.players[playerIndex].weaponMultiplier
        );

        setTimeout(() => {
          setGameState(prevState => ({
            ...prevState,
            players: prevState.players.map((player, index) =>
              index === playerIndex
                ? { ...player, upgradeStatus: false }
                : player
            ),
          }));
        }, 1000);

        return newState;
      }
      return prevState;
    });
  };

  const renderClassSelection = (selection, player) => {
    const classes = ["Warrior", "Ranger", "Wizard"];

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}>
        {classes.map((className, index) => (
          <button
            key={`${player}-class-${index}`}
            style={{
              margin: "0 5px",
              padding: "10px",
              backgroundColor: selection[1] === index ? "lightgrey" : "",
            }}>
            {className}
          </button>
        ))}
      </div>
    );
  };

  const renderButtonGrid = (selection, pressed, playerIndex) => {
    const buttons = [];
    for (let i = 0; i < 5; i++) {
      const row = [];
      for (let j = 0; j < 3; j++) {
        let buttonText;
        const player = gameState.players[playerIndex];

        if (i === 0 && j === 0) {
          buttonText = `Monster A (${player.monsterAHP})`;
        } else if (i === 0 && j === 1) {
          buttonText = `Monster B (${player.monsterBHP})`;
        } else if (i === 0 && j === 2) {
          buttonText = `Monster C (${player.monsterCHP})`;
        } else if (i === 1 && j === 0) {
          buttonText = `Gain Gold (+${player.miningLevel + 1}G/sec)`;
        } else if (i === 1 && j === 2) {
          buttonText = "Rest (+1HP/sec)";
        } else if (i === 2 && j === 2) {
          buttonText = `Full Heal (${player.level * 10} G)`;
        } else if (i === 4 && j === 0) {
          const weaponLevel = player.weaponLevel;
          const upgradeCost = player.weaponUpgradeCost;
          buttonText = `Upgrade Weapon to LV${
            weaponLevel + 1
          } (${upgradeCost})`;
        } else if (i === 4 && j === 2) {
          buttonText = "Study (+1 Max Mana/10sec)";
        } else if (i === 2 && j === 0) {
          buttonText = `Improve Mining to LV${player.miningLevel + 1} (${
            player.improveMiningCost
          })`;
        } else {
          buttonText = `Action ${i},${j}`;
        }

        row.push(
          <button
            key={`${playerIndex}-${i}-${j}`}
            style={{
              flex: 1,
              padding: "10px",
              backgroundColor: player.isDead
                ? "darkred"
                : i === 2 && j === 2 && player.fullHealCooldown
                ? "darkred"
                : pressed[0] === i && pressed[1] === j
                ? "orange"
                : selection[0] === i && selection[1] === j
                ? "lightgrey"
                : "",
              margin: "2px",
            }}
            onClick={() => {
              if (!player.isDead) {
                // Add your click logic here
              }
            }}
            disabled={player.isDead}>
            {buttonText}
          </button>
        );
      }

      buttons.push(
        <div
          key={`${playerIndex}-row-${i}`}
          style={{ display: "flex" }}>
          {row}
        </div>
      );
    }

    return buttons;
  };

  const getMonsterHP = player => {
    switch (player.currentMonster) {
      case "Monster A":
        return player.monsterAHP;
      case "Monster B":
        return player.monsterBHP;
      case "Monster C":
        return player.monsterCHP;
      default:
        throw new Error("Unknown monster");
    }
  };

  const renderHealthBar = (health, maxHealth) => {
    const healthPercentage = (health / maxHealth) * 100;
    const barStyle = {
      position: "relative",
      width: "100%",
      height: "20px",
      backgroundColor: "#e0e0e0",
      borderRadius: "5px",
      overflow: "hidden",
    };
    const fillStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      width: `${healthPercentage}%`,
      height: "100%",
      backgroundColor: health > maxHealth * 0.5 ? "#4CAF50" : "#FF9800",
      transition: "width 0.5s ease-in-out",
    };
    const textStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "black",
      fontWeight: "bold",
      fontSize: "12px",
    };

    return (
      <div style={barStyle}>
        <div style={fillStyle}></div>
        <span style={textStyle}>{`${Math.floor(health)} / ${maxHealth}`}</span>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}>
      <h1>Local RPG</h1>
      <div
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}>
        Time: {formatTimerString(timer)}
      </div>
      <div
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}>
        Counter: {counter}
      </div>
      <div
        style={{
          display: "flex",
          width: "600px",
          padding: "10px",
          backgroundColor: "#f0f0f0",
        }}></div>
      <div
        style={{
          display: "flex",
          width: "600px",
          padding: "10px",
          backgroundColor: "#f0f0f0",
        }}>
        {gameState.players.map((player, index) => (
          <div
            key={index}
            style={{
              flex: 1,
              marginRight: index === 0 ? "5px" : 0,
              marginLeft: index === 1 ? "15px" : 0,
              borderRight:
                index !== gameState.players.length - 1
                  ? "1px solid #ccc"
                  : "none",
              paddingRight: "15px",
            }}>
            {player.chosenClass ? (
              <>
                <div style={{ color: "black", marginBottom: "10px" }}>
                  <p style={{ margin: 0 }}>
                    {player.chosenClass} (LV{player.level})
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "0px",
                    }}>
                    <span>Health: </span>
                    {renderHealthBar(player.currentHealth, player.maxHealth)}
                  </div>
                  <p style={{ margin: 0 }}>
                    Mana: {player.currentMana} / {player.maxMana}
                  </p>
                  <p style={{ margin: 0 }}>Attack: {player.currentAttack}</p>
                  <p style={{ margin: 0 }}>
                    XP to Next Level: {player.experience}
                  </p>
                  <p style={{ margin: 0 }}>Gold: {player.gold}</p>
                </div>
                {renderButtonGrid(player.selection, player.pressed, index)}
              </>
            ) : (
              renderClassSelection(player.selection, index)
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LocalRPGMain;
