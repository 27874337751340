import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentView,
  setSelectedCharacter,
} from "../../redux/ElendiaActions";
import { VIEWS } from "./viewEnums";

const ContinueGame = () => {
  const dispatch = useDispatch();
  const characters = useSelector(state => state.elendia.characters);

  const handleContinue = character => {
    dispatch(setSelectedCharacter(character));
    dispatch(setCurrentView(VIEWS.BATTLE_MAIN));
  };

  const handleReturnToMainMenu = () => {
    dispatch(setCurrentView(VIEWS.MENU_MAIN));
  };

  return (
    <div>
      <h2>Continue Game</h2>
      <button onClick={handleReturnToMainMenu}>Return to Main Menu</button>
      <br />
      <br />
      {characters.map(character => (
        <React.Fragment key={character.id}>
          <button onClick={() => handleContinue(character)}>
            {character.name}
          </button>
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

export default ContinueGame;
