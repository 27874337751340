export function getWebSocketUrl() {
  let environment;

  if (window.location.href.includes("localhost")) {
    environment = "localhost";
  } else if (window.location.href.includes("192.168.0")) {
    environment = "localNetwork";
  } else if (window.location.href.includes("2nguyen.dev")) {
    environment = "production";
  }

  switch (environment) {
    case "localhost":
      return "ws://localhost:5000/cardsandmonsters";
    case "localNetwork":
      return "ws://192.168.0.7:5000/cardsandmonsters";
    case "production":
      return "wss://2nguyen.dev/cardsandmonsters";
    default:
      console.log("Environment not recognized. WebSocket not established.");
      return null;
  }
}
