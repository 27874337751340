import Phaser from "phaser";
import playerImage from "./assets/player.png";
import collectibleImage from "./assets/collectible.png";
import treeImage from "./assets/tree.png";

class CollisionTestScene extends Phaser.Scene {
  constructor() {
    super({ key: "CollisionTestScene" });
  }

  init() {
    this.playerId = null;
    this.x = null;
    this.y = null;

    this.collectible = null;

    this.trees = [];
  }

  preload() {
    this.load.image("player", playerImage);
    this.load.image("collectible", collectibleImage);
    this.load.image("tree", treeImage);
  }

  create() {
    this.playerId = this.generatePlayerId();
    this.x = this.randomizeXposition();
    this.y = this.randomizeYposition();

    this.player = this.add.sprite(this.x, this.y, "player");
    this.physics.add.existing(this.player);

    this.generateTrees();

    this.physics.add.collider(this.player, this.trees);

    this.input.on("pointerdown", pointer => {
      this.targetPosition = { x: pointer.x, y: pointer.y };
    });
  }

  update() {
    if (this.targetPosition) {
      // Calculate the direction to the target position
      const directionX = this.targetPosition.x - this.player.x;
      const directionY = this.targetPosition.y - this.player.y;
      const distance = Math.sqrt(
        directionX * directionX + directionY * directionY
      );

      // If the player is close enough to the target, stop moving
      if (distance < 5) {
        this.player.body.setVelocity(0); // Stop the player
        this.player.x = this.targetPosition.x;
        this.player.y = this.targetPosition.y;
        this.targetPosition = null; // Clear the target position
      } else {
        // Calculate velocity to move the player towards the target position
        const moveSpeed = 200; // Adjust the speed as needed
        const velocityX = (directionX / distance) * moveSpeed;
        const velocityY = (directionY / distance) * moveSpeed;

        this.player.body.setVelocityX(velocityX);
        this.player.body.setVelocityY(velocityY);
      }
    } else {
      // Optionally, stop the player if there's no target position
      this.player.body.setVelocity(0);
    }
  }

  generatePlayerId() {
    return Math.random().toString(36).substring(2, 15);
  }

  randomizeXposition() {
    return Math.floor(Math.random() * this.game.config.width);
  }

  randomizeYposition() {
    return Math.floor(Math.random() * this.game.config.height);
  }

  generateTrees() {
    this.trees = this.physics.add.group();

    for (let i = 0; i < 20; i++) {
      const x = this.randomizeXposition();
      const y = this.randomizeYposition();

      const tree = this.trees.create(x, y, "tree");
    }
  }
}

export default CollisionTestScene;
