import React, { useState, useEffect } from "react";
import PARTY_HAT from "./party-hat.svg";
import HORNED_HELM from "./horned-helm.svg";
import WHITE_BOOK from "./white-book.svg";
import STONE_PILE from "./stone-pile.svg";
import CAULDRON from "./cauldron.svg";
import MOON_BATS from "./moon-bats.svg";
import MINING from "./mining.svg";
import KEY from "./key.svg";
import WOOD_AXE from "./wood-axe.svg";
import MAILED_FIST from "./mailed-fist.svg";
import ANT from "./ant.svg";
import TARGET_DUMMY from "./target-dummy.svg";
import VAMPIRE_CAPE from "./vampire-cape.svg";
import SONIC_SHOES from "./sonic-shoes.svg";
import ARMORED_PANTS from "./armored-pants.svg";
import RATTLESNAKE from "./rattlesnake.svg";
import POURING_POT from "./pouring-pot.svg";
import FLUFFY_WING from "./fluffy-wing.svg";
import MINING_HELMET from "./mining-helmet.svg";
import MEDICINE_PILLS from "./medicine-pills.svg";
import MARBLES from "./marbles.svg";
import HEARTS from "./hearts.svg";
import SPARKLES from "./sparkles.svg";
import ROUND_STAR from "./round-star.svg";
import CASH from "./cash.svg";
import BICEPS from "./biceps.svg";
import FEATHER from "./feather.svg";
import POINTY_HAT from "./pointy-hat.svg";

const VersusGameMain = () => {
  const [focusedButton, setFocusedButton] = useState(4);
  const [selectedButtons, setSelectedButtons] = useState(
    new Array(9).fill(false)
  );
  const [shuffledItems, setShuffledItems] = useState([]);
  const [shuffledItemsWithState, setShuffledItemsWithState] = useState([]);

  const [player, setPlayer] = useState({
    maxHealth: 100,
    currentHealth: 100,
    maxMana: 100,
    currentMana: 100,
    baseStrength: 1,
    currentStrength: 1,
    baseDexterity: 1,
    currentDexterity: 1,
    baseWisdom: 1,
    currentWisdom: 1,
    gold: 0,
    counter: 0,
    damageCounter: 0,
    healthRegenCounter: 0,
    manaRegenCounter: 0,
  });

  const items = [
    {
      image: PARTY_HAT,
      name: "Party Hat",
      value: 3,
      stat: "strength",
      rarity: "common",
      damage: 0,
    },
    {
      image: HORNED_HELM,
      name: "Horned Helm",
      value: 3,
      stat: "wisdom",
      rarity: "common",
      damage: 0,
    },
    {
      image: WHITE_BOOK,
      name: "White Book",
      value: 3,
      stat: "dexterity",
      rarity: "common",
      damage: 0,
    },
    {
      image: STONE_PILE,
      name: "Stone Pile",
      value: 3,
      stat: "strength",
      rarity: "common",
      damage: 1,
    },
    {
      image: CAULDRON,
      name: "Cauldron",
      value: 3,
      stat: "wisdom",
      rarity: "common",
      damage: 0,
    },
    {
      image: MOON_BATS,
      name: "Moon Bats",
      value: 10,
      stat: "dexterity",
      rarity: "uncommon",
      damage: 5,
    },
    {
      image: MINING,
      name: "Mining Pick",
      value: 10,
      stat: "strength",
      rarity: "uncommon",
      damage: 0,
    },
    {
      image: KEY,
      name: "Key",
      value: 10,
      stat: "dexterity",
      rarity: "uncommon",
      damage: 0,
    },
    {
      image: WOOD_AXE,
      name: "Wood Axe",
      value: 3,
      stat: "strength",
      rarity: "common",
      damage: 0,
    },
    {
      image: MAILED_FIST,
      name: "Mailed Fist",
      value: 3,
      stat: "wisdom",
      rarity: "common",
      damage: 0,
    },
    {
      image: ANT,
      name: "Ant",
      value: 3,
      stat: "dexterity",
      rarity: "common",
      damage: 1,
    },
    {
      image: TARGET_DUMMY,
      name: "Target Dummy",
      value: 3,
      stat: "strength",
      rarity: "common",
      damage: 0,
    },
    {
      image: VAMPIRE_CAPE,
      name: "Vampire Cape",
      value: 25,
      stat: "wisdom",
      rarity: "rare",
      damage: 10,
    },
    {
      image: SONIC_SHOES,
      name: "Sonic Shoes",
      value: 10,
      stat: "dexterity",
      rarity: "uncommon",
      damage: 0,
    },
    {
      image: ARMORED_PANTS,
      name: "Armored Pants",
      value: 10,
      stat: "strength",
      rarity: "uncommon",
      damage: 0,
    },
    {
      image: RATTLESNAKE,
      name: "Rattlesnake",
      value: 3,
      stat: "dexterity",
      rarity: "common",
      damage: 2,
    },
    {
      image: POURING_POT,
      name: "Pouring Pot",
      value: 3,
      stat: "wisdom",
      rarity: "common",
      damage: 0,
    },
    {
      image: FLUFFY_WING,
      name: "Fluffy Wing",
      value: 25,
      stat: "dexterity",
      rarity: "rare",
      damage: 0,
    },
    {
      image: MINING_HELMET,
      name: "Mining Helmet",
      value: 10,
      stat: "strength",
      rarity: "uncommon",
      damage: 0,
    },
    {
      image: MEDICINE_PILLS,
      name: "Medicine Pills",
      value: 10,
      stat: "wisdom",
      rarity: "uncommon",
      damage: 0,
    },
    {
      image: MARBLES,
      name: "Marbles",
      value: 3,
      stat: "dexterity",
      rarity: "common",
      damage: 0,
    },
  ];

  useEffect(() => {
    let intervalId;
    let damageIntervalId;
    let healthRegenIntervalId;

    const tickPlayerCounter = () => {
      setPlayer(prevPlayer => {
        const newCounter = prevPlayer.counter + 1;
        const newDamageCounter = prevPlayer.damageCounter + 1;
        const newHealthRegenCounter = prevPlayer.healthRegenCounter + 1;

        if (newCounter >= 100) {
          const selectedIndex = selectedButtons.findIndex(button => button);

          if (selectedIndex !== -1) {
            updateItemCurrentValue(selectedIndex);
          }

          return {
            ...prevPlayer,
            counter: newCounter - 100,
            damageCounter: newDamageCounter,
            healthRegenCounter: newHealthRegenCounter,
          };
        }

        return {
          ...prevPlayer,
          counter: newCounter,
          damageCounter: newDamageCounter,
          healthRegenCounter: newHealthRegenCounter,
        };
      });
    };

    const tickDamageCounter = () => {
      setPlayer(prevPlayer => {
        const selectedIndex = selectedButtons.findIndex(button => button);

        if (selectedIndex !== -1) {
          const selectedItem = shuffledItemsWithState[selectedIndex];
          handlePlayerDamage(selectedItem.damage);
        }

        return { ...prevPlayer, damageCounter: 0 };
      });
    };

    const tickHealthRegen = () => {
      setPlayer(prevPlayer => {
        const newHealthRegenCounter = prevPlayer.healthRegenCounter + 1;

        if (newHealthRegenCounter >= 100) {
          return {
            ...prevPlayer,
            healthRegenCounter: 0,
            currentHealth: Math.min(
              prevPlayer.currentHealth + 1,
              prevPlayer.maxHealth
            ),
          };
        }

        return {
          ...prevPlayer,
          healthRegenCounter: newHealthRegenCounter,
        };
      });
    };

    const isAnyButtonSelected = selectedButtons.some(button => button);

    intervalId = setInterval(tickPlayerCounter, 10);
    healthRegenIntervalId = setInterval(tickHealthRegen, 50);

    if (isAnyButtonSelected) {
      damageIntervalId = setInterval(tickDamageCounter, 1000);
    }

    return () => {
      clearInterval(intervalId);
      clearInterval(damageIntervalId);
      clearInterval(healthRegenIntervalId);
      setPlayer(prevPlayer => ({
        ...prevPlayer,
        counter: 0,
        damageCounter: 0,
        healthRegenCounter: 0,
      }));
    };
  }, [selectedButtons, shuffledItemsWithState]);

  useEffect(() => {
    setPlayer(prevPlayer => ({
      ...prevPlayer,
      currentHealth: prevPlayer.maxHealth,
      currentStrength: prevPlayer.baseStrength,
      currentDexterity: prevPlayer.baseDexterity,
      currentWisdom: prevPlayer.baseWisdom,
    }));
  }, []);

  useEffect(() => {
    const handleKeyDown = event => {
      let newFocus = focusedButton;

      switch (event.key) {
        case "w":
        case "W":
          newFocus =
            focusedButton !== null && focusedButton >= 3
              ? focusedButton - 3
              : focusedButton;
          break;
        case "a":
        case "A":
          newFocus =
            focusedButton !== null && focusedButton % 3 !== 0
              ? focusedButton - 1
              : focusedButton;
          break;
        case "s":
        case "S":
          newFocus =
            focusedButton !== null && focusedButton <= 5
              ? focusedButton + 3
              : focusedButton;
          break;
        case "d":
        case "D":
          newFocus =
            focusedButton !== null && focusedButton % 3 !== 2
              ? focusedButton + 1
              : focusedButton;
          break;
        case "t":
        case "T":
          if (focusedButton !== null) {
            setSelectedButtons(prevState => {
              const newState = new Array(9).fill(false);
              newState[focusedButton] = !prevState[focusedButton];
              return newState;
            });
          }
          break;
      }

      setFocusedButton(newFocus);
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedButton]);

  useEffect(() => {
    const randomItems = shuffleArray(items).slice(0, 9);
    setSelectedButtons(randomItems.map(item => false));
  }, []);

  const handlePlayerDamage = itemDamage => {
    setPlayer(prevPlayer => {
      const newHealth = prevPlayer.currentHealth - itemDamage;
      return { ...prevPlayer, currentHealth: Math.max(newHealth, 0) };
    });
  };

  const renderStat = (statName, statValue) => (
    <div style={{ color: "black" }}>
      {statName} {statValue}
    </div>
  );

  const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const getRandomItem = rarity => {
    const itemsWithMatchingRarity = items.filter(
      item => item.rarity === rarity
    );
    if (itemsWithMatchingRarity.length === 0) {
      console.warn(`No items found with rarity: ${rarity}`);
      return null;
    }

    const randomIndex = Math.floor(
      Math.random() * itemsWithMatchingRarity.length
    );
    return itemsWithMatchingRarity[randomIndex];
  };

  useEffect(() => {
    // Separate items by rarity
    const commonItems = items.filter(item => item.rarity === "common");
    const uncommonItems = items.filter(item => item.rarity === "uncommon");
    const rareItems = items.filter(item => item.rarity === "rare");

    // Shuffle each rarity group separately
    const shuffledCommon = shuffleArray(commonItems);
    const shuffledUncommon = shuffleArray(uncommonItems);
    const shuffledRare = shuffleArray(rareItems);

    // Select the required number of items from each rarity group
    const selectedCommon = shuffledCommon.slice(0, 6);
    const selectedUncommon = shuffledUncommon.slice(0, 2);
    const selectedRare = shuffledRare.slice(0, 1);

    // Combine all selected items
    const combinedItems = [
      ...selectedCommon,
      ...selectedUncommon,
      ...selectedRare,
    ];

    // Shuffle the final combination
    const shuffled = shuffleArray(combinedItems);

    const shuffledWithState = shuffled.map(item => ({
      ...item,
      originalValue: item.value,
      currentValue: 0,
      stat: item.stat,
      damage: item.damage,
      isCompleted: false,
    }));

    setShuffledItems(shuffled);
    setShuffledItemsWithState(shuffledWithState);
  }, []);

  const updateItemCurrentValue = index => {
    setShuffledItemsWithState(prevState => {
      const newState = [...prevState];

      if (newState[index]) {
        const originalItem = { ...newState[index] };
        const newItem = { ...originalItem };

        newItem.currentValue += player.currentStrength;

        if (newItem.currentValue >= newItem.originalValue) {
          const completedItemRarity = originalItem.rarity;

          let replacementItem;
          if (completedItemRarity === "common") {
            const roll = Math.random();
            if (roll < 0.05) {
              replacementItem = getRandomItem("rare");
            } else if (roll < 0.3) {
              replacementItem = getRandomItem("uncommon");
            } else {
              replacementItem = getRandomItem("common");
            }
          } else if (completedItemRarity === "uncommon") {
            const roll = Math.random();
            if (roll < 0.25) {
              replacementItem = getRandomItem("rare");
            } else if (roll < 0.75) {
              replacementItem = getRandomItem("uncommon");
            } else {
              replacementItem = getRandomItem("common");
            }
          } else if (completedItemRarity === "rare") {
            const roll = Math.random();
            if (roll < 0.1) {
              replacementItem = getRandomItem("rare");
            } else if (roll < 0.9) {
              replacementItem = getRandomItem("uncommon");
            } else {
              replacementItem = getRandomItem("common");
            }
          }

          if (replacementItem) {
            newItem.image = replacementItem.image;
            newItem.name = replacementItem.name;
            newItem.value = replacementItem.value;
            newItem.stat = replacementItem.stat;
            newItem.currentValue = 0;
            newItem.isCompleted = false;
            newItem.originalValue = replacementItem.value;
            newItem.rarity = replacementItem.rarity;
            newItem.damage = replacementItem.damage;
          }

          setPlayer(prevPlayer => {
            const newPlayer = { ...prevPlayer };

            switch (originalItem.stat) {
              case "strength":
                newPlayer.baseStrength++;
                newPlayer.currentStrength = newPlayer.baseStrength;
                break;
              case "dexterity":
                newPlayer.baseDexterity++;
                newPlayer.currentDexterity = newPlayer.baseDexterity;
                break;
              case "wisdom":
                newPlayer.baseWisdom++;
                newPlayer.currentWisdom = newPlayer.baseWisdom;
                break;
              default:
                console.warn(`Unknown stat type: ${originalItem.stat}`);
            }

            newPlayer.gold++;

            return newPlayer;
          });

          setSelectedButtons(prevState => {
            const newState = [...prevState];
            newState[index] = false;
            return newState;
          });
        }

        newState[index] = newItem;
      }

      return newState;
    });
  };

  const renderHealthBarButton = (item, index) => {
    if (!item || item.isCompleted) {
      return null;
    }

    const fillPercentage = Math.min(
      (item.currentValue / item.originalValue) * 100,
      100
    );

    let borderColor;
    switch (item.rarity) {
      case "common":
        borderColor = "#bc6c25";
        break;
      case "uncommon":
        borderColor = "#3e5c76";
        break;
      case "rare":
        borderColor = "#ffc300";
        break;
      default:
        borderColor = "black";
    }

    return (
      <button
        style={{
          padding: "5px",
          fontSize: "12px",
          borderRadius: "3px",
          backgroundColor: selectedButtons[index]
            ? "orange"
            : focusedButton === index
            ? "#e0e0e0"
            : "#f0f0f0",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
          height: "60px",
          width: "100%",
          outline: focusedButton === index ? "2px solid limegreen" : "none",
          position: "relative",
          overflow: "hidden",
          border: `4px solid ${borderColor}`,
        }}
        onClick={() => {
          setFocusedButton(index);
          setSelectedButtons(prevState => {
            const newState = new Array(9).fill(false);
            newState[index] = !prevState[index];
            return newState;
          });
        }}>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(128, 128, 128, 0.9)",
            height: `${fillPercentage}%`,
            transition: "height 0.3s ease",
          }}></div>
        <img
          src={item.image}
          alt={item.name}
          style={{ width: "50px", height: "50px" }}
        />
      </button>
    );
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "5px",
        width: "500px",
        margin: "auto",
        border: "2px solid #333",
        borderRadius: "5px",
        padding: "5px",
        backgroundColor: "#edede9",
      }}>
      {/* Player Stats */}
      <div style={{ gridColumn: "1 / 4", marginBottom: "10px" }}>
        {/* Health */}
        {renderStat(
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={HEARTS}
              alt="Health"
              style={{ width: "30px", height: "30px", marginRight: "10px" }}
            />
            <div
              style={{
                backgroundColor: "#8B0A1A",
                height: "20px",
                width: "100%",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#ffffff",
                fontWeight: "bold",
                padding: "0 5px",
                position: "relative",
                overflow: "hidden",
              }}>
              <div
                style={{
                  backgroundColor: "#FF0000",
                  height: "100%",
                  width: `calc(${
                    (player.currentHealth / player.maxHealth) * 100
                  }% + 2px)`,
                  transition: "width 0.3s ease-in-out",
                  position: "absolute",
                  top: 0,
                  left: "-2px",
                  zIndex: 1,
                }}></div>
              <span
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 2,
                }}>
                {player.currentHealth}/{player.maxHealth}
              </span>
            </div>
          </div>,
          null
        )}

        {/* Mana */}
        {renderStat(
          <img
            src={ROUND_STAR}
            alt="Mana"
            style={{ width: "30px", height: "30px" }}
          />,
          player.currentMana
        )}
        {renderStat(
          <img
            src={CASH}
            alt="Gold"
            style={{ width: "30px", height: "30px" }}
          />,
          player.gold
        )}
        {renderStat(
          <img
            src={BICEPS}
            alt="Strength"
            style={{ width: "30px", height: "30px" }}
          />,
          player.currentStrength
        )}
        {renderStat(
          <img
            src={FEATHER}
            alt="Dexterity"
            style={{ width: "30px", height: "30px" }}
          />,
          player.currentDexterity
        )}
        {renderStat(
          <img
            src={POINTY_HAT}
            alt="Wisdom"
            style={{ width: "30px", height: "30px" }}
          />,
          player.currentWisdom
        )}
        {/* Player Counter: {player.counter}  */}
      </div>

      {shuffledItemsWithState.map((item, index) => (
        <div
          key={index}
          style={{ position: "relative" }}>
          {renderHealthBarButton(item, index)}
        </div>
      ))}
    </div>
  );
};

export default VersusGameMain;
