// CogatQuestions.js

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function generateNumberAnalogyQuestion() {
    const a = Math.floor(Math.random() * 5) + 1; // Limit to 5 or less
    const b = a * 2;
    const c = Math.floor(Math.random() * 5) + 1; // Limit to 5 or less
    const d = c * 2;
    let choices = [d, d - 1, d + 1, d + 2].filter((choice) => choice <= 10);

    // Ensure there are always 4 choices
    while (choices.length < 4) {
        const randomChoice = Math.floor(Math.random() * 10) + 1;
        if (!choices.includes(randomChoice)) {
            choices.push(randomChoice);
        }
    }

    choices = shuffleArray(choices);
    return {
        question: `What is the analogy of ${a}:${b} as ${c}:?`,
        choices: choices,
        correctAnswer: `${d}`,
    };
}

function generateNumberSeriesQuestion() {
    let start, step, next;
    const seriesLength = Math.random() < 0.5 ? 3 : 4; // Randomly choose between 3 or 4 numbers in the series

    do {
        start = Math.floor(Math.random() * 5) + 1; // Limit to 5 or less
        step = Math.floor(Math.random() * 2) + 1; // Limit step to 2 or less
        next = start + seriesLength * step;
    } while (next > 10);

    const series = Array.from(
        { length: seriesLength },
        (_, i) => start + i * step
    );
    let choices = [next, next + step, next - step, next + 2 * step].filter(
        (choice) => choice <= 10
    );

    // Ensure there are always 4 choices
    while (choices.length < 4) {
        const randomChoice = Math.floor(Math.random() * 10) + 1;
        if (!choices.includes(randomChoice)) {
            choices.push(randomChoice);
        }
    }

    choices = shuffleArray(choices);
    return {
        question: `What comes next in the series: ${series.join(", ")}, ?`,
        choices: choices,
        correctAnswer: `${next}`,
    };
}

function generateSVGShape(shapeType, size = 50, color = "black") {
    let svg;
    switch (shapeType) {
        case "circle":
            svg = `<svg width="${size}" height="${size}" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="${size / 2}" cy="${size / 2}" r="${
                size / 2
            }" fill="${color}" />
                    </svg>`;
            break;
        case "square":
            svg = `<svg width="${size}" height="${size}" xmlns="http://www.w3.org/2000/svg">
                        <rect width="${size}" height="${size}" fill="${color}" />
                    </svg>`;
            break;
        case "triangle":
            svg = `<svg width="${size}" height="${size}" xmlns="http://www.w3.org/2000/svg">
                        <polygon points="${
                            size / 2
                        },0 ${size},${size} 0,${size}" fill="${color}" />
                    </svg>`;
            break;
        default:
            svg = "";
    }
    console.log(`Generated SVG for ${shapeType} with color ${color}: ${svg}`);
    return svg;
}

function generateReversedNumberSeriesQuestion() {
    let start, step, next;
    const seriesLength = Math.random() < 0.5 ? 3 : 4;

    do {
        start = Math.floor(Math.random() * 5) + 1;
        step = Math.floor(Math.random() * 2) + 1;
        next = start - seriesLength * step;
    } while (next < 1);

    const series = Array.from(
        { length: seriesLength },
        (_, i) => start - i * step
    );
    let choices = [next, next - step, next + step, next - 2 * step].filter(
        (choice) => choice >= 1
    );

    while (choices.length < 4) {
        const randomChoice = Math.floor(Math.random() * 10) + 1;
        if (!choices.includes(randomChoice)) {
            choices.push(randomChoice);
        }
    }

    choices = shuffleArray(choices);
    return {
        question: `What comes next in the series: ${series.join(", ")}, ?`,
        choices: choices,
        correctAnswer: `${next}`,
    };
}

function generateFigureMatrixQuestion() {
    const shapes = ["circle", "square", "triangle"];
    const colors = ["red", "green", "blue"];
    const size = 50;

    // Create a matrix with a straightforward pattern
    const matrix = shapes.map((shape) =>
        colors.map((color) => generateSVGShape(shape, size, color))
    );

    // Randomly choose which position to replace with "?"
    const rowIndex = Math.floor(Math.random() * 3);
    const colIndex = Math.floor(Math.random() * 3);
    const correctAnswer = matrix[rowIndex][colIndex];
    matrix[rowIndex][colIndex] = "?";

    // Generate a pool of potential choices
    const potentialChoices = shapes.flatMap((shape) =>
        colors.map((color) => generateSVGShape(shape, size, color))
    );

    // Ensure the correct answer is among the choices
    const choices = [correctAnswer];

    // Select three additional unique choices from the pool
    while (choices.length < 4 && potentialChoices.length > 0) {
        const randomIndex = Math.floor(Math.random() * potentialChoices.length);
        const choice = potentialChoices[randomIndex];
        if (!choices.includes(choice)) {
            choices.push(choice);
            // Remove the chosen item from the pool to avoid duplicates
            potentialChoices.splice(randomIndex, 1);
        }
    }

    return {
        question: `Complete the matrix: [${matrix[0].join(
            ", "
        )}], [${matrix[1].join(", ")}], [${matrix[2].join(", ")}]`,
        choices: shuffleArray(choices),
        correctAnswer: correctAnswer,
    };
}

function generateShapeSeriesQuestion() {
    const shapes = ["circle", "square", "triangle"];
    const colors = ["red", "green", "blue"];
    const size = 50;

    // Randomly choose a pattern type
    const patternType = Math.floor(Math.random() * 3);

    // Randomly choose starting shape and color
    const startShapeIndex = Math.floor(Math.random() * shapes.length);
    const startColorIndex = Math.floor(Math.random() * colors.length);

    // Randomly choose the length of the series (between 4 and 6)
    const seriesLength = Math.floor(Math.random() * 4) + 4;

    let series;
    let nextShape;

    switch (patternType) {
        case 0: // Alternating shapes
            series = Array.from({ length: seriesLength }, (_, i) => {
                const shape = shapes[(startShapeIndex + i) % shapes.length];
                const color = colors[startColorIndex]; // Fixed color
                return generateSVGShape(shape, size, color);
            });
            nextShape = generateSVGShape(
                shapes[(startShapeIndex + seriesLength) % shapes.length],
                size,
                colors[startColorIndex]
            );
            break;
        case 1: // Alternating colors
            series = Array.from({ length: seriesLength }, (_, i) => {
                const shape = shapes[startShapeIndex]; // Fixed shape
                const color = colors[(startColorIndex + i) % colors.length];
                return generateSVGShape(shape, size, color);
            });
            nextShape = generateSVGShape(
                shapes[startShapeIndex],
                size,
                colors[(startColorIndex + seriesLength) % colors.length]
            );
            break;
        case 2: // Alternating shapes and colors
            series = Array.from({ length: seriesLength }, (_, i) => {
                const shape = shapes[(startShapeIndex + i) % shapes.length];
                const color = colors[(startColorIndex + i) % colors.length];
                return generateSVGShape(shape, size, color);
            });
            nextShape = generateSVGShape(
                shapes[(startShapeIndex + seriesLength) % shapes.length],
                size,
                colors[(startColorIndex + seriesLength) % colors.length]
            );
            break;
    }

    // Generate choices including the correct answer
    const potentialChoices = shapes.flatMap((shape) =>
        colors.map((color) => generateSVGShape(shape, size, color))
    );

    const choices = [nextShape];

    // Select three additional unique choices from the pool
    while (choices.length < 4 && potentialChoices.length > 0) {
        const randomIndex = Math.floor(Math.random() * potentialChoices.length);
        const choice = potentialChoices[randomIndex];
        if (!choices.includes(choice)) {
            choices.push(choice);
            // Remove the chosen item from the pool to avoid duplicates
            potentialChoices.splice(randomIndex, 1);
        }
    }

    return {
        question: `What comes next in the series: ${series.join(", ")}, ?`,
        choices: shuffleArray(choices),
        correctAnswer: nextShape,
    };
}

function getRandomQuestions(numQuestions) {
    const questionGenerators = [
        generateNumberAnalogyQuestion,
        generateNumberSeriesQuestion,
        generateReversedNumberSeriesQuestion,
        generateFigureMatrixQuestion,
        generateShapeSeriesQuestion,
    ];

    const questions = Array.from({ length: numQuestions }, () => {
        const randomGenerator =
            questionGenerators[
                Math.floor(Math.random() * questionGenerators.length)
            ];
        return randomGenerator();
    });

    return questions;
}

export { getRandomQuestions };
