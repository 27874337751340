// CardButton.jsx
import React from "react";

const MAX_LENGTH = 10;

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength - 3)}...`;
};

const CardButton = ({ card, onClick }) => {
  const truncatedName = truncateText(card.name, MAX_LENGTH);

  return (
    <button
      className="card-button"
      onClick={() => onClick(card)}>
      {truncatedName}
    </button>
  );
};

export default CardButton;
