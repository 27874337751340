export const setWebSocket = ws => ({
  type: "SET_WEBSOCKET",
  payload: ws,
});

export const setCharacters = characters => ({
  type: "SET_CHARACTERS",
  payload: characters,
});

export const setCharacterCreated = characterCreated => ({
  type: "SET_CHARACTER_CREATED",
  payload: characterCreated,
});

export const setCurrentView = view => ({
  type: "SET_CURRENT_VIEW",
  payload: view,
});

export const setSelectedCharacter = character => ({
  type: "SET_SELECTED_CHARACTER",
  payload: character,
});

export const setMonsters = monsters => ({
  type: "SET_MONSTERS",
  payload: monsters,
});
