import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getRandomQuestions } from "./CogatQuestions";

const CogatPracticeTest = () => {
    const history = useHistory();
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedChoice, setSelectedChoice] = useState(null);
    const [score, setScore] = useState(0);
    const [showResult, setShowResult] = useState(false);

    useEffect(() => {
        const randomQuestions = getRandomQuestions(10);
        setQuestions(randomQuestions);
    }, []);

    const speak = (text) => {
        const utterance = new SpeechSynthesisUtterance(text);
        const voices = window.speechSynthesis.getVoices();
        utterance.voice =
            voices.find((voice) => voice.name === "Google US English") ||
            voices[0];
        window.speechSynthesis.speak(utterance);
    };

    const handleChoiceClick = (choice) => {
        setSelectedChoice(choice);
        if (String(choice) === questions[currentQuestionIndex].correctAnswer) {
            setScore(score + 1);
        }
    };

    const handleNextClick = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setSelectedChoice(null);
        } else {
            setShowResult(true);
        }
    };

    const handleBackClick = () => {
        history.push("/cogat-practice");
    };

    if (showResult) {
        return (
            <div>
                <h2>Results</h2>
                <p>
                    You got {score} out of {questions.length} correct!
                </p>
                <button onClick={handleBackClick}>Back to Landing Page</button>
            </div>
        );
    }

    if (questions.length === 0) {
        return <p>Loading questions...</p>;
    }

    const currentQuestion = questions[currentQuestionIndex];

    const buttonStyle = {
        padding: "50px 50px",
        fontSize: "24px",
        margin: "10px",
        cursor: "pointer",
    };

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "25px",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <div>
            <button onClick={handleBackClick}>End Practice</button>
            <h2>Cogat Game</h2>
            <div>
                <p>
                    <button onClick={() => speak(currentQuestion.question)}>
                        Read Question
                    </button>{" "}
                    <span
                        dangerouslySetInnerHTML={{
                            __html: currentQuestion.question,
                        }}
                    />
                </p>
                <div style={gridStyle}>
                    {currentQuestion.choices.map((choice, index) => (
                        <button
                            key={index}
                            onClick={() => handleChoiceClick(choice)}
                            disabled={selectedChoice !== null}
                            style={buttonStyle}>
                            <div dangerouslySetInnerHTML={{ __html: choice }} />
                        </button>
                    ))}
                </div>
                {selectedChoice && (
                    <div>
                        <p>
                            {String(selectedChoice) ===
                            currentQuestion.correctAnswer ? (
                                "Correct!"
                            ) : (
                                <span>
                                    Incorrect. The correct answer is{" "}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: currentQuestion.correctAnswer,
                                        }}
                                    />
                                </span>
                            )}
                        </p>
                        <button
                            onClick={handleNextClick}
                            style={buttonStyle}>
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CogatPracticeTest;
