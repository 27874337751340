import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const GAME_STATE = {
  GAME_START: "Start of the game",
  GAME_OVER: "Game over",
  IN_PROGRESS: "In progress",
};

const ADDITIONAL_GAME_STATE = {
  X_WON: "X won",
  O_WON: "O won",
  DRAW: "Game is a draw",
  NOT_ENOUGH_PLAYERS: "Not enough players, waiting for another player to join",
  X_TURN: "Player X's turn",
  O_TURN: "Player O's turn",
};

function TicTacToeAgain() {
  const user = useSelector(store => store.user);
  const [ws, setWs] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const [createRoomName, setCreateRoomName] = useState("");
  const [currentRoom, setCurrentRoom] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [currentPlayers, setCurrentPlayers] = useState([]);

  const [boardState, setBoardState] = useState(Array(9).fill(null));

  const [gameState, setGameState] = useState(GAME_STATE.GAME_START);

  const [additionalGameState, setAdditionalGameState] = useState(
    ADDITIONAL_GAME_STATE.NOT_ENOUGH_PLAYERS
  );

  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [playerPiece, setPlayerPiece] = useState(null);

  useEffect(() => {
    let ws;

    let environment;

    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }

    switch (environment) {
      case "localhost":
        ws = new WebSocket("ws://localhost:5000/again");
        break;
      case "localNetwork":
        ws = new WebSocket("ws://192.168.0.3:5000/again");
        break;
      case "production":
        ws = new WebSocket("wss://2nguyen.dev/again");
        break;
      default:
        console.log("Environment not recognized. WebSocket not established.");
        break;
    }

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          type: "account",
          action: "user",
          user: user.username,
        })
      );

      ws.send(JSON.stringify({ type: "room", action: "get" }));

      setIsConnected(true);
      setWs(ws);
    };

    ws.onclose = () => {
      setIsConnected(false);
      setWs(null);
    };

    ws.onmessage = event => {
      const data = JSON.parse(event.data);
      // console.log(data);

      switch (`${data.type}:${data.action}`) {
        case "room:get":
          setRooms(data.rooms);
          break;

        case "room:join":
          setCurrentRoom(data.room);
          break;

        case "room:update":
          console.log("Room update:", data.room);
          setCurrentRoom(data.room);
          setCurrentPlayers(data.room.players);

          setBoardState(data.room.board);
          setGameState(data.room.gameState);
          setAdditionalGameState(data.room.additionalGameState);
          setCurrentPlayer(data.room.currentPlayer);

          setPlayerPiece(data.room.playerPiece);
          break;
      }
    };

    return () => {
      ws.close();
    };
  }, []);

  // Functions for Room Messages

  const createRoom = () => {
    const message = {
      type: "room",
      action: "create",
      roomName: createRoomName,
    };

    ws.send(JSON.stringify(message));

    setCreateRoomName("");
  };

  const leaveRoom = () => {
    const message = {
      type: "room",
      action: "leave",
    };
    ws.send(JSON.stringify(message));
    setCurrentRoom(null);
  };

  const joinRoom = roomId => {
    const message = {
      type: "room",
      action: "join",
      roomId: roomId,
    };
    ws.send(JSON.stringify(message));
  };

  // Game board rendering

  const renderSquare = (value, index) => (
    <button
      key={index}
      onClick={() => handleClick(index)}
      disabled={
        gameState !== GAME_STATE.IN_PROGRESS ||
        currentPlayer !== playerPiece ||
        boardState[index] !== null
      }
      style={{
        width: "100px",
        height: "100px",
        fontSize: "24px",
        margin: "5px",
        backgroundColor:
          currentPlayer === playerPiece &&
          gameState === GAME_STATE.IN_PROGRESS &&
          boardState[index] === null
            ? "white"
            : "lightgray",
      }}>
      {value}
    </button>
  );

  const renderBoard = () => {
    const squares = [];
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        squares.push(renderSquare(boardState[i * 3 + j], i * 3 + j));
      }
    }
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "10px",
          width: "330px",
          margin: "0 auto",
        }}>
        {squares}
      </div>
    );
  };

  const handleClick = index => {
    if (
      ws &&
      gameState === GAME_STATE.IN_PROGRESS &&
      boardState[index] === null &&
      currentPlayer === playerPiece
    ) {
      ws.send(
        JSON.stringify({
          type: "game",
          action: "move",
          move: index,
        })
      );
    }
  };

  const resetGame = () => {
    ws.send(
      JSON.stringify({
        type: "game",
        action: "reset",
      })
    );
  };

  return (
    <>
      <div>
        <h1>Tic Tac Toe</h1>
      </div>

      {currentRoom ? (
        <div>
          <h2>Current Room: {currentRoom.name}</h2>
          {renderBoard()}
          <p>You are playing as: {playerPiece}</p>
          <p>{gameState}</p>
          <p>{additionalGameState}</p>
          {gameState === GAME_STATE.GAME_OVER && (
            <button onClick={resetGame}>Reset Game</button>
          )}
          <h3>Current Players:</h3>
          <ul>
            {currentPlayers.map((player, index) => (
              <li key={index}>{player}</li>
            ))}
          </ul>
          <button
            onClick={() => leaveRoom()}
            disabled={!isConnected}>
            Leave Room
          </button>
        </div>
      ) : (
        <>
          <div>
            <input
              type="text"
              value={createRoomName}
              onChange={e => setCreateRoomName(e.target.value)}
            />
            <button
              onClick={() => createRoom()}
              disabled={!isConnected}>
              Create and Join Room
            </button>{" "}
          </div>

          <div>
            <h2>Current Games</h2>
            <ul>
              {Object.entries(rooms).map(([roomId, room]) => (
                <li key={roomId}>
                  {room.name} - {room.players.length} - players{" "}
                  <button
                    onClick={() => joinRoom(roomId)}
                    disabled={!isConnected}>
                    Join
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
}

export default TicTacToeAgain;
