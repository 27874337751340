import "./MORPG.css";
import versionInfo from "./versionInfo";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MonsterZombie from "./assets/monster_zombie.svg";
import MonsterSlime from "./assets/monster_slime.svg";
import MonsterBoneDragon from "./assets/monster_bone_dragon.svg";
import MonsterBrute from "./assets/monster_brute.svg";
import MonsterFishCreature from "./assets/monster_fish_creature.svg";
import MonsterGoblin from "./assets/monster_goblin.svg";
import MonsterGolem from "./assets/monster_golem.svg";
import MonsterImp from "./assets/monster_imp.svg";
import MonsterSpider from "./assets/monster_spider.svg";
import ItemGoo from "./assets/item_goo.svg";
import ItemLeather from "./assets/item_leather.svg";
import ItemBone from "./assets/item_bone.svg";
import ItemFish from "./assets/item_fish.svg";
import ItemRock from "./assets/item_rock.svg";
import ItemWeb from "./assets/item_web.svg";
import ItemLesserMonsterEssence from "./assets/item_glass_ball.svg";
import ItemWeaponFragment from "./assets/item_weapon_fragment.svg";
import ItemArmorFragment from "./assets/item_armor_fragment.svg";
import ItemAccessoryFragment from "./assets/item_accessory_fragment.svg";
import ItemNothing from "./assets/item_nothing.svg";
import EquipmentFists from "./assets/equipment_fists.svg";
import EquipmentRoundShield from "./assets/equipment_round_shield.svg";
import EquipmentLeatherBoots from "./assets/equipment_leather_boots.svg";
import EquipmentLeatherTunic from "./assets/equipment_leather_tunic.svg";
import EquipmentHeartPendant from "./assets/equipment_heart_pendant.svg";
import EquipmentLifeBottle from "./assets/equipment_life_bottle.svg";
import EquipmentChronograph from "./assets/equipment_chronograph.svg";
import EquipmentLongBow from "./assets/equipment_long_bow.svg";
import EquipmentRecurveBow from "./assets/equipment_recurve_bow.svg";
import EquipmentCompositeBow from "./assets/equipment_composite_bow.svg";
import EquipmentDagger from "./assets/equipment_dagger.svg";
import EquipmentNunchaku from "./assets/equipment_nunchaku.svg";
import EquipmentJambia from "./assets/equipment_jambia.svg";
import EquipmentCleaver from "./assets/equipment_cleaver.svg";
import ActionRun from "./assets/action_run.svg";
import ActionRevive from "./assets/action_revive.svg";
import ActionRest from "./assets/action_rest.svg";
import CursorFists from "./assets/cursor_fists.svg";
import CursorBow from "./assets/cursor_bow.svg";
import CursorDagger from "./assets/cursor_dagger.svg";
import CursorNunchaku from "./assets/cursor_nunchaku.svg";
import CursorJambia from "./assets/cursor_jambia.svg";
import CursorCleaver from "./assets/cursor_cleaver.svg";
import MenuCharacter from "./assets/menu_character_sheet.svg";
import MenuInventory from "./assets/menu_inventory.svg";
import MenuEquipment from "./assets/menu_equipment.svg";
import MenuPatchNotes from "./assets/menu_patch_notes.svg";
import MenuCards from "./assets/menu_cards.svg";
import MenuUp from "./assets/menu_up.svg";
import MenuDown from "./assets/menu_down.svg";
import MenuExit from "./assets/menu_exit.svg";
import ParticlePunch from "./assets/particle_punch.svg";
import ParticleSmack from "./assets/particle_smack.svg";
import ParticleSlash from "./assets/particle_slash.svg";
import ParticleArrow from "./assets/particle_arrow.svg";

const MORPG = () => {
  const history = useHistory();
  const user = useSelector(store => store.user);
  const [cursorPositions, setCursorPositions] = useState({});
  const [ws, setWs] = useState(null);
  const [mapValues, setMapValues] = useState(
    Array(5).fill(Array(5).fill({ percentage: null }))
  );
  const [itemsReceived, setItemsReceived] = useState([]);
  const [worldMapValues, setWorldMapValues] = useState([[]]);
  const [zoneLevel, setZoneLevel] = useState(1);
  const [zoneBiome, setZoneBiome] = useState("forest");

  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [lastClicked, setLastClicked] = useState(null);
  const [isDead, setIsDead] = useState(false);
  const [isResting, setIsResting] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState("");

  const [isCharacterModalOpen, setIsCharacterModalOpen] = useState(false);
  const [isInventoryModalOpen, setIsInventoryModalOpen] = useState(false);
  const [isEquipmentModalOpen, setIsEquipmentModalOpen] = useState(false);
  const [isDisconnectedModalOpen, setIsDisconnectedModalOpen] = useState(false);
  const [activeDescriptionIndex, setActiveDescriptionIndex] = useState(null);
  const [activeEquipmentDescriptionIndex, setActiveEquipmentDescriptionIndex] =
    useState(null);
  const [isCardsModalOpen, setIsCardsModalOpen] = useState(false);
  const [activeCardDescriptionIndex, setActiveCardDescriptionIndex] =
    useState(null);

  const [showVersionModal, setShowVersionModal] = useState(false);

  const [weapon, setWeapon] = useState(null);
  const [armor, setArmor] = useState(null);
  const [accessory, setAccessory] = useState(null);
  const [level, setLevel] = useState(null);
  const [currentXP, setCurrentXP] = useState(null);
  const [requiredXP, setRequiredXP] = useState(null);
  const [currentHealth, setCurrentHealth] = useState(null);
  const [maxHealth, setMaxHealth] = useState(0);
  const [strength, setStrength] = useState(0);
  const [dexterity, setDexterity] = useState(0);
  const [wisdom, setWisdom] = useState(0);
  const [luck, setLuck] = useState(0);
  const [defense, setDefense] = useState(0);
  const [evasion, setEvasion] = useState(0);
  const [rarity, setRarity] = useState(0);
  const [maxAttackCooldown, setMaxAttackCooldown] = useState(0);
  const [maxHealthRegenerationCooldown, setMaxHealthRegenerationCooldown] =
    useState(0);
  const [maxManaRegenerationCooldown, setMaxManaRegenerationCooldown] =
    useState(0);
  const [currentMana, setCurrentMana] = useState(0);
  const [maxMana, setMaxMana] = useState(0);
  const [minimumDamage, setMinimumDamage] = useState(0);
  const [maximumDamage, setMaximumDamage] = useState(0);

  const [playerInventory, setPlayerInventory] = useState([]);
  const [playerUnlockedCards, setPlayerUnlockedCards] = useState([]);
  const [playerEquippedCards, setPlayerEquippedCards] = useState([]);

  const [activeAnimations, setActiveAnimations] = useState([]);

  const itemImages = {
    goo: ItemGoo,
    leather: ItemLeather,
    bone: ItemBone,
    fish: ItemFish,
    rock: ItemRock,
    web: ItemWeb,
    lesser_monster_essence: ItemLesserMonsterEssence,
    fists: EquipmentFists,
    round_shield: EquipmentRoundShield,
    leather_boots: EquipmentLeatherBoots,
    leather_tunic: EquipmentLeatherTunic,
    heart_pendant: EquipmentHeartPendant,
    life_bottle: EquipmentLifeBottle,
    chronograph: EquipmentChronograph,
    long_bow: EquipmentLongBow,
    recurve_bow: EquipmentRecurveBow,
    composite_bow: EquipmentCompositeBow,
    dagger: EquipmentDagger,
    nunchaku: EquipmentNunchaku,
    jambia: EquipmentJambia,
    cleaver: EquipmentCleaver,
    weapon_fragment: ItemWeaponFragment,
    armor_fragment: ItemArmorFragment,
    accessory_fragment: ItemAccessoryFragment,
    nothing: ItemNothing,
  };

  const cursorImages = {
    fists: CursorFists,
    bow: CursorBow,
    dagger: CursorDagger,
    nunchaku: CursorNunchaku,
    jambia: CursorJambia,
    cleaver: CursorCleaver,
  };

  const particleImages = {
    fists: ParticlePunch,
    long_bow: ParticleArrow,
    recurve_bow: ParticleArrow,
    composite_bow: ParticleArrow,
    nunchaku: ParticleSmack,
    dagger: ParticleSlash,
    jambia: ParticleSlash,
    cleaver: ParticleSlash,
  };

  const enemyImages = {
    zombie: MonsterZombie,
    slime: MonsterSlime,
    bone_dragon: MonsterBoneDragon,
    brute: MonsterBrute,
    fish_creature: MonsterFishCreature,
    goblin: MonsterGoblin,
    golem: MonsterGolem,
    imp: MonsterImp,
    spider: MonsterSpider,
  };

  const cardImages = {
    fortitude: ItemNothing,
    hardiness: ItemNothing,
    durable: ItemNothing,
    reckless: ItemNothing,
    poised: ItemNothing,
    quick: ItemNothing,
    fortunate: ItemNothing,
  };

  useEffect(() => {
    let ws;
    let animationFrameId;

    const lerp = (start, end, amt) => {
      return (1 - amt) * start + amt * end;
    };

    const animateCursor = (
      userId,
      lastX,
      lastY,
      targetX,
      targetY,
      username,
      weapon
    ) => {
      const weaponName = weapon && weapon.name ? weapon.name : "Unknown";

      const startTime = performance.now();
      const duration = 150;

      const startX = lastX;
      const startY = lastY;

      const animate = currentTime => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        const x = lerp(startX, targetX, progress);
        const y = lerp(startY, targetY, progress);

        setCursorPositions(prev => ({
          ...prev,
          [userId]: { x, y, username, weapon },
        }));

        if (progress < 1) {
          requestAnimationFrame(animate);
        } else {
          setCursorPositions(prev => ({
            ...prev,
            [userId]: { x: targetX, y: targetY, username, weapon },
          }));
        }
      };

      requestAnimationFrame(animate);
    };

    let environment;

    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }

    switch (environment) {
      case "localhost":
        ws = new WebSocket("ws://localhost:5000/morpg");
        break;
      case "localNetwork":
        ws = new WebSocket("ws://192.168.0.3:5000/morpg");
        break;
      case "production":
        ws = new WebSocket("wss://2nguyen.dev/morpg");
        break;
      default:
        console.log("Environment not recognized. WebSocket not established.");
        break;
    }

    setWs(ws);

    ws.onopen = () => {
      const message = JSON.stringify({
        type: "username",
        username: user.username,
      });

      ws.send(message);
    };

    ws.onmessage = event => {
      const data = JSON.parse(event.data);

      switch (data.type) {
        case "ping":
          const pongMessage = JSON.stringify({ type: "pong" });
          ws.send(pongMessage);
          break;
        case "duplicateLogin":
          alert("You are already logged in from another location.");
          history.push("/");
          break;
        case "userData":
          setCoordinates(data.coordinates);
          setWeapon(data.weapon);
          setArmor(data.armor);
          setAccessory(data.accessory);
          setLevel(data.level);
          setCurrentXP(data.currentXP);
          setRequiredXP(data.requiredXP);
          setCurrentHealth(Math.ceil(data.currentHealth));
          setMaxHealth(Math.ceil(data.maxHealth));
          setStrength(data.strength);
          setDexterity(data.dexterity);
          setWisdom(data.wisdom);
          setLuck(data.luck);
          setDefense(data.defense);
          setEvasion(data.evasion);
          setRarity(data.rarity);
          setMaxAttackCooldown(data.maxAttackCooldown);
          setMaxHealthRegenerationCooldown(data.maxHealthRegenerationCooldown);
          setMaxManaRegenerationCooldown(data.maxManaRegenerationCooldown);
          setCurrentMana(data.currentMana);
          setMaxMana(data.maxMana);
          setMinimumDamage(data.minimumDamage);
          setMaximumDamage(data.maximumDamage);

          setPlayerInventory(data.inventory);
          setPlayerUnlockedCards(data.unlockedCards);
          setPlayerEquippedCards(data.equippedCards);

          if (data.currentHealth <= 0) {
            setIsDead(true);
          }

          const message = JSON.stringify({
            type: "confirmCoordinates",
            username: user.username,
            coordinates: data.coordinates,
          });

          ws.send(message);
          break;
        case "updateCoordinates":
          setCoordinates(data.newCoordinates);
          break;
        case "updateWorldMap":
          setWorldMapValues(data.worldMap);
          break;
        case "updateZoneInformation":
          setZoneLevel(data.level);
          setZoneBiome(data.biome);
          break;
        case "receiveChatMessages":
          setChatMessages(data.messages);
          break;
        case "disconnect":
          setCursorPositions(prev => {
            const newPositions = { ...prev };
            delete newPositions[data.userId];
            return newPositions;
          });
          break;
        case "clearCursor":
          setCursorPositions(prevPositions => {
            const updatedPositions = { ...prevPositions };
            delete updatedPositions[data.userId];
            return updatedPositions;
          });
          break;
        case "cursorPositions":
          animateCursor(
            data.userId,
            data.lastX,
            data.lastY,
            data.x,
            data.y,
            data.username,
            data.weapon
          );
          break;
        case "itemsReceived":
          if (data.items.length === 0) {
            setItemsReceived([]);
          } else {
            setItemsReceived(data.items);
          }
          break;
        case "updateMap":
          setMapValues(data.mapValues);
          break;
        case "updateXP":
          setCurrentXP(data.currentXP);
          break;
        case "levelUp":
          setLevel(data.level);
          setCurrentXP(data.currentXP);
          setRequiredXP(data.requiredXP);
          setCurrentHealth(Math.ceil(data.currentHealth));
          setMaxHealth(Math.ceil(data.maxHealth));
          setCurrentMana(data.currentMana);
          setMaxMana(data.maxMana);
          setPlayerUnlockedCards(data.unlockedCards);
          break;
        case "updateHealthMana":
          setCurrentHealth(Math.ceil(data.currentHealth));
          setCurrentMana(Math.ceil(data.currentMana));
          break;
        case "playerModifiersUpdate":
          setCurrentHealth(Math.ceil(data.currentHealth));
          setMaxHealth(Math.ceil(data.maxHealth));
          setStrength(data.strength);
          setDexterity(data.dexterity);
          setWisdom(data.wisdom);
          setLuck(data.luck);
          setDefense(Math.ceil(data.defense));
          setEvasion(data.evasion);
          setRarity(data.rarity);
          setMaxAttackCooldown(data.maxAttackCooldown);
          setMaxHealthRegenerationCooldown(data.maxHealthRegenerationCooldown);
          setMaxManaRegenerationCooldown(data.maxManaRegenerationCooldown);
          break;
        case "dead":
          setIsDead(true);
          setLastClicked(null);
          setCurrentXP(data.currentXP);
          break;
        case "revive":
          setIsDead(false);
          setCurrentHealth(Math.ceil(data.currentHealth));
          setMaxHealth(Math.ceil(data.maxHealth));
          setCurrentXP(data.currentXP);
          break;
        case "depletedCell":
          setMapValues(prevMapValues => {
            const newMapValues = [...prevMapValues];
            newMapValues[data.coordinates.row][data.coordinates.column] = {
              ...newMapValues[data.coordinates.row][data.coordinates.column],
              percentage: 0,
              monster: null,
            };
            return newMapValues;
          });
          setLastClicked(prevLastClicked => {
            if (
              prevLastClicked &&
              prevLastClicked.row === data.coordinates.row &&
              prevLastClicked.column === data.coordinates.column
            ) {
              return null;
            }
            return prevLastClicked;
          });
          break;
        case "initialChatMessages":
          setChatMessages(data.messages);
          break;
        case "updateInventory":
          setPlayerInventory(data.inventory);
          break;
        case "updateEquipment":
          setWeapon(data.weapon);
          setArmor(data.armor);
          setAccessory(data.accessory);
          break;
        case "updateEquippedCards":
          setPlayerEquippedCards(data.equippedCards);
          break;
        default:
          console.log("Received unknown data type:", data.type);
      }
    };

    ws.onclose = () => {
      setIsDisconnectedModalOpen(true);
    };

    const sendCursorPosition = event => {
      if (ws.readyState === WebSocket.OPEN) {
        const { clientX, clientY } = event;
        const adjustedX = clientX - 25;
        const adjustedY = clientY - 25;

        const message = JSON.stringify({
          type: "playerCursor",
          username: user.username,
          userId: user.username,
          x: clientX - 10,
          y: clientY - 10,
          weapon: weapon,
        });
        ws.send(message);
      } else {
        console.log("WebSocket is not open. Cannot send message.");
      }
    };

    document.addEventListener("click", sendCursorPosition);

    return () => {
      document.removeEventListener("click", sendCursorPosition);
      ws.close();
      setWs(null);
      cancelAnimationFrame(animationFrameId);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    let intervalId;

    const sendLastClicked = () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        const message = JSON.stringify({
          type: "sendInteract",
          username: user.username,
          interactCoordinates: coordinates,
          row: lastClicked.row,
          column: lastClicked.column,
          weapon: weapon,
        });
        ws.send(message);
      }
    };

    if (lastClicked && ws && ws.readyState === WebSocket.OPEN) {
      sendLastClicked();
      intervalId = setInterval(sendLastClicked, 100);
    }

    return () => {
      clearInterval(intervalId);
      if (ws && ws.readyState === WebSocket.OPEN) {
        const message = JSON.stringify({
          type: "stopAction",
        });
        ws.send(message);
      }
    };
  }, [lastClicked, ws, weapon]);

  useEffect(() => {
    let animationIntervalId;

    const triggerRandomAttackAnimation = () => {
      const cell = mapValues[lastClicked.row][lastClicked.column];

      if (cell && cell.percentage !== null) {
        const weaponName = weapon ? weapon.name : "fists";
        triggerAttackAnimation(lastClicked.row, lastClicked.column, weaponName);
      }
    };

    if (lastClicked && ws && ws.readyState === WebSocket.OPEN && weapon) {
      const cell = mapValues[lastClicked.row][lastClicked.column];

      if (cell && cell.percentage !== null) {
        const randomInterval = () => Math.floor(Math.random() * 250) + 500;
        animationIntervalId = setInterval(() => {
          triggerRandomAttackAnimation();
        }, randomInterval());
      }
    }

    return () => {
      clearInterval(animationIntervalId);
    };
  }, [lastClicked, ws, weapon, mapValues]);

  useEffect(() => {
    let intervalId;

    const sendRest = () => {
      if (ws && ws.readyState === WebSocket.OPEN && currentHealth < maxHealth) {
        const message = JSON.stringify({
          type: "rest",
        });
        ws.send(message);
      }
    };

    if (isResting && ws && ws.readyState === WebSocket.OPEN) {
      sendRest();
      intervalId = setInterval(sendRest, 100);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isResting, ws, currentHealth, maxHealth]);

  useEffect(() => {
    const handleClickOutside = event => {
      const characterModalContent = document.querySelector(
        ".character-modal-content"
      );
      const inventoryModalContent = document.querySelector(
        ".inventory-modal-content"
      );
      const equipmentModalContent = document.querySelector(
        ".equipment-modal-content"
      );
      const cardsModalContent = document.querySelector(".cards-modal-content");

      if (
        characterModalContent &&
        !characterModalContent.contains(event.target)
      ) {
        setIsCharacterModalOpen(false);
      }
      if (
        inventoryModalContent &&
        !inventoryModalContent.contains(event.target)
      ) {
        setIsInventoryModalOpen(false);
      }
      if (
        equipmentModalContent &&
        !equipmentModalContent.contains(event.target)
      ) {
        setIsEquipmentModalOpen(false);
      }
      if (cardsModalContent && !cardsModalContent.contains(event.target)) {
        setIsCardsModalOpen(false);
      }
    };

    if (
      isCharacterModalOpen ||
      isInventoryModalOpen ||
      isEquipmentModalOpen ||
      isCardsModalOpen
    ) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    isCharacterModalOpen,
    isInventoryModalOpen,
    isEquipmentModalOpen,
    isCardsModalOpen,
  ]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  function formatLocalTime(utcString) {
    const date = new Date(utcString);
    return date.toLocaleTimeString();
  }

  useEffect(() => {
    const displayItem = (item, delay) => {
      setTimeout(() => {
        const itemElement = document.createElement("div");
        itemElement.style.position = "absolute";
        itemElement.style.left = `${150}px`;
        itemElement.style.top = `${400}px`;
        itemElement.style.transition = "top 3s, opacity 3s";
        itemElement.style.opacity = "1";
        itemElement.style.zIndex = 1000;
        itemElement.textContent = formatName(item.name);
        document.body.appendChild(itemElement);

        setTimeout(() => {
          itemElement.style.top = "0px";
          itemElement.style.opacity = "0";
        }, 200);

        setTimeout(() => {
          if (document.body.contains(itemElement)) {
            document.body.removeChild(itemElement);
          }
        }, 3000);
      }, delay);
    };

    itemsReceived.forEach((item, index) => {
      displayItem(item, index * 200);
    });
  }, [itemsReceived]);

  const handleButtonClick = (i, j) => {
    setLastClicked({ row: i, column: j });
    setIsChatExpanded(false);
    setIsResting(false);

    if (isDead) {
      setLastClicked(null);
    }
  };

  const triggerAttackAnimation = (row, column, weaponType) => {
    const offsetX = Math.floor(Math.random() * 26);
    const offsetY = Math.floor(Math.random() * 26) - 15;

    const newAnimation = {
      id: `${row}-${column}-${Date.now()}`,
      row,
      column,
      weaponType,
      offsetX,
      offsetY,
      startTime: Date.now(),
    };
    setActiveAnimations(prev => [...prev, newAnimation]);

    setTimeout(() => {
      setActiveAnimations(prev =>
        prev.filter(anim => anim.id !== newAnimation.id)
      );
    }, 300);
  };

  const handleActionReviveClick = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({
        type: "revive",
      });
      ws.send(message);
    }

    setIsChatExpanded(false);
    setIsResting(false);
  };

  const handleActionRunClick = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({
        type: "stopAction",
        username: user.username,
        row: null,
        column: null,
      });
      ws.send(message);
    }

    setLastClicked(null);
    setIsChatExpanded(false);
    setIsResting(false);
  };

  const handleSendMessage = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const timestamp = new Date().toLocaleTimeString();

      const message = JSON.stringify({
        type: "sendChatMessage",
        message: chatInput,
        username: user.username,
        timestamp: timestamp,
      });
      ws.send(message);
      setChatInput("");
    }
  };

  const isItemEquipped = item => {
    return (
      (weapon && item.id === weapon.id && item.equipment_slot === "weapon") ||
      (armor && item.id === armor.id && item.equipment_slot === "armor") ||
      (accessory &&
        item.id === accessory.id &&
        item.equipment_slot === "accessory")
    );
  };

  const handleEquipToggle = item => {
    const isCurrentlyEquipped = isItemEquipped(item);
    const action = isCurrentlyEquipped ? "unequip" : "equip";

    if (ws && ws.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({
        type: "equipmentAction",
        action: action,
        itemId: item.id,
      });
      ws.send(message);
    } else {
      console.log("WebSocket is not open. Cannot send message.");
    }
  };

  const handleDismantle = item => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({
        type: "dismantleAction",
        itemId: item.id,
      });
      ws.send(message);
    } else {
      console.log("WebSocket is not open. Cannot send message.");
    }
  };

  const formatName = name => {
    return name
      .split("_")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const EquipmentDescription = ({
    item,
    onClose,
    handleEquipToggle,
    isItemEquipped,
    playerInventory,
  }) => {
    const {
      name,
      description,
      minDamage,
      maxDamage,
      defense,
      evasion,
      healthPercentage,
      flatHealth,
      attackCooldownPercentage,
      healthRegenerationCooldownPercentage,
      randomMinMinDamage,
      randomMaxMinDamage,
      randomMinMaxDamage,
      randomMaxMaxDamage,
      randomMinDefense,
      randomMaxDefense,
      randomMinEvasion,
      randomMaxEvasion,
      randomMinHealthPercentage,
      randomMaxHealthPercentage,
      randomMinFlatHealth,
      randomMaxFlatHealth,
      randomMinAttackCooldownPercentage,
      randomMaxAttackCooldownPercentage,
      randomMinHealthRegenerationCooldownPercentage,
      randomMaxHealthRegenerationCooldownPercentage,
      ...otherProps
    } = item;

    const formatPercent = value => {
      return `${(value * 100).toFixed(0)}%`;
    };

    const itemImage = itemImages[name.toLowerCase()];

    const isLastItemOfType = () => {
      const itemType = item.equipment_slot;
      const itemCount = playerInventory.filter(
        invItem => invItem.equipment_slot === itemType
      ).length;
      return itemCount === 1;
    };

    return (
      <div className="equipment-description">
        <span
          className="description-close"
          onClick={onClose}>
          &times;
        </span>
        {itemImage && (
          <img
            src={itemImage}
            alt={name}
            style={{ width: "50px", height: "50px" }}
          />
        )}
        <h3>{formatName(name)}</h3>

        <p>{description}</p>
        {minDamage !== undefined && maxDamage !== undefined && (
          <p>
            Inflicts {minDamage}-{maxDamage} damage.
            <span style={{ color: "#CD7F32" }}>
              {" "}
              ({randomMinMinDamage}-{randomMaxMinDamage})-(
              {randomMinMaxDamage}-{randomMaxMaxDamage}){" "}
            </span>
          </p>
        )}
        {defense !== undefined && (
          <p>
            Adds {defense} defense.
            <span style={{ color: "#CD7F32" }}>
              {" "}
              ({randomMinDefense} - {randomMaxDefense})
            </span>
          </p>
        )}
        {evasion !== undefined && (
          <p>
            Adds a(n) {evasion}% chance to evade attacks.
            <span style={{ color: "#CD7F32" }}>
              {" "}
              ({randomMinEvasion}% - {randomMaxEvasion}%)
            </span>
          </p>
        )}

        {flatHealth !== undefined && (
          <p>
            Adds {flatHealth} health.
            <span style={{ color: "#CD7F32" }}>
              {" "}
              ({randomMinFlatHealth} - {randomMaxFlatHealth})
            </span>
          </p>
        )}
        {healthPercentage !== undefined && (
          <p>
            Increase Max Health by {formatPercent(healthPercentage)}.
            <span style={{ color: "#CD7F32" }}>
              {" "}
              ({formatPercent(randomMinHealthPercentage)} -{" "}
              {formatPercent(randomMaxHealthPercentage)})
            </span>
          </p>
        )}
        {attackCooldownPercentage !== undefined && (
          <p>
            Decrease Attack Cooldown by{" "}
            {formatPercent(attackCooldownPercentage)}.
            <span style={{ color: "#CD7F32" }}>
              {" "}
              ({formatPercent(randomMinAttackCooldownPercentage)} -{" "}
              {formatPercent(randomMaxAttackCooldownPercentage)})
            </span>
          </p>
        )}
        {healthRegenerationCooldownPercentage !== undefined && (
          <p>
            Decrease Regeneration Cooldown by{" "}
            {formatPercent(healthRegenerationCooldownPercentage)}.
            <span style={{ color: "#CD7F32" }}>
              {" "}
              ({formatPercent(
                randomMinHealthRegenerationCooldownPercentage
              )} -{" "}
              {formatPercent(randomMaxHealthRegenerationCooldownPercentage)})
            </span>
          </p>
        )}
        <br />
        <center>
          <button onClick={() => handleEquipToggle(item)}>
            {isItemEquipped(item) ? "Unequip" : "Equip"}
          </button>{" "}
          <br /> <br />
          <button
            onClick={() => handleDismantle(item)}
            disabled={isItemEquipped(item) || isLastItemOfType()}>
            Dismantle
          </button>
        </center>
      </div>
    );
  };

  function capitalizeWords(str) {
    return str
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const ProgressBar = ({
    value,
    max,
    label,
    fillColor,
    backgroundColor,
    isDead,
  }) => {
    const percentage = isDead ? 0 : (value / max) * 100;

    return (
      <div className="progress-bar-container">
        <label>
          {label} ({value} / {max})
        </label>
        <div
          className="progress-bar"
          style={{ backgroundColor }}>
          <div
            className="progress-bar-fill"
            style={{
              width: `${percentage}%`,
              backgroundColor: fillColor,
            }}></div>
        </div>
      </div>
    );
  };

  const AttackAnimation = ({ animation }) => {
    const { row, column, weaponType, offsetX, offsetY } = animation;
    const positionStyle = {
      left: `${40 + column * 55 + offsetX}px`,
      top: `${170 + row * 55 + offsetY}px`,
      position: "absolute",
      animation: "fadeAway 0.5s ease-out forwards",
      zIndex: 1000,
    };

    const imageSrc = particleImages[weaponType] || particleImages.particleSmack;

    return (
      <div style={positionStyle}>
        <img
          src={imageSrc}
          alt={weaponType}
          style={{ width: "25px", height: "25px" }}
        />
      </div>
    );
  };

  const handleCloseModal = () => {
    setShowVersionModal(false);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      const modalContent = document.querySelector(".version-modal-content");
      if (modalContent && !modalContent.contains(event.target)) {
        setShowVersionModal(false);
      }
    };

    if (showVersionModal) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showVersionModal]);

  const VersionModal = () => (
    <div
      className="modal"
      style={{ display: showVersionModal ? "block" : "none" }}>
      <div
        className="version-modal-content modal-content"
        onClick={event => event.stopPropagation()}>
        <span
          className="close"
          onClick={handleCloseModal}>
          &times;
        </span>
        <h2>Patch Notes</h2>
        <p>
          If you encounter any bugs, please email me{" "}
          <a href="mailto:nguy1581@gmail.com?subject=Bug%20Report">here</a>.
        </p>

        {versionInfo.map((info, index) => (
          <div key={index}>
            <h2>Version: {info.version}</h2>
            <ul>
              {info.changes.map((change, changeIndex) => (
                <li key={changeIndex}>{change}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
  const DisconnectionModal = () => (
    <div
      className="modal"
      style={{ display: isDisconnectedModalOpen ? "block" : "none" }}>
      <div className="disconnection-modal-content modal-content">
        <span
          className="close"
          onClick={() => setIsDisconnectedModalOpen(false)}>
          &times;
        </span>
        <h2>Connection Lost</h2>
        <p>
          You have lost connection. Please click OK to refresh your connection.
        </p>
        <button onClick={() => window.location.reload()}>OK</button>
      </div>
    </div>
  );

  const handleDirectionClick = direction => {
    setIsChatExpanded(false);
    setLastClicked(null);

    if (ws && ws.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({
        type: "sendCoordinates",
        username: user.username,
        direction: direction,
        coordinates: coordinates,
      });
      ws.send(message);
    } else {
      console.log("WebSocket is not open. Cannot send message.");
    }
  };

  const isMoveValid = direction => {
    const { x, y } = coordinates;
    let newX = x,
      newY = y;

    switch (direction) {
      case "north":
        newY -= 1;
        break;
      case "south":
        newY += 1;
        break;
      case "east":
        newX += 1;
        break;
      case "west":
        newX -= 1;
        break;
      default:
        return false;
    }

    if (
      !worldMapValues ||
      !Array.isArray(worldMapValues) ||
      worldMapValues.length === 0 ||
      !Array.isArray(worldMapValues[0])
    ) {
      return false;
    }

    if (
      newX < 0 ||
      newY < 0 ||
      newX >= worldMapValues[0].length ||
      newY >= worldMapValues.length
    ) {
      return false;
    }

    // Check if the new coordinates are not blocked
    const isValid = worldMapValues[newY][newX] !== null;

    return isValid;
  };

  function CardDescription({ card, onClose }) {
    const isCardEquipped = playerEquippedCards.some(
      equippedCard => equippedCard.name === card.name
    );
    const isCardUnlocked = playerUnlockedCards.includes(card.name);

    const handleEquipCardToggle = () => {
      const action = isCardEquipped ? "unequip" : "equip";

      if (ws && ws.readyState === WebSocket.OPEN) {
        const message = JSON.stringify({
          type: "cardAction",
          action: action,
          cardName: card.name,
        });
        console.log("Sending message:", message);
        ws.send(message);
      } else {
        console.log("WebSocket is not open. Cannot send message.");
      }
    };

    return (
      <div className="modal">
        <div className="card-description modal-content">
          <span
            className="close"
            onClick={onClose}>
            &times;
          </span>
          <h3>{formatName(card.name)}</h3>
          <img
            src={cardImages[card.name.toLowerCase()]}
            alt={card.name}
            style={{ width: "100px", height: "100px" }}
          />
          <p>{card.description}</p>

          <button onClick={handleEquipCardToggle}>
            {isCardEquipped ? "Unequip" : "Equip"}
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="game-container">
      {/* Coordinate information */}
      <p
        style={{
          fontSize: "10px",
          color: "white",
          position: "absolute",
          top: "-10px",
          right: "45px",
        }}>
        Location:&nbsp;({coordinates.x}, {coordinates.y}) <br />
        Biome:&nbsp;{capitalizeWords(zoneBiome)} <br />
        Zone Level:&nbsp;{zoneLevel}
      </p>

      <div>
        {/* Modals */}
        {/* Disconnection Modal */}
        {isDisconnectedModalOpen && <DisconnectionModal />}
        {/* Character Sheet */}
        <button
          onClick={() => setIsCharacterModalOpen(true)}
          style={{ backgroundColor: "black" }}>
          <img
            src={MenuCharacter}
            alt="Character"
            style={{ width: "25px", height: "25px" }}
          />
        </button>
        {isCharacterModalOpen && (
          <div className="modal">
            <div className="character-modal-content modal-content">
              <span
                className="close"
                onClick={() => setIsCharacterModalOpen(false)}>
                &times;
              </span>
              <p>
                &nbsp;{" "}
                <i style={{ textDecoration: "underline" }}>
                  Character Information
                </i>
                <br />
                &nbsp; Name: {user.username} <br />
                &nbsp; Level: {level} <br />
                &nbsp; XP: {currentXP} / {requiredXP} <br />
                &nbsp; Health: {currentHealth} / {maxHealth} <br />
                &nbsp; Mana: {currentMana} / {maxMana} <br />
                &nbsp; Damage: {minimumDamage} - {maximumDamage} <br />
                &nbsp; Defense: {defense} <br />
                &nbsp; Evasion: {evasion} <br />
              </p>
            </div>
          </div>
        )}
        {showVersionModal && <VersionModal />}
        {/* Inventory */}
        <button
          onClick={() => setIsInventoryModalOpen(true)}
          style={{ backgroundColor: "black" }}>
          <img
            src={MenuInventory}
            alt="Inventory"
            style={{ width: "25px", height: "25px" }}
          />
        </button>
        {isInventoryModalOpen && (
          <div className="modal">
            <div className="inventory-modal-content modal-content">
              <span
                className="close"
                onClick={() => setIsInventoryModalOpen(false)}>
                &times;
              </span>
              <h2>Inventory</h2>
              <ul style={{ listStyleType: "none" }}>
                {playerInventory
                  .filter(item => !item.equippable)
                  .map((item, index) => (
                    <li key={index}>
                      <span
                        onClick={() =>
                          setActiveDescriptionIndex(
                            index === activeDescriptionIndex ? null : index
                          )
                        }
                        style={{
                          display: "inline-block",
                          width: "100%",
                          minHeight: "50px",
                          backgroundImage: `url(${
                            itemImages[item.name.toLowerCase()]
                          })`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "left center",
                          paddingLeft: "60px",
                          lineHeight: "50px",
                        }}>
                        {formatName(item.name)}: {item.quantity}
                      </span>
                      {activeDescriptionIndex === index && (
                        <div className="item-description">
                          <span
                            className="description-close"
                            onClick={() => setActiveDescriptionIndex(null)}>
                            &times;
                          </span>
                          {item.description}
                        </div>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}
        {/* Equipment */}
        <button
          onClick={() => setIsEquipmentModalOpen(true)}
          style={{ backgroundColor: "black" }}>
          <img
            src={MenuEquipment}
            alt="Equipment"
            style={{ width: "25px", height: "25px" }}
          />
        </button>
        {isEquipmentModalOpen && (
          <div className="modal">
            <div className="equipment-modal-content modal-content">
              <span
                className="close"
                onClick={() => setIsEquipmentModalOpen(false)}>
                &times;
              </span>
              <h3>Equipped Items</h3>
              <div className="equipment-section">
                <div className="equipment-item">
                  <h4>Weapon</h4>
                  {weapon ? (
                    <div
                      onClick={() =>
                        setActiveEquipmentDescriptionIndex(weapon.id)
                      }>
                      <img
                        src={itemImages[weapon.name]}
                        alt={weapon.name}
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                  ) : (
                    <h6>None</h6>
                  )}
                </div>
                <div className="equipment-item">
                  <h4>Armor</h4>
                  {armor ? (
                    <div
                      onClick={() =>
                        setActiveEquipmentDescriptionIndex(armor.id)
                      }>
                      <img
                        src={itemImages[armor.name]}
                        alt={armor.name}
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                  ) : (
                    <h6>None</h6>
                  )}
                </div>
                <div className="equipment-item">
                  <h4>Accessory</h4>
                  {accessory ? (
                    <div
                      onClick={() =>
                        setActiveEquipmentDescriptionIndex(accessory.id)
                      }>
                      <img
                        src={itemImages[accessory.name]}
                        alt={accessory.name}
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                  ) : (
                    <h6>None</h6>
                  )}
                </div>
                {activeEquipmentDescriptionIndex === weapon?.id && (
                  <EquipmentDescription
                    item={weapon}
                    onClose={() => setActiveEquipmentDescriptionIndex(null)}
                    handleEquipToggle={handleEquipToggle}
                    isItemEquipped={isItemEquipped}
                  />
                )}
                {activeEquipmentDescriptionIndex === armor?.id && (
                  <EquipmentDescription
                    item={armor}
                    onClose={() => setActiveEquipmentDescriptionIndex(null)}
                    handleEquipToggle={handleEquipToggle}
                    isItemEquipped={isItemEquipped}
                  />
                )}
                {activeEquipmentDescriptionIndex === accessory?.id && (
                  <EquipmentDescription
                    item={accessory}
                    onClose={() => setActiveEquipmentDescriptionIndex(null)}
                    handleEquipToggle={handleEquipToggle}
                    isItemEquipped={isItemEquipped}
                  />
                )}
              </div>
              <div className="inventory-section">
                <h3>Equipment</h3>
                <ul>
                  {playerInventory
                    .filter(item => item.equippable)
                    .map((item, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          setActiveEquipmentDescriptionIndex(
                            index === activeEquipmentDescriptionIndex
                              ? null
                              : index
                          )
                        }>
                        <img
                          src={itemImages[item.name.toLowerCase()]}
                          alt={item.name}
                          style={{
                            width: "25px",
                            height: "25px",
                            marginRight: "10px",
                          }}
                        />
                        {formatName(item.name)}
                        {activeEquipmentDescriptionIndex === index && (
                          <EquipmentDescription
                            item={item}
                            onClose={() =>
                              setActiveEquipmentDescriptionIndex(null)
                            }
                            handleEquipToggle={handleEquipToggle}
                            isItemEquipped={isItemEquipped}
                            playerInventory={playerInventory}
                          />
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        {/* Cards */}
        <button
          onClick={() => setIsCardsModalOpen(true)}
          style={{ backgroundColor: "black" }}>
          <img
            src={MenuCards}
            alt="Cards"
            style={{ width: "25px", height: "25px" }}
          />
        </button>
        {isCardsModalOpen && (
          <div className="modal">
            <div className="cards-modal-content modal-content">
              <span
                className="close"
                onClick={() => {
                  if (activeCardDescriptionIndex === null) {
                    setIsCardsModalOpen(false);
                  }
                }}>
                &times;
              </span>
              <h2>Card Deck</h2>
              <ul style={{ listStyleType: "none" }}>
                {playerUnlockedCards.map((card, index) => (
                  <li key={index}>
                    <span
                      onClick={() =>
                        setActiveCardDescriptionIndex(
                          index === activeCardDescriptionIndex ? null : index
                        )
                      }
                      style={{
                        display: "inline-block",
                        width: "100%",
                        minHeight: "50px",
                        backgroundImage: `url(${
                          cardImages[card.name.toLowerCase()]
                        })`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "left center",
                        paddingLeft: "60px",
                        lineHeight: "50px",
                        fontWeight: playerEquippedCards.some(
                          equippedCard => equippedCard.name === card.name
                        )
                          ? "bold"
                          : "normal",
                        fontSize: playerEquippedCards.some(
                          equippedCard => equippedCard.name === card.name
                        )
                          ? "1.2em"
                          : "1em",
                        color: playerEquippedCards.some(
                          equippedCard => equippedCard.name === card.name
                        )
                          ? "orange"
                          : "black",
                        textShadow: playerEquippedCards.some(
                          equippedCard => equippedCard.name === card.name
                        )
                          ? "0 0 10px black"
                          : "none",
                      }}>
                      {formatName(card.name)}
                    </span>
                    {activeCardDescriptionIndex === index && (
                      <CardDescription
                        card={card}
                        onClose={() => setActiveCardDescriptionIndex(null)}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {/* Patch Notes */}
        <button
          onClick={() => setShowVersionModal(!showVersionModal)}
          style={{ backgroundColor: "black" }}>
          <img
            src={MenuPatchNotes}
            alt="Patch Notes"
            style={{ width: "25px", height: "25px" }}
          />
        </button>
        {/* Exit Button */}
        <button
          onClick={() => history.push("/")}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            backgroundColor: "black",
            cursor: "pointer",
          }}>
          <img
            src={MenuExit}
            alt="Exit"
            style={{ width: "25px", height: "25px" }}
          />
        </button>
        {/* Progress Bars */}
        <ProgressBar
          label="Health"
          value={currentHealth}
          max={maxHealth}
          fillColor="#FF0000"
          backgroundColor="#550000"
        />
        <ProgressBar
          label="Experience"
          value={currentXP}
          max={requiredXP}
          fillColor="#FFD700"
          backgroundColor="#333300"
        />
        {/* Map */}
        <div
          style={{
            position: "relative",
            width: `${55 * mapValues[0].length}px`,
            height: `${55 * mapValues.length}px`,
          }}>
          {mapValues.map((row, i) =>
            row.map((cell, j) => {
              const startColor = { r: 65, g: 117, b: 5 };
              const endColor = { r: 0, g: 0, b: 0 };

              const color =
                cell.monster === null
                  ? startColor
                  : {
                      r:
                        startColor.r +
                        (endColor.r - startColor.r) *
                          (1 - cell.percentage / 100),
                      g:
                        startColor.g +
                        (endColor.g - startColor.g) *
                          (1 - cell.percentage / 100),
                      b:
                        startColor.b +
                        (endColor.b - startColor.b) *
                          (1 - cell.percentage / 100),
                    };

              const backgroundColor = `rgb(${color.r}, ${color.g}, ${color.b})`;
              const textColor = cell.percentage <= 50 ? "white" : "black";
              const isLastClicked =
                lastClicked &&
                lastClicked.row === i &&
                lastClicked.column === j;
              const borderStyle = isLastClicked
                ? "3px solid red"
                : "1px solid #ccc";

              let backgroundImage = null;
              if (cell.monster && enemyImages[cell.monster]) {
                backgroundImage = `url(${enemyImages[cell.monster]})`;
              }

              return (
                <button
                  key={`${i}-${j}`}
                  onClick={() => handleButtonClick(i, j)}
                  style={{
                    width: "50px",
                    height: "50px",
                    fontSize: "12px",
                    position: "absolute",
                    left: `${40 + j * 55}px`,
                    top: `${50 + i * 55}px`,
                    backgroundColor: backgroundColor,
                    color: textColor,
                    border: borderStyle,
                    backgroundImage: backgroundImage,
                    backgroundSize: "cover",
                  }}>
                  {/* {cell.percentage} */}
                </button>
              );
            })
          )}
          {/* Directional buttons */}
          <div>
            <button
              onClick={() => handleDirectionClick("east")}
              disabled={!isMoveValid("east")}
              className="directional-button"
              style={{
                position: "absolute",
                top: "47px",
                left: "20px",
                width: "5px",
                height: `${55 * mapValues.length}px`,
                backgroundColor: "grey",
              }}></button>

            <button
              onClick={() => handleDirectionClick("west")}
              disabled={!isMoveValid("west")}
              className="directional-button"
              style={{
                position: "absolute",
                top: "47px",
                right: "-55px",
                width: "5px",
                height: `${55 * mapValues.length}px`,
                backgroundColor: "grey",
              }}></button>

            <button
              onClick={() => handleDirectionClick("north")}
              disabled={!isMoveValid("north")}
              className="directional-button"
              style={{
                position: "absolute",
                top: "33px",
                left: "37px",
                width: `${55 * mapValues[0].length}px`,
                height: "15px",
                backgroundColor: "grey",
              }}></button>

            <button
              onClick={() => handleDirectionClick("south")}
              disabled={!isMoveValid("south")}
              className="directional-button"
              style={{
                position: "absolute",
                bottom: "-63px",
                left: "37px",
                width: `${55 * mapValues[0].length}px`,
                height: "15px",
                backgroundColor: "grey",
              }}></button>
          </div>
        </div>
        {/* Action Buttons */}
        {isDead && (
          <button
            onClick={handleActionReviveClick}
            style={{
              width: "50px",
              height: "50px",
              backgroundImage: `url(${ActionRevive})`,
              backgroundSize: "cover",
              position: "absolute",
              left: "50px",
              top: "480px",
            }}
          />
        )}
        {lastClicked && !isDead && (
          <button
            onClick={handleActionRunClick}
            style={{
              width: "50px",
              height: "50px",
              backgroundImage: `url(${ActionRun})`,
              backgroundSize: "cover",
              position: "absolute",
              left: "105px",
              top: "480px",
            }}
          />
        )}
        {/* Cursors */}
        <div
          className="cursor"
          style={{ position: "absolute", left: "0px", top: "0px" }}>
          {Object.entries(cursorPositions).map(([userId, position]) => (
            <React.Fragment key={userId}>
              <svg
                style={{
                  position: "absolute",
                  zIndex: 200,
                  left: `${position.x + 1}px`,
                  top: `${position.y - 8}px`,
                  pointerEvents: "none",
                }}>
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  fill={userId !== user.username ? "orange" : "black"}
                />
              </svg>
              {position.weapon && cursorImages[position.weapon.name] && (
                <img
                  src={cursorImages[position.weapon.name]}
                  alt={position.weapon.name}
                  style={{
                    position: "absolute",
                    zIndex: 201,
                    left: `${position.x + 1}px`,
                    top: `${position.y - 8}px`,
                    width: "25px",
                    height: "25px",
                    pointerEvents: "none",
                  }}
                />
              )}
              <svg
                style={{
                  position: "absolute",
                  zIndex: 202,
                  left: `${
                    position.x + 7 - (position.username.length - 1) * 2
                  }px`,
                  top: `${position.y + 20}px`,
                  pointerEvents: "none",
                }}
                width="100"
                height="30"
                viewBox="0 0 200 30">
                <text
                  fontSize="16"
                  fill="white"
                  stroke="black"
                  strokeWidth="0.4">
                  {position.username}
                </text>
              </svg>
            </React.Fragment>
          ))}
        </div>
        {activeAnimations.map(animation => (
          <AttackAnimation
            key={animation.id}
            animation={animation}
          />
        ))}
        {/* Chat */}
        <div
          className="chatbox-container"
          style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={() => setIsChatExpanded(!isChatExpanded)}
              className="button-animate"
              style={{ marginRight: "10px" }}>
              {" "}
              {/* Added margin for spacing */}
              {isChatExpanded ? (
                <img
                  src={MenuDown}
                  alt="Minimize Chat"
                  style={{ width: "10px", height: "10px" }}
                />
              ) : (
                <img
                  src={MenuUp}
                  alt="Expand Chat"
                  style={{ width: "10px", height: "10px" }}
                />
              )}
            </button>
            <div
              className="chat-messages-container"
              style={{
                maxHeight:
                  isChatExpanded && chatMessages.length >= 1 ? "110px" : "20px",
                overflowY: "auto",
                transition: "maxHeight 0.3s ease",
                width: "100%", // Ensure it takes the remaining space
              }}>
              {isChatExpanded
                ? [...chatMessages].reverse().map((msg, index) => (
                    <p
                      key={index}
                      style={{
                        color: "black",
                        margin: "0",
                        padding: "0",
                      }}>
                      [{formatLocalTime(msg.timestamp)}] {msg.username}:{" "}
                      {msg.message}
                    </p>
                  ))
                : chatMessages.length > 0 && (
                    <p
                      style={{
                        color: "black",
                        margin: "0",
                        padding: "0",
                      }}>
                      [
                      {formatLocalTime(
                        chatMessages[chatMessages.length - 1].timestamp
                      )}
                      ] {chatMessages[chatMessages.length - 1].username}:{" "}
                      {chatMessages[chatMessages.length - 1].message}
                    </p>
                  )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}>
            <input
              type="text"
              value={chatInput}
              onChange={e => setChatInput(e.target.value)}
              onKeyPress={e => {
                if (e.key === "Enter" && isInputFocused) {
                  handleSendMessage();
                }
              }}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              style={{ flexGrow: 1, marginRight: "10px" }}
              placeholder="Type a message..."
            />
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MORPG;
