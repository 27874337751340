// Card.jsx
import React from "react";
import "./Card.css";

const Faction = {
  Arcanists: "Arcanists",
  Forestkin: "Forestkin",
  Chaos: "Chaos",
  Machinists: "Machinists",
};

const Card = ({
  image,
  name,
  description,
  health,
  attack,
  cost,
  faction,
  maximum_moves,
  isSelected,
  onCardClick,
}) => {
  const cardStyle = isSelected
    ? {
        marginTop: "-40px",
        marginLeft: "-5px",
        marginRight: "-5px",
        border: "7px solid #ECB159",
      }
    : {};

  return (
    <div
      className="unnamed-card"
      style={cardStyle}
      onClick={onCardClick}>
      <div
        className={`unnamed-card-stats unnamed-card-stats-${faction.toLowerCase()}`}>
        <span className="unnamed-card-stat-label">HP</span>
        <span className="unnamed-card-stat-value">{health}</span>
        <span className="unnamed-card-stat-label">ATK</span>
        <span className="unnamed-card-stat-value">{attack}</span>
        <span className="unnamed-card-stat-label">COST</span>
        <span className="unnamed-card-stat-value">{cost}</span>
        <span className="unnamed-card-stat-label">MOVES</span>
        <span className="unnamed-card-stat-value">{maximum_moves}</span>

        <img
          src={image}
          alt={name}
          className={`unnamed-card-image unnamed-card-image-${faction.toLowerCase()}`}
        />
      </div>
      <div
        className={`unnamed-card-content unnamed-card-content-${faction.toLowerCase()}`}>
        <h3 className="unnamed-card-name">{name}</h3>
        <p className="unnamed-card-description">{description}</p>
      </div>
    </div>
  );
};

export default Card;
