import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Phaser from "phaser";
import LoadingScreenScene from "./LoadingScreenScene";
import SpaceSurvivorsScene from "./SpaceSurvivorsScene";
import { GAME_DIMENSIONS } from "./SpaceSurvivorsConstants";

const SpaceSurvivors = () => {
  const user = useSelector(store => store.user);

  useEffect(() => {
    const spaceSurvivorsSceneInstance = new SpaceSurvivorsScene(user);
    const config = {
      type: Phaser.AUTO,
      width: GAME_DIMENSIONS.width,
      height: GAME_DIMENSIONS.height,
      physics: {
        default: "arcade",
      },
      scene: [LoadingScreenScene, spaceSurvivorsSceneInstance],
    };

    const game = new Phaser.Game(config);

    return () => {
      if (spaceSurvivorsSceneInstance.shutdown) {
        spaceSurvivorsSceneInstance.shutdown();
        spaceSurvivorsSceneInstance.destroy();
      }
      game.destroy(true);
    };
  }, [user]);

  return (
    <div
      id="phaser-game"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default SpaceSurvivors;
