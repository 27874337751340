// PhaserGame.jsx
import React, { useEffect } from "react";
import Phaser from "phaser";
import TicTacToeScene from "./TicTacToeScene";
import "./PhaserGame.css";

const PhaserGame = () => {
  useEffect(() => {
    const config = {
      type: Phaser.AUTO,
      width: window.innerWidth, // Use the window's inner width
      height: window.innerHeight, // Use the window's inner height
      scene: TicTacToeScene,
    };

    const game = new Phaser.Game(config);

    return () => {
      game.destroy(true);
    };
  }, []);

  return (
    <div
      id="phaser-game"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default PhaserGame;
