import React from "react";

const TownMenu = ({ townOptions, selectedButtonIndex, onSelect }) => {
  return (
    <div className="town-grid">
      {townOptions.flat().map((townOption, index) => (
        <button
          key={index}
          onClick={() => onSelect(index)}
          className={`couch-menu-button ${
            index === selectedButtonIndex ? "selected" : ""
          }`}>
          {townOption}
        </button>
      ))}
    </div>
  );
};

export default TownMenu;
