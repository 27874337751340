import React from "react";
import { useSelector } from "react-redux";

const BattleAlliesGrid = () => {
  const monsters = useSelector(state => state.elendia.monsters);

  return (
    <div>
      <h3>Allies</h3>
      {monsters.map((monster, index) => (
        <div key={index}>
          <h4>{monster.name}</h4>
          <p>Health: {monster.health}</p>
          <p>Actions: {monster.actions}</p>
        </div>
      ))}
    </div>
  );
};

export default BattleAlliesGrid;
