import React, { useState, useEffect } from "react";
import "./TreasureHunter.css";
import PlayerBoard from "./PlayerBoard";

const TreasureHunter = () => {
  const [currentGridIndex, setCurrentGridIndex] = useState(0);
  const [currentPosition, setCurrentPosition] = useState([0, 0]);

  useEffect(() => {
    const handleKeyDown = e => {
      switch (e.key) {
        case "w":
        case "ArrowUp":
          moveUp();
          break;
        case "a":
        case "ArrowLeft":
          moveLeft();
          break;
        case "s":
        case "ArrowDown":
          moveDown();
          break;
        case "d":
        case "ArrowRight":
          moveRight();
          break;
        case "t":
          clickCurrentCell();
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [currentGridIndex, currentPosition]);

  const grids = [
    { rows: 3, cols: 4 },
    { rows: 5, cols: 2 },
    { rows: 3, cols: 3 },
    { rows: 4, cols: 4 },
    { rows: 3, cols: 3 },
    { rows: 4, cols: 4 },
  ];

  const getCurrentGrid = () => grids[currentGridIndex];

  const moveUp = () => {
    const [row, col] = currentPosition;
    if (row > 0) {
      setCurrentPosition([row - 1, col]);
    } else {
      // Move to previous grid and set position to top left
      setCurrentGridIndex(
        prevIndex => (prevIndex - 1 + grids.length) % grids.length
      );
      setCurrentPosition([0, 0]);
    }
  };

  const moveDown = () => {
    const [row, col] = currentPosition;
    const { rows } = getCurrentGrid();
    if (row < rows - 1) {
      setCurrentPosition([row + 1, col]);
    } else {
      // Move to next grid and set position to top left
      setCurrentGridIndex(prevIndex => (prevIndex + 1) % grids.length);
      setCurrentPosition([0, 0]);
    }
  };

  const moveLeft = () => {
    const [row, col] = currentPosition;
    if (col > 0) {
      setCurrentPosition([row, col - 1]);
    } else {
      // Move to previous grid and set position to top left
      setCurrentGridIndex(
        prevIndex => (prevIndex - 1 + grids.length) % grids.length
      );
      setCurrentPosition([0, 0]);
    }
  };

  const moveRight = () => {
    const [row, col] = currentPosition;
    const { cols } = getCurrentGrid();
    if (col < cols - 1) {
      setCurrentPosition([row, col + 1]);
    } else {
      // Move to next grid and set position to top left
      setCurrentGridIndex(prevIndex => (prevIndex + 1) % grids.length);
      setCurrentPosition([0, 0]);
    }
  };

  const clickCurrentCell = () => {
    console.log(
      `Clicked cell at position ${currentPosition.join(
        ","
      )} in grid ${currentGridIndex}`
    );
    // Implement actual clicking logic here
  };

  return (
    <div className="treasure-hunter">
      {grids.map((grid, index) => (
        <PlayerBoard
          key={`grid-${index}`}
          rows={grid.rows}
          cols={grid.cols}
          focusedCell={index === currentGridIndex ? currentPosition : null}
        />
      ))}
    </div>
  );
};

export default TreasureHunter;
