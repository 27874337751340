import React from "react";
import { useSelector } from "react-redux";

const BattlePlayerCards = () => {
  const selectedCharacter = useSelector(
    state => state.elendia.selectedCharacter
  );

  return (
    <div>
      <h3>Player Cards</h3>
      {selectedCharacter ? (
        <p>
          Cards:{" "}
          {selectedCharacter.monsters[0].cards
            .map(card => card.name)
            .join(", ")}
        </p>
      ) : (
        <p>No character selected</p>
      )}
    </div>
  );
};

export default BattlePlayerCards;
