import Phaser from "phaser";
import SpaceSurvivorsScene from "./SpaceSurvivorsScene";

class LoadingScreenScene extends Phaser.Scene {
  constructor() {
    super({ key: "LoadingScreenScene" });
  }

  preload() {
    // Load assets needed for the loading screen
  }

  create() {
    // Create the loading screen text
    this.add
      .text(175, 300, "Space Survivors", { fontSize: "32px", fill: "#fff" })
      .setOrigin(0.5);

    // Fade out and remove the loading screen after 2 seconds
    this.time.delayedCall(1000, () => {
      this.cameras.main.fadeOut(1000);
      this.time.delayedCall(1000, () => {
        this.scene.start("SpaceSurvivorsScene");
      });
    });
  }
}

export default LoadingScreenScene;
