import Phaser from "phaser";
import { ws, globalUsername } from "../CardMonsters/CardMonstersGlobal";

class CardMonstersScene extends Phaser.Scene {
    constructor(user) {
        super({ key: "CardMonstersScene" });
    }

    init() {
        this.setUsernamePayload;
        this.playerId = null;

        this.username = globalUsername;

        this.ws = ws;
    }

    preload() {}

    create() {
        this.ws.onopen = () => {};

        let fullscreenButton = this.add
            .text(10, 10, "[<->]", { fill: "#fff" })
            .setInteractive()
            .on("pointerdown", () => {
                if (this.scale.isFullscreen) {
                    this.scale.stopFullscreen();
                } else {
                    this.scale.startFullscreen();
                }
            });

        this.add
            .text(
                this.cameras.main.centerX,
                this.cameras.main.centerY,
                "Card Monsters",
                { fontSize: "32px", fill: "#fff" }
            )
            .setOrigin(0.5);

        this.ws.onmessage = (event) => {
            let message = JSON.parse(event.data);

            switch (message.method) {
                default:
                    console.log("Message not recognized.");
                    break;
            }
        };
    }

    shutdown() {
        if (this.ws) {
            this.ws.close();
        }
    }

    destroy() {
        this.shutdown();
    }
}

export default CardMonstersScene;
