import React, { useState } from "react";
import "./Cardosis.css";

const Cardosis = () => {
  const [activeCard, setActiveCard] = useState(null);

  const handleCardClick = index => {
    setActiveCard(index);
  };

  const generateHexagonRow = (isOffset = false) => (
    <div
      className={`cardosis-hexagon-row ${
        isOffset ? "cardosis-hexagon-row-offset" : ""
      }`}>
      {Array.from({ length: 3 }).map((_, index) => (
        <div
          key={index}
          className="cardosis-hexagon"></div>
      ))}
    </div>
  );

  return (
    <div className="cardosis-main">
      <h1>Cardosis</h1>
      <div className="cardosis-boards">
        <div className="cardosis-player-board">
          {generateHexagonRow()}
          {generateHexagonRow(true)}
          {generateHexagonRow()}
          {generateHexagonRow(true)}
          {generateHexagonRow()}
        </div>
        <div className="cardosis-enemy-board">
          {generateHexagonRow()}
          {generateHexagonRow(true)}
          {generateHexagonRow()}
          {generateHexagonRow(true)}
          {generateHexagonRow()}
        </div>
      </div>

      <div className="cardosis-card-container">
        <h1>Cards here</h1>
        {Array.from({ length: 6 }).map((_, index) => (
          <div
            key={index}
            className={`cardosis-card ${
              activeCard === index ? "cardosis-card-active" : ""
            }`}
            onClick={() => handleCardClick(index)}>
            {" "}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cardosis;
