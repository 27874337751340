import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCharacterCreated,
  setCurrentView,
} from "../../redux/ElendiaActions";
import { VIEWS } from "./viewEnums";

const CreateCharacter = () => {
  const dispatch = useDispatch();
  const [characterName, setCharacterName] = useState("");
  const ws = useSelector(state => state.elendia.ws);
  const username = useSelector(state => state.user.username);

  const handleCreateCharacter = () => {
    if (ws) {
      ws.send(
        JSON.stringify({
          type: "CREATE_CHARACTER",
          username,
          characterName,
        })
      );
      dispatch(setCharacterCreated(true));
      dispatch(setCurrentView(VIEWS.BATTLE_MAIN));
    }
  };

  const handleReturnToMainMenu = () => {
    dispatch(setCurrentView(VIEWS.MENU_MAIN));
  };

  return (
    <div>
      <h2>Create Character</h2>
      <button onClick={handleReturnToMainMenu}>Return to Main Menu</button>
      <br />
      <br />
      <input
        type="text"
        value={characterName}
        onChange={e => setCharacterName(e.target.value)}
        placeholder="Enter character name"
      />
      <button onClick={handleCreateCharacter}>Create</button>
    </div>
  );
};

export default CreateCharacter;
