import React, { useState } from "react";
import "./UnnamedGame.css";
import Card from "./Card";
import { cards } from "./cardData.js";

const Faction = {
  Forestkin: "Forestkin",
  Chaos: "Chaos",
  Machinists: "Machinists",
  Arcanists: "Arcanists",
};

const UnnamedGame = () => {
  // these hold references to the tokens via their cardID
  const [playerBoard, setPlayerBoard] = useState(Array(15).fill(null));
  const [enemyBoard, setEnemyBoard] = useState(Array(15).fill(null));

  const [playerStats, setPlayerStats] = useState({
    resource_forestkin: 0,
    resource_chaos: 0,
    resource_machinists: 0,
    resource_arcanists: 0,
    row_1_health: 10,
    row_2_health: 10,
    row_3_health: 10,
    row_4_health: 10,
    row_5_health: 10,
  });
  const [enemyStats, setEnemyStats] = useState({
    resource_forestkin: 0,
    resource_chaos: 0,
    resource_machinists: 0,
    resource_arcanists: 0,
    row_1_health: 10,
    row_2_health: 10,
    row_3_health: 10,
    row_4_health: 10,
    row_5_health: 10,
  });

  // these will hold the actual current state of the tokens on the board such as their
  // remaining moves, health, etc.
  const [dynamicPlayerBoard, setDynamicPlayerBoard] = useState(
    Array(15).fill(null)
  );
  const [dynamicEnemyBoard, setDynamicEnemyBoard] = useState(
    Array(15).fill(null)
  );

  const [playerRows, setPlayerRows] = useState([0, 1, 2, 3, 4]);
  const [enemyRows, setEnemyRows] = useState([0, 1, 2, 3, 4]);

  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [selectedToken, setSelectedToken] = useState({
    cardData: null,
    index: null,
    boardType: null,
  });

  const [playerCards, setPlayerCards] = useState([
    4, 3, 2, 1, 0, 0, 1, 0, 0, 0, 0, 2,
  ]);

  const findCardData = cardID => {
    return cards.find(card => card.cardID === cardID);
  };

  const renderHexagon = (boardType, index) => {
    // const cardID = (boardType === "player" ? playerBoard : enemyBoard)[index];
    const cardData = (boardType === "player" ? playerBoard : enemyBoard)[index];

    const isSelectedToken =
      selectedToken.index === index && selectedToken.boardType === boardType;

    // Determine the dynamic board based on the selectedToken's boardType
    const dynamicBoard =
      selectedToken.boardType === "player"
        ? dynamicPlayerBoard
        : dynamicEnemyBoard;

    // Fetch the selected token's dynamic data from the appropriate dynamic board
    const selectedTokenDynamicData = dynamicBoard[selectedToken.index];

    // Check if the selected token has remaining moves by looking at the dynamic data
    const hasRemainingMoves =
      selectedTokenDynamicData && selectedTokenDynamicData.remaining_moves > 0;

    const isAdjacentToSelectedToken =
      hasRemainingMoves && // Only consider adjacent if the token has remaining moves
      selectedToken.boardType === boardType &&
      getAdjacentFreeHexes(
        selectedToken.index,
        selectedToken.boardType
      ).includes(index);

    return (
      <button
        className={`unnamed-hexagon ${
          isSelectedToken ? "unnamed-hexagon-selected" : ""
        } ${isAdjacentToSelectedToken ? "unnamed-hexagon-adjacent" : ""}`}
        onClick={() => {
          if (cardData !== null) {
            if (activeCardIndex !== null) {
              setActiveCardIndex(null);
              setSelectedToken({
                cardData: null,
                index: null,
                boardType: null,
              });
            } else {
              if (
                selectedToken.index === index &&
                selectedToken.boardType === boardType
              ) {
                setSelectedToken({
                  cardData: null,
                  index: null,
                  boardType: null,
                });
              } else {
                setSelectedToken({ cardData, index, boardType });
              }
            }
          }
          // Check if the clicked hex is a valid move for the selected token
          if (
            isAdjacentToSelectedToken &&
            selectedToken.cardData &&
            ((boardType === "player" &&
              dynamicPlayerBoard[selectedToken.index] &&
              dynamicPlayerBoard[selectedToken.index].remaining_moves >= 1) ||
              (boardType === "enemy" &&
                dynamicEnemyBoard[selectedToken.index] &&
                dynamicEnemyBoard[selectedToken.index].remaining_moves >= 1))
          ) {
            // Determine the board we're working with
            const board = boardType === "player" ? playerBoard : enemyBoard;
            const dynamicBoard =
              boardType === "player" ? dynamicPlayerBoard : dynamicEnemyBoard;
            const newBoard = [...board];
            const newDynamicBoard = [...dynamicBoard];

            // Move the token: set the current position to null and update the new position
            // Since we're now storing the entire cardData object, we move the whole object
            const movedCardData = newBoard[selectedToken.index]; // Store the cardData object being moved
            newBoard[selectedToken.index] = null; // Remove from current position
            newBoard[index] = movedCardData; // Place the entire cardData object in new position

            // Update the dynamic board: decrease remaining_moves and move the token stats
            const tokenStats = {
              ...newDynamicBoard[selectedToken.index],
              remaining_moves:
                newDynamicBoard[selectedToken.index].remaining_moves - 1,
            };
            newDynamicBoard[selectedToken.index] = null; // Remove stats from current position
            newDynamicBoard[index] = tokenStats; // Place stats in new position

            // Update the board state
            if (boardType === "player") {
              setPlayerBoard(newBoard);
              setDynamicPlayerBoard(newDynamicBoard);
            } else {
              setEnemyBoard(newBoard);
              setDynamicEnemyBoard(newDynamicBoard);
            }

            // Reset selectedToken state
            setSelectedToken({
              cardData: null,
              index: null,
              boardType: null,
            });

            console.log(`${boardType}Board hexagon clicked:`, index);
          }
          // creating a token on the board
          if (activeCardIndex !== null) {
            const activeCardID = playerCards[activeCardIndex];
            const targetSpace = (
              boardType === "player" ? playerBoard : enemyBoard
            )[index];

            if (targetSpace === null) {
              const cardData = cards.find(card => card.cardID === activeCardID);
              const tokenStats = {
                remaining_moves: cardData.maximum_moves,
                // Add other stats here as needed
              };

              if (boardType === "player") {
                const newPlayerBoard = [...playerBoard];

                // newPlayerBoard[index] = activeCardID;
                newPlayerBoard[index] = cardData;
                setPlayerBoard(newPlayerBoard);

                const newDynamicPlayerBoard = [...dynamicPlayerBoard];
                newDynamicPlayerBoard[index] = tokenStats;
                setDynamicPlayerBoard(newDynamicPlayerBoard);

                const newPlayerCards = playerCards.filter(
                  (_, i) => i !== activeCardIndex
                );
                setPlayerCards(newPlayerCards);

                setActiveCardIndex(null);
              } else if (boardType === "enemy") {
                const newEnemyBoard = [...enemyBoard];
                // newEnemyBoard[index] = activeCardID;
                newEnemyBoard[index] = cardData;
                setEnemyBoard(newEnemyBoard);

                const newDynamicEnemyBoard = [...dynamicEnemyBoard];
                newDynamicEnemyBoard[index] = tokenStats;
                setDynamicEnemyBoard(newDynamicEnemyBoard);

                const newPlayerCards = playerCards.filter(
                  (_, i) => i !== activeCardIndex
                );
                setPlayerCards(newPlayerCards);
                setActiveCardIndex(null);
              }
              console.log(
                playerBoard,
                enemyBoard,
                dynamicPlayerBoard,
                dynamicEnemyBoard
              );
            } else {
              console.log("Space is already occupied.");
            }
          }
        }}>
        {cardData && (
          <img
            src={cardData.token_image}
            alt={cardData.name}
            className={`unnamed-card-token unnamed-card-token-${cardData.faction.toLowerCase()}`}
          />
        )}
      </button>
    );
  };

  const renderHexagonRow = (boardType, row, isPlayer) => {
    // Determine the stats object based on the boardType
    const stats = boardType === "player" ? playerStats : enemyStats;

    // Assuming each row corresponds to a specific stat, you can map rows to stats like this:
    // This is just an example. Adjust the mapping according to your actual game logic.
    const rowToStatMap = {
      0: "row_1_health",
      1: "row_2_health",
      2: "row_3_health",
      3: "row_4_health",
      4: "row_5_health",
    };

    // Get the stat for the current row
    const statKey = rowToStatMap[row];
    const statValue = stats[statKey];

    // Define inline styles for enemy text
    const enemyTextStyle = {
      display: "inline-block", // Ensure the text is inline
      transform: "scaleX(-1)", // Flip the text horizontally
    };

    return (
      <div
        key={row}
        className="unnamed-hexagon-row">
        <button
          className={`unnamed-grey-square ${
            boardType === "enemy" ? "enemy-square" : ""
          }`}
          onClick={() =>
            console.log(
              `${isPlayer ? "playerRows" : "enemyRows"} row clicked:`,
              row
            )
          }>
          {/* Display the stat value as text inside the button with inline styles */}
          <span style={boardType === "enemy" ? enemyTextStyle : {}}>
            {statValue}
          </span>
        </button>
        <div
          className={`unnamed-hexagon-container ${
            row % 2 === 0 ? "" : "offset"
          }`}>
          {Array.from({ length: 3 }, (_, i) =>
            renderHexagon(boardType, row * 3 + i)
          )}
        </div>
      </div>
    );
  };

  const renderBoard = boardType => {
    const rows = [];
    const isPlayer = boardType === "player";
    const rowsState = isPlayer ? playerRows : enemyRows;
    for (let i = 0; i < rowsState.length; i++) {
      rows.push(renderHexagonRow(boardType, rowsState[i], isPlayer));
    }
    return <div>{rows}</div>;
  };

  const renderCards = () => {
    return playerCards.map((cardID, index) => {
      const cardData = findCardData(cardID);
      const zIndex = playerCards.length - index;
      return cardData ? (
        <div
          key={index}
          className={`unnamed-cards__item ${
            activeCardIndex === index ? "active" : ""
          }`}
          style={{
            marginLeft: `${
              index * 12.5 -
              (((playerCards.length - 8 > 0) *
                (playerCards.length - 8) *
                12.5) /
                (playerCards.length - 1)) *
                index
            }%`,
            zIndex:
              activeCardIndex === index
                ? 1000
                : hoveredCardIndex === index
                ? 999
                : zIndex,
          }}
          onClick={() => {
            if (activeCardIndex === index) {
              setActiveCardIndex(null);
              setSelectedToken({
                cardData: null,
                index: null,
                boardType: null,
              });
            } else {
              setActiveCardIndex(index);
              setSelectedToken({
                cardData: null,
                index: null,
                boardType: null,
              });
            }
          }}
          onMouseEnter={() => setHoveredCardIndex(index)}
          onMouseLeave={() => setHoveredCardIndex(null)}>
          <Card
            image={cardData.card_image}
            name={cardData.name}
            description={cardData.description}
            health={cardData.health}
            attack={cardData.attack}
            cost={cardData.cost}
            faction={cardData.faction}
            maximum_moves={cardData.maximum_moves}
            isSelected={activeCardIndex === index}
          />
        </div>
      ) : null;
    });
  };

  const getAdjacentFreeHexes = (index, boardType) => {
    const adjacentIndices = [];
    const totalHexes = 15; // Total number of hexes in the grid
    const rowLength = 3; // Number of hexes in a row

    // Determine the row and column of the current hex
    const row = Math.floor(index / rowLength);
    const col = index % rowLength;

    // Check if the row is even or odd
    const isEvenRow = row % 2 === 0;

    // Determine the board we're working with
    const board = boardType === "player" ? playerBoard : enemyBoard;

    // Top left
    if (
      (col > 0 || (!isEvenRow && col === 0)) &&
      board[index - rowLength + (isEvenRow ? -1 : 0)] === null
    ) {
      adjacentIndices.push(index - rowLength + (isEvenRow ? -1 : 0));
    }

    // Top right
    if (
      (col < rowLength - 1 || (isEvenRow && col === rowLength - 1)) &&
      board[index - rowLength + (isEvenRow ? 0 : 1)] === null
    ) {
      adjacentIndices.push(index - rowLength + (isEvenRow ? 0 : 1));
    }

    // Right
    if (col < rowLength - 1 && board[index + 1] === null) {
      adjacentIndices.push(index + 1);
    }

    // Bottom right
    if (
      (col < rowLength - 1 || (isEvenRow && col === rowLength - 1)) &&
      board[index + rowLength + (isEvenRow ? 0 : 1)] === null
    ) {
      adjacentIndices.push(index + rowLength + (isEvenRow ? 0 : 1));
    }

    // Bottom left
    if (
      (col > 0 || (!isEvenRow && col === 0)) &&
      board[index + rowLength + (isEvenRow ? -1 : 0)] === null
    ) {
      adjacentIndices.push(index + rowLength + (isEvenRow ? -1 : 0));
    }

    // Left
    if (col > 0 && board[index - 1] === null) {
      adjacentIndices.push(index - 1);
    }

    // Filter out invalid indices (e.g., negative indices or indices greater than the total number of hexes)
    return adjacentIndices.filter(i => i >= 0 && i < totalHexes);
  };

  const getAdjacentHexes = index => {
    const adjacentIndices = [];
    const totalHexes = 15; // Total number of hexes in the grid
    const rowLength = 3; // Number of hexes in a row

    // Determine the row and column of the current hex
    const row = Math.floor(index / rowLength);
    const col = index % rowLength;

    // Check if the row is even or odd
    const isEvenRow = row % 2 === 0;

    // Top left
    if (col > 0 || (!isEvenRow && col === 0)) {
      adjacentIndices.push(index - rowLength + (isEvenRow ? -1 : 0));
    }

    // Top right
    if (col < rowLength - 1 || (isEvenRow && col === rowLength - 1)) {
      adjacentIndices.push(index - rowLength + (isEvenRow ? 0 : 1));
    }

    // Right
    if (col < rowLength - 1) {
      adjacentIndices.push(index + 1);
    }

    // Bottom right
    if (col < rowLength - 1 || (isEvenRow && col === rowLength - 1)) {
      adjacentIndices.push(index + rowLength + (isEvenRow ? 0 : 1));
    }

    // Bottom left
    if (col > 0 || (!isEvenRow && col === 0)) {
      adjacentIndices.push(index + rowLength + (isEvenRow ? -1 : 0));
    }

    // Left
    if (col > 0) {
      adjacentIndices.push(index - 1);
    }

    // Filter out invalid indices (e.g., negative indices or indices greater than the total number of hexes)
    return adjacentIndices.filter(i => i >= 0 && i < totalHexes);
  };

  const cycleCards = direction => {
    // Check if there are any cards left
    if (playerCards.length === 0) {
      setActiveCardIndex(null);
      return; // Exit the function early
    }

    const lastIndex = playerCards.length - 1;
    if (activeCardIndex === null) {
      // If there's no active card, set the active card to the first or last item
      setActiveCardIndex(direction === 1 ? 0 : lastIndex);
    } else {
      // Calculate the new index based on the direction
      let newIndex = activeCardIndex + direction;
      // Ensure the new index is within the bounds of the array
      if (newIndex < 0) {
        newIndex = lastIndex; // Wrap around to the last item
      } else if (newIndex > lastIndex) {
        newIndex = 0; // Wrap around to the first item
      }
      setActiveCardIndex(newIndex);
    }
  };

  return (
    <div>
      <div className="unnamed-game-info-container">
        <div className="unnamed-player-name-box">Player Name</div>
        <div className="unnamed-round-timer-box">Time: 00:00</div>
        <div className="unnamed-enemy-name-box">Enemy Name</div>
      </div>
      <div className="unnamed-boards-container">
        <div className="unnamed-board unnamed-player-board">
          {renderBoard("player")}
        </div>
        <div className="unnamed-board unnamed-enemy-board unnamed-flip-horizontal">
          {renderBoard("enemy")}
        </div>
        <div className="info-display">
          Player Stats:{"  "}
          <ul>
            {Object.entries(playerStats).map(([key, value]) => (
              <li key={key}>
                {key}: {value},
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="unnamed-player-cards">
        <div className="unnamed-demo__cards">
          <div className="unnamed-cards">{renderCards()}</div>
        </div>
      </div>
      <div>
        <button onClick={() => cycleCards(-1)}>Previous Card</button>
        <button onClick={() => cycleCards(1)}>Next Card</button>
      </div>
    </div>
  );
};

export default UnnamedGame;
