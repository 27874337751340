import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DeckBuilder from "./DeckBuilder";
import ATTACK_SYMBOL from "./assets/attack_symbol.svg";
import HEALTH_SYMBOL from "./assets/health_symbol.svg";
import COOLDOWN_SYMBOL from "./assets/cooldown_symbol.svg";
import { ReactComponent as DrawTwoSymbol } from "./assets/draw_two_symbol.svg";
import { ReactComponent as GainResourceSymbol } from "./assets/gain_resource_symbol.svg";

import CRYSTAL_01 from "./assets/crystal_01.png";
import CRYSTAL_02 from "./assets/crystal_02.png";
import CRYSTAL_03 from "./assets/crystal_03.png";
import CRYSTAL_04 from "./assets/crystal_04.png";
import CRYSTAL_05 from "./assets/crystal_05.png";

const CRYSTAL_SIZE = 60;
const CRYSTAL_SPACING_Y = 5;
const OPPONENT_CRYSTAL_OFFSET_X = 65;

function ParchmentGame() {
  const user = useSelector(store => store.user);
  const [ws, setWs] = useState(null);

  const [CARD_DATABASE, setCardDatabase] = useState(null);

  const [players, setPlayers] = useState([]);
  const [inQueue, setInQueue] = useState(false);
  const [isInGame, setIsInGame] = useState(false);

  const [roomId, setRoomId] = useState(null);
  const [pendingRejoinRoomId, setPendingRejoinRoomId] = useState(null);
  const [opponent, setOpponent] = useState(null);
  const [isInRoom, setIsInRoom] = useState(false);
  const [opponentInRoom, setOpponentInRoom] = useState(false);

  const [myBoard, setMyBoard] = useState(null);
  const [opponentBoard, setOpponentBoard] = useState(null);

  const [myResources, setMyResources] = useState({
    nature: { current: 0, max: 0 },
    mechanical: { current: 0, max: 0 },
    vanguard: { current: 0, max: 0 },
  });

  const [opponentResources, setOpponentResources] = useState({
    nature: { current: 0, max: 0 },
    mechanical: { current: 0, max: 0 },
    vanguard: { current: 0, max: 0 },
  });

  const [mySacrificeUsed, setMySacrificeUsed] = useState(false);
  const [amPlayer1, setAmPlayer1] = useState(null);
  const [currentTurnPlayer, setCurrentTurnPlayer] = useState(null);
  const [highlightedAttackers, setHighlightedAttackers] = useState([]);
  const [highlightedVictims, setHighlightedVictims] = useState([]);
  const [validMoves, setValidMoves] = useState([]);

  const [myHand, setMyHand] = useState([]);
  const [myDeckCount, setMyDeckCount] = useState(0);
  const [myDiscard, setMyDiscard] = useState([]);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [cardPlayingLog, setCardPlayingLog] = useState([]);
  const [showCardPlayingLogModal, setShowCardPlayingLogModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedTargets, setSelectedTargets] = useState([]);

  const [gameOver, setGameOver] = useState(false);
  const [winner, setWinner] = useState(null);

  useEffect(() => {
    let newWs;
    let environment;

    if (window.location.href.includes("localhost")) {
      environment = "localhost";
    } else if (window.location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (window.location.href.includes("2nguyen.dev")) {
      environment = "production";
    }

    switch (environment) {
      case "localhost":
        newWs = new WebSocket("ws://localhost:5000/parchment-game");
        break;
      case "localNetwork":
        newWs = new WebSocket("ws://192.168.0.7:5000/parchment-game");
        break;
      case "production":
        newWs = new WebSocket("wss://2nguyen.dev/parchment-game");
        break;
      default:
        console.log("Environment not recognized. WebSocket not established.");
        break;
    }

    if (!newWs) return;

    newWs.onopen = () => {
      newWs.send(
        JSON.stringify({
          type: "account",
          action: "user",
          user: user.username,
        })
      );

      newWs.send(
        JSON.stringify({
          type: "card",
          action: "getDatabase",
        })
      );

      setWs(newWs);
    };

    newWs.onclose = () => {
      setWs(null);
      setIsInGame(false);
      setRoomId(null);
      setOpponent(null);

      setMyBoard(null);
      setOpponentBoard(null);
      setMyResources(0);
      setOpponentResources(0);
      setCurrentTurnPlayer(null);
    };

    newWs.onmessage = event => {
      const data = JSON.parse(event.data);

      switch (`${data.type}:${data.action}`) {
        case "account:playersList":
          setPlayers(data.players || []);
          break;

        case "account:boot":
          alert(data.message || "Logged out from this session");
          window.location.href = "/";
          break;

        case "card:database":
          setCardDatabase(data.cardDatabase);
          break;

        case "game:matchFound":
        case "game:soloMatchFound":
          setIsInGame(true);
          setRoomId(data.roomId);
          setOpponent(data.opponent);
          if (typeof data.opponentInRoom === "boolean") {
            setOpponentInRoom(data.opponentInRoom);
          } else {
            setOpponentInRoom(true);
          }

          setMyBoard(data.myBoard);
          setOpponentBoard(data.opponentBoard);
          setMyResources(data.myResources);
          setOpponentResources(data.opponentResources);
          setCurrentTurnPlayer(data.currentTurnPlayer);

          setAmPlayer1(data.amPlayer1);

          setMyHand(data.myHand || []);
          setMyDiscard(data.myDiscard || []);
          setMyDeckCount(data.myDeckCount || 0);

          setHighlightedAttackers([]);
          setHighlightedVictims([]);
          break;

        case "game:updateState": {
          setMyBoard(data.myBoard);
          setOpponentBoard(data.opponentBoard);
          setMyResources(data.myResources);
          setOpponentResources(data.opponentResources);
          setCurrentTurnPlayer(data.currentTurnPlayer);

          if (data.recentAttacks && data.recentAttacks.length > 0) {
            const newAttackers = [];
            const newVictims = [];

            data.recentAttacks.forEach(attack => {
              newAttackers.push(attack.attacker);
              newVictims.push(attack.victim);
            });

            setHighlightedAttackers(newAttackers);
            setHighlightedVictims(newVictims);
          }

          setMyHand(data.myHand || []);
          setMyDiscard(data.myDiscard || []);
          setMyDeckCount(data.myDeckCount || 0);
          setMySacrificeUsed(data.sacrificeUsedPlayer);
          break;
        }

        case "game:viewDiscardResponse":
          setMyDiscard(data.discard);
          setShowDiscardModal(true);
          break;

        case "game:viewCardPlayingLogResponse":
          setCardPlayingLog(data.cardPlayingLog);
          setShowCardPlayingLogModal(true);
          break;

        case "game:gameOver":
          setGameOver(true);
          setWinner(data.winner);
          setIsInRoom(false);
          setOpponentInRoom(false);
          break;

        case "game:opponentLeft":
          setGameOver(true);
          setWinner(user.username);
          alert("Your opponent left the game. You win by default!");
          break;

        case "game:leftGame":
          console.log(data.message);
          break;

        case "game:resumePossible":
          setPendingRejoinRoomId(data.roomId);
          break;

        case "game:roomExpired":
          setPendingRejoinRoomId(null);
          break;

        case "game:updatePlayerRoomStatus":
          setIsInRoom(data.inRoom);
          break;

        case "game:opponentRoomStatus":
          setOpponentInRoom(data.inRoom);
          break;

        case "game:opponentDisconnectedFromRoom":
          setOpponentInRoom(false);
          break;

        case "game:opponentRejoined":
          setOpponentInRoom(true);
          break;

        case "game:updateInRoomStatus":
          setIsInRoom(data.inRoom);
          break;

        case "game:updateOpponentInRoomStatus":
          setOpponentInRoom(data.inRoom);
          break;

        default:
          break;
      }
    };

    return () => {
      newWs.close();
    };
  }, [user.username]);

  function handleReconnect() {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "game",
        action: "resume",
        roomId: pendingRejoinRoomId,
      })
    );
  }

  function handleStartSoloGame() {
    if (pendingRejoinRoomId) {
      const userConfirmed = window.confirm(
        "You have a game in progress. Starting a new solo game will forfeit the current game. Do you want to continue?"
      );
      if (!userConfirmed) {
        return;
      }

      ws.send(
        JSON.stringify({
          type: "game",
          action: "leave",
          roomId: pendingRejoinRoomId,
        })
      );

      setPendingRejoinRoomId(null);
    }

    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          type: "matchmaking",
          action: "solo",
        })
      );
    }
  }

  const handleJoinQueue = () => {
    if (pendingRejoinRoomId) {
      const userConfirmed = window.confirm(
        "You have a game in progress. Joining a new queue will forfeit the current game. Do you want to continue?"
      );
      if (!userConfirmed) {
        return;
      }

      ws.send(
        JSON.stringify({
          type: "game",
          action: "leave",
          roomId: pendingRejoinRoomId,
        })
      );

      setPendingRejoinRoomId(null);
    }

    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ type: "matchmaking", action: "join" }));
      setInQueue(true);
    }
  };

  const handleLeaveQueue = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ type: "matchmaking", action: "leave" }));
      setInQueue(false);
    }
  };

  const handleLeaveGame = () => {
    if (roomId) {
      ws.send(
        JSON.stringify({
          type: "game",
          action: "leave",
          roomId,
        })
      );
    }

    setIsInGame(false);
    setIsInRoom(false);
    setPendingRejoinRoomId(null);
    setOpponentInRoom(false);
    setRoomId(null);
    setOpponent(null);
    setMyBoard(null);
    setOpponentBoard(null);
    setMyResources(0);
    setOpponentResources(0);
    setCurrentTurnPlayer(null);
    setInQueue(false);
    setMyHand([]);
    setMyDiscard([]);
    setMyDeckCount(0);
    setSelectedCard(null);
    setShowDiscardModal(false);
    setHighlightedAttackers([]);
    setHighlightedVictims([]);

    setGameOver(false);
    setWinner(null);
  };

  const handleSelectCard = (cardType, index) => {
    if (selectedCard?.cardType === cardType && selectedCard?.index === index) {
      setSelectedCard(null);
    } else {
      setSelectedCard({ cardType, index });
    }
  };

  const handlePlaceUnit = (columnIndex, cellIndex, isMyBoard) => {
    if (!selectedCard) return;
    if (!ws || !roomId) return;

    const { cardType } = selectedCard;

    let boardName = "";
    if (amPlayer1) {
      boardName = isMyBoard ? "player1" : "player2";
    } else {
      boardName = isMyBoard ? "player2" : "player1";
    }

    ws.send(
      JSON.stringify({
        type: "game",
        action: "placeUnit",
        roomId,
        pieceType: cardType,
        columnIndex,
        cellIndex,
        targetBoard: boardName,
      })
    );

    setHighlightedAttackers([]);
    setHighlightedVictims([]);
    setSelectedCard(null);
  };

  const handleEndTurn = () => {
    if (!ws || !roomId) return;

    ws.send(
      JSON.stringify({
        type: "game",
        action: "skipTurn",
        roomId,
      })
    );

    setHighlightedAttackers([]);
    setHighlightedVictims([]);
  };

  const isMyTurn = currentTurnPlayer === user.username;

  let selectedCardStats = null;
  if (selectedCard?.cardType) {
    selectedCardStats = CARD_DATABASE[selectedCard.cardType] || null;
  }

  if (gameOver && isInGame) {
    return (
      <div style={{ textAlign: "center" }}>
        <h1>Game Over</h1>
        <h2>Winner: {winner}</h2>

        <div style={styles.gameContainer}>
          <div style={styles.boardWrapper}>
            <h3>{user.username}</h3>
            {myBoard && renderBoard(myBoard, true)}
          </div>
          <div style={styles.boardWrapper}>
            <h3>{opponent}</h3>
            {opponentBoard && renderBoard(opponentBoard, false)}
          </div>
        </div>

        <button onClick={handleLeaveGame}>Return to Lobby</button>
      </div>
    );
  }

  if (!isInGame) {
    return (
      <div style={styles.lobbyContainer}>
        <h1>Parchment Game</h1>

        {gameOver && (
          <div>
            <h2>Your opponent forfeited the previous game.</h2>
          </div>
        )}

        <h3>Online Players</h3>
        <div
          style={{
            ...styles.playerList,
            overflowY: players.length > 5 ? "scroll" : "visible",
          }}>
          {players.map((playerName, idx) => (
            <div key={idx}>{playerName}</div>
          ))}
        </div>

        {pendingRejoinRoomId && (
          <button onClick={handleReconnect}>Reconnect To My Last Game</button>
        )}

        <button onClick={handleStartSoloGame}>Play Solo</button>

        {!inQueue ? (
          <button
            style={{ display: "block", margin: "0 auto" }}
            onClick={handleJoinQueue}>
            Queue for a Game
          </button>
        ) : (
          <button
            style={{ display: "block", margin: "0 auto" }}
            onClick={handleLeaveQueue}>
            Queued for a Game... Click Again To Stop Queueing
          </button>
        )}

        <DeckBuilder
          ws={ws}
          cardDatabase={CARD_DATABASE}
        />
      </div>
    );
  }

  const handleCardAction = action => {
    if (!selectedCard || !ws || !roomId) return;

    switch (action) {
      case "play":
        break;

      case "sacrificeForResources":
        ws.send(
          JSON.stringify({
            type: "game",
            action: "sacrificeCardForResources",
            roomId,
            cardType: selectedCard.cardType,
            index: selectedCard.index,
          })
        );
        setSelectedCard(null);
        break;

      case "sacrificeForCards":
        ws.send(
          JSON.stringify({
            type: "game",
            action: "sacrificeCardForCards",
            roomId,
            cardType: selectedCard.cardType,
            index: selectedCard.index,
          })
        );
        setSelectedCard(null);
        break;

      default:
        break;
    }
  };

  return (
    <div style={styles.container}>
      {showDiscardModal && (
        <div style={modalStyle}>
          <h3>Discard Pile</h3>
          {myDiscard && myDiscard.length === 0 ? (
            <div>No discarded cards yet.</div>
          ) : (
            myDiscard &&
            myDiscard.map((cardType, idx) => <div key={idx}>{cardType}</div>)
          )}
          <button onClick={() => setShowDiscardModal(false)}>Close</button>
        </div>
      )}

      <button
        onClick={handleLeaveGame}
        style={{ marginTop: "1rem" }}>
        Forfeit Game and Return to Lobby
      </button>

      {showCardPlayingLogModal && (
        <div style={cardPlayingLogModalStyle}>
          <h3>Card Playing Log</h3>
          {cardPlayingLog && cardPlayingLog.length === 0 ? (
            <div>No cards played yet.</div>
          ) : (
            cardPlayingLog.map((entry, idx) => (
              <div key={idx}>
                <strong>{entry.actionType}</strong> - {entry.message} <br />
                <small>{entry.timestamp}</small>
              </div>
            ))
          )}
          <button onClick={() => setShowCardPlayingLogModal(false)}>
            Close
          </button>
        </div>
      )}

      {!isInRoom && (
        <div style={{ color: "gray" }}>You are currently not in a room.</div>
      )}
      {isInRoom && !opponentInRoom && (
        <div style={{ color: "red" }}>
          Your opponent is disconnected from the room.
        </div>
      )}
      {isInRoom && opponentInRoom && (
        <div style={{ color: "green" }}>
          Both you and your opponent are in the room!
        </div>
      )}

      <div style={styles.turnIndicator}>
        {isMyTurn ? <h2>Your Turn</h2> : <h2>{opponent}'s Turn</h2>}
      </div>
      {isMyTurn && (
        <div style={{ marginTop: 16 }}>
          <button
            style={{ fontSize: "1.2rem" }}
            onClick={handleEndTurn}>
            End Turn
          </button>
        </div>
      )}
      <button
        style={{ fontSize: "0.5rem" }}
        onClick={handleViewDiscardPile}>
        View Discard Pile
      </button>

      <button
        style={{ fontSize: "0.5rem" }}
        onClick={handleViewCardPlayingLog}>
        View Card Playing Log
      </button>

      <div style={styles.deckCount}>
        <strong>Cards Remaining in Deck:</strong> {myDeckCount}
      </div>
      <div style={styles.gameContainer}>
        <div style={styles.boardWrapper}>
          <h3>{user.username}</h3>
          {myBoard && renderBoard(myBoard, true)}
          <div>
            {myResources.nature.max > 0 && (
              <div>
                Nature: {myResources.nature.current} / {myResources.nature.max}
              </div>
            )}
            {myResources.mechanical.max > 0 && (
              <div>
                Mechanical: {myResources.mechanical.current} /{" "}
                {myResources.mechanical.max}
              </div>
            )}
            {myResources.vanguard.max > 0 && (
              <div>
                Vanguard: {myResources.vanguard.current} /{" "}
                {myResources.vanguard.max}
              </div>
            )}
          </div>
        </div>

        <div style={styles.boardWrapper}>
          <h3>{opponent}</h3>
          {opponentBoard && renderBoard(opponentBoard, false)}
          <div>
            {opponentResources.nature.max > 0 && (
              <div>
                Nature: {opponentResources.nature.current} /{" "}
                {opponentResources.nature.max}
              </div>
            )}
            {opponentResources.mechanical.max > 0 && (
              <div>
                Mechanical: {opponentResources.mechanical.current} /{" "}
                {opponentResources.mechanical.max}
              </div>
            )}
            {opponentResources.vanguard.max > 0 && (
              <div>
                Vanguard: {opponentResources.vanguard.current} /{" "}
                {opponentResources.vanguard.max}
              </div>
            )}
          </div>
        </div>
      </div>
      {selectedCard && selectedCardStats && (
        <div style={cardStatsModalStyle}>
          <span style={{ fontSize: "0.6rem" }}>
            <strong>
              {selectedCard.cardType.replace(/_/g, " ").toUpperCase()}
            </strong>{" "}
            HP: {selectedCardStats.maximumHealth}, ATK:{" "}
            {selectedCardStats.maximumAttack}, CD:{" "}
            {selectedCardStats.maximumCooldown}, $: {selectedCardStats.cost},
            Type: {selectedCardStats.resourceType.replace(/_/g, " ")}{" "}
          </span>
        </div>
      )}
      {!gameOver && (
        <div style={styles.actionPanel}>
          {selectedCard && (
            <div style={{ marginTop: 12 }}>
              <div className="cardActionOptions">
                <ActionButton
                  onClick={() => handleCardAction("sacrificeForResources")}
                  disabled={!isMyTurn || mySacrificeUsed}
                  ariaLabel="Sacrifice for Resources"
                  IconComponent={GainResourceSymbol}
                />

                <ActionButton
                  onClick={() => handleCardAction("sacrificeForCards")}
                  disabled={!isMyTurn || mySacrificeUsed}
                  ariaLabel="Sacrifice to Draw 2 Cards"
                  IconComponent={DrawTwoSymbol}
                />
                <br />
              </div>
            </div>
          )}
        </div>
      )}
      <div style={styles.handContainer}>
        {myHand && myHand.length > 0 ? (
          myHand.map((cardType, index) => {
            const isSelected =
              selectedCard?.cardType === cardType &&
              selectedCard?.index === index;

            const cardData = CARD_DATABASE ? CARD_DATABASE[cardType] : null;

            return (
              <div
                key={index}
                style={{
                  ...styles.card,
                  outline: isSelected
                    ? "4px solid limegreen"
                    : "4px solid black",
                  zIndex: isSelected ? 999 : 1,
                  transform: isSelected ? "translateY(-20px)" : "translateY(0)",
                }}
                onClick={() => handleSelectCard(cardType, index)}>
                {cardData && (
                  <img
                    src={cardData.image}
                    alt={cardType}
                    style={styles.cardImage}
                  />
                )}
              </div>
            );
          })
        ) : (
          <div>No cards in hand.</div>
        )}
      </div>
    </div>
  );

  function HexCell({ color, children }) {
    const points = "40,0 74.64,20 74.64,60 40,80 5.36,60 5.36,20";

    return (
      <div style={{ position: "relative", width: 80, height: 80 }}>
        <svg
          width="80"
          height="80">
          <polygon
            points={points}
            fill={color}
            stroke="black"
            strokeWidth="2"
          />
        </svg>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {children}
        </div>
      </div>
    );
  }

  function SquareCell({ color, children }) {
    return (
      <div
        style={{
          position: "relative",
          width: CRYSTAL_SIZE,
          height: CRYSTAL_SIZE,
          backgroundColor: color,
          border: "2px solid black",
          boxSizing: "border-box",
          zIndex: 10,
        }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {children}
        </div>
      </div>
    );
  }

  function renderBoard(board, isMyBoard) {
    const displayedBoard = isMyBoard ? board : [...board].reverse();
    const originalBoardLength = board.length;

    const isOpponentBoardLocal = !isMyBoard;

    const HEX_SIDE = 40;
    const HEX_WIDTH = Math.sqrt(3) * HEX_SIDE;
    const HEX_HEIGHT = 2 * HEX_SIDE;

    function getHexPosition(row, col, isCrystal, isMyBoard) {
      if (isCrystal) {
        let x = col * (CRYSTAL_SIZE + 5);
        const y = row * (CRYSTAL_SIZE + CRYSTAL_SPACING_Y);

        if (!isMyBoard) {
          x += OPPONENT_CRYSTAL_OFFSET_X;
        }

        return { x, y };
      } else {
        let x = col * HEX_WIDTH;
        let y = row * (HEX_HEIGHT * 0.75);

        const shouldOffset = isMyBoard ? row % 2 === 0 : row % 2 === 1;
        if (shouldOffset) {
          x += HEX_WIDTH / 2;
        }

        return { x, y };
      }
    }

    const totalCols = displayedBoard.length;
    const maxRows = Math.max(...displayedBoard.map(col => col.length));

    const containerWidth = (totalCols - 1) * HEX_WIDTH + HEX_WIDTH;
    const containerHeight = (maxRows - 1) * (HEX_HEIGHT * 0.75) + HEX_HEIGHT;

    return (
      <div
        style={{
          position: "relative",
          margin: "1rem auto",
          width: containerWidth,
          height: containerHeight,
        }}>
        {displayedBoard.map((column, cIdx) =>
          column.map((cell, rIdx) => {
            const trueColIndex = isMyBoard
              ? cIdx
              : originalBoardLength - 1 - cIdx;

            let boardName = "";
            if (amPlayer1) {
              boardName = isMyBoard ? "player1" : "player2";
            } else {
              boardName = isMyBoard ? "player2" : "player1";
            }
            const isAttacker = highlightedAttackers.some(
              c =>
                c.board === boardName &&
                c.col === trueColIndex &&
                c.row === rIdx
            );
            const isVictim = highlightedVictims.some(
              c =>
                c.board === boardName &&
                c.col === trueColIndex &&
                c.row === rIdx
            );

            const isValidMoveCell =
              isMyBoard &&
              validMoves.some(m => m.col === trueColIndex && m.row === rIdx);

            const baseColor =
              cell?.type === "crystal" || cell?.type === "DESTROYED"
                ? "#9ef"
                : "#ccc";
            let cellBackground = baseColor;

            if (isAttacker) {
              cellBackground = "orange";
            } else if (isVictim) {
              cellBackground = "blue";
            } else if (isValidMoveCell) {
              cellBackground = "lightgreen";
            }

            const isCrystal =
              cell?.type === "crystal" || cell?.type === "DESTROYED";
            const { x, y } = getHexPosition(rIdx, cIdx, isCrystal, isMyBoard);

            const cellContainerStyle = {
              position: "absolute",
              transform: `translate(${x}px, ${y}px)`,
              cursor: canClickCell(isMyBoard) ? "pointer" : "default",
              filter: isMyBoard && !isMyTurn ? "brightness(0.3)" : "none",
              zIndex: isCrystal ? 10 : 5,
            };

            return (
              <div
                key={`${cIdx}-${rIdx}`}
                style={cellContainerStyle}
                onClick={() => {
                  if (canClickCell(isMyBoard)) {
                    handleCellClick(trueColIndex, rIdx, cell, isMyBoard);
                  }
                }}>
                {isCrystal ? (
                  <SquareCell color={cellBackground}>
                    {renderCellContent(cell, rIdx, isOpponentBoardLocal)}
                  </SquareCell>
                ) : (
                  <HexCell color={cellBackground}>
                    {renderCellContent(cell, rIdx, isOpponentBoardLocal)}
                  </HexCell>
                )}
              </div>
            );
          })
        )}
      </div>
    );
  }

  function handleCellClick(columnIndex, rowIndex, cell, isMyBoard) {
    if (selectedCard) {
      setValidMoves([]);

      const cardDef = CARD_DATABASE[selectedCard.cardType];
      if (!cardDef) {
        console.log("Error: No definition found for selected card.");
        return;
      }

      if (cardDef.card_type === "event") {
        const { targeting } = cardDef;

        if (targeting === "twoEntities") {
          if (!cell || cell.type === "crystal" || cell.type === "DESTROYED") {
            alert(
              "This card requires targeting a valid entity (unit or structure)."
            );
            return;
          }

          if (selectedTargets.length < 1) {
            setSelectedTargets([
              { col: columnIndex, row: rowIndex, isMyBoard },
            ]);
            alert("First target selected. Please choose the second target.");
            return;
          }

          if (selectedTargets.length === 1) {
            const first = selectedTargets[0];
            if (
              first.col === columnIndex &&
              first.row === rowIndex &&
              first.isMyBoard === isMyBoard
            ) {
              alert("You've already selected that entity as the first target!");
              return;
            }

            const secondTarget = { col: columnIndex, row: rowIndex, isMyBoard };
            const allTargets = [first, secondTarget];

            handlePlaceUnitMultiTarget(allTargets);

            setSelectedTargets([]);
            return;
          }
        }

        if (targeting === "entity") {
          if (!cell || cell.type === "crystal" || cell.type === "DESTROYED") {
            alert(
              "This event requires targeting a valid entity (unit or structure)."
            );
            return;
          }
          handlePlaceUnit(columnIndex, rowIndex, isMyBoard);
          return;
        } else if (targeting === "none") {
          handlePlaceUnit(columnIndex, rowIndex, isMyBoard);
          return;
        }

        handlePlaceUnit(columnIndex, rowIndex, isMyBoard);
        return;
      }

      if (!cell) {
        handlePlaceUnit(columnIndex, rowIndex, isMyBoard);
      } else {
        console.log("Cannot place a unit on an occupied cell!");
      }
      return;
    }

    if (selectedUnit) {
      if (selectedUnit.col === columnIndex && selectedUnit.row === rowIndex) {
        setSelectedUnit(null);
        setValidMoves([]);
        return;
      }

      if (!cell) {
        ws.send(
          JSON.stringify({
            type: "game",
            action: "moveUnit",
            roomId,
            startCol: selectedUnit.col,
            startRow: selectedUnit.row,
            endCol: columnIndex,
            endRow: rowIndex,
          })
        );

        setHighlightedAttackers([]);
        setHighlightedVictims([]);
        setSelectedUnit(null);
        setValidMoves([]);
      } else {
        console.log("Target cell is occupied, cannot move here.");
      }
      return;
    }

    if (cell && cell.type !== "crystal" && cell.type !== "DESTROYED") {
      if (cell.currentMovement && cell.currentMovement > 0) {
        setSelectedUnit({ col: columnIndex, row: rowIndex });

        const moves = getValidAdjacentMoves(
          myBoard,
          columnIndex,
          rowIndex,
          cell.currentMovement
        );
        setValidMoves(moves);
      } else {
        console.log("That unit has no movement left or doesn't belong to you.");
      }
    } else {
      setValidMoves([]);
    }
  }

  function handlePlaceUnitMultiTarget(targets) {
    if (!selectedCard) return;
    if (!ws || !roomId) return;

    const { cardType } = selectedCard;

    const finalTargets = targets.map(t => {
      let boardName = "";
      if (amPlayer1) {
        boardName = t.isMyBoard ? "player1" : "player2";
      } else {
        boardName = t.isMyBoard ? "player2" : "player1";
      }
      return {
        columnIndex: t.col,
        cellIndex: t.row,
        targetBoard: boardName,
      };
    });

    ws.send(
      JSON.stringify({
        type: "game",
        action: "placeMultiTargetEvent",
        roomId,
        pieceType: cardType,
        targets: finalTargets,
      })
    );

    setHighlightedAttackers([]);
    setHighlightedVictims([]);
    setSelectedCard(null);
  }

  function renderCellContent(cell, rowIndex, isOpponentBoard) {
    if (!cell) return null;

    if (cell.type === "crystal") {
      const crystalImages = [
        CRYSTAL_01,
        CRYSTAL_02,
        CRYSTAL_03,
        CRYSTAL_04,
        CRYSTAL_05,
      ];

      const safeIndex = Math.max(
        0,
        Math.min(rowIndex, crystalImages.length - 1)
      );

      const crystalImage = crystalImages[safeIndex];

      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            textAlign: "center",
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}>
            <img
              src={crystalImage}
              alt={`Crystal for row ${rowIndex + 1}`}
              style={{
                maxWidth: "80%",
                maxHeight: "80%",
              }}
            />
          </div>

          <div
            style={{
              position: "absolute",
              bottom: "5px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "80%",
              zIndex: 9999,
              padding: "0px",
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2px",
              }}>
              {/* HEALTH */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.85)",
                  borderRadius: "4px",
                  padding: "1px 2px",
                  zIndex: 9999,
                }}>
                <img
                  src={HEALTH_SYMBOL}
                  alt="Health"
                  style={{
                    width: "10px",
                    height: "10px",
                    opacity: 0.85,
                  }}
                />
                <span
                  style={{
                    fontSize: "0.75rem",
                    color: "rgba(255, 255, 255, 0.85)",
                    marginLeft: "2px",
                    lineHeight: "1",
                  }}>
                  {cell.currentHealth}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (cell.type === "DESTROYED") {
      return (
        <div style={{ textAlign: "center" }}>
          <div style={{ color: "red", fontWeight: "bold" }}>DESTROYED</div>
          <div
            style={{ fontSize: "0.5rem", lineHeight: "1.2", color: "black" }}>
            X
          </div>
        </div>
      );
    }

    if (CARD_DATABASE && CARD_DATABASE[cell.type]) {
      const cardData = CARD_DATABASE[cell.type];

      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: 0,
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}>
            {cardData.unit_image ? (
              <img
                src={cardData.unit_image}
                alt={cell.type}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  transform: isOpponentBoard ? "scaleX(-1)" : "none",
                  position: "relative",
                  zIndex: 0,
                }}
              />
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "0.6rem",
                  color: "black",
                }}>
                {cardData.displayName || capitalizeFirstLetter(cell.type)}
              </div>
            )}
          </div>

          <div
            style={{
              transform: "translateX(-50%)",
              position: "absolute",
              bottom: "5px",
              left: "50%",
              width: "80%",
              zIndex: 9999,
              padding: "0px",
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2px",
              }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.85)",
                  borderRadius: "4px",
                  padding: "1px 2px",
                  zIndex: 9999,
                }}>
                <img
                  src={HEALTH_SYMBOL}
                  alt="Health"
                  style={{
                    width: "10px",
                    height: "10px",
                    opacity: 0.85,
                  }}
                />
                <span
                  style={{
                    fontSize: "0.75rem",
                    color: "rgba(255, 255, 255, 0.85)",
                    marginLeft: "2px",
                    lineHeight: "1",
                  }}>
                  {cell.currentHealth}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.85)",
                  borderRadius: "4px",
                  padding: "1px 2px",
                  zIndex: 9999,
                }}>
                <img
                  src={ATTACK_SYMBOL}
                  alt="Attack"
                  style={{
                    width: "10px",
                    height: "10px",
                    opacity: 0.85,
                  }}
                />
                <span
                  style={{
                    fontSize: "0.75rem",
                    color: "rgba(255, 255, 255, 0.85)",
                    marginLeft: "2px",
                    lineHeight: "1",
                  }}>
                  {cell.currentAttack}
                </span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "-1px",
                gap: "2px",
              }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.85)",
                  borderRadius: "4px",
                  padding: "1px 2px",
                  zIndex: 9999,
                }}>
                <img
                  src={COOLDOWN_SYMBOL}
                  alt="Cooldown"
                  style={{
                    width: "10px",
                    height: "10px",
                    opacity: 0.85,
                  }}
                />
                <span
                  style={{
                    fontSize: "0.75rem",
                    color: "rgba(255, 255, 255, 0.85)",
                    marginLeft: "2px",
                    lineHeight: "1",
                  }}>
                  {cell.currentCooldown}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <div>{cell.type}</div>;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function handleViewDiscardPile() {
    if (!ws || !roomId) return;
    ws.send(
      JSON.stringify({
        type: "game",
        action: "viewDiscard",
        roomId,
      })
    );
  }

  function handleViewCardPlayingLog() {
    if (!ws || !roomId) return;
    ws.send(
      JSON.stringify({
        type: "game",
        action: "viewCardPlayingLog",
        roomId,
      })
    );
  }

  function getValidAdjacentMoves(board, startCol, startRow, currentMovement) {
    if (!currentMovement || currentMovement < 1) return [];

    const evenOffsets = [
      [0, -1],
      [+1, -1],
      [-1, 0],
      [+1, 0],
      [0, +1],
      [+1, +1],
    ];

    const oddOffsets = [
      [-1, -1],
      [0, -1],
      [-1, 0],
      [+1, 0],
      [-1, +1],
      [0, +1],
    ];

    const rowIsEven = startRow % 2 === 0;
    const offsets = rowIsEven ? evenOffsets : oddOffsets;

    const validMoves = [];
    for (const [dc, dr] of offsets) {
      const newCol = startCol + dc;
      const newRow = startRow + dr;

      if (newCol < 0 || newCol >= board.length) continue;
      if (newRow < 0 || newRow >= board[newCol].length) continue;

      if (board[newCol][newRow] === null) {
        validMoves.push({ col: newCol, row: newRow });
      }
    }

    return validMoves;
  }

  function canClickCell(isMyBoard) {
    if (!isMyTurn) return false;

    if (selectedCard) {
      return true;
    }

    return isMyBoard;
  }
}

const cardPlayingLogModalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#fff",
  padding: "1rem",
  border: "1px solid #ccc",
  zIndex: 9999,
  color: "black",
  maxHeight: "80vh",
  overflowY: "auto",
  width: "90%",
  maxWidth: "500px",
};

const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#fff",
  padding: "1rem",
  border: "1px solid #ccc",
  zIndex: 9999,
  color: "black",
};

const cardStatsModalStyle = {
  position: "absolute",
  top: "100px",
  left: "50%",
  transform: "translateX(-50%)",
  margin: "1rem auto",
  padding: "0.2rem",
  maxWidth: "100%",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  borderRadius: "4px",
  zIndex: 50,
  color: "black",
};

const ActionButton = ({ onClick, disabled, ariaLabel, IconComponent }) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    ...styles.actionButton,
    cursor: disabled ? "not-allowed" : "pointer",
    opacity: disabled ? 0.5 : 1,
    color: disabled ? "#999" : "#000",
    transform: isHovered && !disabled ? "scale(1.5)" : "scale(1)",
  };

  return (
    <button
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      style={buttonStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <IconComponent style={styles.svgIcon} />
    </button>
  );
};

const styles = {
  actionButton: {
    background: "transparent",
    border: "none",
    alignItems: "center",
    justifyContent: "center",
    width: "60px",
    marginTop: "-200px",
    transition: "transform 0.2s",
  },
  svgIcon: {
    width: "50px",
    height: "50px",
    fill: "currentColor",
  },
  deckCount: {
    margin: "1rem 0",
    fontSize: "1.2rem",
    textAlign: "center",
  },
  hexBoardContainer: {
    position: "relative",
    margin: "1rem auto",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  lobbyContainer: {
    textAlign: "center",
  },
  turnIndicator: {
    margin: "1rem",
  },
  playerListContainer: {
    marginBottom: 20,
  },
  playerList: {
    width: "90%",
    height: 120,
    border: "1px solid #ccc",
    margin: "1rem auto",
    overflowY: "auto",
    padding: 10,
  },
  gameContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "90%",
    marginTop: 20,
  },
  boardWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 1.5rem",
  },
  boardContainer: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "0 5px",
  },
  actionPanel: {
    marginTop: 20,
    textAlign: "center",
  },
  handContainer: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    height: 200,
    marginTop: 10,
    justifyContent: "center",
  },
  card: {
    position: "relative",
    margin: "0 4px",
    transition: "transform 0.2s ease",
    boxSizing: "border-box",
    width: 125,
    height: 175,
    marginLeft: -40,
    border: "2px solid black",
    borderRadius: 4,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  cardImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
};

export default ParchmentGame;
