// InnMenu.jsx
import React from "react";

const InnMenu = ({ selectedButtonIndex, onSelect }) => {
  const innOptions = ["Rest", "Chat", "Leave Inn"];

  return (
    <div className="inn-menu">
      {innOptions.map((option, index) => (
        <button
          key={index}
          onClick={() => onSelect(index)}
          className={`couch-menu-button ${
            index === selectedButtonIndex ? "selected" : ""
          }`}>
          {option}
        </button>
      ))}
    </div>
  );
};

export default InnMenu;
